import { CodeFreezeSchedule, Marketplace, NavigationStatus, StageConfiguration } from './models';

export const ASSIGNMENT_MARKETPLACES: ReadonlyArray<Marketplace> = ['NA', 'EU', 'FE', 'IN'];
export const LOCAL_TESTING_DOMAIN = 'localhost.a2z.com';
export const UNITTEST_IDENTIFIER = 'unittest';
export const DEFAULT_LOCAL_DEV_IDENTIFIER = 'default-local';
export const MOCK_SERVER_DOMAIN = 'http://mock-server-domain';

export const stageConfigurations: StageConfiguration[] = [
  {
    id: DEFAULT_LOCAL_DEV_IDENTIFIER,
    domain: '',
    identityPoolId: 'us-west-2:e5be0cf5-9f0e-4a4c-b0be-fcb35f309ec6',
    apiGatewayBaseURL: 'http://localhost:8080',
    stage: 'devo',
    marketplace: 'NA',
    awsRegion: 'us-west-2',
  },
  {
    id: 'nqqin',
    domain: 'cm-nqqin.flex-routing.last-mile.amazon.dev',
    identityPoolId: 'us-west-2:e5be0cf5-9f0e-4a4c-b0be-fcb35f309ec6',
    apiGatewayBaseURL: 'https://9rriwz5tpg.execute-api.us-west-2.amazonaws.com/prod',
    stage: 'devo',
    marketplace: 'NA',
    awsRegion: 'us-west-2',
  },
  {
    id: 'rajurit',
    domain: 'cm-rajurit.flex-routing.last-mile.amazon.dev',
    identityPoolId: 'us-west-2:e5be0cf5-9f0e-4a4c-b0be-fcb35f309ec6',
    apiGatewayBaseURL: 'http://localhost:8080',
    stage: 'devo',
    marketplace: 'NA',
    awsRegion: 'us-west-2',
  },
  {
    id: 'dvelychk',
    domain: 'cm-dvelychk.flex-routing.last-mile.amazon.dev',
    identityPoolId: 'us-west-2:e50bfe0a-d255-4810-a271-d00080b00138',
    apiGatewayBaseURL: 'https://sfiwjhhsu9.execute-api.us-west-2.amazonaws.com/prod/',
    stage: 'devo',
    marketplace: 'NA',
    awsRegion: 'us-west-2',
  },
  {
    id: UNITTEST_IDENTIFIER,
    domain: 'localhost.a2z.com',
    identityPoolId: '',
    apiGatewayBaseURL: MOCK_SERVER_DOMAIN,
    awsRegion: 'us-west-2',
    stage: 'devo',
    marketplace: 'NA',
  },
  {
    id: 'beta.na',
    domain: 'cm-na-beta.flex-routing.last-mile.amazon.dev',
    identityPoolId: 'us-west-2:e5be0cf5-9f0e-4a4c-b0be-fcb35f309ec6',
    apiGatewayBaseURL: 'https://9rriwz5tpg.execute-api.us-west-2.amazonaws.com/prod',
    stage: 'beta',
    marketplace: 'NA',
    awsRegion: 'us-west-2',
  },
  {
    id: 'beta.eu',
    domain: 'cm-eu-beta.flex-routing.last-mile.amazon.dev',
    identityPoolId: 'eu-west-1:115b64ce-a4e3-4340-93c9-f7a642708716',
    apiGatewayBaseURL: 'https://exqucz1vi5.execute-api.eu-west-1.amazonaws.com/prod',
    stage: 'beta',
    marketplace: 'EU',
    awsRegion: 'eu-west-1',
  },
  {
    id: 'beta.fe',
    domain: 'cm-fe-beta.flex-routing.last-mile.amazon.dev',
    identityPoolId: 'us-west-2:9817064c-3392-41dd-916c-78198478e7e2',
    apiGatewayBaseURL: 'https://ial2qhovae.execute-api.us-west-2.amazonaws.com/prod',
    stage: 'beta',
    marketplace: 'FE',
    awsRegion: 'us-west-2',
  },
  {
    id: 'gamma.na',
    domain: 'cm-na-gamma.flex-routing.last-mile.amazon.dev',
    identityPoolId: 'us-east-1:3613810b-11ac-40fa-9486-dad1126c6868',
    apiGatewayBaseURL: 'https://l87zxwmaib.execute-api.us-east-1.amazonaws.com/prod',
    stage: 'gamma',
    marketplace: 'NA',
    awsRegion: 'us-east-1',
  },
  {
    id: 'gamma.eu',
    domain: 'cm-eu-gamma.flex-routing.last-mile.amazon.dev',
    identityPoolId: 'eu-west-1:17abe1ad-36b1-4e6d-a6bb-888b4a5fabf2',
    apiGatewayBaseURL: 'https://5h1109yogh.execute-api.eu-west-1.amazonaws.com/prod',
    stage: 'gamma',
    marketplace: 'EU',
    awsRegion: 'eu-west-1',
  },
  {
    id: 'gamma.in',
    domain: 'cm-in-gamma.flex-routing.last-mile.amazon.dev',
    identityPoolId: 'eu-west-1:a1f29a95-8102-4d75-9120-dbe1ced67285',
    apiGatewayBaseURL: 'https://ps921m9u86.execute-api.eu-west-1.amazonaws.com/prod',
    stage: 'gamma',
    marketplace: 'IN',
    awsRegion: 'eu-west-1',
  },
  {
    id: 'gamma.fe',
    domain: 'cm-fe-gamma.flex-routing.last-mile.amazon.dev',
    identityPoolId: 'us-west-2:c08b8795-94f1-4019-9e44-33b8644c3e44',
    apiGatewayBaseURL: 'https://sxv79lqmjj.execute-api.us-west-2.amazonaws.com/prod',
    stage: 'gamma',
    marketplace: 'FE',
    awsRegion: 'us-west-2',
  },
  {
    id: 'prod.na',
    domain: 'cm-na.flex-routing.last-mile.amazon.dev',
    identityPoolId: 'us-east-1:f1829a7a-4287-4366-8be1-c5eab23eb3e6',
    apiGatewayBaseURL: 'https://2j32elwma2.execute-api.us-east-1.amazonaws.com/prod',
    stage: 'prod',
    marketplace: 'NA',
    awsRegion: 'us-east-1',
  },
  {
    id: 'prod.eu',
    domain: 'cm-eu.flex-routing.last-mile.amazon.dev',
    identityPoolId: 'eu-west-1:76f37f95-0849-4a2b-abd1-ed15ca1e2215',
    apiGatewayBaseURL: 'https://30np1vaa4e.execute-api.eu-west-1.amazonaws.com/prod',
    stage: 'prod',
    marketplace: 'EU',
    awsRegion: 'eu-west-1',
  },
  {
    id: 'prod.in',
    domain: 'cm-in.flex-routing.last-mile.amazon.dev',
    identityPoolId: 'eu-west-1:010ca7e3-34d7-4418-a2d8-2c22b87f01bc',
    apiGatewayBaseURL: 'https://mjhnxtkude.execute-api.eu-west-1.amazonaws.com/prod',
    stage: 'prod',
    marketplace: 'IN',
    awsRegion: 'eu-west-1',
  },
  {
    id: 'prod.fe',
    domain: 'cm-fe.flex-routing.last-mile.amazon.dev',
    identityPoolId: 'us-west-2:1da0f4f9-ba56-4314-908b-63b389e1508f',
    apiGatewayBaseURL: 'https://sa1p40a8jc.execute-api.us-west-2.amazonaws.com/prod',
    stage: 'prod',
    marketplace: 'FE',
    awsRegion: 'us-west-2',
  },
];

export const UI_MODE_KEY = 'UI_MODE';
export const DEFAULT_UI_MODE = 'light';

export const UI_THEME_KEY = 'UI_THEME';
export const DEFAULT_UI_THEME = 'regular';

export const UI_DENSITY_KEY = 'UI_DENSITY';
export const DEFAULT_UI_DENSITY = 'comfortable';

export const OPERATING_MODE_KEY = 'OPERATING_MODE';
export const DEFAULT_OPERATING_MODE = 'regular';

export const PREFERRED_TIMEZONE_KEY = 'PREFERRED_TIMEZONE';
export const DEFAULT_TITLE = 'UFRAA Viz';

export const NAVIGATION_STATUS_KEY = 'NAVGIATION_STATUS';
export const DEFAULT_NAVIGATION_STATUS: NavigationStatus = { open: true };

export const COMPLETED_TUTORIALS = 'COMPLETED_TUTORIALS';

/**
 * https://freeze.tools.amazon.dev/
 *
 * An API is currently in the works.
 * We are overshooting the expected end by about a week.
 * This is because, in case of extensions, we cannot push an update.
 * But if it ends on time, we can push an update to remove any extra.
 */
export const CODE_FREEZE_SCHEDULES: ReadonlyArray<CodeFreezeSchedule> = [
  // NA
  {
    name: 'Lead to Thanksgiving Peak',
    status: 'restricted',
    startDate: '2023-11-20',
    endDate: '2023-11-22',
    marketplace: 'NA',
  },
  {
    name: 'Black Friday / Cyber Monday Peak',
    status: 'blocked',
    startDate: '2023-11-23',
    endDate: '2023-12-04',
    marketplace: 'NA',
  },
  {
    name: 'Lead to December Peak',
    status: 'restricted',
    startDate: '2023-12-05',
    endDate: '2023-12-08',
    marketplace: 'NA',
  },
  {
    name: 'December Peak',
    status: 'blocked',
    startDate: '2023-12-09',
    endDate: '2023-12-29',
    marketplace: 'NA',
  },
  {
    name: 'Prime Day',
    status: 'blocked',
    startDate: '2024-07-03',
    endDate: '2024-07-28',
    marketplace: 'NA',
  },
  // EU
  {
    name: 'Lead to Thanksgiving Peak',
    status: 'restricted',
    startDate: '2023-11-19',
    endDate: '2023-11-21',
    marketplace: 'EU',
  },
  {
    name: 'Black Friday / Cyber Monday Peak',
    status: 'blocked',
    startDate: '2023-11-22',
    endDate: '2023-12-04',
    marketplace: 'EU',
  },
  {
    name: 'Lead to December Peak',
    status: 'restricted',
    startDate: '2023-12-05',
    endDate: '2023-12-07',
    marketplace: 'EU',
  },
  {
    name: 'December Peak',
    status: 'blocked',
    startDate: '2023-12-08',
    endDate: '2023-12-29',
    marketplace: 'EU',
  },
  {
    name: 'Prime Day',
    status: 'blocked',
    startDate: '2024-07-03',
    endDate: '2024-07-28',
    marketplace: 'EU',
  },
  // FE
  {
    name: 'Lead to Thanksgiving Peak',
    status: 'restricted',
    startDate: '2023-11-19',
    endDate: '2023-11-21',
    marketplace: 'FE',
  },
  {
    name: 'Black Friday / Cyber Monday Peak',
    status: 'blocked',
    startDate: '2023-11-22',
    endDate: '2023-12-04',
    marketplace: 'FE',
  },
  {
    name: 'Lead to December Peak',
    status: 'restricted',
    startDate: '2023-12-05',
    endDate: '2023-12-07',
    marketplace: 'FE',
  },
  {
    name: 'December Peak',
    status: 'blocked',
    startDate: '2023-12-08',
    endDate: '2023-12-29',
    marketplace: 'FE',
  },
  {
    name: 'Prime Day',
    status: 'blocked',
    startDate: '2024-07-03',
    endDate: '2024-07-28',
    marketplace: 'FE',
  },
];

export const MARKETPLACE_TO_CODE_FREEZE_TIMEZONE: Record<Marketplace, string> = {
  NA: 'America/Los_Angeles',
  EU: 'Europe/London',
  IN: 'Asia/Kolkata',
  FE: 'Asia/Tokyo',
};
