import { ConfigTableDefinition } from '../../../models';

export const TRANSPORTER_CONFIGURATIONS: ConfigTableDefinition = {
  headerText: 'Transporter Configurations',
  description: 'Control transporter input to assignment algorithm.',
  valueColMinWidth: '150px',
  sizeOnGrid: 'default',
  items: [
    {
      field: 'useProviderSnapshot',
      scope: 'assignmentConfig',
      name: 'Use provider snapshot',
      description: 'Get transporters from ProviderSnapshot. Otherwise, read from Caspian snapshot.',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'planningHorizonStartInMinutesRelativeToPlanTime',
      scope: 'assignmentConfig',
      name: 'Planning horizon window start time',
      description: 'Filter out block drivers and routes that are outside planning horizon.',
      representationType: 'number',
      validators: [
        {
          name: 'ValueBetween-600And720Validator',
        },
      ],
    },
    {
      field: 'planningHorizonEndInMinutesRelativeToPlanTime',
      scope: 'assignmentConfig',
      name: 'Planning horizon window end time',
      representationType: 'number',
      validators: [
        {
          name: 'ValueBetween-300And720Validator',
        },
      ],
    },
    {
      field: 'populateOverflowTransporters',
      scope: 'assignmentConfig',
      name: 'Populate overflow transporters',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'maxNurseryProviderOnboardingDays',
      scope: 'assignmentConfig',
      name: 'Maximum Onboarding days for a nursery transporter',
      representationType: 'number',
      visibility: 'developer-only',
      validators: [
        {
          name: 'ValueBetween10And300Validator',
        },
      ],
    },
    {
      field: 'useAutoAssignDenylist',
      scope: 'assignmentConfig',
      name: 'Use Transporter Deny List',
      description: 'Filter out transporters from assignment planning based on transporter denylist',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'discardUnsupportedProviderTypesFromPlannerInput',
      scope: 'assignmentConfig',
      name: 'Completely filter out DSP drivers',
      description: 'If enabled, DSP drivers will be completely removed from the planner input. The feature is to reduce the planner input size.',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'previousCycleTransporterFilterEnabled',
      scope: 'assignmentConfig',
      name: 'Filter out drivers from previous cycles',
      description: 'If enabled, we only keep Flex drivers in the latest cycle. The latest cycle is determined based on available routes.',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
  ],
};
