/**
 * path /service-areas
 * This component shows a list of LMDP enabled service areas. It's a nagivation page that direct users to each service area details page.
 */
import React from 'react';
import { ServiceAreaList as MainContent } from '../../common-components/service-area-list/service-area-list';
import { GlobalContext } from '../../main-app/global-context';
import { readable } from '../../utilities';
import { commonClient, routingClient } from '../../http-clients';
import { ServiceAreaInfo } from '../../models';

const breadcrumbs = [
  {
    text: 'Routing Service Areas',
    href: '/service-areas',
  },
];

interface RoutingServiceAreaListProps {}

interface RoutingServiceAreaListState {
  readonly serviceAreas?: ReadonlyArray<ServiceAreaInfo>;
  readonly isLoading: boolean;
}
/**
 * GSF Routing service area list.
 */
export class RoutingServiceAreaList extends React.Component<RoutingServiceAreaListProps, RoutingServiceAreaListState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: RoutingServiceAreaListProps) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.updateBreadcrumbItems(breadcrumbs);
    this.context.setTools('info-panel');

    try {
      this.setState({ isLoading: true });
      const serviceAreaListResp = await commonClient.listServiceAreas();
      const routingServiceAreasResp = await routingClient.listRoutingServiceAreas();
      const routingSaIds = new Set(routingServiceAreasResp.serviceAreaIds);
      this.setState({
        serviceAreas: serviceAreaListResp.serviceAreas.filter((sa) => routingSaIds.has(sa.serviceAreaId)),
      });
    } catch (err: any) {
      this.context.addNotification({
        type: 'error',
        header: 'Error',
        content: `Failed to load service areas due to ${readable(err)}`,
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <MainContent
        header="Routing Service Areas"
        serviceAreas={this.state.serviceAreas}
        onServiceAreaClick={(serviceArea) => {
          this.context.onGoto(`/service-areas/${serviceArea.serviceAreaId}`);
        }}
        buildHref={(serviceArea) => `/service-areas/${serviceArea.serviceAreaId}`}
      />
    );
  }
}
