export class PreferencesStore {
  set<T>(key: string, data: T) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  get<T>(key: string, default_: T): T {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    } else {
      return default_;
    }
  }
}
