import { ConfigTableDefinition } from '../../../models';

export const CYCLE_RELATED_CONFIGURATIONS: ConfigTableDefinition = {
  headerText: 'Cycle Related Configurations',
  description: 'Cycle related configurations for AMZL auto assign',
  valueColMinWidth: '150px',
  sizeOnGrid: 'default',
  visibility: 'developer-only',
  items: [
    {
      field: 'missingCycleIdFilterEnabled',
      scope: 'assignmentConfig',
      name: 'Filter out route and transporter without cycle',
      description: "Cycle matching is a hard constraint in AMZL assignment, filter out route and transporter if they don't have a cycle.",
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'cycleCapacitiesEnabled',
      scope: 'assignmentConfig',
      name: 'Enable cycle constraint',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
  ],
};
