import React from 'react';
import Table from '@amzn/awsui-components-react-v3/polaris/table';
import FormField from '@amzn/awsui-components-react-v3/polaris/form-field';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import { InputCell } from '../input-cell/input-cell';
import { InputTableItem, InputTableProps, InputTableState } from './models';
import styles from './input-table.module.scss';
import lodash from 'lodash';

export class InputTable extends React.Component<InputTableProps, InputTableState> {
  render() {
    return (
      <Table<InputTableItem>
        header={
          <Header variant="h3" description={this.props.description}>
            {this.props.headerText}
          </Header>
        }
        columnDefinitions={[
          {
            id: 'name',
            header: this.props.nameColHeaderText ? this.props.nameColHeaderText : 'Parameter',
            cell: (item) => {
              if (this.props.showField) {
                return (
                  <FormField
                    constraintText={
                      <Box>
                        <Box color="text-body-secondary" fontSize="body-s" margin={{ bottom: 'xxs' }}>
                          {item.description}
                        </Box>
                        <Box variant="code" fontSize="body-s" fontWeight="light" className={styles['field']}>
                          {lodash.last(item.field.split('.'))}
                        </Box>
                      </Box>
                    }
                  >
                    {item.name}
                  </FormField>
                );
              }

              return <FormField constraintText={item.description}>{item.name}</FormField>;
            },
            minWidth: this.props.nameColMinWidth,
          },
          {
            id: 'value',
            header: this.props.valueColHeaderText ? this.props.valueColHeaderText : 'Value',
            minWidth: this.props.valueColMinWidth,
            cell: (item) => (
              <InputCell
                field={item.field}
                value={item.value}
                scope={item.scope}
                representationType={item.representationType}
                dataType={item.dataType}
                isEditing={this.props.isEditing}
                inputValidator={item.inputValidator}
                notifyValidationResult={this.props.notifyValidationResult}
                notifyUpdate={this.props.notifyUpdate}
              />
            ),
          },
        ]}
        items={this.props.items}
      />
    );
  }
}
