import React from 'react';
import ColumnLayout from '@amzn/awsui-components-react-v3/polaris/column-layout';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Checkbox from '@amzn/awsui-components-react-v3/polaris/checkbox';
import Container from '@amzn/awsui-components-react-v3/polaris/container';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import { OrderType } from '../../../../models/routing-models';
import { ORDER_TYPE_OPTIONS, ORDER_TYPE_TO_LABEL } from '../constants';
import { OrderTypeUpdate } from './models';

interface IsolatedOrderTypesTableProps {
  readonly isolatedOrderTypes: ReadonlyArray<OrderType>;
  readonly isEditing: boolean;
  readonly onUpdate: (orderTypeUpdate: OrderTypeUpdate) => void;
}

interface IsolatedOrderTypesTableState {}

export class IsolatedOrderTypesTable extends React.Component<IsolatedOrderTypesTableProps, IsolatedOrderTypesTableState> {
  render() {
    return (
      <Container
        disableContentPaddings
        header={
          <Header variant="h3" description="Isolated order types is a soft constraint, where routing system tries to isolate these orders from other orders and put them into separate routes.">
            Isolated Order Types
          </Header>
        }
      >
        {this.props.isEditing ? this.renderInputContents() : this.renderContents()}
      </Container>
    );
  }

  private renderContents() {
    return (
      <ColumnLayout columns={1} borders={'all'} disableGutters={true}>
        {this.props.isolatedOrderTypes.map((orderType, i) => {
          return (
            <Box key={i} padding={{ vertical: 'xs', horizontal: 'l' }}>
              {ORDER_TYPE_TO_LABEL[orderType]}
            </Box>
          );
        })}
      </ColumnLayout>
    );
  }

  private renderInputContents() {
    return (
      <ColumnLayout columns={1} borders={'all'} disableGutters={true}>
        {ORDER_TYPE_OPTIONS.map((option, i) => {
          return (
            <Box key={i} padding={{ vertical: 'xs', horizontal: 'l' }}>
              <Checkbox
                checked={this.props.isolatedOrderTypes.includes(option.id)}
                onChange={(evt) =>
                  this.props.onUpdate({
                    orderType: option.id,
                    select: evt.detail.checked,
                  })
                }
              >
                {option.id === 'HOTWHEELS' ? 'HOTWHEELS (Always isolated)' : option.text}
              </Checkbox>
            </Box>
          );
        })}
      </ColumnLayout>
    );
  }
}
