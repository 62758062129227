import React from 'react';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import FormField from '@amzn/awsui-components-react-v3/polaris/form-field';
import Input from '@amzn/awsui-components-react-v3/polaris/input';
import Modal from '@amzn/awsui-components-react-v3/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';

interface Props {
  readonly serviceAreaId: string;
  readonly isSaving: boolean;
  readonly onCancel: () => void;
  readonly onUpdate: (description: string) => Promise<void>;
}

interface State {
  readonly configUpdateDescription: string;
  readonly errorHint?: string;
}

export class ConfigUpdateConfirmModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      configUpdateDescription: '',
    };
  }

  render() {
    return (
      <Modal
        visible={true}
        header="Confirm the change"
        onDismiss={() => this.props.onCancel()}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button disabled={this.props.isSaving} onClick={() => this.props.onCancel()}>
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={this.props.isSaving}
                onClick={async () => {
                  if (this.state.configUpdateDescription.length < 5) {
                    this.setState({ errorHint: 'At least 5 characters' });
                    return;
                  } else if (this.state.configUpdateDescription.length > 200) {
                    this.setState({ errorHint: 'At most 200 characters' });
                    return;
                  } else {
                    this.setState({ errorHint: undefined });
                  }
                  await this.props.onUpdate(this.state.configUpdateDescription);
                }}
              >
                Confirm
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <FormField label="Description" description="The information will be used for auditing purpose. At least 5 characters" errorText={this.state.errorHint}>
          <Input
            value={this.state.configUpdateDescription}
            onChange={(evt) => {
              this.setState({ configUpdateDescription: evt.detail.value });
            }}
          />
        </FormField>
      </Modal>
    );
  }
}
