import React from 'react';

import Header from '@amzn/awsui-components-react-v3/polaris/header';
import Table from '@amzn/awsui-components-react-v3/polaris/table';
import TextFilter from '@amzn/awsui-components-react-v3/polaris/text-filter';
import Pagination from '@amzn/awsui-components-react-v3/polaris/pagination';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { EnrichedDriaScopeInfo } from './models';
import { buildTableDefinition } from './build-table-definition';
import { buildDriaScopeDetailsUrl } from './utilities';

interface DriaScopeTableProps {
  readonly items?: ReadonlyArray<EnrichedDriaScopeInfo>;
  readonly onScopeClick: (scope: EnrichedDriaScopeInfo) => void;
}

/**
 * ToDo:
 *  1. Improve the filtering logic with affinity based
 * https://code.amazon.com/packages/GSFRoutingConfigurationsManagerFrontendUI/blobs/1872003d13a0ac3dca415149c2a8506a9ad200e6/--/src/common-components/service-area-list/utilities.tsx#L55
 *  2. Support filtering by enabled snapshots.
 * @param item
 * @param text
 * @returns
 */
const filter = (item: EnrichedDriaScopeInfo, text: string) => {
  text = text.toLowerCase();
  return item.name?.toLowerCase().includes(text) || item.stationCode?.toLowerCase().includes(text) || item.scopeType.toLowerCase().includes(text) || item.scopeId.toLowerCase().includes(text);
};

export function DriaScopeTable(props: DriaScopeTableProps) {
  const { items, collectionProps, filterProps, paginationProps } = useCollection<EnrichedDriaScopeInfo>(props.items ?? [], {
    filtering: { filteringFunction: filter },
    pagination: { pageSize: 30 },
    sorting: {},
  });

  return (
    <Table
      loading={props.items === undefined}
      loadingText="Loading..."
      {...collectionProps}
      header={<Header variant="h2">DRIA Regions and Service Areas</Header>}
      columnDefinitions={buildTableDefinition({
        onScopeClick: (scope) => props.onScopeClick(scope),
        buildHref: (scope) => buildDriaScopeDetailsUrl(scope),
      })}
      items={items}
      pagination={
        <Pagination
          {...paginationProps}
          ariaLabels={{
            pageLabel: (idx) => `Page ${idx} of ${paginationProps.pagesCount}.`,
          }}
        />
      }
      filter={<TextFilter {...filterProps} filteringAriaLabel="Filter scopes" />}
    />
  );
}
