import React from 'react';
import Grid, { GridProps } from '@amzn/awsui-components-react-v3/polaris/grid';

export type SizeOnGrid = 'default' | 'wide';
const gridSizes: ReadonlyArray<SizeOnGrid> = ['default', 'wide'];

export interface GridSizeDefaultProp {
  readonly sizeOnGrid: SizeOnGrid;
}

function isGridSize(val: string): val is SizeOnGrid {
  return gridSizes.includes(val as any);
}

const LOOKUP: Record<SizeOnGrid, GridProps.ElementDefinition> = {
  //   default: col-12 col-s-6 col-l-4 col-xl-4    for most things
  //   wide:    col-12         col-l-8 col-xl-8    for PlanningHorizonOverrides
  default: { colspan: { default: 12, s: 6, l: 4 } },
  wide: { colspan: { default: 12, l: 8 } },
};

export interface ConfigGridProps {}

// Default Grid takes
//   list of sizes + list of children
// This ConfigGrid takes
//   list of children, where each child specifies its own size
class ConfigGrid extends React.Component<ConfigGridProps, {}> {
  render() {
    const gridDefinition: GridProps.ElementDefinition[] = [];
    React.Children.forEach(this.props.children, (element) => {
      if (React.isValidElement(element)) {
        let size: SizeOnGrid = 'default';
        if (isGridSize(element.props.sizeOnGrid)) {
          size = element.props.sizeOnGrid;
        }
        gridDefinition.push(LOOKUP[size]);
      }
    });

    return <Grid gridDefinition={gridDefinition}>{this.props.children}</Grid>;
  }
}

export default ConfigGrid;
