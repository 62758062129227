import React from 'react';
import lodash from 'lodash';
import Checkbox from '@amzn/awsui-components-react-v3/polaris/checkbox';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Table from '@amzn/awsui-components-react-v3/polaris/table';
import RadioGroup from '@amzn/awsui-components-react-v3/polaris/radio-group';
import { CapacityConstraint, CapacityType } from '../../../../models/routing-models';
import { IntegerInput } from '../../../../common-components/numeric-input';
import { CAPACITY_TYPE_TO_CAPACITY_OPTION } from './constants';
import { CapacityConstraintUpdate, CapacityOption } from './models';
import { findUnitByName } from './utilities';

interface EditableConstraintItem {
  capacityOption: CapacityOption;
  enabled: boolean;
  value?: number;
  unit?: string;
}

interface CapacityConstraintsEditorProps {
  readonly capacityConstraints: ReadonlyArray<CapacityConstraint>;
  readonly onUpdate: (capacityConstraintUpdate: CapacityConstraintUpdate) => void;
}

interface CapacityConstraintsEditorState {}

export class CapacityConstraintsEditor extends React.Component<CapacityConstraintsEditorProps, CapacityConstraintsEditorState> {
  private readonly constraintItems: Partial<Record<CapacityType, EditableConstraintItem>> = {};

  constructor(props: CapacityConstraintsEditorProps) {
    super(props);
    Object.values(CAPACITY_TYPE_TO_CAPACITY_OPTION).forEach((capacityOption) => {
      const data = this.props.capacityConstraints.find((constraint) => constraint.type === capacityOption.value);
      this.constraintItems[capacityOption.value] = {
        capacityOption: capacityOption,
        enabled: data !== undefined,
        value: data?.value,
        unit: data?.unit,
      };
    });
  }

  private updateRow(item: EditableConstraintItem) {
    this.constraintItems[item.capacityOption.value] = item;
    this.props.onUpdate({
      enabled: item.enabled,
      value: item.value ?? 0,
      unit: item.unit ?? item.capacityOption.defaultUnit,
      type: item.capacityOption.value,
    });
  }

  render() {
    return (
      <Table
        header={<Header variant="h3">Route Capacity Limit</Header>}
        columnDefinitions={[
          {
            id: 'constraint',
            header: 'Constraint ',
            cell: (item) => (
              <Checkbox
                checked={item.enabled}
                onChange={(evt) => {
                  item.enabled = evt.detail.checked;
                  this.updateRow(item);
                }}
              >
                {item.capacityOption.label}
              </Checkbox>
            ),
          },
          {
            id: 'value',
            header: 'Value',
            cell: (item) => (
              <IntegerInput
                disabled={!item.enabled}
                startingText={item.value?.toString() ?? '0'}
                minimum={0}
                onChange={(value) => {
                  item.value = value;
                  this.updateRow(item);
                }}
              />
            ),
          },
          {
            id: 'unit',
            header: 'Unit',
            cell: (item) => (
              <SpaceBetween direction="horizontal" size="xxs">
                <RadioGroup
                  onChange={({ detail }) => {
                    item.unit = detail.value;
                    this.updateRow(item);
                  }}
                  value={findUnitByName(item.unit, item.capacityOption)?.value ?? null}
                  items={item.capacityOption.units
                    .filter((unit) => !unit.deprecated || this.props.capacityConstraints.find((constraint) => constraint.unit === unit.value))
                    .map((unit) => {
                      return { ...unit, disabled: !item.enabled };
                    })}
                />
              </SpaceBetween>
            ),
          },
        ]}
        items={lodash.sortBy(Object.values(this.constraintItems), (item) => item.capacityOption.label)}
      />
    );
  }
}
