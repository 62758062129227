import { CapacityConstraintUpdate } from '../capacity-constraints';
import { CapacityConstraint, ExecutorNameToRouteConstraint, ServiceAreaConfig } from '../../../../models/routing-models';

import { DEFAULT_EXECUTOR_NAME } from './mmot-constraints';
import { MMOTConstraintUpdate, MMOTTableRow } from './models';
import { isNotNil } from '../../../../utilities';

export function handleMmotConstraintUpdate(serviceAreaConfig: ServiceAreaConfig, update: MMOTConstraintUpdate): ServiceAreaConfig {
  let namesToUpdate: string[] = [update.tableRow?.executorName];

  if (update.tableRow?.default) {
    namesToUpdate.push(DEFAULT_EXECUTOR_NAME);
  }

  let mmotConstraints: ExecutorNameToRouteConstraint = {};
  if (isNotNil(serviceAreaConfig.routeConstraintsConfig)) {
    Object.entries(serviceAreaConfig.routeConstraintsConfig).forEach((entry) => {
      const executorName = entry[0];
      const routeConstraint = entry[1];

      if (!namesToUpdate.includes(executorName)) {
        mmotConstraints[executorName] = routeConstraint;
      }
    });
  }

  if (Object.keys(mmotConstraints).length == 0) {
    namesToUpdate.push(DEFAULT_EXECUTOR_NAME);
    update.tableRow.default = true;
    update.tableRow.overflow = true;
  }

  namesToUpdate.forEach((name) => updateSingleRow(mmotConstraints, name, update));

  return {
    ...serviceAreaConfig,
    routeConstraintsConfig: mmotConstraints,
  };
}

function updateSingleRow(mmotConstraints: ExecutorNameToRouteConstraint, executorName: string, update: MMOTConstraintUpdate) {
  if (update.updateType == 'ADD' || update.updateType == 'UPDATE') {
    if (update.tableRow === undefined) {
      console.warn('Skipping MMOT table update.');
      return;
    }
    mmotConstraints[executorName] = {
      executorName: update.tableRow?.executorName,
      overflowEligible: update.tableRow?.overflow,
      serviceFactor: update.tableRow?.serviceFactor,
      transitFactor: update.tableRow?.transitFactor,
      capacityConstraints: update.tableRow?.capacityConstraints,
    };
  } else if (update.updateType == 'REMOVE') {
    delete mmotConstraints[executorName];
  }
}

export function updateRowCapacityConstraints(row: MMOTTableRow, update: CapacityConstraintUpdate) {
  let capacityConstraints: CapacityConstraint[] = [];
  if (row.capacityConstraints !== undefined) {
    // keep the existing fields that haven't just been updated
    capacityConstraints = row.capacityConstraints.filter((constraint) => constraint.type !== update.type);
  }

  if (update.enabled) {
    capacityConstraints.push({
      value: update.value,
      unit: update.unit,
      type: update.type,
    });
  }
  return { ...row, capacityConstraints: capacityConstraints };
}
