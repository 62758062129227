import { DEFAULT_LOCAL_DEV_IDENTIFIER, UNITTEST_IDENTIFIER } from '../constants';
import { Environment } from '../models';

export class EnvironmentAccessor {
  static IDENTIFIER_ENV_KEY = 'STAGE_ID';
  getEnvironment(): Environment {
    let identifier = process.env.NODE_ENV === 'test' ? UNITTEST_IDENTIFIER : process.env[EnvironmentAccessor.IDENTIFIER_ENV_KEY];
    if (typeof identifier !== 'string') {
      // If you don't explicitly configure the `STAGE_ID` environment variable, the DEFAULT_LOCAL_DEV_IDENTIFIER will be used.
      // the identifier is used to select the stage configuration for your development environment.
      identifier = DEFAULT_LOCAL_DEV_IDENTIFIER;
    }
    return {
      domain: window.location.hostname,
      identifier: identifier,
    };
  }
}
