import React from 'react';

import { GlobalContext } from '../../main-app/global-context';
import { readable } from '../../utilities';

import { commonClient } from '../../http-clients';
import { ServiceArea } from '../../common-components/config-profile-panel';
import ConfigSchedulePanel from '../../common-components/config-schedule';
import { ServiceAreaInfo } from '../../models';

const breadcrumbs = [
  {
    text: 'Config Schedules',
    href: '/config-schedules',
  },
];

interface ConfigSchedulesProps {}

interface ConfigSchedulesState {
  readonly showConfirmationModal: boolean;
  readonly selectedServiceAreaIds: ReadonlyArray<string>;
  readonly serviceAreas: ReadonlyArray<ServiceAreaInfo>;
  readonly isLoadingServiceAreas: boolean;
  readonly filteredServiceAreas?: ReadonlyArray<ServiceArea>;
}

export class ConfigSchedules extends React.Component<ConfigSchedulesProps, ConfigSchedulesState, ConfigSchedulePanel> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: ConfigSchedulesProps) {
    super(props);
    this.state = {
      showConfirmationModal: false,
      selectedServiceAreaIds: [],
      isLoadingServiceAreas: false,
      filteredServiceAreas: [],
      serviceAreas: [],
    };
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.setTools('info-panel');
    this.context.updateBreadcrumbItems(breadcrumbs);
    this.loadingServiceAreas();
  }

  private async loadingServiceAreas() {
    try {
      this.setState({ isLoadingServiceAreas: true });
      const resp = await commonClient.listServiceAreas();
      this.setState({
        serviceAreas: resp.serviceAreas,
      });
    } catch (err) {
      this.context.addNotification({
        header: 'Error',
        dismissible: true,
        type: 'error',
        content: `Failed to load service areas due to ${readable(err)}`,
      });
    } finally {
      this.setState({ isLoadingServiceAreas: false });
    }
  }

  render() {
    return <ConfigSchedulePanel isLoadingServiceAreas={this.state.isLoadingServiceAreas} serviceAreas={this.state.serviceAreas} hasPermission={true} requestPermissionMessage={`ToDo`} />;
  }
}
