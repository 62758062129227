import { OrderType, orderTypes } from '../../../models/routing-models/service-area-config';

export const ORDER_TYPE_TO_LABEL: Record<OrderType, string> = {
  ONE_HOUR_FIXED: 'One Hour Fixed',
  ONE_HOUR_RUSH: 'One Hour Rush',
  TWO_HOUR_FIXED: 'Two Hour Fixed',
  FSM: 'FSM',
  FRESH: 'Fresh',
  HOTWHEELS: 'Hotwheels',
  ROAR: 'ROAR',
  ROAR_ONE_DAY_CORE: 'ROAR One Day Core',
  OTHER: 'Other',
};

export const ORDER_TYPE_OPTIONS: ReadonlyArray<{ readonly id: OrderType; readonly text: string }> = orderTypes.map((type) => ({ id: type, text: ORDER_TYPE_TO_LABEL[type] }));
