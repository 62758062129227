import React from 'react';

import Header from '@amzn/awsui-components-react-v3/polaris/header';
import Container from '@amzn/awsui-components-react-v3/polaris/container';
import ColumnLayout from '@amzn/awsui-components-react-v3/polaris/column-layout';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Spinner from '@amzn/awsui-components-react-v3/polaris/spinner';
import StatusIndicator from '@amzn/awsui-components-react-v3/polaris/status-indicator';
import { AssignmentConfig } from '../../models/assignment-models';
import { ServiceArea } from '../../models';
import { Clock } from '../../common-components/clock';
import { CopyText } from '../../common-components/copy-text';
import { ServiceAreaId, ServiceAreaName, StationTime, StationTimezone } from '../../common-components/service-area-items';

interface AssignmentConfigSummaryProps {
  readonly assignmentConfig?: AssignmentConfig;
  readonly planExecutorConsumeLmdpPlan?: boolean;
  readonly serviceArea?: ServiceArea;
}

interface AssignmentConfigSummaryState {}

export class AssignmentConfigSummary extends React.Component<AssignmentConfigSummaryProps, AssignmentConfigSummaryState> {
  render() {
    return <Container header={this.renderHeader()}>{this.renderContent()}</Container>;
  }

  private renderHeader() {
    return <Header variant="h2">Summary</Header>;
  }

  private renderContent() {
    return (
      <ColumnLayout columns={4} variant="text-grid">
        <ServiceAreaName serviceAreaName={this.props.serviceArea?.serviceAreaName} />
        <ServiceAreaId serviceAreaId={this.props.serviceArea?.serviceAreaId} />
        <StationTimezone timezone={this.props.serviceArea?.timeZone} />
        <StationTime timezone={this.props.serviceArea?.timeZone} />
        <Box>
          <Box margin={{ bottom: 'xxxs' }} color="text-label">
            Operating mode
          </Box>
          <Box margin={{ bottom: 'xxxs' }}>{this.renderOperatingMode()}</Box>
        </Box>
        <Box>
          <Box margin={{ bottom: 'xxxs' }} color="text-label">
            LMDP/PE Interaction
          </Box>
          <Box margin={{ bottom: 'xxxs' }}>{this.props.planExecutorConsumeLmdpPlan ? 'PE consumes LMDP Plan (Legacy LMDP AutoAssign)' : 'None'}</Box>
        </Box>

        <Box>
          <Box margin={{ bottom: 'xxxs' }} color="text-label">
            Executor
          </Box>
          <Box margin={{ bottom: 'xxxs' }}>{this.renderExecutor()}</Box>
        </Box>
        <Box>
          <Box margin={{ bottom: 'xxxs' }} color="text-label">
            Algorithm
          </Box>
          <Box margin={{ bottom: 'xxxs' }}>{this.renderAssignmentAlgorithm()}</Box>
        </Box>
      </ColumnLayout>
    );
  }

  private renderOperatingMode() {
    if (this.props.assignmentConfig === undefined) {
      return <Spinner />;
    }
    const mode = this.props.assignmentConfig.assignmentMode;

    switch (mode) {
      case 'DISABLED':
        return (
          <StatusIndicator type="stopped" colorOverride="red">
            Disabled
          </StatusIndicator>
        );
      case 'ENABLED':
        return <StatusIndicator type="success">Enabled</StatusIndicator>;
      case 'SHADOW_MODE':
        return <StatusIndicator type="info">Shadow</StatusIndicator>;
      default:
        return <StatusIndicator type="warning">{mode}</StatusIndicator>;
    }
  }

  private renderExecutor() {
    if (this.props.assignmentConfig === undefined) {
      return <Spinner />;
    }
    const executor = this.props.assignmentConfig.assignmentExecutor;

    switch (executor) {
      case 'CORE_PLAN_EXECUTOR':
        return <Box>Plan Executor (PE)</Box>;
      case 'ROUTE_DISPATCH_MANAGER':
        return <Box>Route Dispatch Manager (RDM)</Box>;

      default:
        return <Box>{executor}</Box>;
    }
  }

  private renderAssignmentAlgorithm() {
    if (this.props.assignmentConfig === undefined) {
      return <Spinner />;
    }
    const handler = this.props.assignmentConfig.dispatchPlannerType;

    switch (handler) {
      case 'FLEX_DISPATCH_PLANNER':
        return <Box>Flex Dispatch Planner (FDP)</Box>;
      case 'FUNGIBLE_ASSIGNMENT_ALGORITHM':
        return <Box>Fungible Algorithm</Box>;
      case 'SIMPLE_DISPATCH_PLANNER':
        return <Box>Simple Dispatch Planner</Box>;
      default:
        return <Box>{handler}</Box>;
    }
  }

  private renderText(text?: string, copyable?: boolean) {
    if (typeof text === 'string') {
      if (copyable) {
        return <CopyText copyText={text} />;
      } else {
        return <Box>{text}</Box>;
      }
    } else {
      return <Spinner />;
    }
  }

  private renderStationClock(timezone?: string) {
    if (typeof timezone === 'string') {
      return <Clock timezone={timezone} />;
    } else {
      return <Spinner />;
    }
  }
}
