// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vuNAYftdWl3wkbB5LMtDkw\\=\\={background-color:var(--color-background-button-normal-hover-yqjern, #fafafa) !important;border:1px solid var(--color-background-cell-shaded-zw6xp4, #f2f3f3) !important;border-radius:2px;padding-left:2px;padding-right:2px}", "",{"version":3,"sources":["webpack://./src/common-components/input-table/input-table.module.scss"],"names":[],"mappings":"AAEA,4BACI,uFAAA,CACA,+EAAA,CACA,iBAAA,CACA,gBAAA,CACA,iBAAA","sourcesContent":["@use '~@amzn/awsui-design-tokens/polaris' as awsui;\n\n.field {\n    background-color: awsui.$color-background-button-normal-hover !important;\n    border: 1px solid awsui.$color-background-cell-shaded !important;\n    border-radius: 2px;\n    padding-left: 2px;\n    padding-right: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": "vuNAYftdWl3wkbB5LMtDkw=="
};
export default ___CSS_LOADER_EXPORT___;
