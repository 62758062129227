import React from 'react';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import StatusIndicator from '@amzn/awsui-components-react-v3/polaris/status-indicator';
import InputTable from '../../common-components/input-table';
import ConfigGrid from '../../common-components/config-grid';
import {
  PlanningHorizonOverridesTable,
  deleteOverrides as deletaPlanningHorizonConfigOverrides,
  addOverride as addPlanningHorizonConfigOverride,
  updateStagedPlanningHorizon,
  updateNonStagedPlanningHorizon,
  updateLocking,
} from './tables/planning-horizon-overrides';
import { IsolatedOrderTypesTable, updateIsolatedOrderTypes } from './tables/isolated-order-types';
import { PlaceholderTRSizeEstimationTable, updatePlaceholderTRDefaults } from './tables/placeholder-tr-size-estimation';
import {
  AccessPointConfigsTable,
  PickupStartOffsetConfigItem,
  convertAccessPointConfiguration,
  deleteItems as deleteAccessPointConfigItems,
  addItem as addAccessPointConfigItem,
  updateTRPickupOffset,
} from './tables/access-point-configs';
import { CapacityConstraintsTable } from './tables/capacity-constraints';
import {
  DeliveryWindowOverridesTable,
  addOverride as addDeliveryWindowOverride,
  deleteOverrides as deleteDeliveryWindowOverrides,
  updateStartTime as updateDeliveryWindowOverrideStartTime,
  updateEndTime as updateDeliveryWindowOverrideEndTime,
} from './tables/delivery-window-overrides';

import { Access, ConfigTableDefinition } from '../../models';
import * as tableDefinitions from './table-definitions';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import { GlobalContext } from '../../main-app/global-context';
import { convertConfigToItem } from './utilities';
import { updateGSFRoutingConfigurations } from './table-definitions/state-utilities';
import { handleCapacityConstraintUpdate } from './tables/capacity-constraints';
import { InputUpdate, ValidationResult } from '../../common-components/input-cell';
import { MMOTConstraintsTable } from './tables/mmot-constraints';
import { handleMmotConstraintUpdate } from './tables/mmot-constraints/state-utilities';
import { AccessPoint, AlgorithmSettings, ServiceAreaConfig } from '../../models/routing-models';
import Select, { SelectProps } from '@amzn/awsui-components-react-v3/polaris/select';
import PermissionTableModal from '../../common-components/permission-table-modal';

export interface RoutingConfigFormEditorProps {
  readonly serviceAreaConfig?: ServiceAreaConfig;
  readonly algorithmSettings?: AlgorithmSettings;
  readonly userAccesses?: ReadonlyArray<Access>;
  readonly accessPoints?: ReadonlyArray<AccessPoint>;
  readonly operatingMode: 'developer' | 'regular';
  readonly isSaving: boolean;
  readonly isEditing: boolean;

  /**
   * Method is called when a user is typing to change configurations
   * @param serviceAreaConfig
   * @param algorithmSettings
   * @returns
   */
  readonly onUpdate: (serviceAreaConfig: ServiceAreaConfig, algorithmSettings: AlgorithmSettings) => void;

  /**
   * Method is called when the "Save" button is clicked.
   * @returns
   */
  readonly onSave: () => void;

  /**
   * Method is called when the "Edit" button is clicked
   * @returns
   */
  readonly onEdit: () => void;

  /**
   * Method is called when the "Discard" button is clicked.
   */
  readonly onDiscard: () => void;
}

type RoutingConfigView = 'ROUTING_CONFIG_FULL_VIEW' | 'CO_TECH_CON_ACCESS' | 'ROUTING_CONFIG_BASIC_VIEW';

const VIEW_TO_LABEL: Record<RoutingConfigView, string> = {
  ROUTING_CONFIG_BASIC_VIEW: 'Basic View',
  CO_TECH_CON_ACCESS: 'CO View',
  ROUTING_CONFIG_FULL_VIEW: 'Full View',
};

export interface RoutingConfigFormEditorState {
  readonly invalidKeyCounter: number;
  readonly displayPermissionHint: boolean;
  readonly showRequestPermissionModal: boolean;
  readonly avaliableViews: ReadonlyArray<{ readonly type: RoutingConfigView; readonly disabled: boolean }>;
  readonly selectedView: RoutingConfigView;
}

export class RoutingConfigFormEditor extends React.Component<RoutingConfigFormEditorProps, RoutingConfigFormEditorState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;
  private invalidParameterKeys: Set<string>;

  constructor(props: RoutingConfigFormEditorProps) {
    super(props);
    this.invalidParameterKeys = new Set();

    this.state = {
      invalidKeyCounter: 0,
      displayPermissionHint: false,
      showRequestPermissionModal: false,
      ...this.deriveViewSelectionStates(this.props.userAccesses),
    };
  }

  componentDidUpdate(prevProps: Readonly<RoutingConfigFormEditorProps>): void {
    if (prevProps.userAccesses !== this.props.userAccesses) {
      this.setState({
        ...this.deriveViewSelectionStates(this.props.userAccesses),
      });
    }
  }

  private deriveViewSelectionStates(userAccesses?: ReadonlyArray<Access>) {
    const avaliableViews: ReadonlyArray<{ type: RoutingConfigView; disabled: boolean }> = [
      { type: 'ROUTING_CONFIG_FULL_VIEW', disabled: !userAccesses?.includes('ROUTING_CONFIG_FULL_VIEW') },
      { type: 'CO_TECH_CON_ACCESS', disabled: !userAccesses?.includes('CO_TECH_CON_ACCESS') },
      { type: 'ROUTING_CONFIG_BASIC_VIEW', disabled: false },
    ];
    let selectedView: RoutingConfigView = 'ROUTING_CONFIG_BASIC_VIEW';

    if (userAccesses?.includes('CO_TECH_CON_ACCESS')) {
      selectedView = 'CO_TECH_CON_ACCESS';
    }

    if (userAccesses?.includes('ROUTING_CONFIG_FULL_VIEW')) {
      selectedView = 'ROUTING_CONFIG_FULL_VIEW';
    }

    return {
      avaliableViews: avaliableViews,
      selectedView: selectedView,
    };
  }

  render() {
    if (this.props.userAccesses === undefined || this.props.serviceAreaConfig === undefined || this.props.algorithmSettings === undefined) {
      return (
        <Box textAlign="center">
          <StatusIndicator type="loading">Loading</StatusIndicator>
        </Box>
      );
    } else {
      return (
        <React.Fragment>
          {this.renderRequestPermissionModal()}
          <SpaceBetween direction="vertical" size="s">
            <Header
              actions={
                <SpaceBetween direction="horizontal" size="s">
                  {this.renderViewSelectionButton()}
                  {this.renderHeaderAdditionalActions()}
                </SpaceBetween>
              }
            ></Header>
            {this.renderContent()}
          </SpaceBetween>
        </React.Fragment>
      );
    }
  }

  private renderHeaderAdditionalActions() {
    if (this.props.isEditing) {
      return (
        <React.Fragment>
          <Button disabled={this.props.isSaving} onClick={() => this.props.onDiscard()}>
            Discard
          </Button>
          <Button variant="primary" disabled={this.props.isSaving || this.state.invalidKeyCounter > 0} onClick={() => this.props.onSave()}>
            Save
          </Button>
        </React.Fragment>
      );
    } else {
      if (this.props.userAccesses?.includes('ROUTING_CONFIG_WRITE_ACCESS')) {
        return (
          <Button disabled={this.props.serviceAreaConfig === undefined || this.props.algorithmSettings === undefined} onClick={() => this.props.onEdit()}>
            Edit
          </Button>
        );
      } else {
        return <Button onClick={() => this.setState({ showRequestPermissionModal: true })}>Request Permission</Button>;
      }
    }
  }

  private renderRequestPermissionModal() {
    if (this.state.showRequestPermissionModal) {
      return (
        <PermissionTableModal
          message={`You don't have permission to update routing configurations. Request "Update routing configurations" permission.`}
          onDismiss={() => this.setState({ showRequestPermissionModal: false })}
          access="routing"
        />
      );
    }
  }

  private renderViewSelectionButton() {
    const options: SelectProps.Option[] = this.state.avaliableViews.map((view) => ({
      label: VIEW_TO_LABEL[view.type],
      value: view.type,
      disabled: view.disabled,
      description: view.disabled ? 'Missing permission' : undefined,
    }));

    return (
      <Select
        options={options}
        onChange={(option) => this.setState({ selectedView: option.detail.selectedOption.value as RoutingConfigView })}
        selectedOption={options.find((o) => o.value === this.state.selectedView) ?? null}
      ></Select>
    );
  }

  private renderContent() {
    switch (this.state.selectedView) {
      case 'ROUTING_CONFIG_FULL_VIEW':
        return this.renderFullView();
      case 'CO_TECH_CON_ACCESS':
        return this.renderCOTechConView();
      case 'ROUTING_CONFIG_BASIC_VIEW':
      default:
        return this.renderBasicView();
    }
  }

  private renderFullView() {
    return (
      <ConfigGrid>
        {this.generateInputTable(tableDefinitions.defaultPlanningHorizon)}
        {this.renderPlanningHorizonOverridesTable()}
        {this.renderAccessPointConfigsTable()}
        {this.renderPlaceholderTRSizeEstimationTable()}
        {this.renderCapacityConstraintsTable()}
        <MMOTConstraintsTable
          mmotConstraints={this.props.serviceAreaConfig?.routeConstraintsConfig ?? {}}
          mmotFlag={this.props.serviceAreaConfig?.featureFlags ? this.props.serviceAreaConfig.featureFlags.mmotEnabled : false}
          isEditing={this.props.isEditing}
          onUpdate={(update) => {
            if (this.props.serviceAreaConfig !== undefined && this.props.algorithmSettings !== undefined) {
              this.props.onUpdate(handleMmotConstraintUpdate(this.props.serviceAreaConfig, update), this.props.algorithmSettings);
            }
          }}
          onUpdateFlag={(flag) => {
            if (this.props.serviceAreaConfig !== undefined && this.props.algorithmSettings !== undefined) {
              const flags = { ...this.props.serviceAreaConfig?.featureFlags, mmotEnabled: flag };
              this.props.onUpdate({ ...this.props.serviceAreaConfig, featureFlags: flags }, this.props.algorithmSettings);
            }
          }}
        />
        <DeliveryWindowOverridesTable
          items={this.props.serviceAreaConfig?.deliveryWindowOverrides ?? []}
          isEditing={this.props.isEditing}
          onNewOverride={(override) => {
            if (this.props.serviceAreaConfig !== undefined && this.props.algorithmSettings !== undefined) {
              this.props.onUpdate(addDeliveryWindowOverride(this.props.serviceAreaConfig, override), this.props.algorithmSettings);
            }
          }}
          onDeleteOverrides={(overrides) => {
            if (this.props.serviceAreaConfig !== undefined && this.props.algorithmSettings !== undefined) {
              this.props.onUpdate(deleteDeliveryWindowOverrides(this.props.serviceAreaConfig, overrides), this.props.algorithmSettings);
            }
          }}
          onUpdateStartTime={(orderType, daysOfWeek, value) => {
            if (this.props.serviceAreaConfig !== undefined && this.props.algorithmSettings !== undefined) {
              this.props.onUpdate(updateDeliveryWindowOverrideStartTime(this.props.serviceAreaConfig, orderType, daysOfWeek, value), this.props.algorithmSettings);
            }
          }}
          onUpdateEndTime={(orderType, daysOfWeek, value) => {
            if (this.props.serviceAreaConfig !== undefined && this.props.algorithmSettings !== undefined) {
              this.props.onUpdate(updateDeliveryWindowOverrideEndTime(this.props.serviceAreaConfig, orderType, daysOfWeek, value), this.props.algorithmSettings);
            }
          }}
        />

        {this.props.operatingMode === 'developer' ? (
          <IsolatedOrderTypesTable
            isolatedOrderTypes={this.props.serviceAreaConfig?.isolatedOrderTypes ?? []}
            isEditing={this.props.isEditing}
            onUpdate={(update) => {
              if (this.props.serviceAreaConfig !== undefined && this.props.algorithmSettings !== undefined) {
                this.props.onUpdate(updateIsolatedOrderTypes(this.props.serviceAreaConfig, update), this.props.algorithmSettings);
              }
            }}
          />
        ) : null}

        {this.generateInputTable(tableDefinitions.elongation)}
        {this.generateInputTable(tableDefinitions.readyToWork)}
        {this.generateInputTable(tableDefinitions.locking)}
        {this.generateInputTable(tableDefinitions.assignment)}
        {this.generateInputTable(tableDefinitions.overflowRoute)}
        {this.generateInputTable(tableDefinitions.earlyDropoff)}
        {this.generateInputTable(tableDefinitions.anomalyConfig)}
        {this.generateInputTable(tableDefinitions.instantOffer)}
        {this.generateInputTable(tableDefinitions.endBufferMinutes)}
        {this.generateInputTable(tableDefinitions.unitTaskDelayCost)}
        {this.generateInputTable(tableDefinitions.drpConfigs)}
        {this.generateInputTable(tableDefinitions.experiments)}
      </ConfigGrid>
    );
  }

  /**
   * Created two views CO view and OE view. CO view has a Service Area Detail page
   * with a lot less configurations as COs do not need access to all of them.
   */
  private renderCOTechConView() {
    return (
      <ConfigGrid>
        {this.generateInputTable(tableDefinitions.defaultPlanningHorizonCOView)}
        {this.renderPlanningHorizonOverridesTable()}
        {/**
         * The following input tables are required based on the SOP
         * https://w.amazon.com/bin/view/Stratos/Internal/MBEDashboard/ExceptionSOP/EU_3P_TECHCON/EU_3P_LMDP_CONFIG
         * https://w.amazon.com/bin/view/Stratos/Internal/MBEDashboard/ExceptionSOP/NA_3P_TECHCON/NA_3P_LMDP_CONFIG/
         *
         * AccessPointConfigsTable
         * PlaceholderTRSizeEstimationTable
         * CapacityConstraintsTable
         * earlyDropoff
         * endBufferMinutes
         * Ready to Work
         * Routing Experiments
         * Overflow Route Configuration
         */}
        {this.renderAccessPointConfigsTable()}
        {this.renderPlaceholderTRSizeEstimationTable()}
        {this.renderCapacityConstraintsTable()}
        {this.generateInputTable(tableDefinitions.earlyDropoff)}
        {this.generateInputTable(tableDefinitions.endBufferMinutes)}
        {this.generateInputTable(tableDefinitions.elongationCOView)}
        {this.generateInputTable(tableDefinitions.readyToWork)}
        {this.generateInputTable(tableDefinitions.overflowRoute)}
        {this.generateInputTable(tableDefinitions.instantOfferCOView)}
        {this.generateInputTable(tableDefinitions.experiments)}
        {this.props.userAccesses?.includes('ROUTING_CONFIG_CO_AUTO_ASSIGN_VIEW')
          ? this.generateInputTable(tableDefinitions.assignmentCOViewWithAutoAssign)
          : this.generateInputTable(tableDefinitions.assignmentCOView)}
      </ConfigGrid>
    );
  }

  /**
   * For anyone without special access, we display the basic view.
   */
  private renderBasicView() {
    return (
      <ConfigGrid>
        {this.generateInputTable(tableDefinitions.elongationCOView)}
        {this.generateInputTable(tableDefinitions.instantOfferCOView)}
        {this.props.userAccesses?.includes('ROUTING_CONFIG_CO_AUTO_ASSIGN_VIEW')
          ? this.generateInputTable(tableDefinitions.assignmentCOViewWithAutoAssign)
          : this.generateInputTable(tableDefinitions.assignmentCOView)}
      </ConfigGrid>
    );
  }

  private generateInputTable(tableDefinition: ConfigTableDefinition) {
    if (tableDefinition.visibility === 'developer-only' && this.context.operatingMode !== 'developer') {
      return null;
    }

    return (
      <InputTable
        headerText={tableDefinition.headerText}
        showField={this.context.operatingMode === 'developer'}
        description={tableDefinition.description}
        valueColMinWidth={tableDefinition.valueColMinWidth}
        valueColHeaderText={tableDefinition.valueColHeaderText}
        nameColHeaderText={tableDefinition.nameColHeaderText}
        nameColMinWidth={tableDefinition.nameColMinWidth}
        sizeOnGrid={tableDefinition.sizeOnGrid ?? 'default'}
        items={tableDefinition.items
          .filter((itemConfig) => {
            if (itemConfig.visibility === 'developer-only') {
              return this.context.operatingMode === 'developer';
            } else {
              return true;
            }
          })
          .map((itemConfig) => convertConfigToItem(itemConfig, this.props.serviceAreaConfig, this.props.algorithmSettings))}
        notifyUpdate={(update: InputUpdate) => {
          if (this.props.serviceAreaConfig !== undefined && this.props.algorithmSettings !== undefined) {
            const newConfigs = updateGSFRoutingConfigurations(update, {
              serviceAreaConfig: this.props.serviceAreaConfig,
              algorithmSettings: this.props.algorithmSettings,
            });
            this.props.onUpdate(newConfigs.serviceAreaConfig, newConfigs.algorithmSettings);
          }
        }}
        notifyValidationResult={(result: ValidationResult) => {
          if (result.isValid) {
            this.invalidParameterKeys.delete(`${result.scope}#${result.field}`);
          } else {
            this.invalidParameterKeys.add(`${result.scope}#${result.field}`);
          }
          this.setState({ invalidKeyCounter: this.invalidParameterKeys.size });
        }}
        isEditing={this.props.isEditing}
      />
    );
  }

  private renderPlanningHorizonOverridesTable() {
    return (
      <PlanningHorizonOverridesTable
        items={this.props.serviceAreaConfig?.horizonConfigOverrides ?? []}
        isEditing={this.props.isEditing}
        onNewOverride={(override) => {
          if (this.props.serviceAreaConfig !== undefined && this.props.algorithmSettings !== undefined) {
            this.props.onUpdate(addPlanningHorizonConfigOverride(this.props.serviceAreaConfig, override), this.props.algorithmSettings);
          }
        }}
        onDeleteOverrides={(overrides) => {
          console.log('delete overrides', overrides);
          if (this.props.serviceAreaConfig !== undefined && this.props.algorithmSettings !== undefined) {
            this.props.onUpdate(deletaPlanningHorizonConfigOverrides(this.props.serviceAreaConfig, overrides), this.props.algorithmSettings);
          }
        }}
        onUpdateStagedPlanningHorizon={(inputType, effectiveTime, orderType, value) => {
          if (this.props.serviceAreaConfig !== undefined && this.props.algorithmSettings !== undefined) {
            this.props.onUpdate(updateStagedPlanningHorizon(this.props.serviceAreaConfig, inputType, effectiveTime, orderType, value), this.props.algorithmSettings);
          }
        }}
        onUpdateNonStagedPlanningHorizon={(inputType, effectiveTime, orderType, value) => {
          if (this.props.serviceAreaConfig !== undefined && this.props.algorithmSettings !== undefined) {
            this.props.onUpdate(updateNonStagedPlanningHorizon(this.props.serviceAreaConfig, inputType, effectiveTime, orderType, value), this.props.algorithmSettings);
          }
        }}
        onUpdateLocking={(inputType, effectiveTime, orderType, value) => {
          if (this.props.serviceAreaConfig !== undefined && this.props.algorithmSettings !== undefined) {
            this.props.onUpdate(updateLocking(this.props.serviceAreaConfig, inputType, effectiveTime, orderType, value), this.props.algorithmSettings);
          }
        }}
      />
    );
  }

  private renderPlaceholderTRSizeEstimationTable() {
    return (
      <PlaceholderTRSizeEstimationTable
        placeholderTRDefaults={this.props.serviceAreaConfig?.placeholderTRDefaults}
        isEditing={this.props.isEditing}
        onUpdate={(update) => {
          if (this.props.serviceAreaConfig !== undefined && this.props.algorithmSettings !== undefined) {
            this.props.onUpdate(updatePlaceholderTRDefaults(this.props.serviceAreaConfig, update), this.props.algorithmSettings);
          }
        }}
      />
    );
  }

  private renderAccessPointConfigsTable() {
    let pickupStartOffsetConfigItems: PickupStartOffsetConfigItem[] = [];
    if (this.props.serviceAreaConfig?.accessPointConfigs !== undefined && this.props.accessPoints !== undefined) {
      pickupStartOffsetConfigItems = convertAccessPointConfiguration(this.props.serviceAreaConfig.accessPointConfigs, this.props.accessPoints);
    }
    return (
      <AccessPointConfigsTable
        accessPoints={this.props.accessPoints ?? []}
        items={pickupStartOffsetConfigItems}
        isEditing={this.props.isEditing}
        onDeleteItems={(items) => {
          if (this.props.serviceAreaConfig !== undefined && this.props.algorithmSettings !== undefined) {
            this.props.onUpdate(deleteAccessPointConfigItems(this.props.serviceAreaConfig, items), this.props.algorithmSettings);
          }
        }}
        onAddItem={(item) => {
          if (this.props.serviceAreaConfig !== undefined && this.props.algorithmSettings !== undefined) {
            this.props.onUpdate(addAccessPointConfigItem(this.props.serviceAreaConfig, item), this.props.algorithmSettings);
          }
        }}
        onUpdateSlammedTRPickupOffset={(accessPointId: string, orderType: string | undefined, value: number) => {
          if (this.props.serviceAreaConfig !== undefined && this.props.algorithmSettings !== undefined) {
            this.props.onUpdate(updateTRPickupOffset(this.props.serviceAreaConfig, 'slammed', accessPointId, orderType, value), this.props.algorithmSettings);
          }
        }}
        onUpdateNonSlammedTRPickupOffset={(accessPointId: string, orderType: string | undefined, value: number) => {
          if (this.props.serviceAreaConfig !== undefined && this.props.algorithmSettings !== undefined) {
            this.props.onUpdate(updateTRPickupOffset(this.props.serviceAreaConfig, 'nonslammed', accessPointId, orderType, value), this.props.algorithmSettings);
          }
        }}
      />
    );
  }

  private renderCapacityConstraintsTable() {
    return (
      <CapacityConstraintsTable
        capacityConstraints={this.props.serviceAreaConfig?.capacityConstraints ?? []}
        isEditing={this.props.isEditing}
        onUpdate={(update) => {
          if (this.props.serviceAreaConfig !== undefined && this.props.algorithmSettings !== undefined) {
            this.props.onUpdate(handleCapacityConstraintUpdate(this.props.serviceAreaConfig, update), this.props.algorithmSettings);
          }
        }}
      />
    );
  }
}
