import React from 'react';
import AceEditor from 'react-ace';
import stringify from 'json-stable-stringify';
// a reusable AWS style json viewer
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-xcode';
import 'ace-builds/src-noconflict/ext-searchbox';

export declare namespace JSONEditor {
  export interface Props {
    readOnly: boolean;
    showGutter: boolean;
    data: any;
  }
}

// eslint-disable-next-line no-redeclare
export class JSONEditor extends React.Component<JSONEditor.Props> {
  render() {
    let readOnly = true;
    let showGutter = false;
    if (typeof this.props.readOnly === 'boolean') {
      readOnly = this.props.readOnly;
    }
    if (typeof this.props.showGutter === 'boolean') {
      showGutter = this.props.showGutter;
    }
    return (
      <AceEditor
        setOptions={{
          useWorker: false,
        }}
        mode="json"
        theme="xcode"
        showPrintMargin={false} // if true, there is a vertical line indicate #chars on a line
        readOnly={readOnly}
        focus={false}
        width={'100%'}
        // onChange={onChange}
        name="UNIQUE_ID_OF_DIV"
        maxLines={70}
        minLines={10}
        showGutter={showGutter}
        value={stringify(this.props.data, { space: 2 })}
        editorProps={{ $blockScrolling: true }}
      />
    );
  }
}

export default JSONEditor;
