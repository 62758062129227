import React from 'react';

import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import ButtonDropdown from '@amzn/awsui-components-react-v3/polaris/button-dropdown';
import Checkbox from '@amzn/awsui-components-react-v3/polaris/checkbox';
import FormField from '@amzn/awsui-components-react-v3/polaris/form-field';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import Modal from '@amzn/awsui-components-react-v3/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Table from '@amzn/awsui-components-react-v3/polaris/table';
import TimeInput from '@amzn/awsui-components-react-v3/polaris/time-input';
import orderBy from 'lodash/orderBy';
import { OrderType, orderTypes, DeliveryWindowOverride, DayOfWeek } from '../../../../models/routing-models';
import { GridSizeDefaultProp } from '../../../../common-components/config-grid';
import { ORDER_TYPE_OPTIONS } from '../constants';
import { isNil } from '../../../../utilities';

/**
 * orderType | daysOfWeek      | startLocalTime | endLocalTime
 * ROAR      | SATURDAY,SUNDAY | 17:00          | 22:00
 *
 */

interface DeliveryWindowOverridesModalInput {
  readonly orderType: string | null;
  readonly daysOfWeek: Map<DayOfWeek, boolean>;
  readonly startLocalTime: string | null;
  readonly endLocalTime: string | null;
}

function convertModalInputToTableItem(modal: DeliveryWindowOverridesModalInput): DeliveryWindowOverride {
  const days: string[] = [];
  modal.daysOfWeek.forEach((selected, day) => {
    if (selected) {
      days.push(day.toUpperCase());
    }
  });

  return {
    orderType: modal.orderType,
    daysOfWeek: days.join(','),
    startLocalTime: modal.startLocalTime,
    endLocalTime: modal.endLocalTime,
  };
}

const daysOfWeekRegex = /^[(MONDAY|TUESDAY|WEDNESDAY|THURSDAY|FRIDAY|SATURDAY|SUNDAY),]*(MONDAY|TUESDAY|WEDNESDAY|THURSDAY|FRIDAY|SATURDAY|SUNDAY)$/;
const localTimeRegex = /^\d{2}:\d{2}$/;

const dayAbbreviations: Record<DayOfWeek, string> = {
  MONDAY: 'Mon',
  TUESDAY: 'Tue',
  WEDNESDAY: 'Wed',
  THURSDAY: 'Thu',
  FRIDAY: 'Fri',
  SATURDAY: 'Sat',
  SUNDAY: 'Sun',
};
const day_groupings = [
  { name: 'EVERYDAY', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] },
  { name: 'WEEKDAYS', days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'] },
  { name: 'WEEKENDS', days: ['Sat', 'Sun'] },
];

function displayDaysOfWeek(daysOfWeekString: string | null) {
  // In GSFMissandei null values apply to every day.
  if (daysOfWeekString === null || daysOfWeekString === undefined) {
    return 'null (applies EVERYDAY)';
  }

  let daysOfWeek = new Set();
  daysOfWeekString.split(',').forEach((fullName) => {
    daysOfWeek.add(dayAbbreviations[fullName as DayOfWeek]);
  });

  // Match at most one group of days
  // Either everything, weekends, or weekdays
  let group_name = null;
  for (let grouping of day_groupings) {
    if (grouping.days.every((day) => daysOfWeek.has(day))) {
      grouping.days.forEach((day) => daysOfWeek.delete(day));
      group_name = grouping.name;
      break;
    }
  }

  let straggler_days = Array.from(daysOfWeek).join(',');
  if (straggler_days.length === 0) {
    return group_name;
  } else if (group_name === 'WEEKDAYS') {
    return `WEEKDAYS + ${straggler_days}`;
  } else if (group_name === 'WEEKENDS') {
    return `${straggler_days} + WEEKENDS`;
  }
  return straggler_days;
}

interface DeliveryWindowOverrideModalProps {
  // readonly isVisible: boolean;
  readonly onDismiss: () => void;
  readonly onNewOverride?: (override: DeliveryWindowOverride) => void; // callback when a new override is created
  readonly currentOverrides: DeliveryWindowOverride[];
}
interface DeliveryWindowOverrideModalState {
  readonly inputOverride: DeliveryWindowOverridesModalInput;
  readonly inputHint?: string;
}

class DeliveryWindowOverrideModal extends React.Component<DeliveryWindowOverrideModalProps, DeliveryWindowOverrideModalState> {
  constructor(props: DeliveryWindowOverrideModalProps) {
    super(props);
    this.state = {
      inputOverride: {
        orderType: null,
        daysOfWeek: new Map([
          ['MONDAY', false],
          ['TUESDAY', false],
          ['WEDNESDAY', false],
          ['THURSDAY', false],
          ['FRIDAY', false],
          ['SATURDAY', false],
          ['SUNDAY', false],
        ]),
        startLocalTime: null,
        endLocalTime: null,
      },
    };
  }

  allDaysSelected() {
    return Array.from(this.state.inputOverride.daysOfWeek.values()).every((b) => b);
  }

  setAllDays(val: boolean) {
    let inputOverride = { ...this.state.inputOverride };
    inputOverride.daysOfWeek.forEach((_, key) => {
      inputOverride.daysOfWeek.set(key, val);
    });
    this.setState({ inputOverride });
  }

  validateAndGetOverride(): DeliveryWindowOverride | undefined {
    const newOverride = convertModalInputToTableItem(this.state.inputOverride);

    // validate individual fields
    if (newOverride.orderType === null || !orderTypes.includes(newOverride.orderType as OrderType)) {
      this.setState({ inputHint: 'Order type must be specified.' });
      return;
    }

    const daysOfWeek = newOverride.daysOfWeek;
    if (daysOfWeek === null || !daysOfWeekRegex.test(daysOfWeek)) {
      this.setState({ inputHint: 'At least one day must be selected.' });
      return;
    }

    const startTime = newOverride.startLocalTime;
    if (startTime === null || !localTimeRegex.test(startTime)) {
      this.setState({ inputHint: 'Start time must be in a format like "05:00" or "14:30".' });
      return;
    }

    const endTime = newOverride.endLocalTime;
    if (endTime === null || !localTimeRegex.test(endTime)) {
      this.setState({ inputHint: 'End time must be in a format like "05:00" or "14:30".' });
      return;
    }

    // ensure start comes before end
    // safe to use builtin comparison here b/c we've just validate shape with regex
    if (startTime >= endTime) {
      this.setState({ inputHint: 'Start time must come before end time.' });
      return;
    }

    // ensure no overlap
    let alreadyThere: Set<String> = new Set();
    for (let existingOverride of this.props.currentOverrides) {
      let days = existingOverride.daysOfWeek ?? 'MONDAY,TUESDAY,WEDNESDAY,THURSDAY,FRIDAY,SATURDAY,SUNDAY';
      for (var day of days.split(',')) {
        alreadyThere.add(`${existingOverride.orderType} ${day}`);
      }
    }
    for (let day of newOverride.daysOfWeek!.split(',')) {
      let key = `${newOverride.orderType} ${day}`;
      if (alreadyThere.has(key)) {
        this.setState({ inputHint: `Overlapping overrides for ${newOverride.orderType} on ${day}.` });
        return;
      }
      alreadyThere.add(key); // to catch if you include MONDAY twice in your input
    }

    // otherwise it's valid
    this.setState({ inputHint: undefined });
    return newOverride;
  }

  renderModalContent() {
    return (
      <SpaceBetween direction="vertical" size="m">
        <ButtonDropdown
          items={ORDER_TYPE_OPTIONS}
          onItemClick={(evt) => {
            let inputOverride = { ...this.state.inputOverride };
            inputOverride.orderType = evt.detail.id;
            this.setState({ inputOverride });
          }}
        >
          {this.state.inputOverride.orderType ?? 'Select an order type'}
        </ButtonDropdown>
        <SpaceBetween direction="horizontal" size="s">
          <TimeInput
            format="hh:mm"
            value={this.state.inputOverride.startLocalTime ?? ''}
            placeholder="start time e.g. 17:00"
            onChange={(evt) => {
              let inputOverride = { ...this.state.inputOverride };
              inputOverride.startLocalTime = evt.detail.value;
              this.setState({ inputOverride });
            }}
          />
          <TimeInput
            format="hh:mm"
            value={this.state.inputOverride.endLocalTime ?? ''}
            placeholder="end time e.g. 22:00"
            onChange={(evt) => {
              let inputOverride = { ...this.state.inputOverride };
              inputOverride.endLocalTime = evt.detail.value;
              this.setState({ inputOverride });
            }}
          />
        </SpaceBetween>
        <Checkbox checked={this.allDaysSelected()} onChange={(_) => (this.allDaysSelected() ? this.setAllDays(false) : this.setAllDays(true))}>
          -- Toggle All --
        </Checkbox>
        {Array.from(this.state.inputOverride.daysOfWeek.keys()).map((day) => (
          <Checkbox
            key={day}
            checked={this.state.inputOverride.daysOfWeek.get(day) ?? false}
            onChange={(evt) => {
              let inputOverride = { ...this.state.inputOverride };
              inputOverride.daysOfWeek.set(day, evt.detail.checked);
              this.setState({ inputOverride });
            }}
          >
            {`${day.charAt(0) + day.substring(1).toLowerCase()}`}
          </Checkbox>
        ))}
        <FormField errorText={this.state.inputHint} />
      </SpaceBetween>
    );
  }

  render() {
    return (
      <Modal
        visible={true}
        header="Add an override"
        onDismiss={this.props.onDismiss}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={this.props.onDismiss}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  if (this.props.onNewOverride) {
                    let validItem = this.validateAndGetOverride();
                    if (validItem) {
                      this.props.onNewOverride!(validItem);
                      this.props.onDismiss();
                    }
                    // do not dismiss in case of an error.
                  } else {
                    this.props.onDismiss();
                  }
                }}
              >
                Add
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        {this.renderModalContent()}
      </Modal>
    );
  }
}

export interface DeliveryOverrideWindowTableProps {
  readonly isEditing?: boolean;
  readonly items: DeliveryWindowOverride[];
  readonly onNewOverride: (override: DeliveryWindowOverride) => void; // callback when a new override is created
  readonly onDeleteOverrides: (overrides: DeliveryWindowOverride[]) => void; // callback when delete overrides
  readonly onUpdateStartTime: (orderType: string | null, daysOfWeek: string | null, value: string) => void;
  readonly onUpdateEndTime: (orderType: string | null, daysOfWeek: string | null, value: string) => void;
}

interface DeliveryWindowOverrideTableState {
  readonly isAddingNewOverride: boolean; // control whether to open the popup modal.
  readonly selectedItems: DeliveryWindowOverride[];
  readonly isDescending: boolean | undefined;
  readonly sortingColumn: any;
}

export class DeliveryWindowOverridesTable extends React.Component<DeliveryOverrideWindowTableProps, DeliveryWindowOverrideTableState> {
  static defaultProps: GridSizeDefaultProp = { sizeOnGrid: 'wide' };

  // private newOverride: DeliveryWindowOverridesTableItem;
  constructor(props: DeliveryOverrideWindowTableProps) {
    super(props);
    this.state = {
      isAddingNewOverride: false,
      selectedItems: [],
      isDescending: false,
      sortingColumn: null,
    };
  }

  /**
   * Function that renders editable fields in the Delivery Window Override table.
   * Some cells are editable and frontEnd validation is included to show
   * a hint if an invalid value is entered.
   * @param isEditing boolean that indicates whether state is in editing or not.
   * @param key is used to determine what inputCell to update with an error if an invalid input was entered.
   * @param value currently being displayed in the cell.
   * @param onUpdate Callback function that gets updated value from user input and passes to the reducer.js file. The
   * value is passed via one of three actions and based off the action will update one of the three fields.
   */
  renderInputCell(isEditing: boolean, key: string, value: string, onUpdate: (newValue: string) => void, columnId: string) {
    if (!isEditing) {
      return value;
    }

    if (columnId === 'startLocalTime' || columnId === 'endLocalTime') {
      return (
        <TimeInput
          key={key}
          value={value}
          format="hh:mm"
          placeholder="hh:mm"
          onChange={(evt) => {
            onUpdate(evt.detail.value);
          }}
        />
      );
    } else {
      return value;
    }
  }

  renderHeader() {
    // "delete" and "add" buttons
    return (
      <Header
        variant="h3"
        actions={
          this.props.isEditing ? (
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                onClick={() => {
                  if (isNil(this.props.onDeleteOverrides)) {
                    return;
                  }
                  this.props.onDeleteOverrides(this.state.selectedItems);
                  this.setState({ selectedItems: [] });
                }}
              >
                Delete
              </Button>
              <Button variant="primary" onClick={() => this.setState({ isAddingNewOverride: true })}>
                Add
              </Button>
            </SpaceBetween>
          ) : null
        }
      >
        Delivery Window Overrides
      </Header>
    );
  }

  render() {
    let items = this.props.items;

    if (this.state.sortingColumn !== null) {
      const sortDirection = this.state.isDescending ? 'desc' : 'asc';
      const sortField = this.state.sortingColumn.sortingField;

      // typescript complains if you lookup the field directly by string
      if (sortField === 'orderType') {
        items = orderBy(items, (item) => item.orderType, sortDirection);
      } else if (sortField === 'startLocalTime') {
        items = orderBy(items, (item) => item.startLocalTime, sortDirection);
      } else if (sortField === 'endLocalTime') {
        items = orderBy(items, (item) => item.endLocalTime, sortDirection);
      }
    }

    const isEditing = this.props.isEditing ?? false;
    return (
      <React.Fragment>
        {this.state.isAddingNewOverride ? (
          <DeliveryWindowOverrideModal currentOverrides={this.props.items} onNewOverride={this.props.onNewOverride} onDismiss={() => this.setState({ isAddingNewOverride: false })} />
        ) : null}
        <Table<DeliveryWindowOverride>
          header={this.renderHeader()}
          columnDefinitions={[
            {
              id: 'orderType',
              header: 'Order Type',
              cell: (item) => item.orderType,
              sortingField: 'orderType',
            },
            {
              id: 'daysOfWeek',
              header: 'Days Of Week',
              cell: (item) => displayDaysOfWeek(item.daysOfWeek),
            },
            {
              id: 'startLocalTime',
              header: 'Start Time',
              cell: (item) => {
                if (item.startLocalTime == null) {
                  return 'N/A';
                }
                return this.renderInputCell(
                  isEditing,
                  `${item.daysOfWeek}-${item.orderType}`,
                  item.startLocalTime,
                  (value: string) => {
                    this.props.onUpdateStartTime(item.orderType, item.daysOfWeek, value);
                  },
                  'startLocalTime',
                );
              },
              sortingField: 'startLocalTime',
            },
            {
              id: 'endLocalTime',
              header: 'End Time',
              cell: (item) => {
                if (item.endLocalTime == null) {
                  return 'N/A';
                }
                return this.renderInputCell(
                  isEditing,
                  `${item.daysOfWeek}-${item.orderType}`,
                  item.endLocalTime,
                  (value: string) => {
                    this.props.onUpdateEndTime(item.orderType, item.daysOfWeek, value);
                  },
                  'endLocalTime',
                );
              },
              sortingField: 'endLocalTime',
            },
          ]}
          items={items}
          selectionType={isEditing ? 'multi' : undefined}
          onSelectionChange={({ detail }) => this.setState({ selectedItems: detail.selectedItems })}
          selectedItems={this.state.selectedItems}
          onSortingChange={({ detail }) => {
            this.setState({
              isDescending: detail.isDescending,
              sortingColumn: detail.sortingColumn,
            });
          }}
          sortingColumn={this.state.sortingColumn}
          sortingDescending={this.state.isDescending}
        />
      </React.Fragment>
    );
  }
}
