import React from 'react';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import FormField from '@amzn/awsui-components-react-v3/polaris/form-field';
import Modal from '@amzn/awsui-components-react-v3/polaris/modal';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import Multiselect from '@amzn/awsui-components-react-v3/polaris/multiselect';
import Select from '@amzn/awsui-components-react-v3/polaris/select';
import Tiles from '@amzn/awsui-components-react-v3/polaris/tiles';
import { configScheduleClient } from '../../http-clients';
import Alert from '@amzn/awsui-components-react-v3/polaris/alert';

export interface AddScheduleModalProps {
  readonly onDismiss: () => void;
  readonly onSubmitSuccess: (scopeId: string) => void;
  readonly scopeId: string;
  readonly stationCode?: string;
}

export interface AddScheduleState {
  readonly description: string;
  readonly time: string;
  readonly selectedDayIds: ReadonlyArray<string>;
  readonly daysOfWeek: ReadonlyArray<string>;
  readonly day: string;
  readonly setting: string;
  readonly errorMessage?: string;
  readonly loading: boolean;
}

export class AddScheduleModal extends React.Component<AddScheduleModalProps, AddScheduleState> {
  constructor(props: AddScheduleModalProps) {
    super(props);
    this.state = {
      description: '',
      time: '',
      day: '',
      selectedDayIds: [],
      daysOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      setting: '',
      loading: false,
    };
  }

  render() {
    return (
      <Modal
        visible={true}
        header={`New Config Schedule - ${this.props.stationCode}`}
        onDismiss={() => this.props.onDismiss()}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button disabled={this.state.loading} onClick={() => this.props.onDismiss()}>
                Cancel
              </Button>
              <Button
                loading={this.state.loading}
                variant="primary"
                disabled={!this.state.setting}
                onClick={async () => {
                  try {
                    this.setState({ loading: true });
                    await configScheduleClient.createConfigSchedules({
                      scopeId: this.props.scopeId,
                      setting: this.state.setting,
                      days: this.state.selectedDayIds,
                      hour: this.state.time,
                    });
                    this.setState({ errorMessage: undefined });
                    this.props.onSubmitSuccess(this.props.scopeId);
                    this.props.onDismiss();
                  } catch (error) {
                    this.setState({ errorMessage: `Adding config schedules failed due to ${error}` });
                  } finally {
                    this.setState({ loading: false });
                  }
                }}
              >
                Confirm
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        {this.state.errorMessage !== undefined && (
          <Alert
            header="Error"
            type="error"
            dismissible={true}
            dismissAriaLabel="Close Alert"
            onDismiss={() => {
              this.setState({ errorMessage: undefined });
            }}
          >
            {this.state.errorMessage}
          </Alert>
        )}

        <SpaceBetween direction="vertical" size="l">
          <FormField label="Time Of Day">{this.renderTimeOptions()}</FormField>
          <FormField>{this.renderDayOptions()}</FormField>
          <FormField label="Setting">
            <Tiles
              onChange={(evt) => this.setState({ setting: evt.detail.value })}
              value={this.state.setting}
              items={[
                { label: 'Moderate', value: 'MODERATE' },
                { label: 'Conservative', value: 'CONSERVATIVE' },
                { label: 'Ultra Conservative', value: 'ULTRACONSERVATIVE' },
              ]}
            />
          </FormField>
        </SpaceBetween>
      </Modal>
    );
  }

  private renderDayOptions() {
    const optionsInDisplay = [
      {
        label: 'Sun',
        value: 'Sunday',
      },
      {
        label: 'Mon',
        value: 'Monday',
      },
      {
        label: 'Tue',
        value: 'Tuesday',
      },
      {
        label: 'Wed',
        value: 'Wednesday',
      },
      {
        label: 'Thu',
        value: 'Thursday',
      },
      {
        label: 'Fri',
        value: 'Friday',
      },
      {
        label: 'Sat',
        value: 'Saturday',
      },
    ];

    const selectedOptions = this.state.selectedDayIds?.map((dayId) => {
      const option = optionsInDisplay.find((opt) => opt.value === dayId);
      return option || { value: dayId };
    });

    return (
      <FormField label="Days of the week">
        <Multiselect
          options={optionsInDisplay}
          placeholder="Choose days"
          filteringType={'manual'}
          onChange={(evt) => {
            const selectedDayIds = evt.detail.selectedOptions.map((o) => o.value as string);
            this.setState({ selectedDayIds: selectedDayIds });
          }}
          selectedOptions={selectedOptions}
        ></Multiselect>
      </FormField>
    );
  }

  private renderTimeOptions() {
    const optionsInDisplay: { label: string; value: string }[] = [];
    for (let i = 0; i < 24; i++) {
      const hour = i;
      const label = `${hour.toString().padStart(2, '0')}:00`;
      const value = label;
      optionsInDisplay.push({
        label,
        value,
      });
    }
    const selectedOption = optionsInDisplay.find((opt) => opt.value === (this.state.time || '')) ?? null;
    return (
      <Select
        selectedOption={selectedOption}
        onChange={(evt) => {
          if (evt.detail.selectedOption.value) {
            this.setState({ time: evt.detail.selectedOption.value });
          }
        }}
        options={optionsInDisplay}
        placeholder="Choose station time"
      ></Select>
    );
  }
}
