import { DrasScopeConfigClient } from './dras-scope-config-client';
import { AxiosInstance } from 'axios';
import {
  GetDrasScopeConfigRequest,
  GetDrasScopeConfigResponse,
  ListDrasScopeConfigHistoryRequest,
  ListDrasScopeConfigHistoryResponse,
  PutDrasScopeConfigRequest,
  PutDrasScopeConfigResponse,
  RevertDrasScopeConfigRequest,
  RevertDrasScopeConfigResponse,
} from './request-response';

interface Query {
  [key: string]: string;
}

export class DrasScopeConfigClientImpl implements DrasScopeConfigClient {
  private readonly httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  async getDrasScopeConfig<T>(request: GetDrasScopeConfigRequest): Promise<GetDrasScopeConfigResponse<T>> {
    const query: Query = {
      scopeId: request.scopeId,
      configType: request.configType,
    };
    if (typeof request.configId === 'string') {
      query['configId'] = request.configId;
    }
    const queryString = new URLSearchParams(query).toString();
    const resp = await this.httpClient.get<GetDrasScopeConfigResponse<T>>(`/dras-scope-config?${queryString}`);
    return resp.data;
  }

  async listDrasScopeConfigHistory(request: ListDrasScopeConfigHistoryRequest): Promise<ListDrasScopeConfigHistoryResponse> {
    const query: Query = {
      scopeId: request.scopeId,
      configType: request.configType,
      before: request.before,
      numberOfRecords: request.numberOfRecords.toString(),
    };
    const queryString = new URLSearchParams(query).toString();
    const resp = await this.httpClient.get<ListDrasScopeConfigHistoryResponse>(`/dras-scope-config-history?${queryString}`);
    return resp.data;
  }

  async putDrasScopeConfig(request: PutDrasScopeConfigRequest): Promise<PutDrasScopeConfigResponse> {
    const resp = await this.httpClient.put<PutDrasScopeConfigResponse>('/dras-scope-config', request, {
      headers: {
        'content-type': 'application/json',
      },
    });
    return resp.data;
  }

  async revertDrasScopeConfigRequest(request: RevertDrasScopeConfigRequest): Promise<RevertDrasScopeConfigResponse> {
    const resp = await this.httpClient.post<RevertDrasScopeConfigResponse>('/revert-dras-scope-config', request, {
      headers: {
        'content-type': 'application/json',
      },
    });
    return resp.data;
  }
}
