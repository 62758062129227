import React from 'react';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Link from '@amzn/awsui-components-react-v3/polaris/link';
import Badge from '@amzn/awsui-components-react-v3/polaris/badge';
import { DriaScopeInfo } from '../../http-clients/dria-client';
import { EnrichedDriaScopeInfo } from './models';
import { WORK_TYPE_TO_LABEL } from './constants';

export interface BuildTableDefinitionProps {
  readonly onScopeClick: (scopeInfo: DriaScopeInfo) => void;
  readonly buildHref: (scope: DriaScopeInfo) => string;
}

export function buildTableDefinition(props: BuildTableDefinitionProps): ReadonlyArray<TableProps.ColumnDefinition<EnrichedDriaScopeInfo>> {
  const columns: TableProps.ColumnDefinition<EnrichedDriaScopeInfo>[] = [];

  columns.push({
    id: 'stationCode',
    header: 'Station Code',
    cell: (item) => {
      if (item.scopeType === 'REGION') {
        // region doesn't have a code.
        return <Badge color="blue">Region</Badge>;
      } else {
        return <Box>{item.stationCode ?? '-'}</Box>;
      }
    },
    sortingField: 'stationCode',
    sortingComparator: (i1, i2) => {
      const s1 = i1.stationCode ?? '-';
      const s2 = i2.stationCode ?? '-';
      return s1.localeCompare(s2);
    },
  });

  columns.push({
    id: 'name',
    header: 'Name',
    cell: (item) => {
      return <Box>{item.name ?? '-'}</Box>;
    },
    sortingField: 'name',
    sortingComparator: (i1, i2) => {
      const s1 = i1.name ?? '-';
      const s2 = i2.name ?? '-';
      return s1.localeCompare(s2);
    },
  });

  columns.push({
    id: 'scopeId',
    header: 'Region / Service Area Id',
    cell: (item) => {
      return (
        <Link
          href={props.buildHref(item)}
          onFollow={(evt) => {
            evt.preventDefault();
            props.onScopeClick(item);
          }}
        >
          {item.scopeId}
        </Link>
      );
    },
    sortingField: 'scopeId',
    sortingComparator: (i1, i2) => i1.scopeId.localeCompare(i2.scopeId),
  });

  columns.push({
    id: 'snapshots',
    header: 'Enabled Snapshot(s)',
    cell: (item) => {
      const workTypes = item.workCadences.map((cadence) => WORK_TYPE_TO_LABEL[cadence.workType] ?? cadence.workType).sort();
      if (workTypes.length > 2) {
        return (
          <Box>
            {workTypes.slice(0, 2).join(', ')} and {workTypes.length - 2} more
          </Box>
        );
      } else {
        return <Box>{workTypes.slice(0, 2).join(', ')}</Box>;
      }
    },
    sortingField: 'snapshots',
  });

  return columns;
}
