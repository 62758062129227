import React from 'react';
import Textarea from '@amzn/awsui-components-react-v3/polaris/textarea';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import Container from '@amzn/awsui-components-react-v3/polaris/container';

interface Props {
  readonly isEditing: boolean;
  readonly header: string;
  readonly description?: string;
  readonly textList?: ReadonlyArray<string>;
  readonly numberRows?: number;
  readonly inputValidator?: (textList: ReadonlyArray<string>) => boolean;
  readonly notifyUpdate: (textlist: ReadonlyArray<string>) => void;
  readonly notifyValidationResult?: (result: boolean) => void;
}

interface State {
  readonly userInput: string;
  readonly isValidInput: boolean;
}

export class InputBox extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userInput: this.props.textList?.join('\n') ?? '', // for now assume newline separator, should support comma later
      isValidInput: true,
    };
  }

  private renderHeader(title: string, description: string) {
    return (
      <Header variant="h3" description={description}>
        {title}
      </Header>
    );
  }

  private renderTextArea() {
    return (
      <Textarea
        onChange={({ detail }) => {
          this.setState({
            ...this.state,
            userInput: detail.value,
            isValidInput: true,
          });
        }}
        value={this.state.userInput}
        disabled={!this.props.isEditing}
        onBlur={() => this.triggerUpdate()}
        rows={this.props.numberRows}
        invalid={!this.state.isValidInput}
      />
    );
  }

  private convertInput(text: string): ReadonlyArray<string> {
    const trimmedText = text.trim();
    if (trimmedText === '') {
      return [];
    }

    return trimmedText.split('\n').map((line) => line.trim());
  }

  private triggerUpdate() {
    const newText = this.convertInput(this.state.userInput);
    if (this.props.inputValidator) {
      const result = this.props.inputValidator(newText);
      this.setState({ isValidInput: result });
      if (this.props.notifyValidationResult) {
        this.props.notifyValidationResult(result);
      }
    }
    this.props.notifyUpdate(newText);
  }

  render() {
    return <Container header={this.renderHeader(this.props.header ?? '', this.props.description ?? '')}>{this.renderTextArea()}</Container>;
  }
}
