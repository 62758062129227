//LordCommanderMode === AssignmentMode
export type LordCommanderMode = 'ENABLED' | 'DISABLED' | 'SHADOW_MODE';
export type AssignmentExecutor = 'ROUTE_DISPATCH_MANAGER' | 'CORE_PLAN_EXECUTOR';
export type DispatchPlannerType = 'SIMPLE_DISPATCH_PLANNER' | 'FLEX_DISPATCH_PLANNER' | 'FUNGIBLE_ASSIGNMENT_ALGORITHM' | undefined;

export interface AssignmentWorkflow {
  readonly planExecutorConsumeLmdpPlan: boolean;
  readonly lordCommanderMode: LordCommanderMode;
  readonly assignmentConfig?: AssignmentConfig | null;
  readonly assignmentExecutor?: AssignmentExecutor | null;
  readonly dispatchPlannerType?: DispatchPlannerType | null;
}

export type TransportRequestDetailsFacadeType = 'FLEX_ROUTE_ASSIGNMENT_HANDLER' | 'GSF_ASSIGNMENT_HANDLER_WITH_FALLBACK' | 'AMZL_IO_ASSIGNMENT_HANDLER';
export type AssignmentHandlerType = 'GSF_ASSIGNMENT_HANDLER_WITH_FALLBACK' | 'FLEX_ROUTE_ASSIGNMENT_HANDLER' | 'AMZL_IO_ASSIGNMENT_HANDLER';
export type ProviderType = 'DSP | FLEX';

export interface AlgorithmParametersMap {
  readonly dispatchPlannerType?: DispatchPlannerType;
  readonly parameters?: Partial<Record<string, any>>;
  [key: string]: any;
}

export const SUPPORTED_TRANSPORTER_TYPES: ReadonlyArray<string> = ['IO', 'BLOCK', 'OVERFLOW'];
export type TransporterType = (typeof SUPPORTED_TRANSPORTER_TYPES)[number];

/**
 * Based off of Java model found here:
 * https://code.amazon.com/packages/FlexRouteAssignmentTypes/blobs/mainline/--/src/com/amazon/flex/assignment/types/model/AssignmentConfig.java
 *
 */
export interface AssignmentConfig {
  readonly assignmentMode?: LordCommanderMode; //AssignmentMode
  readonly assignmentExecutor?: AssignmentExecutor;
  readonly assignmentHandlerType?: AssignmentHandlerType;
  readonly dispatchPlannerType?: DispatchPlannerType;
  readonly transportRequestDetailsFacadeType?: TransportRequestDetailsFacadeType;
  readonly disableRouteSyncHandler?: boolean;
  readonly executeRoutesIndividually?: boolean;
  readonly routeSyncOnlyOpenRoutes?: boolean;
  readonly routeSyncOnlyAbovePercentRoutes?: boolean;
  readonly enableBatchingSidelinedStatus?: boolean;
  readonly mmotEnabled?: boolean;
  readonly planningHorizonEndInMinutesRelativeToPlanTime?: number;
  readonly planningHorizonStartInMinutesRelativeToPlanTime?: number;
  readonly supportedTransporterTypes?: TransporterType[];
  readonly unsupportedProviderTypes?: ProviderType[];
  readonly transporterReleaseTimeInMinutes?: number;
  readonly onDutyBufferTimeInMinutes?: number;
  readonly operationalWindowsBufferInMinutes?: number;
  readonly maxRouteRejectedCount?: number;
  readonly excludeTransportersAfterRejection?: boolean;
  readonly useProviderSnapshot?: boolean;
  readonly algorithmParametersMap?: AlgorithmParametersMap;
  readonly blockExtensionOptedInProvidersList?: ReadonlyArray<string>;
  readonly enableBlockExtension?: boolean;
  readonly maxBlockExtensionMinutes?: number;
  readonly minBlockExtensionScheduleBufferMinutes?: number;
  readonly enableNurseryAssignment?: boolean;
  readonly maxNurseryBlockUtilization?: number;
  readonly maxNurseryCost?: number;
  readonly [key: string]: any;
  readonly useAutoAssignDenylist?: boolean;
  readonly autoAssignDenylist?: ReadonlyArray<string>;
  readonly useAutoAssignRouteDenylist?: boolean;
  readonly autoAssignRouteDenylist?: ReadonlyArray<string>;
}
