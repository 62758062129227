import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import ConfigProfilePanel, { ApplyProfileResults, ServiceArea } from '../../common-components/config-profile-panel';
import { RoutingConfigProfile } from '../../models/routing-models';
import { config, readable } from '../../utilities';
import { LaunchRoutingServiceAreasResponse } from '../../http-clients/routing-client';
import ROUTING_PROFILE_SORTING_GROUPS from '../routing-config-profiles/routing-profile-sorting-groups';
import { commonClient, routingClient } from '../../http-clients';
import { ServiceAreaInfo } from '../../models';

const breadcrumbs = [
  {
    text: 'Launch new sites',
    href: '/launch-service-areas',
  },
];

interface RoutingLaunchServiceAreasProps {}

interface RoutingLaunchServiceAreasState {
  readonly serviceAreas?: ReadonlyArray<ServiceAreaInfo & { readonly disabled: boolean }>;
  readonly configProfiles?: ReadonlyArray<RoutingConfigProfile>;
  readonly isLoadingServiceAreas: boolean;
  readonly isLoadingConfigProfiles: boolean;
}

export class RoutingLaunchServiceAreas extends React.Component<RoutingLaunchServiceAreasProps, RoutingLaunchServiceAreasState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: RoutingLaunchServiceAreasProps) {
    super(props);
    this.state = {
      isLoadingServiceAreas: false,
      isLoadingConfigProfiles: false,
    };
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.setTools('info-panel');
    this.context.updateBreadcrumbItems(breadcrumbs);
    this.loadingServiceAreas();
    this.loadingConfigProfiles();
  }

  private async loadingServiceAreas() {
    try {
      this.setState({ isLoadingServiceAreas: true });
      const routingServiceAreasResp = await routingClient.listRoutingServiceAreas();
      const routingSaIds = new Set(routingServiceAreasResp.serviceAreaIds);
      const serviceAreaListResp = await commonClient.listServiceAreas();
      this.setState({
        serviceAreas: serviceAreaListResp.serviceAreas.map((sa) => ({
          ...sa,
          // don't allow to launch profiles if the service area is already routing enabled.
          disabled: routingSaIds.has(sa.serviceAreaId),
        })),
      });
    } catch (err) {
      this.context.addNotification({
        header: 'Error',
        dismissible: true,
        type: 'error',
        content: `Failed to load service areas due to ${readable(err)}`,
      });
    } finally {
      this.setState({ isLoadingServiceAreas: false });
    }
  }

  private async loadingConfigProfiles() {
    try {
      this.setState({ isLoadingConfigProfiles: true });
      const resp = await routingClient.getRoutingConfigProfiles({
        scope: 'internal',
        marketplace: config.marketplace,
      });
      this.setState({
        configProfiles: resp.configProfiles,
      });
    } catch (err) {
      this.context.addNotification({
        header: 'Error',
        dismissible: true,
        type: 'error',
        content: `Failed to load routing config profiles due to ${readable(err)}`,
      });
    } finally {
      this.setState({ isLoadingConfigProfiles: false });
    }
  }

  render() {
    return (
      <ConfigProfilePanel
        profileType="routing"
        operatingMode="launch"
        configProfiles={this.state.configProfiles?.filter((profile) => {
          if (this.context.operatingMode !== 'developer' && profile.accessScope === 'INTERNAL') {
            // avoid displaying the internal profile while it's not developer mode.
            return false;
          }
          return true;
        })}
        isLoadingConfigProfiles={this.state.isLoadingConfigProfiles}
        isLoadingServiceAreas={this.state.isLoadingServiceAreas}
        serviceAreasPerRequest={1}
        apply={async (request) => {
          if (request.profileType === 'routing') {
            const resp = await routingClient.launchRoutingServiceAreas({
              serviceAreaIds: request.serviceAreaIds,
              configProfileName: request.configProfileKey,
              description: request.description,
              isPEAutoAssignEnabled: request.isPEAutoAssignEnabled,
            });
            return this.convertResult(resp);
          }
          throw new Error(`Routing config profile UI can't process ${request.profileType} profile`);
        }}
        serviceAreas={this.state.serviceAreas}
        filterServiceAreas={(filteringText, serviceAreas) => {
          const text = filteringText.trim().toLowerCase();
          if (text.length < 2) {
            // Starting filter after user enters 2 letters. We could return too many service areas if we don't have the restriction.
            return [];
          } else {
            return serviceAreas?.filter((sa) => this.match(sa, text));
          }
        }}
        serviceAreaFilteringPlaceholder="Enter 2 letters to start search"
        profileSortingGroups={ROUTING_PROFILE_SORTING_GROUPS}
        hasPermission={this.context.userAccesses ? this.context.userAccesses.includes('ROUTING_CONFIG_WRITE_ACCESS') : true}
        requestPermissionMessage={`You don't have permission to launch routing. Request "Update routing configurations" permission.`}
        accessPermission="routing"
      />
    );
  }

  private match(serviceArea: ServiceArea, text: string): boolean {
    return serviceArea.serviceAreaId.includes(text) || serviceArea.serviceAreaName.toLowerCase().includes(text) || serviceArea.stationCode.toLowerCase().startsWith(text);
  }

  private convertResult(resp: LaunchRoutingServiceAreasResponse): ApplyProfileResults {
    return {
      succeededServiceAreaIds: resp.succeededServiceAreaIds,
      failedServiceAreaIdAndReasons: resp.failedServiceAreaIdAndReasons,
    };
  }
}
