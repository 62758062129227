import { EnrichedCommitRecord } from './models';

export function filterRecords(records: ReadonlyArray<EnrichedCommitRecord>, text: string): ReadonlyArray<EnrichedCommitRecord> {
  text = text.trim().toLowerCase();
  return text.length === 0 ? records : records.filter((r) => match(r, text));
}

function match(record: EnrichedCommitRecord, text: string): boolean {
  return (
    !!record.userAlias?.toLowerCase().includes(text) ||
    record.stationCode.toLowerCase().includes(text) ||
    record.serviceAreaName.toLowerCase().includes(text) ||
    record.serviceAreaId.toLowerCase().includes(text) ||
    !!record.description?.toLowerCase().includes(text) ||
    !!record.clientId?.toLowerCase().includes(text)
  );
}
