import { ValidationInput, ValidationResult } from './models';
import { strictParseFloat } from './utilities';

export function AlwaysPassValidator(input: ValidationInput): ValidationResult {
  return {
    ...input,
    isValid: true,
    hint: undefined,
  };
}

export interface NumberValidatorProps {
  readonly min?: number;
  /**
   * @default true
   */
  readonly minInclusive?: boolean;
  readonly max?: number;
  /**
   * @default true
   */
  readonly maxInclusive?: boolean;
}

export function generateNumberValidator(props: NumberValidatorProps): (input: ValidationInput) => ValidationResult {
  return (input) => {
    const result: ValidationResult = {
      ...input,
      isValid: true,
    };

    let inputValue = input.value;
    if (inputValue === null) {
      /**
       * all configuration parameters are optional
       */
      return result;
    } else if (typeof inputValue === 'string') {
      const num = strictParseFloat(inputValue);
      if (typeof num === 'number') {
        inputValue = num;
      }
    }

    if (typeof inputValue !== 'number') {
      result.isValid = false;
      result.hint = 'Input is not a valid number';
      return result;
    }

    if (typeof props.min === 'number' && typeof props.max === 'number') {
      let isValid = props.minInclusive === undefined || props.minInclusive ? inputValue >= props.min : inputValue > props.min;
      isValid = isValid && (props.maxInclusive === undefined || props.maxInclusive ? inputValue <= props.max : inputValue < props.min);
      if (!isValid) {
        result.isValid = false;
        result.hint = `Input number should be between ${props.min} and ${props.max}`;
      }
    } else if (typeof props.min === 'number') {
      if (props.minInclusive === undefined || props.minInclusive) {
        if (inputValue < props.min) {
          result.isValid = false;
          result.hint = `Input number should be greater than or equal to ${props.min}`;
        }
      } else {
        if (inputValue <= props.min) {
          result.isValid = false;
          result.hint = `Input number should be greater than ${props.min}`;
        }
      }
    } else if (typeof props.max === 'number') {
      if (props.maxInclusive === undefined || props.maxInclusive) {
        if (inputValue > props.max) {
          result.isValid = false;
          result.hint = `Input number should be less than or equal to ${props.max}`;
        }
      } else {
        if (inputValue >= props.max) {
          result.isValid = false;
          result.hint = `Input number should be less than ${props.max}`;
        }
      }
    }
    return result;
  };
}

export type ValidatorName =
  | 'AlwaysPassValidator'
  | 'NumberValidator'
  | 'PositiveNumberValidator'
  | 'NonNegativeValidator'
  | 'Negative360ValueOrGreaterValidator'
  | 'ValueBetween-360And2880Validator'
  | 'ValueBetween0And90Validator'
  | 'ValueBetween0And900Validator'
  | 'ValueBetween0And5000Validator'
  | 'ValueBetween0And5Validator'
  | 'ValueBetween5And30Validator'
  | 'ValueBetweenNeg90And0Validator'
  | 'ValueBetween1And10Validator'
  | 'ValueBetween0And10Validator'
  | 'ValueBetween0And1Validator'
  | 'ValueBetween-1And240Validator'
  | 'ValueBetween0And120Validator'
  | 'ValueBetween10And300Validator'
  | 'ValueBetween-600And300Validator'
  | 'ValueBetween-300And300Validator'
  | 'ValueBetween-600And720Validator'
  | 'ValueBetween-300And720Validator';

export const validatorFactory: Record<ValidatorName, (input: ValidationInput) => ValidationResult> = {
  AlwaysPassValidator: AlwaysPassValidator,
  NumberValidator: generateNumberValidator({}),
  NonNegativeValidator: generateNumberValidator({ min: 0, minInclusive: true }), // >= 0
  PositiveNumberValidator: generateNumberValidator({ min: 1, minInclusive: true }), // > 0
  Negative360ValueOrGreaterValidator: generateNumberValidator({ min: -360, minInclusive: true }), // >= -360,
  'ValueBetween-360And2880Validator': generateNumberValidator({ min: -360, max: 2880 }),
  ValueBetween0And90Validator: generateNumberValidator({ min: 0, max: 90 }),
  ValueBetween0And900Validator: generateNumberValidator({ min: 0, max: 900 }),
  ValueBetween0And5000Validator: generateNumberValidator({ min: 0, max: 5000 }),
  ValueBetween0And5Validator: generateNumberValidator({ min: 0, max: 5 }),
  ValueBetween5And30Validator: generateNumberValidator({ min: 5, max: 30 }),
  ValueBetweenNeg90And0Validator: generateNumberValidator({ min: -90, max: 0 }),
  ValueBetween1And10Validator: generateNumberValidator({ min: 1, max: 10 }),
  ValueBetween0And10Validator: generateNumberValidator({ min: 0, max: 10 }),
  ValueBetween0And1Validator: generateNumberValidator({ min: 0, max: 1 }),
  'ValueBetween-1And240Validator': generateNumberValidator({ min: -1, max: 240 }),
  ValueBetween0And120Validator: generateNumberValidator({ min: 0, max: 120 }),
  ValueBetween10And300Validator: generateNumberValidator({ min: 10, max: 300 }),
  'ValueBetween-600And300Validator': generateNumberValidator({ min: -600, max: 300 }),
  'ValueBetween-300And300Validator': generateNumberValidator({ min: -300, max: 300 }),
  'ValueBetween-600And720Validator': generateNumberValidator({ min: -600, max: 720 }),
  'ValueBetween-300And720Validator': generateNumberValidator({ min: -300, max: 720 }),
};
