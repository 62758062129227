import {
  GetAssignmentConfigProfilesRequest,
  GetAssignmentConfigProfilesResponse,
  GetAssignmentWorkflowResponse,
  LaunchAssignmentServiceAreasRequest,
  LaunchAssignmentServiceAreasResponse,
  PutAssignmentServiceAreaDetailsRequest,
  PutAssignmentServiceAreaDetailsResponse,
} from './request-response';
import { AssignmentClient } from './assignment-client';
import { AxiosInstance } from 'axios';

export class AssignmentClientImpl implements AssignmentClient {
  private readonly httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  async getAssignmentWorkflow(serviceAreaId: string): Promise<GetAssignmentWorkflowResponse> {
    const resp = await this.httpClient.get<GetAssignmentWorkflowResponse>(`/assignment-workflow?serviceAreaId=${serviceAreaId}`);
    return resp.data;
  }

  async putAssignmentServiceAreaDetails(request: PutAssignmentServiceAreaDetailsRequest): Promise<PutAssignmentServiceAreaDetailsResponse> {
    const resp = await this.httpClient.put<PutAssignmentServiceAreaDetailsResponse>(
      `/assignment-service-area-details?serviceAreaId=${request.serviceAreaId}`,
      {
        description: request.description,
        assignmentConfig: request.assignmentConfig,
      },
      {
        headers: {
          'content-type': 'application/json',
        },
      },
    );
    return resp.data;
  }

  async getAssignmentConfigProfiles(request: GetAssignmentConfigProfilesRequest): Promise<GetAssignmentConfigProfilesResponse> {
    const resp = await this.httpClient.get<GetAssignmentConfigProfilesResponse>(`/assignment-config-profiles?marketplace=${request.marketplace}`);
    return resp.data;
  }

  async launchAssignmentServiceAreas(request: LaunchAssignmentServiceAreasRequest): Promise<LaunchAssignmentServiceAreasResponse> {
    const resp = await this.httpClient.post<LaunchAssignmentServiceAreasResponse>(`/launch-assignment-config-profile`, request, {
      headers: {
        'content-type': 'application/json',
      },
    });
    return resp.data;
  }
}
