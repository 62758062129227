import moment from 'moment-timezone';

// formatter: 2021-05-13 12:34:43 PDT
export const PREFERRED_FORMAT = 'YYYY-MM-DD HH:mm:ss z';

interface Options {
  readonly tz: string;
  readonly format: string;
}

export type TimezonePreference = 'user_tz' | 'station_tz' | 'utc_tz';

export const TIMEZONE_PREFERENCE_TO_LABEL: Record<TimezonePreference, string> = {
  station_tz: 'Station',
  user_tz: 'User',
  utc_tz: 'UTC',
};

export const DEFAULT_TIMEZONE_PREFERENCE: TimezonePreference = 'station_tz';

export class TimezoneManager {
  private timezonePreference: TimezonePreference;
  private preferredFormat: string;

  private stationTimezone?: string;
  private userTimezone: string;

  constructor() {
    this.timezonePreference = DEFAULT_TIMEZONE_PREFERENCE;
    this.userTimezone = moment.tz.guess();
    this.preferredFormat = PREFERRED_FORMAT;
  }

  setPreferredFormat(format: string) {
    this.preferredFormat = format;
  }

  getUserTimezone(): string {
    return this.userTimezone;
  }

  setStationTimezone(stationTimezone?: string) {
    if (typeof stationTimezone === 'string') {
      if (!moment.tz.names().includes(stationTimezone)) {
        throw new Error(`Unknown timezone ${stationTimezone}`);
      }
    }
    this.stationTimezone = stationTimezone;
  }

  getStationTimezone(): string | undefined {
    return this.stationTimezone;
  }

  setTimezonePreference(preference: TimezonePreference) {
    this.timezonePreference = preference;
  }

  getTimezonePreference(): TimezonePreference {
    return this.timezonePreference;
  }

  getInUseTimezonePreference(): TimezonePreference {
    if (this.timezonePreference === 'station_tz') {
      const stationTimezone = this.getStationTimezone();
      if (typeof stationTimezone === 'string') {
        return 'station_tz';
      } else {
        return 'user_tz';
      }
    }
    return this.timezonePreference;
  }

  getPreferredTimezone(): string {
    if (this.timezonePreference === 'station_tz') {
      const stationTimezone = this.getStationTimezone();
      if (typeof stationTimezone === 'string') {
        return stationTimezone;
      }
    } else if (this.timezonePreference === 'user_tz') {
      return this.userTimezone;
    } else if (this.timezonePreference === 'utc_tz') {
      return 'UTC';
    }
    // fall back to user timezone.
    return this.userTimezone;
  }

  // offset in minutes
  getUTCOffset(): number {
    return moment().tz(this.getPreferredTimezone()).utcOffset();
  }

  convertTimestampToString(time: number | Date | string, options?: Partial<Options>): string {
    const options_: Options = {
      tz: this.getPreferredTimezone(),
      format: this.preferredFormat,
      ...options,
    };

    return moment(time).tz(options_.tz).format(options_.format);
  }
}
