import { ConfigTableDefinition } from '../../../models';

export const FDP_CONFIGURATIONS: ConfigTableDefinition = {
  headerText: 'FDP Algorithm Configurations',
  description: 'Control the core FDP assignment algorithm.',
  valueColMinWidth: '150px',
  sizeOnGrid: 'default',
  items: [
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.io_almost_late_initial_minutes',
      scope: 'assignmentConfig',
      name: 'IO Delay Cost Factor',
      representationType: 'number',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.io_almost_late_laput_cost_per_minute',
      scope: 'assignmentConfig',
      name: 'IO Almost Late LaPut Cost Per Minute',
      representationType: 'number',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.io_cost_per_minute',
      scope: 'assignmentConfig',
      name: 'IO Cost Per Minute',
      representationType: 'number',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.io_search_radius_minutes',
      scope: 'assignmentConfig',
      name: 'IO Search Radius Minutes',
      representationType: 'number',
      validators: [
        {
          name: 'ValueBetween-1And240Validator',
        },
      ],
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.io_switch_cost',
      scope: 'assignmentConfig',
      name: 'IO Switch Cost',
      representationType: 'number',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.late_delivery_impulse_coef',
      scope: 'assignmentConfig',
      name: 'Late Delivery Impulse Coef',
      representationType: 'number',
      validators: [
        {
          name: 'NonNegativeValidator',
        },
      ],
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.late_delivery_quadratic_coef',
      scope: 'assignmentConfig',
      name: 'Late Delivery Quadratic Coef',
      representationType: 'number',
      validators: [
        {
          name: 'NonNegativeValidator',
        },
      ],
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.mip_max_seconds',
      scope: 'assignmentConfig',
      name: 'Mip Max Seconds',
      representationType: 'number',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.pickup_travel_time_coef',
      scope: 'assignmentConfig',
      name: 'Pickup Travel Time Coef',
      representationType: 'number',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.prompt_delivery_coef',
      scope: 'assignmentConfig',
      name: 'Prompt Delivery Coef',
      representationType: 'number',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.stop_duration_block_pickup_entry_portion',
      scope: 'assignmentConfig',
      name: 'Stop Duration Block Pickup Entry Portion',
      representationType: 'number',
      validators: [
        {
          name: 'ValueBetween0And1Validator',
        },
      ],
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.stop_duration_io_pickup_entry_portion',
      scope: 'assignmentConfig',
      name: 'Stop Duration IO Pickup Entry Portion',
      representationType: 'number',
      validators: [
        {
          name: 'ValueBetween0And1Validator',
        },
      ],
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.use_expected_slam_model',
      scope: 'assignmentConfig',
      name: 'Use Expected Slam Model',
      representationType: 'boolean',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.use_ppst_as_cook_dt',
      scope: 'assignmentConfig',
      name: 'Use PPST As Cook DT',
      representationType: 'boolean',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.wait_time_coef',
      scope: 'assignmentConfig',
      name: 'Wait Time Coef',
      representationType: 'number',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.minutes_to_complete_sidelined_tasks',
      scope: 'assignmentConfig',
      name: 'Minutes to Complete Sidelined Tasks',
      representationType: 'number',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.block_wait_to_offer_minutes_coef',
      scope: 'assignmentConfig',
      name: 'Block Wait to Offer Minutes Coef',
      representationType: 'number',
    },
    {
      field: 'updateInboundStemDistance',
      scope: 'assignmentConfig',
      name: 'Update Inbound Steam Distance',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'useUbpBatchTasksPrioritizationPlan',
      scope: 'assignmentConfig',
      name: 'Use UBP Batch Tasks Prioritization Plan',
      representationType: 'boolean',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.use_ubp_batch_eta',
      scope: 'assignmentConfig',
      name: 'Use UBP Batch ETA in FDP',
      representationType: 'boolean',
    },
    {
      field: 'usePickupReadyPlan',
      scope: 'assignmentConfig',
      name: 'Use Pickup Ready Plan',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.log_pickup_ready_dt_diff_against_cook_dt',
      scope: 'assignmentConfig',
      name: 'Pickup Readiness Shadow Mode in FDP',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.use_pickup_ready_etas',
      scope: 'assignmentConfig',
      name: 'Use Pickup Ready Etas in FDP',
      description: 'Uses pickup readiness estimates provided by PUPTech instead of FDP CookDtService calculated cook_dt for assignment planning runs',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.use_pickup_ready_duration',
      scope: 'assignmentConfig',
      name: 'Use Pickup Ready Durations for UBP',
      description: 'Uses batch task durations provided by PUPTech in UBP planning runs instead of CookDtService calculations',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'useTransporterScheduleSelectorForFdp',
      scope: 'assignmentConfig',
      name: 'Use TransporterScheduleSelector Filter in FDP',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'useAssignedRouteAndTransporterFilterForFdp',
      scope: 'assignmentConfig',
      name: 'Use AssignedRouteAndTransporterFilter in FDP',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.use_overflow_transporters',
      scope: 'assignmentConfig',
      name: 'Use Overflow Transporters in FDP',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.overflow_fixed_cost',
      scope: 'assignmentConfig',
      name: 'Overflow Fixed Cost',
      representationType: 'number',
      visibility: 'developer-only',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.overflow_cost_coefficient',
      scope: 'assignmentConfig',
      name: 'Overflow Cost Coefficient',
      representationType: 'number',
      visibility: 'developer-only',
    },
    {
      field: 'mmotEnabled',
      scope: 'assignmentConfig',
      name: 'Enable Multiple Modes of Transport (MMOT)',
      representationType: 'boolean',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.nursery_target_max_utilization_ratio',
      scope: 'assignmentConfig',
      name: 'Maximum Nursery Block Utilization',
      description: 'Maximum percent of block duration a nursery transporter can work to deliver a route.',
      representationType: 'number',
      validators: [
        {
          name: 'ValueBetween0And1Validator',
        },
      ],
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.nursery_underutilization_coef',
      scope: 'assignmentConfig',
      name: 'Cost Coefficient for under-utilizing nursery block',
      description: 'Additional cost for assigning routes to nursery transporter.',
      representationType: 'number',
      validators: [
        {
          name: 'ValueBetween0And900Validator',
        },
      ],
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.batch_minutes_per_package',
      scope: 'assignmentConfig',
      name: 'Batch Minutes Per Package',
      representationType: 'number',
      visibility: 'developer-only',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.packages_per_cart',
      scope: 'assignmentConfig',
      name: 'Packages Per Cart',
      description: 'Specifies packages per cart, used in PUPLMOrcSnapshot to add time for extra carts',
      representationType: 'number',
      visibility: 'developer-only',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.time_per_extra_route_cart',
      scope: 'assignmentConfig',
      name: 'Time Per Extra Route Cart',
      description: 'Constant multiplied by number of extra carts, used in PUPLMOrcSnapshot to add time for extra carts',
      representationType: 'number',
      visibility: 'developer-only',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.block_overrun_max_minutes',
      scope: 'assignmentConfig',
      name: 'Block Overrun Max Minutes',
      description: 'Defines number of minutes after a block for which FDP can still assign routes (block elongation)',
      representationType: 'number',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.make_unprepared_block_offers',
      scope: 'assignmentConfig',
      name: 'Assign Not Prepared Block Routes',
      description: 'Whether allow FDP to assign not prepared/ready routes to block drivers.',
      representationType: 'boolean',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.make_unslammed_block_offers',
      scope: 'assignmentConfig',
      name: 'Assign Not Batched Block Routes',
      description: 'This is an old config that will be deprecated. But for now, it needs to be the same as Assign Not Prepared Block Routes',
      representationType: 'boolean',
    },
    {
      field: 'algorithmParametersMap.FLEX_DISPATCH_PLANNER.sort_remove_actions_by_initial_order_id_sequence',
      scope: 'assignmentConfig',
      name: 'Sort orders in a delivery stop',
      description: 'If enabled, we will sort dropffs by orderId in a delivery stop. The feature is to fix FDP double counting stop duration issue.',
      representationType: 'boolean',
    },
  ],
};
