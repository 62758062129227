/**
 * path /dria-scopes/{region|service-area}/{scopeId}
 */
import React from 'react';
import Tabs from '@amzn/awsui-components-react-v3/polaris/tabs';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Alert from '@amzn/awsui-components-react-v3/polaris/alert';
import ConfigJSONEditor from '../../common-components/config-json-editor';
import { GlobalContext } from '../../main-app/global-context';
import { Region, ServiceArea } from '../../models';
import { DriaConfig } from '../../models/dria-models';
import { DriaWorkCadence, ScopeType } from '../../http-clients/dria-client';
import { ConfigAuditingTable, ConfigAuditingTableItem } from '../../common-components/config-auditing-table';
import { DrasScopeCommitRecord } from '../../models/dras-scope-models';
import { commonClient, drasScopeConfigClient, driaClient } from '../../http-clients';
import { asleep, deepClone, readable } from '../../utilities';
import { DriaConfigFormEditor } from './dria-config-form-editor';
import { ConfigUpdateConfirmModal } from './config-update-confirm-modal';
import { DriaScopeSummary } from './dria-scope-summary';

export interface DriaScopeDetailsProps {
  readonly scopeId: string;
  readonly scopeType: string;
  readonly activeTabId?: string;
  readonly switchTab: (tabId: string) => void;
}

export interface DriaScopeDetailsState {
  readonly isLoading: boolean;
  readonly displayConfirmModal: boolean;
  readonly errorMessage?: string;
  readonly isSaving: boolean;

  readonly serviceArea?: ServiceArea;
  readonly region?: Region;
  readonly workCadences?: ReadonlyArray<DriaWorkCadence>;
  readonly isFormInEditing: boolean;
  readonly driaConfig?: DriaConfig | null;

  readonly isReverting: boolean;
  readonly hasMoreCommitRecords: boolean;
  readonly commitRecords?: ReadonlyArray<ConfigAuditingTableItem<DrasScopeCommitRecord>>;
}

const SCOPE_TYPE_STRING_TO_SCOPE_TYPE: Map<string, ScopeType> = new Map();
SCOPE_TYPE_STRING_TO_SCOPE_TYPE.set('region', 'REGION');
SCOPE_TYPE_STRING_TO_SCOPE_TYPE.set('service-area', 'SERVICE_AREA');
const DEFAULT_TAB = 'forms';
const UPDATE_RECORDS_PER_REQUEST = 50;

export class DriaScopeDetails extends React.Component<DriaScopeDetailsProps, DriaScopeDetailsState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  // Reset state to the backup config when editing is discarded.
  private driaConfigBackup?: DriaConfig | null;

  constructor(props: DriaScopeDetailsProps) {
    super(props);
    this.state = {
      isLoading: false,
      isSaving: false,
      isFormInEditing: false,
      displayConfirmModal: false,
      isReverting: false,
      hasMoreCommitRecords: false,
    };
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.setTools('info-panel');

    if (!SCOPE_TYPE_STRING_TO_SCOPE_TYPE.has(this.props.scopeType)) {
      this.context.addNotification({
        type: 'warning',
        header: 'Invalid url',
        content: `Invalid url, bad scope type ${this.props.scopeType}.`,
      });
      return;
    }

    const breadcrumbsItems = [
      {
        text: 'DRIA Regions and Service Areas',
        href: '/dria-scopes',
      },
      {
        text: this.props.scopeId,
        href: `/dria-scopes/${this.props.scopeType === 'region' ? 'region' : 'service-area'}/${this.props.scopeId}`,
      },
    ];

    this.context.updateBreadcrumbItems(breadcrumbsItems);
    this.loadScope();
    this.refreshConfigs();
    this.loadCommitRecords(false);
  }

  async componentWillUnmount() {
    this.context.updateStationTimezone(undefined);
  }

  private async loadScope() {
    this.setState({ serviceArea: undefined, region: undefined });

    const scopeType = SCOPE_TYPE_STRING_TO_SCOPE_TYPE.get(this.props.scopeType);
    if (scopeType === undefined) {
      return;
    }

    try {
      if (scopeType === 'SERVICE_AREA') {
        const response = await commonClient.getServiceArea(this.props.scopeId);
        this.setState({ serviceArea: response.serviceArea });
      } else if (scopeType === 'REGION') {
        // todo: provide a backend API that provides region data, like region name, etc
        this.setState({ region: { regionId: this.props.scopeId } });
      }

      const driaScopeListResp = await driaClient.getDriaScopeInfoList({});
      const workCadences = driaScopeListResp.scopeInfos.find((item) => item.scopeId === this.props.scopeId && item.scopeType === scopeType)?.workCadences;
      this.setState({ workCadences: workCadences });
    } catch (err: any) {
      this.context.addNotification({
        header: 'Error',
        content: `Failed to load scope data due to ${readable(err)}`,
        type: 'error',
        dismissible: true,
      });
    }
  }

  private async refreshConfigs() {
    this.setState({ driaConfig: undefined });

    try {
      const response = await drasScopeConfigClient.getDrasScopeConfig<DriaConfig>({
        scopeId: this.props.scopeId,
        configType: 'DRIA_CONFIG', // todo: for region, we will use a different configType/artifactType
      });
      this.setState({ driaConfig: response.config });
    } catch (err: any) {
      if (typeof err?.response === 'object' && err?.response?.status === 404) {
        // It's expected that some service areas or regions may not have a DRIA config.
        // In this case, DRIA will use configurations with default value.
        this.setState({ driaConfig: null });
        return;
      }
      this.context.addNotification({
        header: 'Error',
        content: `Failed to load DRIAConfig due to ${readable(err)}`,
        type: 'error',
        dismissible: true,
      });
    }
  }

  private async loadCommitRecords(loadMore: boolean) {
    let before = new Date().toISOString();
    if (loadMore && this.state.commitRecords && this.state.commitRecords.length > 0) {
      before = new Date(this.state.commitRecords[this.state.commitRecords.length - 1].commit.lastUpdateAt).toISOString();
    }

    try {
      const response = await drasScopeConfigClient.listDrasScopeConfigHistory({
        scopeId: this.props.scopeId,
        configType: 'DRIA_CONFIG',
        before: before,
        numberOfRecords: UPDATE_RECORDS_PER_REQUEST,
      });

      const records = loadMore ? Array.from(this.state.commitRecords ?? []) : [];
      const newRecords = response.records.sort((r1, r2) => r2.createdAt.localeCompare(r1.createdAt));
      newRecords.forEach((record) => {
        records.push({
          label: records.length === 0 ? 'latest' : undefined,
          commit: {
            commitId: record.configId,
            serviceAreaId: this.props.scopeId,
            clientId: record.clientId,
            description: record.description,
            lastUpdateAt: new Date(record.createdAt).getTime(),
            userAlias: record.userAlias,
          },
        });
      });

      this.setState({
        hasMoreCommitRecords: response.records.length === UPDATE_RECORDS_PER_REQUEST,
        commitRecords: records,
      });
    } catch (err: any) {
      this.context.addNotification({
        header: 'Error',
        content: `Failed to load DRIAConfig history due to ${readable(err)}`,
        type: 'error',
        dismissible: true,
      });
    }
  }

  private async saveConfigurations(description: string) {
    const driaConfig = this.state.driaConfig;

    if (!driaConfig) {
      return;
    }

    this.setState({ isSaving: true });
    try {
      await drasScopeConfigClient.putDrasScopeConfig({
        scopeId: this.props.scopeId,
        description: description,
        configType: 'DRIA_CONFIG',
        serializedConfig: JSON.stringify(driaConfig),
      });

      // sleep 2000 ms to wait DRAS to populate its index.
      await asleep(2000);
      this.setState({ displayConfirmModal: false, isFormInEditing: false });

      this.driaConfigBackup = undefined;

      this.refreshConfigs();
      this.loadCommitRecords(false);
    } catch (err) {
      this.setState({ errorMessage: `Failed to save DRIA config due to ${readable(err)}` });
    } finally {
      this.setState({ isSaving: false });
    }
  }

  private async revertConfigs(record: DrasScopeCommitRecord) {
    try {
      this.setState({ isReverting: true });

      await drasScopeConfigClient.revertDrasScopeConfigRequest({
        configId: record.commitId,
        configType: 'DRIA_CONFIG',
        scopeId: this.props.scopeId,
        description: `Revert configuration to [${record.commitId}] "${record.description}"`,
      });
      // sleep 2000 ms to wait DRAS to populate its index.
      await asleep(2000);
      this.driaConfigBackup = undefined;

      this.refreshConfigs();
      this.loadCommitRecords(false);
    } catch (err) {
      this.setState({ errorMessage: `Failed to revert configurations to ${record.commitId} due to ${readable(err)}` });
    } finally {
      this.setState({ isReverting: false });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderConfirmationModal()}
        <SpaceBetween direction="vertical" size="m">
          {this.renderErrorMessage()}
          <SpaceBetween direction="vertical" size="l">
            {this.renderScopeSummary()}
            {this.renderTabs()}
          </SpaceBetween>
        </SpaceBetween>
      </React.Fragment>
    );
  }

  private renderErrorMessage() {
    if (typeof this.state.errorMessage === 'string') {
      return (
        <Alert
          header="Error"
          type="error"
          dismissible={true}
          dismissAriaLabel="Close Alert"
          onDismiss={() => {
            this.setState({ errorMessage: undefined });
          }}
        >
          {this.state.errorMessage}
        </Alert>
      );
    }
  }

  private renderScopeSummary() {
    return <DriaScopeSummary serviceArea={this.state.serviceArea} region={this.state.region} workCadences={this.state.workCadences} />;
  }

  private renderTabs() {
    const activeTabId = this.props.activeTabId ? this.props.activeTabId : DEFAULT_TAB;

    return (
      <Tabs
        activeTabId={activeTabId}
        tabs={[
          {
            label: 'Forms Editor',
            id: 'forms',
            content: this.renderFormEditor(),
          },
          {
            label: 'Update History',
            id: 'history',
            content: this.renderUpdateHistory(),
          },
          {
            label: 'JSON Viewer',
            id: 'json',
            content: <ConfigJSONEditor headerText="JSON Viewer" isLoading={this.state.isLoading} data={this.state.driaConfig ?? (this.state.driaConfig === undefined ? 'loading...' : 'N/A')} />,
          },
        ]}
        onChange={(evt) => this.props.switchTab(evt.detail.activeTabId)}
      />
    );
  }

  private renderConfirmationModal() {
    if (this.state.displayConfirmModal) {
      return (
        <ConfigUpdateConfirmModal
          scope={this.props.scopeId}
          isSaving={this.state.isSaving}
          onCancel={() => {
            this.setState({ displayConfirmModal: false });
          }}
          onConfirm={async (description: string) => {
            await this.saveConfigurations(description);
          }}
        />
      );
    }
  }

  private renderFormEditor() {
    return (
      <DriaConfigFormEditor
        isEditing={this.state.isFormInEditing}
        isSaving={this.state.isSaving}
        driaConfig={this.state.driaConfig}
        onEdit={() => {
          this.driaConfigBackup = this.state.driaConfig;
          this.setState({
            driaConfig: this.driaConfigBackup ? deepClone(this.driaConfigBackup) : undefined,
            isFormInEditing: true,
          });
        }}
        onDiscard={() => {
          this.setState({
            driaConfig: this.driaConfigBackup,
            isFormInEditing: false,
          });
          this.driaConfigBackup = undefined;
        }}
        onSave={() => this.setState({ displayConfirmModal: true })}
        onUpdate={(driaConfig) => {
          this.setState({
            driaConfig: driaConfig,
          });
        }}
      />
    );
  }

  private renderUpdateHistory() {
    return (
      <ConfigAuditingTable
        serviceAreaId={this.props.scopeId}
        timezonePreference={this.context.timezonePreference}
        itemsPerPage={10}
        items={this.state.commitRecords}
        onRefresh={() => this.loadCommitRecords(false)}
        isReverting={this.state.isReverting}
        onRevert={async (commit) => await this.revertConfigs(commit)}
        hasMore={this.state.hasMoreCommitRecords}
        onLoadMore={() => this.loadCommitRecords(true)}
        loadConfig={async (commitId: string) => {
          try {
            const resp = await drasScopeConfigClient.getDrasScopeConfig({
              scopeId: this.props.scopeId,
              configId: commitId,
              configType: 'DRIA_CONFIG',
            });
            return resp.config;
          } catch (err) {
            this.setState({ errorMessage: `Failed to load config ${commitId} configurations due to ${readable(err)}` });
            return undefined;
          }
        }}
      />
    );
  }
}
