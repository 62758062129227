import React from 'react';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import Container from '@amzn/awsui-components-react-v3/polaris/container';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import FormField from '@amzn/awsui-components-react-v3/polaris/form-field';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import { SelectProps } from '@amzn/awsui-components-react-v3/polaris/select';
import Multiselect from '@amzn/awsui-components-react-v3/polaris/multiselect';
import Alert from '@amzn/awsui-components-react-v3/polaris/alert';
import Cards from '@amzn/awsui-components-react-v3/polaris/cards';
import { ConfigSchedulePanelProps, ConfigSchedulePanelState, ServiceArea } from './models';
import { BulkUploadModal } from './bulk-upload-modal';
import { ConfigSettingsTable } from './config-setting-table';
import { configScheduleClient } from '../../http-clients';
import Box from '@amzn/awsui-components-react-v3/polaris/box';

export class ConfigSchedulePanel extends React.Component<ConfigSchedulePanelProps, ConfigSchedulePanelState> {
  constructor(props: ConfigSchedulePanelProps) {
    super(props);
    this.state = {
      showConfirmationModal: false,
      selectedServiceAreaIds: [],
      serviceAreaFilteringText: '',
      enableAddConfigButton: true,
      configScheduleItems: new Map(),
      showBulkUploadModal: false,
      editedValue: '',
    };
  }

  componentDidMount() {
    this.setState({ filteredServiceAreas: this.filterServiceAreas(this.state.serviceAreaFilteringText, this.props.serviceAreas) });
  }

  render() {
    return (
      <React.Fragment>
        {this.renderBulkUploadModal()}
        {this.renderErrorMessage()}
        <Container
          header={
            <Header
              variant="h2"
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="primary" onClick={() => this.setState({ showBulkUploadModal: true })}>
                    Bulk Upload
                  </Button>
                </SpaceBetween>
              }
            >
              Config Schedules
            </Header>
          }
        >
          {this.renderServiceAreasMultiSelect()}
        </Container>
        <Box margin={'m'}></Box>
        {this.renderConfigScheduleTables()}
      </React.Fragment>
    );
  }

  handleValueEdited = (newEditedValue: string) => {
    this.setState({ editedValue: newEditedValue });
  };

  handleOnError = (error: string) => {
    this.setState({ errorMessage: error });
  };

  private renderConfigScheduleTables() {
    const serviceAreaIdToServiceArea: Map<string, ServiceArea> = new Map();
    this.props.serviceAreas.forEach((sa) => serviceAreaIdToServiceArea.set(sa.serviceAreaId, sa));

    return (
      <SpaceBetween direction="vertical" size="m">
        {this.state.selectedServiceAreaIds.map((serviceAreaId) => {
          return (
            <ConfigSettingsTable
              key={serviceAreaId}
              items={this.state.configScheduleItems.get(serviceAreaId) ?? []}
              scopeId={serviceAreaId}
              serviceArea={serviceAreaIdToServiceArea.get(serviceAreaId)}
              onValueEdited={this.handleValueEdited}
              onSubmitSuccess={this.handleSubmitSuccess}
              onError={this.handleOnError}
            />
          );
        })}
      </SpaceBetween>
    );
  }

  handleSubmitSuccess = (scopeId: string) => {
    this.setState({ errorMessage: undefined });
    this.refreshRecords(scopeId);
  };

  private async getConfigSchedulesList(scopeIdsToKeep: string[], scopeIdsToRefresh: string[]) {
    this.setState({ errorMessage: undefined });
    try {
      // Fetch configurations for the scope IDs to refresh
      const resp = await configScheduleClient.getConfigSchedules({ scopeIds: scopeIdsToRefresh });

      // Initialize the new map with the existing state for the scope IDs to keep
      const newScheduleItemMap = new Map(this.state.configScheduleItems);

      Object.entries(resp.schedules).forEach((entry) => {
        newScheduleItemMap.set(entry[0], entry[1]);
      });

      for (const scopeId of Array.from(newScheduleItemMap.keys())) {
        if (!scopeIdsToKeep.includes(scopeId) && !scopeIdsToRefresh.includes(scopeId)) {
          newScheduleItemMap.delete(scopeId);
        }
      }

      this.setState({ configScheduleItems: newScheduleItemMap });
    } catch (error) {
      this.handleOnError(`Error fetching Config schedules due to ${error}`);
      this.setState({ configScheduleItems: new Map() });
    }
  }

  private renderServiceAreasMultiSelect() {
    const options: ReadonlyArray<{ readonly value: string } & SelectProps.Option> = (this.props.serviceAreas ?? []).map((serviceArea) => {
      return {
        value: serviceArea.serviceAreaId,
        label: serviceArea.stationCode,
        description: `${serviceArea.stationCode} - ${serviceArea.serviceAreaName} - ${serviceArea.serviceAreaId}`,
      };
    });

    const selectedOptions = options.filter((o) => this.state.selectedServiceAreaIds.includes(o.value));
    const filteredServiceAreaIds = new Set();
    this.state.filteredServiceAreas?.forEach((sa) => filteredServiceAreaIds.add(sa.serviceAreaId));
    const optionsInDisplay = options.filter((o) => filteredServiceAreaIds.has(o.value));

    return (
      <FormField label="Service areas">
        <Multiselect
          statusType={this.props.isLoadingServiceAreas ? 'loading' : 'finished'}
          options={optionsInDisplay}
          placeholder="Choose service areas"
          filteringType={'manual'}
          filteringPlaceholder="Enter 2 letters to start search"
          onChange={(evt) => {
            const serviceAreaIds = evt.detail.selectedOptions.map((o) => o.value as string);
            if (serviceAreaIds.length > 5) {
              this.setState({ errorMessage: 'You can only select up to 5 Service Area Ids.' });
              return;
            }
            this.setState({ selectedServiceAreaIds: serviceAreaIds });
            this.setState({ selectedServiceAreaIds: serviceAreaIds }, () => {
              // Call getConfigSchedulesList after the state is updated
              this.getConfigSchedulesList(serviceAreaIds, serviceAreaIds);
            });
            serviceAreaIds.length > 0 && this.setState({ enableAddConfigButton: false });
            serviceAreaIds.length == 0 && this.setState({ enableAddConfigButton: true });
          }}
          onLoadItems={(evt: { detail: { filteringText: string } }) => {
            this.setState({ serviceAreaFilteringText: evt.detail.filteringText });
            this.setState({ filteredServiceAreas: this.filterServiceAreas(evt.detail.filteringText, this.props.serviceAreas) });
          }}
          selectedOptions={selectedOptions}
        ></Multiselect>
      </FormField>
    );
  }

  filterServiceAreas = (filteringText: string, serviceAreas: readonly ServiceArea[]) => {
    const text = filteringText.trim().toLowerCase();
    if (text.length < 2) {
      // Starting filter after user enters 2 letters. We could return too many service areas if we don't have the restriction.
      return [];
    } else {
      return serviceAreas?.filter((sa: ServiceArea) => this.match(sa, text));
    }
  };

  private match(serviceArea: ServiceArea, text: string): boolean {
    return serviceArea.serviceAreaId.includes(text) || serviceArea.serviceAreaName.toLowerCase().includes(text) || serviceArea.stationCode.toLowerCase().startsWith(text);
  }

  private refreshRecords = (serviceAreaIdToRefresh: string) => {
    if (serviceAreaIdToRefresh.length > 1) {
      this.getConfigSchedulesList([...this.state.selectedServiceAreaIds], [serviceAreaIdToRefresh]);
    } else {
      this.getConfigSchedulesList([...this.state.selectedServiceAreaIds], [...this.state.selectedServiceAreaIds]);
    }
  };

  private renderErrorMessage() {
    if (typeof this.state.errorMessage === 'string') {
      return (
        <Alert
          header="Error"
          type="error"
          dismissible={true}
          dismissAriaLabel="Close Alert"
          onDismiss={() => {
            this.setState({ errorMessage: undefined });
          }}
        >
          {this.state.errorMessage}
        </Alert>
      );
    }
  }

  private renderBulkUploadModal() {
    const { showBulkUploadModal } = this.state;

    if (!showBulkUploadModal) return null;

    const handleDismiss = () => {
      this.setState({ showBulkUploadModal: false });
    };

    if (this.state.showBulkUploadModal) {
      return <BulkUploadModal onDismiss={handleDismiss} onSubmitSuccess={this.handleSubmitSuccess} />;
    }
  }
}
