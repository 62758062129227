import {
  GetConfigSchedulesRequest,
  GetConfigSchedulesResponse,
  CreateConfigSchedulesRequest,
  CreateConfigSchedulesResponse,
  UpdateConfigSchedulesRequest,
  UpdateConfigSchedulesResponse,
  BulkUploadConfigSchedulesRequest,
  BulkUploadConfigSchedulesResponse,
  DeleteConfigSchedulesRequest,
  DeleteConfigSchedulesResponse,
} from './request-response';
import { ConfigScheduleClient } from './config-schedule-client';
import { AxiosInstance } from 'axios';

export class ConfigScheduleClientImpl implements ConfigScheduleClient {
  private readonly httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  async getConfigSchedules(request: GetConfigSchedulesRequest): Promise<GetConfigSchedulesResponse> {
    const resp = await this.httpClient.post<GetConfigSchedulesResponse>('/list-config-schedules', request, {
      headers: {
        'content-type': 'application/json',
      },
    });
    return resp.data;
  }

  async createConfigSchedules(request: CreateConfigSchedulesRequest): Promise<CreateConfigSchedulesResponse> {
    const resp = await this.httpClient.post<CreateConfigSchedulesResponse>('/config-schedules', request, {
      headers: {
        'content-type': 'application/json',
      },
    });
    return resp.data;
  }

  async updateConfigSchedules(request: UpdateConfigSchedulesRequest): Promise<UpdateConfigSchedulesResponse> {
    const resp = await this.httpClient.put<UpdateConfigSchedulesResponse>('/config-schedules', request, {
      headers: {
        'content-type': 'application/json',
      },
    });
    return resp.data;
  }

  async deleteConfigSchedules(request: DeleteConfigSchedulesRequest): Promise<DeleteConfigSchedulesResponse> {
    const resp = await this.httpClient.post<DeleteConfigSchedulesResponse>('/delete-config-schedules', request, {
      headers: {
        'content-type': 'application/json',
      },
    });
    return resp.data;
  }

  async bulkUploadConfigSchedules(request: BulkUploadConfigSchedulesRequest): Promise<BulkUploadConfigSchedulesResponse> {
    const resp = await this.httpClient.post<BulkUploadConfigSchedulesResponse>('/upload-drive-time-setting-schedules', request, {
      headers: {
        'content-type': 'application/json',
      },
    });
    return resp.data;
  }
}
