import { AlgorithmSettings, ServiceAreaConfig } from '../../models/routing-models';
import { validatorFactory } from '../../common-components/input-cell/input-validators';
import { ValidationInput, ValidationResult, ValueType } from '../../common-components/input-cell';
import { InputTableItem } from '../../common-components/input-table';
import { ItemConfig } from '../../models';

/**
 * @param itemConfig the definition to find the parameters from the configurations
 * @param serviceAreaConfig
 * @param algorithmSettings
 * @returns the target parameter, null if the parameter doesn't exist, undefined if the serviceAreaConfig or algorithmSettings is missing.
 */
export function findValue(itemConfig: ItemConfig, serviceAreaConfig?: ServiceAreaConfig, algorithmSettings?: AlgorithmSettings): ValueType | undefined {
  if (itemConfig.scope === 'serviceAreaConfig' && serviceAreaConfig !== undefined) {
    // value is inside serviceAreaConfig;
    let value: any = serviceAreaConfig;
    const fields = itemConfig.field.split('.');
    for (let i = 0; i < fields.length; i++) {
      if (value === null || value === undefined) {
        // the parameter hasn't been set.
        return null;
      } else {
        value = value[fields[i]];
      }
    }
    return value === undefined ? null : value;
  } else if (itemConfig.scope === 'algorithmSettings' && algorithmSettings !== undefined) {
    // value is inside algorithmSettings
    if (itemConfig.field === 'algorithm') {
      const result = algorithmSettings['algorithm'];
      return result ?? null;
    } else if (itemConfig.field.startsWith('parameters.')) {
      const field = itemConfig.field;
      const parameterKey = field.slice('parameters.'.length, field.length);
      if (algorithmSettings.parameters) {
        const result = algorithmSettings.parameters[parameterKey];
        return result ?? null;
      } else {
        return null;
      }
    }
  }
  return undefined;
}

export function convertConfigToItem(itemConfig: ItemConfig, serviceAreaConfig?: ServiceAreaConfig, algorithmSettings?: AlgorithmSettings): InputTableItem {
  let inputValidator: ((input: ValidationInput) => ValidationResult) | undefined = undefined;
  if (itemConfig.validators && itemConfig.validators.length > 0) {
    if (itemConfig.validators.length > 1) {
      console.warn(`Don't support multiple validators yet. ${itemConfig.validators[0].name} will be used.`);
    }
    if (validatorFactory[itemConfig.validators[0].name]) {
      inputValidator = validatorFactory[itemConfig.validators[0].name];
    } else {
      console.warn(`Unknown validator ${itemConfig.validators[0].name}`);
    }
  }

  let itemName: string;
  if (itemConfig.name === undefined) {
    let fields = itemConfig.field.split('.');
    itemName = fields[fields.length - 1];
  } else {
    itemName = itemConfig.name;
  }

  return {
    field: itemConfig.field,
    name: itemName,
    description: itemConfig.description,
    value: findValue(itemConfig, serviceAreaConfig, algorithmSettings),
    scope: itemConfig.scope,
    representationType: itemConfig.representationType,
    dataType: itemConfig.dataType,
    inputValidator: inputValidator,
  };
}
