import { FlashbarProps } from '@amzn/awsui-components-react-v3/polaris/flashbar';
import { DEFAULT_TIMEZONE_PREFERENCE, TimezonePreference } from '../utilities';
import * as React from 'react';
import { AppLayoutProps } from '@amzn/awsui-components-react-v3/polaris/app-layout';
import { Access, CodeFreezeSchedule } from '../models';

export interface BreadcrumbItem {
  readonly text: string;
  readonly href: string;
}

export interface Notification {
  notificationId?: string;
  readonly header: string;
  readonly content: string | React.ReactNode;
  readonly type: FlashbarProps.Type;
  readonly dismissible?: boolean;
}

export type ToolsType = 'welcome-panel' | 'info-panel' | 'routing-site-program-type';
export type Tutorial = 'placeholder';

export interface GlobalContextType {
  readonly username?: string;

  readonly userAccesses?: ReadonlyArray<Access>;

  readonly uiMode: 'light' | 'dark';
  readonly updateUIMode: (mode: 'light' | 'dark') => void;

  readonly theme: 'regular' | 'visual-refresh';
  readonly updateTheme: (theme: 'regular' | 'visual-refresh') => void;

  readonly density: 'compact' | 'comfortable';
  readonly updateDensity: (density: 'compact' | 'comfortable') => void;

  readonly operatingMode: 'developer' | 'regular';
  readonly setOperatingMode: (mode: 'developer' | 'regular') => void;

  /**
   * The following properties controls the app layout behavior.
   * https://cloudscape.aws.dev/components/app-layout/?tabId=api
   * contentType
   * disableContentPaddings
   * navigationOpen
   * breadcrumbItems
   * tools
   */
  readonly contentType: AppLayoutProps.ContentType;
  readonly updateContentType: (type: AppLayoutProps.ContentType) => void;

  readonly disableContentPaddings: boolean;
  readonly setDisableContentPaddings: (flag: boolean) => void;

  readonly navigationOpen: boolean;
  readonly updateNavigationOpen: (open: boolean) => void;

  readonly breadcrumbItems?: BreadcrumbItem[];
  readonly updateBreadcrumbItems: (items?: BreadcrumbItem[]) => void;

  readonly notifications: Notification[];
  readonly addNotification: (notification: Notification) => void;
  readonly removeNotification: (notificationId: string) => void;

  readonly tools?: ToolsType;
  readonly setTools: (tools?: ToolsType) => void;
  readonly toolsOpen: boolean;
  readonly openTools: () => void;
  readonly closeTools: () => void;

  readonly tutorial?: Tutorial;
  readonly setTutorial: (tutorial?: Tutorial) => void;
  readonly tutorialOpen: boolean;
  readonly tutorialComplete: boolean;
  readonly openTutorial: () => void;
  readonly closeTutorial: () => void;

  readonly timezonePreference: TimezonePreference;
  readonly updateTimezonePreference: (timezonePreference: TimezonePreference) => void;

  readonly stationTimezone?: string;
  readonly updateStationTimezone: (stationTimezone?: string) => void;

  readonly resetLayout: () => void;

  // in browser navigation handler
  readonly onGoto: (url: string) => void;

  readonly activeCodeFreezes?: ReadonlyArray<CodeFreezeSchedule>;

  /**
   * Default, we show code freeze warning on every page. But for certain pages, like the welcome page, we should hide code freeze warning because
   * showing the warning is awkward on a welcome page.
   */
  readonly hideCodeFreezeWarning: boolean;
  readonly setHideCodeFreezeWarning: (flag: boolean) => void;
}

export const defaultContextValue: GlobalContextType = {
  uiMode: 'light',
  updateUIMode: () => {},

  contentType: 'default',
  updateContentType: (type: AppLayoutProps.ContentType) => {},

  disableContentPaddings: false,
  setDisableContentPaddings: (flag: boolean) => {},

  theme: 'regular',
  updateTheme: (theme: 'regular' | 'visual-refresh') => {},

  density: 'comfortable',
  updateDensity: (density: 'compact' | 'comfortable') => {},

  operatingMode: 'regular',
  setOperatingMode: (mode: 'developer' | 'regular') => {},

  userAccesses: undefined,

  navigationOpen: false,
  updateNavigationOpen: () => {},

  updateBreadcrumbItems: () => {},

  notifications: [],
  addNotification: () => {},
  removeNotification: () => {},

  tools: undefined,
  toolsOpen: false,
  openTools: () => {},
  closeTools: () => {},
  setTools: () => {},

  tutorial: undefined,
  setTutorial: () => {},
  tutorialComplete: false,
  tutorialOpen: false,
  openTutorial: () => {},
  closeTutorial: () => {},

  timezonePreference: DEFAULT_TIMEZONE_PREFERENCE,
  updateTimezonePreference: () => {},

  stationTimezone: undefined,
  updateStationTimezone: () => {},

  resetLayout: () => {},
  onGoto: (url: string) => {},

  hideCodeFreezeWarning: false,
  setHideCodeFreezeWarning: () => {},
};

export const GlobalContext = React.createContext<GlobalContextType>(defaultContextValue);
