import { ConfigTableDefinition } from '../../../models';

export const EXECUTION_CONFIGURATIONS: ConfigTableDefinition = {
  headerText: 'Execution Configurations',
  description: 'Control assignment execution.',
  valueColMinWidth: '150px',
  sizeOnGrid: 'default',
  items: [
    {
      field: 'disableOperationalWindowReconciliation',
      scope: 'assignmentConfig',
      name: 'Disable operational window reconciliation',
      description: 'Disable FRAES reconciliating operational window when driver checks in.',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'rdmMigrationEnabled',
      scope: 'assignmentConfig',
      name: 'Enable RDM migration',
      description: 'Migrate GSF assignment from PE to RDM.',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'transporterReleaseTimeInMinutes',
      scope: 'assignmentConfig',
      name: 'Transporter release time',
      description: 'Auto discharge the driver if no assignment after the configured minutes.',
      representationType: 'number',
    },
    {
      field: 'backfillStageLocation',
      scope: 'assignmentConfig',
      name: 'Backfill stage location',
      description: "AMZL and grocery routes don't have staging location. FRAES will backfill a hypen '-' to RouteStore when RDM calls its API.",
      representationType: 'boolean',
    },
    {
      field: 'fastMigrationEnabled',
      scope: 'assignmentConfig',
      name: 'Enable FAST migration',
      description: 'If enabled, FAST will publish regular assignment plans, while LC will publish shadow plans, and vice versa.',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'requestWorkForBlockEnabled',
      scope: 'assignmentConfig',
      name: 'Enable Re-assignment',
      description: 'If enabled, FRAES will send a requestWork request to RDM upon route unassignment, which fakes a TSMS session event for re-assignment.',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
  ],
};
