import { AxiosInstance } from 'axios';
import { CommonClient } from './common-client';
import { GetServiceAreaResponse, ListServiceAreasResponse, GetUserAccessesResponse, GetServiceAreaAllowListResponse } from './request-response';

interface Query {
  [key: string]: string;
}

export class CommonClientImpl implements CommonClient {
  private readonly httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  async getUserAccesses(): Promise<GetUserAccessesResponse> {
    const resp = await this.httpClient.get<GetUserAccessesResponse>('/user-accesses');
    return resp.data;
  }

  async listServiceAreas(): Promise<ListServiceAreasResponse> {
    const resp = await this.httpClient.get<ListServiceAreasResponse>('/service-areas');
    return resp.data;
  }

  async getServiceArea(serviceAreaId: string): Promise<GetServiceAreaResponse> {
    const resp = await this.httpClient.get<GetServiceAreaResponse>(`/service-area-details-v2?serviceAreaId=${serviceAreaId}`);
    return resp.data;
  }

  async getServiceAreaAllowList(allowlistName: string): Promise<GetServiceAreaAllowListResponse> {
    const resp = await this.httpClient.get<GetServiceAreaAllowListResponse>(`/service-area-allowlist?scope=${allowlistName}`);
    return resp.data;
  }
}
