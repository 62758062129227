import React from 'react';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import Modal from '@amzn/awsui-components-react-v3/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Spinner from '@amzn/awsui-components-react-v3/polaris/spinner';
import Select, { SelectProps } from '@amzn/awsui-components-react-v3/polaris/select';
import Table from '@amzn/awsui-components-react-v3/polaris/table';
import Pagination from '@amzn/awsui-components-react-v3/polaris/pagination';
import Container from '@amzn/awsui-components-react-v3/polaris/container';
import ColumnLayout from '@amzn/awsui-components-react-v3/polaris/column-layout';
import { BaseCommitRecord } from '../../models';
import { ConfigAuditingTableItem, ConfigAuditingTableProps, ConfigAuditingTableState } from './models';
import { buildTableDefinition } from './build-table-definition';
import './config-auditing-table.scss';
import { timezoneManager } from '../../utilities';
import 'json-diff-kit/dist/viewer.css';
import { ToggleWithLabel } from '../toggle-with-label';
import { DiffViewer } from './diff-viewer';

const ITEM_PER_PAGE = 10;

/**
 * Display config changes in a table format. It also provides the diff features to show the differences between configurations.
 */
export class ConfigAuditingTable<T extends BaseCommitRecord, Config> extends React.Component<ConfigAuditingTableProps<T, Config>, ConfigAuditingTableState<T, Config>> {
  constructor(props: ConfigAuditingTableProps<T, Config>) {
    super(props);

    const columns = buildTableDefinition({ timezonePreference: props.timezonePreference });
    this.state = {
      currentPage: 1,
      showRevertConfirmationModal: false,
      columns: columns,
      sortingColumn: columns.find((col) => col.sortingField === 'lastUpdateAt'),
      sortingDescending: true,
      sideBySideDiff: true,
    };
  }

  async componentDidMount() {}

  async componentDidUpdate(prevProps: Readonly<ConfigAuditingTableProps<T, Config>>) {
    if (this.props.timezonePreference !== prevProps.timezonePreference) {
      // timezone change, update the table definition with latest timezone
      const columns = buildTableDefinition({ timezonePreference: this.props.timezonePreference });
      this.setState({
        columns: columns,
        sortingColumn: columns.find((col) => col.sortingField === 'lastUpdateAt'),
      });
    }
  }

  /**
   * sort items in place
   * @param items
   * @param column
   * @param sortingDescending
   * @returns
   */
  private getItems(): ReadonlyArray<ConfigAuditingTableItem<T>> | undefined {
    const comparator = this.state.sortingColumn?.sortingComparator;
    if (comparator !== undefined && this.props.items !== undefined) {
      return Array.from(this.props.items).sort((i1, i2) => {
        return this.state.sortingDescending ? comparator(i2, i1) : comparator(i1, i2);
      });
    } else {
      return this.props.items;
    }
  }

  render() {
    return (
      <SpaceBetween direction="vertical" size="l">
        {this.renderRevertConfirmationModal()}
        {this.renderCommitTable()}
        {this.renderDiffContainer()}
      </SpaceBetween>
    );
  }

  private renderRevertConfirmationModal() {
    const selectedItem = this.props.items?.find((item) => item.commit.commitId === this.state.selectedCommitId);

    return (
      <Modal
        visible={this.state.showRevertConfirmationModal}
        header="Confirm the change"
        onDismiss={() => {
          this.setState({ showRevertConfirmationModal: false });
        }}
        footer={this.renderFooter(selectedItem)}
      >
        {this.renderRevertConfirmation(selectedItem)}
      </Modal>
    );
  }

  private renderRevertConfirmation(selectedItem?: ConfigAuditingTableItem<T>) {
    if (selectedItem === undefined) {
      return <Box>You haven't selected a configuration history.</Box>;
    } else if (selectedItem.label === 'latest') {
      return <Box>Cannot revert to the selected configuration because it is currently in effect.</Box>;
    } else {
      return (
        <SpaceBetween direction="vertical" size="s">
          <Box>Revert configuration to {selectedItem.commit.commitId}</Box>
          <Box>
            {new Date(selectedItem.commit.lastUpdateAt).toLocaleString()}: {selectedItem.commit.description}
          </Box>
        </SpaceBetween>
      );
    }
  }

  private renderFooter(selectedItem?: ConfigAuditingTableItem<T>) {
    return (
      <Box float="right">
        <SpaceBetween direction="horizontal" size="xs">
          <Button disabled={this.props.isReverting} onClick={() => this.setState({ showRevertConfirmationModal: false })}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={selectedItem === undefined || this.props.isReverting || selectedItem.label === 'latest'}
            onClick={async () => {
              if (selectedItem !== undefined) {
                await this.props.onRevert(selectedItem.commit);
                this.setState({ showRevertConfirmationModal: false });
              }
            }}
          >
            {this.props.isReverting ? <Spinner size="normal" /> : null} Confirm
          </Button>
        </SpaceBetween>
      </Box>
    );
  }

  private renderCommitTable() {
    const itemsPerPage = this.props.itemsPerPage ?? ITEM_PER_PAGE;
    const pageStartIndex = (this.state.currentPage - 1) * itemsPerPage;
    const items = this.getItems();
    const itemsToRender = items?.slice(pageStartIndex, pageStartIndex + itemsPerPage);
    const selectedItem = items?.find((item) => item.commit.commitId === this.state.selectedCommitId);

    return (
      <Table<ConfigAuditingTableItem<T>>
        loadingText="Loading update history..."
        stickyHeader={true}
        columnDefinitions={this.state.columns}
        items={itemsToRender ?? []}
        wrapLines={false}
        header={this.renderHeader()}
        loading={itemsToRender === undefined}
        onSortingChange={({ detail }) => {
          this.setState({
            sortingColumn: detail.sortingColumn,
            sortingDescending: detail.isDescending,
            currentPage: 1,
          });
        }}
        selectionType="single"
        onSelectionChange={({ detail }) => {
          if (detail.selectedItems.length === 1) {
            this.setState({ selectedCommitId: detail.selectedItems[0].commit.commitId });
          }
        }}
        selectedItems={selectedItem ? [selectedItem] : []}
        sortingColumn={this.state.sortingColumn}
        sortingDescending={this.state.sortingDescending}
        pagination={this.renderCommitTablePagination(itemsPerPage)}
      />
    );
  }

  private renderHeader() {
    return (
      <Header
        variant="h2"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="primary"
              disabled={this.state.selectedCommitId === undefined}
              onClick={() => {
                this.setState({ showRevertConfirmationModal: true });
              }}
            >
              Revert to
            </Button>
            <Button iconName="refresh" onClick={() => this.props.onRefresh()} />
          </SpaceBetween>
        }
      >
        Update History
      </Header>
    );
  }

  private renderCommitTablePagination(itemsPerPage: number) {
    let pagesCount = 1;
    if (this.props.items !== undefined) {
      pagesCount = Math.max(pagesCount, Math.ceil(this.props.items.length / itemsPerPage));
    }
    return (
      <Pagination
        currentPageIndex={this.state.currentPage}
        openEnd={this.props.hasMore}
        pagesCount={pagesCount}
        onChange={(evt) => {
          this.setState({ currentPage: evt.detail.currentPageIndex });
        }}
        onNextPageClick={async (evt) => {
          this.setState({ currentPage: evt.detail.requestedPageIndex });
          if (!evt.detail.requestedPageAvailable) {
            this.props.onLoadMore();
          }
        }}
        ariaLabels={{
          nextPageLabel: 'Next page',
          previousPageLabel: 'Previous page',
          pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
        }}
      />
    );
  }

  private renderDiffContainer() {
    return (
      <Container header={<Header variant="h2">Compare Configurations</Header>}>
        <SpaceBetween direction="vertical" size="m">
          {this.renderDiffButtons()}
          {this.renderDifferences()}
        </SpaceBetween>
      </Container>
    );
  }

  private renderDiffButtons() {
    const options = this.convertCommitsToOptions(this.props.items?.map((i) => i.commit) ?? []);
    const selectedCommitA = options.find((option) => option.value === this.state.selectedCommitAId);
    const selectedCommitB = options.find((option) => option.value === this.state.selectedCommitBId);

    return (
      <ColumnLayout columns={2}>
        <Select
          options={options}
          onChange={async (option) => {
            const commitId = option.detail.selectedOption.value;
            if (commitId !== this.state.selectedCommitAId) {
              this.setState({
                selectedCommitAId: option.detail.selectedOption.value,
                configA: undefined,
              });
              if (typeof commitId === 'string') {
                const config = await this.props.loadConfig(commitId);
                this.setState({ configA: config });
              }
            }
          }}
          selectedOption={selectedCommitA ?? null}
        ></Select>

        <Select
          options={options}
          onChange={async (option) => {
            const commitId = option.detail.selectedOption.value;
            if (commitId !== this.state.selectedCommitBId) {
              this.setState({
                selectedCommitBId: option.detail.selectedOption.value,
                configB: undefined,
              });
              if (typeof commitId === 'string') {
                const config = await this.props.loadConfig(commitId);
                this.setState({ configB: config });
              }
            }
          }}
          selectedOption={selectedCommitB ?? null}
        ></Select>
      </ColumnLayout>
    );
  }

  private convertCommitsToOptions(commits: BaseCommitRecord[]): SelectProps.Option[] {
    const options: SelectProps.Option[] = commits.map((commit) => {
      return {
        label: commit.commitId,
        value: commit.commitId,
        description: `${timezoneManager.convertTimestampToString(commit.lastUpdateAt)}: ${commit.description ?? '-'}`,
      };
    });

    options.unshift({
      label: 'Select a change',
    });

    return options;
  }

  private renderDifferences() {
    if (this.state.configA === undefined || this.state.configB === undefined) {
      return null;
    } else {
      return (
        <React.Fragment>
          <ToggleWithLabel checked={this.state.sideBySideDiff} onChange={(newValue) => this.setState({ sideBySideDiff: newValue })} label="Side by side view" whatDisabled={'NOTHING'} />
          <DiffViewer left={this.state.configA} right={this.state.configB} sideBySide={this.state.sideBySideDiff} />
        </React.Fragment>
      );
    }
  }
}
