import { AxiosInstance } from 'axios';
import { LaborPlanningClient } from './labor-planning-client';
import { GetLaborPlanningServiceAreasResponse } from './request-response';

export class LaborPlanningClientImpl implements LaborPlanningClient {
  private readonly httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  async getServiceAreas(): Promise<GetLaborPlanningServiceAreasResponse> {
    await this.asleep(1000);
    return {
      serviceAreas: [
        {
          serviceAreaId: '03c90c49-74fe-4912-bef9-bed41b98f659',
          stationCode: 'C081',
          serviceAreaName: 'Long Island Lake Grove (C081) - Whole Foods',
        },
        {
          serviceAreaId: '00d01032-425a-43a0-b46c-d91e4a1e1f54',
          stationCode: 'C207',
          serviceAreaName: 'Manchester Bedford (C207) - Whole Foods',
        },
        {
          serviceAreaId: '20a329da-68bd-4451-a724-6f15d5e2236f',
          stationCode: 'CCG2',
          serviceAreaName: 'Accokeek (CCG2) - Weis Markets',
        },
        {
          serviceAreaId: 'fd0d6850-b2a2-44bf-9f95-c86eb9a3cdd0',
          stationCode: 'T023',
          serviceAreaName: 'Stockton - Ceres (T023) SaveMart',
        },
        {
          serviceAreaId: '7cf7a8ca-b181-4644-89bf-a1d02d0bfeca',
          stationCode: 'MAG6',
          serviceAreaName: 'Franconia (MAG6) - Fresh Stores',
        },
        {
          serviceAreaId: '479968bb-e253-4c6e-a78a-1629507a8c63',
          stationCode: 'VTN1',
          serviceAreaName: 'Nashville TN (VTN1) - Sub Same-Day',
        },
      ],
    };
  }

  private asleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
