import React from 'react';
import Input from '@amzn/awsui-components-react-v3/polaris/input';
import FormField from '@amzn/awsui-components-react-v3/polaris/form-field';

/*
 * Polaris 3 makes Input elements controllable -- their contents are synced directly to a React variable.
 * If your React variable is number, this means your text entry MUST be a valid number at all times.
 * You can't start typing a negative number because a minus sign by itself isn't a valid number.
 * You have to type a number, move the cursor to the left, and enter minus -- keeping the state valid at every step.
 *
 * This is a numeric input field which tracks its own state.
 * It lets users move through one non-numeric state ("-") if its lower bound permits negative numbers.
 * It exposes the entered number only on valid (numeric & in-bounds) states.
 */

interface NumericInputProps {
  readonly fieldLabel?: string;
  readonly startingText: string;
  readonly minimum?: number;
  readonly maximum?: number;
  readonly exclusive?: boolean;
  readonly disabled?: boolean;
  readonly onChange: (newValue: number) => void;
  readonly secondaryControl?: React.ReactNode;
}

interface NumericInputState {
  readonly fieldText: string;
  readonly errorText: string;
}

export class NumericInput extends React.Component<NumericInputProps, NumericInputState> {
  constructor(props: NumericInputProps) {
    super(props);
    this.state = {
      fieldText: props.startingText ?? '0',
      errorText: '',
    };
  }

  inbounds(value: number) {
    const minBound = this.props.minimum ?? Number.MIN_SAFE_INTEGER;
    const maxBound = this.props.maximum ?? Number.MAX_SAFE_INTEGER;
    if (this.props.exclusive ?? false) {
      return minBound < value && maxBound > value;
    }
    return minBound <= value && maxBound >= value;
  }

  getErrorText() {
    if (this.props.maximum === undefined && this.props.minimum === undefined) {
      return 'Must be a valid number.';
    } else if (this.props.maximum === undefined) {
      if (this.props.exclusive ?? false) {
        return `Must be greater than ${this.props.minimum}.`;
      }
      return `Must be ${this.props.minimum} or greater.`;
    } else if (this.props.minimum === undefined) {
      if (this.props.exclusive ?? false) {
        return `Must be less than ${this.props.maximum}.`;
      }
      return `Must be ${this.props.maximum} or less`;
    } else {
      return `Out of bounds (${this.props.minimum}, ${this.props.maximum})`;
    }
  }

  validate(newFieldText: string) {
    // when field is 0, typing a digit should replace the zero
    // except if we're going to write a fraction
    if (newFieldText.startsWith('0') && !newFieldText.startsWith('0.')) {
      newFieldText = newFieldText.substring(1);
    }

    // backspacing all the way defaults to 0
    if (newFieldText === '') {
      newFieldText = '0';
    }

    const candidateValue = Number(newFieldText);
    const negativesAllowed = (this.props.minimum ?? -1) < 0;

    if (negativesAllowed && newFieldText === '-') {
      // special case allow lone negative sign, but don't indicate value has changed
      this.setState({
        fieldText: newFieldText,
      });
    } else if (!negativesAllowed && newFieldText.startsWith('-')) {
      // show error message if they try to type a minus sign but are prevented by bounds
      this.setState({
        errorText: this.getErrorText(),
      });
    } else if (isNaN(candidateValue)) {
      return; // prevent the change entirely for invalid numbers like "adajfasd"
    } else if (!this.inbounds(candidateValue)) {
      this.setState({
        fieldText: newFieldText,
        errorText: this.getErrorText(),
      });
    } else {
      this.setState({
        fieldText: newFieldText,
        errorText: '',
      });
      this.props.onChange(candidateValue);
    }
  }

  render() {
    return (
      <FormField errorText={this.state.errorText} label={this.props.fieldLabel} secondaryControl={this.props.secondaryControl}>
        <Input value={this.state.fieldText} disabled={this.props.disabled} onChange={(evt) => this.validate(evt.detail.value)} inputMode="numeric" />
      </FormField>
    );
  }
}

export class IntegerInput extends NumericInput {
  validate(newFieldText: string): void {
    if (newFieldText.includes('.')) {
      this.setState({ errorText: 'Must be a whole number.' });
    } else {
      super.validate(newFieldText);
    }
  }
}
