/**
 * path /site-program-type
 * The page is for ops convenience to show routing enabled service by program type.
 */
import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import { copyToClipboard, readable } from '../../utilities';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import ButtonDropdown from '@amzn/awsui-components-react-v3/polaris/button-dropdown';
import Container from '@amzn/awsui-components-react-v3/polaris/container';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import Popover from '@amzn/awsui-components-react-v3/polaris/popover';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import StatusIndicator from '@amzn/awsui-components-react-v3/polaris/status-indicator';
import TextEditor from '../../common-components/text-editor';
import { commonClient } from '../../http-clients';
import { ServiceAreaInfo } from '../../models';

const breadcrumbs = [
  {
    text: 'Program Type',
    href: '/site-program-type',
  },
];

interface RoutingSiteProgramPageProps {}

interface RoutingSiteProgramPageState {
  readonly serviceAreas?: ReadonlyArray<ServiceAreaInfo>;
  readonly serviceAreaIds: ReadonlyArray<string>;
  readonly selectedProgramType?: string;
}

export class RoutingSiteProgramPage extends React.Component<RoutingSiteProgramPageProps, RoutingSiteProgramPageState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: RoutingSiteProgramPageProps) {
    super(props);
    this.state = {
      serviceAreaIds: [],
    };
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.updateBreadcrumbItems(breadcrumbs);
    this.context.setTools('routing-site-program-type');
    try {
      const resp = await commonClient.listServiceAreas();
      this.setState({
        serviceAreas: resp.serviceAreas,
      });
    } catch (err: any) {
      this.setState({ serviceAreas: [] });
      this.context.addNotification({
        type: 'error',
        header: 'Error',
        content: `Failed to load routing service areas due to ${readable(err)}`,
      });
    }
  }

  render() {
    const programTypes: Set<string> = new Set();
    this.state.serviceAreas?.filter((sa) => typeof sa.programType === 'string')?.forEach((sa) => programTypes.add(sa.programType!));

    return (
      <Container
        header={
          <Header variant="h2" actions={this.renderHeaderActions(Array.from(programTypes).sort())}>
            Service Area Ids
          </Header>
        }
      >
        <TextEditor data={this.state.serviceAreaIds.join('\n')} minLine={40} />
      </Container>
    );
  }

  private renderHeaderActions(programTypes: string[]) {
    return (
      <SpaceBetween direction="horizontal" size="xs">
        <ButtonDropdown
          items={programTypes.length > 0 ? programTypes.map((pt) => ({ text: pt, id: pt })) : [{ text: 'loading...', id: 'loading...' }]}
          onItemClick={(evt) => this.handleSelectProgramType(evt.detail.id)}
        >
          {this.state.selectedProgramType ? this.state.selectedProgramType : 'Program Type'}
        </ButtonDropdown>
        <Popover size="small" position="top" triggerType="custom" dismissButton={false} content={<StatusIndicator type="success">Copied</StatusIndicator>}>
          <Button
            onClick={() => {
              const saIds = this.state.serviceAreaIds.join('\n');
              copyToClipboard(saIds);
            }}
          >
            Copy
          </Button>
        </Popover>
      </SpaceBetween>
    );
  }

  private handleSelectProgramType(programType: string) {
    const saIds = this.state.serviceAreas
      ?.filter((sa) => sa.programType === programType)
      .map((sa) => sa.serviceAreaId)
      .sort();
    this.setState({
      selectedProgramType: programType,
      serviceAreaIds: saIds ? saIds : [],
    });
  }
}
