import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table';
import { PickupStartOffsetConfigItem } from './models';

interface SortOverrideProps {
  readonly isDescending?: boolean;
  readonly sortingColumn?: TableProps.SortingColumn<PickupStartOffsetConfigItem>;
}

export function sortPickupStartOffsetConfigItems(overrides: ReadonlyArray<PickupStartOffsetConfigItem>, props: SortOverrideProps): ReadonlyArray<PickupStartOffsetConfigItem> {
  const comparator = props.sortingColumn?.sortingComparator;
  if (comparator) {
    const items = Array.from(overrides);
    return items.sort((i1, i2) => {
      return props.isDescending ? comparator(i2, i1) : comparator(i1, i2);
    });
  }
  return overrides;
}
