/**
 * reference: https://www.npmjs.com/package/aws4-axios
 *  https://code.amazon.com/packages/MidwayIdentityJavaScriptClient
 */

import axios, { AxiosRequestConfig } from 'axios';
import { AxiosInstance } from 'axios';
import { axiosSigV4Interceptor } from './axios-aws-sigv4-interceptor';
import { credentials } from '../utilities';
import { TokenRetriever } from './midway-token-retriever';

export interface MidwayCognitoAuthClientBuilderProps {
  readonly identityPoolId?: string;
  readonly awsRegion?: string;
  readonly baseUrl: string;
  readonly timeoutInMs?: number;
}

const DEFAULT_TIMEOUT = 40_000; // 40 seconds.
export class MidwayCognitoAuthClientBuilder {
  static build(props: MidwayCognitoAuthClientBuilderProps): AxiosInstance {
    const client = axios.create({
      baseURL: props.baseUrl,
      timeout: props.timeoutInMs ?? DEFAULT_TIMEOUT,
    });

    client.interceptors.request.use(async (config: AxiosRequestConfig) => {
      // assume it use some method to resolve username.
      config.headers['X-CM-Username'] = await TokenRetriever.getUsername();

      config.headers['X-Midway-Token'] = await TokenRetriever.getTokenOrRedirect();
      return config;
    });

    if (typeof props.identityPoolId === 'string') {
      if (props.awsRegion === undefined) {
        throw new Error('MidwayCognitoAuthClientBuilder missing awsRegion.');
      }
      client.interceptors.request.use(
        axiosSigV4Interceptor(
          {
            region: props.awsRegion,
          },
          credentials,
        ),
      );
    }

    return client;
  }
}
