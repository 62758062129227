import React from 'react';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import ColumnLayout from '@amzn/awsui-components-react-v3/polaris/column-layout';
import Container from '@amzn/awsui-components-react-v3/polaris/container';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import FormField from '@amzn/awsui-components-react-v3/polaris/form-field';
import ProgressBar from '@amzn/awsui-components-react-v3/polaris/progress-bar';
import ExpandableSection from '@amzn/awsui-components-react-v3/polaris/expandable-section';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import Select, { SelectProps } from '@amzn/awsui-components-react-v3/polaris/select';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Link from '@amzn/awsui-components-react-v3/polaris/link';
import Multiselect from '@amzn/awsui-components-react-v3/polaris/multiselect';
import Tiles from '@amzn/awsui-components-react-v3/polaris/tiles';
import TextFilter from '@amzn/awsui-components-react-v3/polaris/text-filter';

import MarkdownRender from '../markdown-render';
import FileUpload from '../file-upload';
import { parse } from 'papaparse';
import { ApplyProfileRequest, ConfigProfilePanelProps, ConfigProfilePanelState, ProfileSortingGroups } from './models';
import { ConfigProfileModal } from './confirmation-modal';
import { ApplyProfileResults as ApplyProfileResultsComponent } from './apply-profile-results';
import JSONEditor from '../json-editor';
import lodash from 'lodash';
import { readable } from '../../utilities';
import { mergeApplyProfileResults } from './utilities';
import PermissionTableModal from '../permission-table-modal';

const SERVICE_AREA_FILE_EXAMPLE_LINK = 'https://drive.corp.amazon.com/view/nqqin@/DO_NOT_DELETE/config_manager_service_areas_input_file.csv';

export class ConfigProfilePanel extends React.Component<ConfigProfilePanelProps, ConfigProfilePanelState> {
  constructor(props: ConfigProfilePanelProps) {
    super(props);
    this.state = {
      showProfileDescription: true,
      showConfirmationModal: false,
      showRequestPermissionModal: false,
      selectedServiceAreaIds: [],
      tagGroupFilter: undefined,
      serviceAreaFilteringText: '',
      isApplying: false,
      results: {},
    };
  }

  componentDidMount(): void {
    this.setState({
      filteredServiceAreas: this.props.filterServiceAreas(this.state.serviceAreaFilteringText, this.props.serviceAreas),
    });
  }

  componentDidUpdate(prevProps: Readonly<ConfigProfilePanelProps>): void {
    if (this.props.serviceAreas !== prevProps.serviceAreas) {
      this.setState({
        filteredServiceAreas: this.props.filterServiceAreas(this.state.serviceAreaFilteringText, this.props.serviceAreas),
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderConfirmationModal()}
        {this.renderRequestPermissionModal()}
        <SpaceBetween direction="vertical" size="m">
          {this.renderApplyResult()}
          <Container header={this.renderHeader()}>
            <SpaceBetween direction="vertical" size="l">
              {this.renderServiceAreasMultiSelect()}
              {this.renderServiceAreasFileUpload()}
              {this.renderConfigProfileSelect()}
              {this.renderProgressBar()}
              {this.renderExpandableSection()}
            </SpaceBetween>
          </Container>
        </SpaceBetween>
      </React.Fragment>
    );
  }

  private renderConfirmationModal() {
    if (this.state.showConfirmationModal) {
      const selectedServiceAreaIds = new Set(this.state.selectedServiceAreaIds);
      const serviceAreas = this.props.serviceAreas?.filter((sa) => selectedServiceAreaIds.has(sa.serviceAreaId));
      return (
        <ConfigProfileModal
          profileType={this.props.profileType}
          operatingMode={this.props.operatingMode}
          selectedConfigProfileKey={this.state.selectedConfigProfileKey}
          selectedServiceAreas={serviceAreas ?? []}
          onDismiss={() => this.setState({ showConfirmationModal: false })}
          onConfirm={(request) => this.applyConfigProfile(request)}
        />
      );
    }
  }

  private renderRequestPermissionModal() {
    if (this.state.showRequestPermissionModal) {
      return <PermissionTableModal access={this.props.accessPermission} message={this.props.requestPermissionMessage} onDismiss={() => this.setState({ showRequestPermissionModal: false })} />;
    }
  }

  private async applyConfigProfile(request: ApplyProfileRequest) {
    const partitionedServiceAreaIds = lodash.chunk(request.serviceAreaIds, this.props.serviceAreasPerRequest);

    try {
      this.setState({ isApplying: true, applyingProgress: 0 });
      for (let i = 0; i < partitionedServiceAreaIds.length; i++) {
        const results = await this.props.apply({
          ...request,
          serviceAreaIds: partitionedServiceAreaIds[i],
        });
        const percentage = Math.ceil(((i + 1) / partitionedServiceAreaIds.length) * 100);
        this.setState({
          results: mergeApplyProfileResults(results, this.state.results),
          applyingProgress: percentage,
        });
      }
    } catch (err) {
      this.setState({
        errorMessage: `Failed to use the config profile due to ${readable(err)}`,
      });
    } finally {
      this.setState({ isApplying: false });
    }
  }

  private renderApplyResult() {
    return (
      <ApplyProfileResultsComponent
        errorMessage={this.state.errorMessage}
        operatingMode={this.props.operatingMode}
        results={this.state.results}
        onDismissError={() => {
          this.setState({ errorMessage: undefined });
        }}
        onDismissSucceededServiceAreas={() => {
          this.setState({
            results: {
              ...this.state.results,
              succeededServiceAreaIds: undefined,
            },
          });
        }}
        onDismissFailedServiceAreas={() => {
          this.setState({
            results: {
              ...this.state.results,
              failedServiceAreaIdAndReasons: undefined,
            },
          });
        }}
        onDismissUrl={() => {
          this.setState({
            results: {
              ...this.state.results,
              url: undefined,
            },
          });
        }}
      />
    );
  }

  private renderHeader() {
    const segments: string[] = [];
    switch (this.props.operatingMode) {
      case 'launch':
        segments.push('Launch');
        break;
      case 'update':
        segments.push('Apply');
        break;
      default:
        break;
    }
    switch (this.props.profileType) {
      case 'routing':
        segments.push('Routing');
        break;
      case 'assignment':
        segments.push('Assignment');
        break;
      default:
        break;
    }

    switch (this.props.operatingMode) {
      case 'launch':
        segments.push('Sites');
        break;
      case 'update':
        segments.push('Config Profile');
        break;
      default:
        break;
    }

    /**
     * examples
     *
     * Launch Routing Sites
     * Apply Routing Config Profile
     */
    return (
      <Header variant="h2" actions={this.renderHeaderActions()}>
        {segments.join(' ')}
      </Header>
    );
  }

  private renderHeaderActions() {
    if (this.props.hasPermission) {
      return (
        <Button
          variant="primary"
          disabled={this.state.isApplying || this.props.isLoadingConfigProfiles || this.props.isLoadingServiceAreas}
          onClick={() => this.setState({ showConfirmationModal: true })}
        >
          {this.props.operatingMode === 'launch' ? 'Launch' : 'Apply'}
        </Button>
      );
    } else {
      return <Button onClick={() => this.setState({ showRequestPermissionModal: true })}>Request Permission</Button>;
    }
  }

  private renderServiceAreasMultiSelect() {
    const options: ReadonlyArray<{ readonly value: string } & SelectProps.Option> = (this.props.serviceAreas ?? []).map((serviceArea) => {
      return {
        value: serviceArea.serviceAreaId,
        label: serviceArea.stationCode,
        description: `${serviceArea.stationCode} - ${serviceArea.serviceAreaName} - ${serviceArea.serviceAreaId}`,
        disabled: serviceArea.disabled,
      };
    });

    const selectedOptions = options.filter((o) => this.state.selectedServiceAreaIds.includes(o.value));
    const filteredServiceAreaIds = new Set();
    this.state.filteredServiceAreas?.forEach((sa) => filteredServiceAreaIds.add(sa.serviceAreaId));
    const optionsInDisplay = options.filter((o) => filteredServiceAreaIds.has(o.value));

    return (
      <FormField label="Service areas">
        <Multiselect
          statusType={this.props.isLoadingServiceAreas ? 'loading' : 'finished'}
          options={optionsInDisplay}
          placeholder="Choose service areas"
          filteringType={'manual'}
          filteringPlaceholder={this.props.serviceAreaFilteringPlaceholder}
          onChange={(evt) => {
            const serviceAreaIds = evt.detail.selectedOptions.map((o) => o.value as string);
            this.setState({ selectedServiceAreaIds: serviceAreaIds });
          }}
          onLoadItems={(evt) => {
            this.setState({
              serviceAreaFilteringText: evt.detail.filteringText,
            });
            this.setState({
              filteredServiceAreas: this.props.filterServiceAreas(evt.detail.filteringText, this.props.serviceAreas),
            });
          }}
          selectedOptions={selectedOptions}
        ></Multiselect>
      </FormField>
    );
  }

  private renderServiceAreasFileUpload() {
    let hint: string | undefined = undefined;
    let hintStatus: 'success' | 'error' | undefined = undefined;

    if (this.state.selectedFileErrorMessage) {
      hint = this.state.selectedFileErrorMessage;
      hintStatus = 'error';
    } else if (this.state.selectedFileSuccessMessage) {
      hint = this.state.selectedFileSuccessMessage;
      hintStatus = 'success';
    }

    return (
      <FileUpload
        label={
          <Box>
            Or select service areas by file.{' '}
            <Link external={true} href={SERVICE_AREA_FILE_EXAMPLE_LINK}>
              Example
            </Link>
          </Box>
        }
        hint={hint}
        hintStatus={hintStatus}
        accept={'text/csv'}
        onFileUpload={(file) => {
          parse<any>(file, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => this.selectServiceAreaIdsFromFile(file.name, result.data),
            error: (err) => console.log(err),
          });
        }}
      />
    );
  }

  private selectServiceAreaIdsFromFile(filename: string, data: any[]) {
    const ids = data.map((datum) => datum['Service_Area_ID']).filter((id) => id !== undefined && id.length > 0);
    this.setState({
      selectedFilename: filename,
      selectedServiceAreaIds: ids,
      selectedFileSuccessMessage: ids.length > 0 ? `${filename} has ${ids.length} service area Ids selected.` : undefined,
      selectedFileErrorMessage: ids.length === 0 ? `${filename} doesn't contain valid service area Ids.` : undefined,
    });
  }

  private renderConfigProfileSelect() {
    const options = (this.props.configProfiles ?? [])
      .filter((profile) => {
        if (this.state.tagGroupFilter === undefined) {
          return true;
        }

        const cleanedProfileName = profile.name.replaceAll(' ', '').toLowerCase();
        return this.state.tagGroupFilter.some((tag) => {
          if (tag === tag.toUpperCase()) {
            // some tags are acronyms, eg "IO", and we don't want to hit "elongatIOn"
            // so all cap acronym tags only hit capital letters, which is okay for now
            return profile.name.includes(tag);
          }
          return cleanedProfileName.includes(tag.replaceAll(' ', '').toLowerCase());
        });
      })
      .filter((v, i, a) => a.findIndex((v2) => v2.name === v.name) === i) // dedup identical names (should never happen)
      .map((profile) => {
        return {
          value: profile.name,
          label: profile.name,
          description: profile.accessScope === 'INTERNAL' ? 'Developer Only' : undefined,
        };
      });

    return (
      <React.Fragment>
        <FormField label="Filter Profiles">
          <SpaceBetween direction="vertical" size="s">
            {this.renderConfigProfileTagDirectory()}
            <TextFilter
              filteringText={this.state.filteringText ?? ''}
              filteringPlaceholder={'Or by search:'}
              onChange={(evt) => {
                this.setState({
                  filteringText: evt.detail.filteringText,
                  tagGroupFilter: [evt.detail.filteringText],
                });
              }}
            />
          </SpaceBetween>
        </FormField>
        <FormField label="Select Profile">
          <Tiles onChange={(evt) => this.setState({ selectedConfigProfileKey: evt.detail.value })} value={this.state.selectedConfigProfileKey ?? null} items={options} />
        </FormField>
      </React.Fragment>
    );
  }

  private renderConfigProfileTagDirectory() {
    const numColumns = this.props.profileSortingGroups.size;
    const entries = Array.from(this.props.profileSortingGroups.entries());

    return <ColumnLayout columns={numColumns}>{entries.map((entry) => this.renderTagGroup(entry[0], entry[1]))}</ColumnLayout>;
  }

  private renderTagGroup(title: string, tags: ReadonlyArray<ReadonlyArray<string>>) {
    const dropdownOption = (tagQueryTerms: ReadonlyArray<string>) => {
      return {
        label: tagQueryTerms.at(0),
        filteringTags: tagQueryTerms,
      };
    };

    let selectedOption = null;
    if (this.state.tagGroupFilter !== undefined) {
      if (tags.some((tag) => tag.at(0) === this.state.tagGroupFilter?.at(0))) {
        selectedOption = dropdownOption(this.state.tagGroupFilter);
      }
    }

    return (
      <Select
        placeholder={title}
        options={tags.map(dropdownOption)}
        selectedOption={selectedOption}
        onChange={(evt) =>
          this.setState({
            filteringText: undefined,
            tagGroupFilter: Array.from(evt.detail.selectedOption.filteringTags ?? []),
          })
        }
      />
    );
  }

  private renderProgressBar() {
    return typeof this.state.applyingProgress === 'number' ? <ProgressBar value={this.state.applyingProgress} label="Applying Progress" /> : undefined;
  }

  private renderExpandableSection() {
    return (
      <ExpandableSection headerText="Profile description" expanded={this.state.showProfileDescription} onChange={(evt) => this.setState({ showProfileDescription: evt.detail.expanded })}>
        {this.renderProfileTypeSpecificDetails()}
      </ExpandableSection>
    );
  }

  private renderProfileTypeSpecificDetails() {
    const selectedProfile = this.props.configProfiles?.find((p) => p.name === this.state.selectedConfigProfileKey);
    if (this.props.profileType === 'assignment') {
      return (
        <SpaceBetween direction="vertical" size="m">
          <MarkdownRender markdownText={selectedProfile?.description ?? 'N/A'} />
          {selectedProfile?.assignmentConfig !== undefined ? (
            <React.Fragment>
              <Header variant="h2">Assignment Config</Header>
              <JSONEditor readOnly={true} showGutter={true} data={selectedProfile?.assignmentConfig} />
            </React.Fragment>
          ) : null}
        </SpaceBetween>
      );
    } else if (this.props.profileType === 'routing') {
      return <MarkdownRender markdownText={selectedProfile?.description ?? 'N/A'} />;
    }
  }
}
