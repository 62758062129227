import React from 'react';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import InputTable from '../../common-components/input-table';
import ConfigGrid from '../../common-components/config-grid';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import StatusIndicator from '@amzn/awsui-components-react-v3/polaris/status-indicator';
import { PROVIDER_SNAPSHOT, ROUTE_SNAPSHOT } from './table-definitions';
import { ConfigTableDefinition } from '../../models';
import { GlobalContext } from '../../main-app/global-context';
import { InputUpdate } from '../../common-components/input-cell';
import { DriaConfig } from '../../models/dria-models';
import { convertDriaConfigToItem } from './utilities';
import { updateDriaConfigurations } from './table-definitions/state-utilities';

export interface DriaConfigFormEditorProps {
  readonly isSaving: boolean;
  readonly isEditing: boolean;
  readonly driaConfig?: DriaConfig | null;

  /**
   * Method is called when a user is typing to change configurations
   * @param driaConfig
   * @returns
   */
  readonly onUpdate: (driaConfig: DriaConfig) => void;

  /**
   * Method is called when the "Save" button is clicked.
   * @returns
   */
  readonly onSave: () => void;

  /**
   * Method is called when the "Edit" button is clicked
   * @returns
   */
  readonly onEdit: () => void;

  /**
   * Method is called when the "Discard" button is clicked.
   */
  readonly onDiscard: () => void;
}

export interface DriaConfigFormEditorState {
  readonly invalidKeyCounter: number;
  readonly invalidTransportType: boolean;
}

export class DriaConfigFormEditor extends React.Component<DriaConfigFormEditorProps, DriaConfigFormEditorState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;
  private invalidParameterKeys: Set<string>;

  constructor(props: DriaConfigFormEditorProps) {
    super(props);
    this.state = {
      invalidKeyCounter: 0,
      invalidTransportType: false,
    };
    this.invalidParameterKeys = new Set();
  }

  render() {
    if (this.props.driaConfig === null) {
      return (
        <Box textAlign="center">
          <SpaceBetween direction="vertical" size="s">
            <StatusIndicator type="info">The service area or region doesn't have a DRIA config yet.</StatusIndicator>
          </SpaceBetween>
        </Box>
      );
    } else if (this.props.driaConfig === undefined) {
      return (
        <Box textAlign="center">
          <StatusIndicator type="loading">Loading</StatusIndicator>
        </Box>
      );
    } else {
      return (
        <SpaceBetween direction="vertical" size="s">
          <Header actions={this.renderHeaderActions()}></Header>
          <ConfigGrid>
            {this.generateInputTable(PROVIDER_SNAPSHOT)}
            {this.generateInputTable(ROUTE_SNAPSHOT)}
          </ConfigGrid>
        </SpaceBetween>
      );
    }
  }

  private renderHeaderActions() {
    if (this.props.isEditing) {
      return (
        <SpaceBetween direction="horizontal" size="xs">
          <Button disabled={this.disableBtn()} onClick={() => this.props.onDiscard()}>
            Discard
          </Button>
          <Button disabled={this.disableBtn() || this.state.invalidKeyCounter > 0 || this.state.invalidTransportType} variant="primary" onClick={() => this.props.onSave()}>
            Save
          </Button>
        </SpaceBetween>
      );
    } else {
      return (
        <Button disabled={this.disableBtn()} onClick={() => this.props.onEdit()}>
          Edit
        </Button>
      );
    }
  }

  private disableBtn() {
    return !this.props.driaConfig || this.props.isSaving;
  }

  private generateInputTable(tableDefinition: ConfigTableDefinition) {
    if (tableDefinition.visibility === 'developer-only' && this.context.operatingMode !== 'developer') {
      return null;
    }

    return (
      <InputTable
        headerText={tableDefinition.headerText}
        showField={this.context.operatingMode === 'developer'}
        description={tableDefinition.description}
        valueColMinWidth={tableDefinition.valueColMinWidth}
        valueColHeaderText={tableDefinition.valueColHeaderText}
        nameColHeaderText={tableDefinition.nameColHeaderText}
        nameColMinWidth={tableDefinition.nameColMinWidth}
        sizeOnGrid={tableDefinition.sizeOnGrid ?? 'default'}
        items={tableDefinition.items
          .filter((itemConfig) => {
            return itemConfig.visibility === 'developer-only' ? this.context.operatingMode === 'developer' : true;
          })
          .map((itemConfig) => convertDriaConfigToItem(itemConfig, this.props.driaConfig))}
        notifyUpdate={(input: InputUpdate) => {
          const driaConfig = this.props.driaConfig;
          if (driaConfig) {
            this.props.onUpdate(updateDriaConfigurations(input, driaConfig));
          }
        }}
        notifyValidationResult={(result) => {
          if (result.isValid) {
            this.invalidParameterKeys.delete(`${result.scope}#${result.field}`);
          } else {
            this.invalidParameterKeys.add(`${result.scope}#${result.field}`);
          }
          this.setState({ invalidKeyCounter: this.invalidParameterKeys.size });
        }}
        isEditing={this.props.isEditing}
      />
    );
  }
}
