import React from 'react';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Table, { TableProps } from '@amzn/awsui-components-react-v3/polaris/table';
import { PlanningHorizonConfigOverride } from '../../../../models/routing-models';
import { GridSizeDefaultProp } from '../../../../common-components/config-grid';
import { PlanningHorizonOverrideModal } from './planning-horizon-override-modal';
import { buildTableDefinition } from './build-table-definition';
import { sortOverrides } from './utilities';
import { PlanningHorizonType } from './models';

interface PlanningHorizonOverridesTableProps {
  readonly isEditing: boolean;
  readonly items: ReadonlyArray<PlanningHorizonConfigOverride>;
  readonly onNewOverride: (override: PlanningHorizonConfigOverride) => void; // callback when a new override is created
  readonly onDeleteOverrides: (overrides: ReadonlyArray<PlanningHorizonConfigOverride>) => void; // callback when delete overrides
  readonly onUpdateStagedPlanningHorizon: (inputType: PlanningHorizonType, effectiveTime: string, orderType: string | null, value: number | string) => void;
  readonly onUpdateNonStagedPlanningHorizon: (inputType: PlanningHorizonType, effectiveTime: string, orderType: string | null, value: number | string) => void;
  readonly onUpdateLocking: (inputType: PlanningHorizonType, effectiveTime: string, orderType: string | null, value: number | string) => void;
}

interface PlanningHorizonOverridesTableState {
  readonly isAddingNewOverride: boolean; // control whether to open the popup modal.
  readonly selectedItems: ReadonlyArray<PlanningHorizonConfigOverride>;
  readonly isDescending?: boolean;
  readonly sortingColumn?: TableProps.SortingColumn<PlanningHorizonConfigOverride>;
}

export class PlanningHorizonOverridesTable extends React.Component<PlanningHorizonOverridesTableProps, PlanningHorizonOverridesTableState> {
  static defaultProps: GridSizeDefaultProp = { sizeOnGrid: 'wide' };

  constructor(props: PlanningHorizonOverridesTableProps) {
    super(props);

    this.state = {
      isAddingNewOverride: false,
      selectedItems: [],
      isDescending: false,
      sortingColumn: undefined,
    };
  }

  render() {
    const columns = buildTableDefinition({
      isEditing: this.props.isEditing,
      onUpdateStagedPlanningHorizon: this.props.onUpdateStagedPlanningHorizon,
      onUpdateNonStagedPlanningHorizon: this.props.onUpdateNonStagedPlanningHorizon,
      onUpdateLocking: this.props.onUpdateLocking,
    });

    const sortingColumn = columns.find((col) => col.sortingField !== undefined && col.sortingField === this.state.sortingColumn?.sortingField);

    const items = sortOverrides(this.props.items, {
      sortingColumn: sortingColumn,
      isDescending: this.state.isDescending,
    });

    return (
      <React.Fragment>
        {this.state.isAddingNewOverride ? (
          <PlanningHorizonOverrideModal currentOverrides={this.props.items} onNewOverride={this.props.onNewOverride} onDismiss={() => this.setState({ isAddingNewOverride: false })} />
        ) : null}
        <Table<PlanningHorizonConfigOverride>
          header={this.renderHeader()}
          wrapLines={true}
          columnDefinitions={columns}
          items={items}
          selectionType={this.props.isEditing ? 'multi' : undefined}
          onSelectionChange={({ detail }) => this.setState({ selectedItems: detail.selectedItems })}
          selectedItems={this.state.selectedItems}
          onSortingChange={({ detail }) => {
            this.setState({
              isDescending: detail.isDescending,
              sortingColumn: detail.sortingColumn,
            });
          }}
          sortingColumn={sortingColumn}
          sortingDescending={this.state.isDescending}
        />
      </React.Fragment>
    );
  }

  private renderHeader() {
    // "delete" and "add" buttons
    return (
      <Header
        variant="h3"
        actions={
          this.props.isEditing ? (
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                onClick={() => {
                  this.props.onDeleteOverrides(this.state.selectedItems);
                  this.setState({ selectedItems: [] });
                }}
              >
                Delete
              </Button>
              <Button variant="primary" onClick={() => this.setState({ isAddingNewOverride: true })}>
                Add
              </Button>
            </SpaceBetween>
          ) : null
        }
      >
        Planning Horizon Overrides
      </Header>
    );
  }
}
