import React from 'react';

import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import Modal from '@amzn/awsui-components-react-v3/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Container from '@amzn/awsui-components-react-v3/polaris/container';
import Input from '@amzn/awsui-components-react-v3/polaris/input';
import { MMOTTableRow } from './models';
import FormField from '@amzn/awsui-components-react-v3/polaris/form-field';
import { NumericInput } from '../../../../common-components/numeric-input';
import { CapacityConstraintsEditor } from '../capacity-constraints';

interface Props {
  readonly onCommit: (tableRow: MMOTTableRow) => void;
  readonly onClose: () => void;
  readonly tableRow: MMOTTableRow;
}

interface State {
  readonly tableRow: MMOTTableRow;
}

export class EditCapacityModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tableRow: this.props.tableRow,
    };
  }

  renderFields() {
    return (
      <Container>
        <SpaceBetween direction="vertical" size="s">
          <FormField label="Executor Name" key="en">
            <Input
              value={this.state.tableRow.executorName}
              disabled={this.props.tableRow.executorName !== ''}
              placeholder={'amzn1.flex.st.v1...'}
              onChange={(evt) => {
                const executorName = evt.detail.value.trim();
                this.setState({ tableRow: { ...this.state.tableRow, executorName } });
              }}
            />
          </FormField>
          <FormField label="Transit Factor" key="tf">
            <NumericInput
              startingText={this.state.tableRow.transitFactor.toString()}
              minimum={0}
              exclusive={true}
              onChange={(value) => this.setState({ tableRow: { ...this.state.tableRow, transitFactor: value } })}
            />
          </FormField>
          <FormField label="Service Factor" key="sf">
            <NumericInput
              startingText={this.state.tableRow.serviceFactor.toString()}
              minimum={0}
              exclusive={true}
              onChange={(value) => this.setState({ tableRow: { ...this.state.tableRow, serviceFactor: value } })}
            />
          </FormField>
        </SpaceBetween>
      </Container>
    );
  }

  renderCapacities() {
    return (
      <CapacityConstraintsEditor
        capacityConstraints={this.state.tableRow.capacityConstraints}
        onUpdate={(update) => {
          {
            let capacityConstraints = this.state.tableRow.capacityConstraints.filter((constraint) => constraint.type !== update.type);

            if (update.enabled) {
              capacityConstraints.push({
                value: update.value,
                unit: update.unit,
                type: update.type,
              });
            }
            const newRow = { ...this.state.tableRow, capacityConstraints };
            this.setState({ tableRow: newRow });
          }
        }}
      />
    );
  }

  valid() {
    return this.state.tableRow.executorName.length >= 1 && this.state.tableRow.capacityConstraints.length >= 1 && this.state.tableRow.transitFactor > 0 && this.state.tableRow.serviceFactor > 0;
  }

  render() {
    return (
      <Modal
        visible={true}
        header="MMOT Constraints"
        onDismiss={this.props.onClose}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={this.props.onClose}>Cancel Changes</Button>
              <Button variant="primary" disabled={!this.valid()} onClick={() => this.props.onCommit(this.state.tableRow)}>
                Keep Changes
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <SpaceBetween direction="vertical" size="m">
          {this.renderFields()}
          {this.renderCapacities()}
        </SpaceBetween>
      </Modal>
    );
  }
}
