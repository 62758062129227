import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import TextContent from '@amzn/awsui-components-react-v3/polaris/text-content';
import ButtonDropdown from '@amzn/awsui-components-react-v3/polaris/button-dropdown';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import './header.scss';
import flexLogo from './flex_logo.png';
import { StageConfiguration } from '../models';
import { configAccessor, config } from '../utilities';
import { Preferences } from './preferences';
import { GlobalContext } from './global-context';

interface Props {
  readonly username?: string;
}

interface State {
  readonly stageConfigs: ReadonlyArray<StageConfiguration>;
  readonly shouldDisplayCustomizationPopup: boolean;
}

export default class Header extends React.Component<Props, State> {
  static contextType = GlobalContext;
  // declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: Props) {
    super(props);

    this.state = {
      stageConfigs: configAccessor.getConfigurationsByStage(config.stage),
      shouldDisplayCustomizationPopup: false,
    };
  }

  private onStageChange(stageIdentifier: string) {
    const targetStage = configAccessor.getStageConfigurationById(stageIdentifier);
    window.location.assign(`https://${targetStage.domain}`);
  }

  private renderStageName(stageConfig: StageConfiguration): string {
    if (stageConfig.stage === 'prod') {
      return `Region ${stageConfig.marketplace}`;
    } else if (stageConfig.stage === 'devo') {
      return `Region ${stageConfig.marketplace} - ${stageConfig.stage} - ${stageConfig.id}`;
    } else {
      return `Region ${stageConfig.marketplace} - ${stageConfig.stage}`;
    }
  }

  private renderWelcomeMessage() {
    return (
      <TextContent>
        <p style={{ color: 'white' }}>
          Hello,{' '}
          <Box color="inherit" variant="span" fontWeight="bold">
            {this.props.username}
          </Box>
        </p>
      </TextContent>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.context.liveModeEffectEnabled ? <div id="live-mode-effect"></div> : null}
        <Preferences
          visible={this.state.shouldDisplayCustomizationPopup}
          hide={() => {
            this.setState({ shouldDisplayCustomizationPopup: false });
          }}
        />
        <Box id="header">
          <Box className="header-section left logo">
            <Link to="/">
              <img src={flexLogo} alt="Amazon Flex Logo" height="30px" />
            </Link>
          </Box>
          <Box className="header-section right">
            <Box className="header-item">{this.renderWelcomeMessage()}</Box>
            <Box className="header-item">
              <ButtonDropdown
                items={this.state.stageConfigs.map((config) => ({
                  id: config.id,
                  text: this.renderStageName(config),
                }))}
                onItemClick={(evt) => this.onStageChange(evt.detail.id)}
              >
                {this.renderStageName(config)}
              </ButtonDropdown>
            </Box>
            <Box className="header-item">
              <Button
                iconName="settings"
                variant="icon"
                className="setting-icon"
                onClick={() => {
                  this.setState({ shouldDisplayCustomizationPopup: true });
                }}
              />
            </Box>
          </Box>
        </Box>
      </React.Fragment>
    );
  }
}
