import React from 'react';
import Alert from '@amzn/awsui-components-react-v3/polaris/alert';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Tabs from '@amzn/awsui-components-react-v3/polaris/tabs';
import { ConfigUpdateConfirmModal } from './config-update-confirm-modal';
import ConfigJSONEditor from '../../common-components/config-json-editor';
import { AssignmentConfigFormEditor } from './assignment-config-form-editor';
import { ConfigAuditingTable, ConfigAuditingTableItem } from '../../common-components/config-auditing-table';
import { AssignmentCommitRecord, AssignmentConfig } from '../../models/assignment-models';
import { AssignmentConfigSummary } from './assignment-config-summary';
import { GlobalContext } from '../../main-app/global-context';
import { deepClone, asleep, readable } from '../../utilities';
import { assignmentClient, commonClient, drasScopeConfigClient } from '../../http-clients';
import { ServiceArea } from '../../models';

export interface AssignmentServiceAreaDetailsProps {
  readonly serviceAreaId: string;
  readonly activeTabId?: string;
  readonly switchTab: (tabId: string) => void;
}

export interface AssignmentServiceAreaDetailsState {
  readonly isLoading: boolean;
  readonly isSaving: boolean;
  readonly isFormInEditing: boolean;

  readonly errorMessage?: string;
  readonly displayConfirmModal: boolean;
  readonly serviceArea?: ServiceArea;

  readonly assignmentConfig?: AssignmentConfig;
  readonly planExecutorConsumeLmdpPlan?: boolean;

  readonly isReverting: boolean;
  readonly hasMoreCommitRecords: boolean;
  readonly commitRecords?: ReadonlyArray<ConfigAuditingTableItem<AssignmentCommitRecord>>;
}

const DEFAULT_TAB = 'assignment-config-forms';
const NUMBER_OF_RECORDS_PER_REQUEST = 100;

export class AssignmentServiceAreaDetails extends React.Component<AssignmentServiceAreaDetailsProps, AssignmentServiceAreaDetailsState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  // Reset state to the backup config when editing is discarded.
  private assignmentConfigBackup?: AssignmentConfig;

  constructor(props: AssignmentServiceAreaDetailsProps) {
    super(props);
    this.state = {
      isLoading: false,
      isSaving: false,
      isFormInEditing: false,
      displayConfirmModal: false,
      isReverting: false,
      hasMoreCommitRecords: false,
    };
  }

  async componentDidMount() {
    const breadcrumbsItems = [
      {
        text: 'Assignment Service Areas',
        href: '/assignment-service-areas',
      },
      {
        text: this.props.serviceAreaId,
        href: `/assignment-service-area/${this.props.serviceAreaId}`,
      },
    ];
    this.context.resetLayout();
    this.context.setTools('info-panel');
    this.context.updateBreadcrumbItems(breadcrumbsItems);

    this.loadServiceArea(this.props.serviceAreaId);
    this.refreshConfigs(this.props.serviceAreaId);
    this.loadCommitRecords(false);
  }

  async componentWillUnmount() {
    this.context.updateStationTimezone(undefined);
  }

  private async loadServiceArea(serviceAreaId: string) {
    try {
      const resp = await commonClient.getServiceArea(serviceAreaId);
      this.setState({
        serviceArea: resp.serviceArea,
      });
      if (typeof resp.serviceArea.timeZone === 'string') {
        this.context.updateStationTimezone(resp.serviceArea.timeZone);
      }
    } catch (err) {
      this.context.addNotification({
        header: 'Error',
        dismissible: true,
        type: 'error',
        content: `Failed to load service area ${serviceAreaId} due to ${readable(err)}`,
      });
    }
  }
  private async refreshConfigs(serviceAreaId: string) {
    try {
      this.setState({ isLoading: true });
      const resp = await drasScopeConfigClient.getDrasScopeConfig<AssignmentConfig>({
        scopeId: serviceAreaId,
        configType: 'FLEX_ASSIGNMENT_CONFIG',
      });
      this.setState({
        assignmentConfig: resp.config,
      });

      const workflow = await assignmentClient.getAssignmentWorkflow(serviceAreaId);
      this.setState({
        planExecutorConsumeLmdpPlan: workflow.assignmentWorkflow.planExecutorConsumeLmdpPlan,
      });
    } catch (err) {
      this.setState({
        errorMessage: `Failed to load assignment config due to ${readable(err)}`,
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  private async saveConfigurations(description: string) {
    const assignmentConfig = this.state.assignmentConfig;

    if (assignmentConfig === undefined) {
      return;
    }

    this.setState({ isSaving: true });
    try {
      await assignmentClient.putAssignmentServiceAreaDetails({
        serviceAreaId: this.props.serviceAreaId,
        description: description,
        assignmentConfig: assignmentConfig,
      });

      // sleep 2000 ms to wait DRAS to populate its index.
      await asleep(2000);
      this.setState({ displayConfirmModal: false, isFormInEditing: false });

      this.assignmentConfigBackup = undefined;

      this.refreshConfigs(this.props.serviceAreaId);
      this.loadCommitRecords(false);
    } catch (err) {
      this.setState({ errorMessage: `Failed to save assignment config due to ${readable(err)}` });
    } finally {
      this.setState({ isSaving: false });
    }
  }

  private async loadCommitRecords(loadMore: boolean) {
    // user can double clicks the > (load more) icon, and we should not call the backend if there is a on going call.
    // todo
    try {
      let startTimestamp: number | undefined = undefined;
      if (loadMore && this.state.commitRecords !== undefined && this.state.commitRecords.length > 0) {
        startTimestamp = this.state.commitRecords[0].commit.lastUpdateAt;
        this.state.commitRecords.forEach((item) => {
          startTimestamp = Math.min(item.commit.lastUpdateAt, startTimestamp!);
        });
        // -1 to avoid load a commit twice.
        startTimestamp = startTimestamp - 1;
      } else {
        this.setState({ commitRecords: undefined });
      }

      const resp = await drasScopeConfigClient.listDrasScopeConfigHistory({
        scopeId: this.props.serviceAreaId,
        configType: 'FLEX_ASSIGNMENT_CONFIG',
        numberOfRecords: NUMBER_OF_RECORDS_PER_REQUEST,
        before: typeof startTimestamp === 'number' ? new Date(startTimestamp).toISOString() : new Date().toISOString(),
      });

      let newItems = Array.from(this.state.commitRecords ?? []);

      if (loadMore) {
        resp.records.forEach((record) => {
          newItems.push({
            commit: {
              commitId: record.configId,
              serviceAreaId: this.props.serviceAreaId,
              clientId: record.clientId,
              description: record.description,
              lastUpdateAt: new Date(record.createdAt).getTime(),
              userAlias: record.userAlias,
            },
          });
        });
      } else {
        newItems = resp.records
          .sort((c1, c2) => c2.createdAt.localeCompare(c1.createdAt))
          .map((record, index) => ({
            commit: {
              commitId: record.configId,
              serviceAreaId: this.props.serviceAreaId,
              clientId: record.clientId,
              description: record.description,
              lastUpdateAt: new Date(record.createdAt).getTime(),
              userAlias: record.userAlias,
            },
            label: index === 0 ? 'latest' : undefined,
          }));
      }

      this.setState({
        commitRecords: newItems,
        hasMoreCommitRecords: resp.records.length >= NUMBER_OF_RECORDS_PER_REQUEST,
      });
    } catch (err) {
      this.setState({ errorMessage: `Failed to load change history due to ${readable(err)}` });
    }
  }

  private async revertConfigs(commit: AssignmentCommitRecord) {
    try {
      this.setState({ isReverting: true });

      await drasScopeConfigClient.revertDrasScopeConfigRequest({
        configId: commit.commitId,
        scopeId: this.props.serviceAreaId,
        configType: 'FLEX_ASSIGNMENT_CONFIG',
        description: `Revert configuration to [${commit.commitId}] "${commit.description}"`,
      });
      // sleep 2000 ms to wait DRAS to populate its index.
      await asleep(2000);
      this.assignmentConfigBackup = undefined;

      this.refreshConfigs(this.props.serviceAreaId);
      this.loadCommitRecords(false);
    } catch (err) {
      this.setState({ errorMessage: `Failed to revert configurations to ${commit.commitId} due to ${readable(err)}` });
    } finally {
      this.setState({ isReverting: false });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderConfirmationModal()}
        <SpaceBetween direction="vertical" size="m">
          {this.renderErrorMessage()}
          <SpaceBetween direction="vertical" size="l">
            {this.renderConfigSummary()}
            {this.renderTabs()}
          </SpaceBetween>
        </SpaceBetween>
      </React.Fragment>
    );
  }

  private renderErrorMessage() {
    if (typeof this.state.errorMessage === 'string') {
      return (
        <Alert
          header="Error"
          type="error"
          dismissible={true}
          dismissAriaLabel="Close Alert"
          onDismiss={() => {
            this.setState({ errorMessage: undefined });
          }}
        >
          {this.state.errorMessage}
        </Alert>
      );
    }
  }

  private renderConfirmationModal() {
    if (this.state.displayConfirmModal) {
      return (
        <ConfigUpdateConfirmModal
          serviceAreaId={this.props.serviceAreaId}
          isSaving={this.state.isSaving}
          onCancel={() => {
            this.setState({ displayConfirmModal: false });
          }}
          onConfirm={async (description: string) => {
            await this.saveConfigurations(description);
          }}
        />
      );
    }
  }

  private renderConfigSummary() {
    return <AssignmentConfigSummary serviceArea={this.state.serviceArea} assignmentConfig={this.state.assignmentConfig} planExecutorConsumeLmdpPlan={this.state.planExecutorConsumeLmdpPlan} />;
  }

  private renderTabs() {
    const activeTabId = this.props.activeTabId ? this.props.activeTabId : DEFAULT_TAB;

    return (
      <Tabs
        activeTabId={activeTabId}
        tabs={[
          {
            label: 'Forms Editor',
            id: 'assignment-config-forms',
            content: (
              <AssignmentConfigFormEditor
                isEditing={this.state.isFormInEditing}
                isSaving={this.state.isSaving}
                assignmentConfig={this.state.assignmentConfig}
                onEdit={() => {
                  this.assignmentConfigBackup = this.state.assignmentConfig;
                  this.setState({
                    assignmentConfig: this.assignmentConfigBackup ? deepClone(this.assignmentConfigBackup) : undefined,
                    isFormInEditing: true,
                  });
                }}
                onDiscard={() => {
                  this.setState({
                    assignmentConfig: this.assignmentConfigBackup,
                    isFormInEditing: false,
                  });
                  this.assignmentConfigBackup = undefined;
                }}
                onSave={() => this.setState({ displayConfirmModal: true })}
                onUpdate={(assignmentConfig) => {
                  this.setState({
                    assignmentConfig: assignmentConfig,
                  });
                }}
              />
            ),
          },
          {
            label: 'Update History',
            id: 'update-history',
            content: (
              <ConfigAuditingTable
                serviceAreaId={this.props.serviceAreaId}
                timezonePreference={this.context.timezonePreference}
                itemsPerPage={10}
                items={this.state.commitRecords}
                onRefresh={() => this.loadCommitRecords(false)}
                isReverting={this.state.isReverting}
                onRevert={async (commit) => await this.revertConfigs(commit)}
                hasMore={this.state.hasMoreCommitRecords}
                onLoadMore={() => this.loadCommitRecords(true)}
                loadConfig={async (commitId: string) => {
                  try {
                    const resp = await drasScopeConfigClient.getDrasScopeConfig<AssignmentConfig>({
                      scopeId: this.props.serviceAreaId,
                      configId: commitId,
                      configType: 'FLEX_ASSIGNMENT_CONFIG',
                    });
                    return resp.config;
                  } catch (err) {
                    this.setState({ errorMessage: `Failed to load commit ${commitId} configurations due to ${readable(err)}` });
                    return undefined;
                  }
                }}
              />
            ),
          },
          {
            label: 'JSON Viewer',
            id: 'assignment-config-json',
            content: <ConfigJSONEditor headerText="JSON Viewer" isLoading={this.state.isLoading} data={this.state.assignmentConfig ? this.state.assignmentConfig : 'loading...'} />,
          },
        ]}
        onChange={(evt) => this.props.switchTab(evt.detail.activeTabId)}
      />
    );
  }
}
