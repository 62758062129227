import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table';
import { PlanningHorizonConfigOverride } from '../../../../models/routing-models';
import { PlanningHorizonType } from './models';
import { ORDER_TYPE_TO_LABEL } from '../constants';
import { isNotNil } from '../../../../utilities';

export function inferPlanningHorizonType(item: PlanningHorizonConfigOverride): PlanningHorizonType | undefined {
  if (isNotNil(item.timeBasedHorizonConfig)) {
    if (isNotNil(item.horizonConfig)) {
      console.warn("Time based horizon can't coexist with traditional horizon. Ignore traditional horizon.");
    }
    return 'timebased';
  } else if (isNotNil(item.horizonConfig)) {
    return 'normal';
  } else {
    console.warn(`planning horizon doesn't exist on ${JSON.stringify(item)}.`);
    return undefined;
  }
}

export function getOrderTypeForItem(item: PlanningHorizonConfigOverride) {
  if (item.effectiveForAllTypes) {
    return 'All';
  } else if (item.orderType) {
    return ORDER_TYPE_TO_LABEL[item.orderType];
  } else {
    return '-';
  }
}

export function getEffectiveTimeForItem(item: PlanningHorizonConfigOverride) {
  return item.effectiveAllDay ? 'All Day Effective' : `${item.startLocalTime} - ${item.endLocalTime}`;
}

interface SortOverrideProps {
  readonly isDescending?: boolean;
  readonly sortingColumn?: TableProps.SortingColumn<PlanningHorizonConfigOverride>;
}

export function sortOverrides(overrides: ReadonlyArray<PlanningHorizonConfigOverride>, props: SortOverrideProps): ReadonlyArray<PlanningHorizonConfigOverride> {
  const comparator = props.sortingColumn?.sortingComparator;
  if (comparator) {
    const items = Array.from(overrides);
    return items.sort((i1, i2) => {
      return props.isDescending ? comparator(i2, i1) : comparator(i1, i2);
    });
  }
  return overrides;
}
