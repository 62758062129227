import { SUPPORTED_TRANSPORTER_TYPES, TransporterType } from '../../../models/assignment-models';

export const SUPPORTED_TRANSPORTER_TYPE_TO_LABEL: Record<TransporterType, string> = {
  IO: 'Instant Offer',
  BLOCK: 'Block',
  OVERFLOW: 'Overflow',
};

export const SUPPORTED_TRANSPORTER_TYPE_OPTIONS: ReadonlyArray<{ readonly id: TransporterType; readonly text: string }> = SUPPORTED_TRANSPORTER_TYPES.map((type) => ({
  id: type,
  text: SUPPORTED_TRANSPORTER_TYPE_TO_LABEL[type],
}));
