import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import '@amzn/awsui-global-styles/polaris.css';
import './custom-theme.less';
import Root from './main-app';
import { DEFAULT_TIMEZONE_PREFERENCE, preferencesStore, timezoneManager, credentials, applyTheme } from './utilities';
import { applyMode, Mode, applyDensity, Density } from '@amzn/awsui-global-styles';
import { DEFAULT_UI_DENSITY, DEFAULT_UI_MODE, DEFAULT_UI_THEME, PREFERRED_TIMEZONE_KEY, UI_DENSITY_KEY, UI_MODE_KEY, UI_THEME_KEY } from './constants';
import Box from '@amzn/awsui-components-react-v3/polaris/box';

// initialize website states
applyMode('light' === preferencesStore.get(UI_MODE_KEY, DEFAULT_UI_MODE) ? Mode.Light : Mode.Dark);
applyDensity('comfortable' === preferencesStore.get(UI_DENSITY_KEY, DEFAULT_UI_DENSITY) ? Density.Comfortable : Density.Compact);
applyTheme(preferencesStore.get(UI_THEME_KEY, DEFAULT_UI_THEME));

timezoneManager.setTimezonePreference(preferencesStore.get(PREFERRED_TIMEZONE_KEY, DEFAULT_TIMEZONE_PREFERENCE));

credentials.refresh((err) => {
  if (err !== undefined) {
    ReactDOM.render(<Box>Login required</Box>, document.getElementById('root'));
  } else {
    ReactDOM.render(
      <React.StrictMode>
        <BrowserRouter>
          <Root />
        </BrowserRouter>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  }
});
