import { ServiceArea } from './models';

export interface RefreshServiceAreaInDisplayProps {
  readonly serviceAreas?: ReadonlyArray<ServiceArea>;
  readonly filteringText: string;
  readonly sortingDescending?: boolean;
  readonly comparatorFunc?: (s1: ServiceArea, s2: ServiceArea) => number;
}

/**
 * filter and sort
 * @param props
 * @returns
 */
export function refreshServiceAreaInDisplay(props: RefreshServiceAreaInDisplayProps): ServiceArea[] | undefined {
  if (props.serviceAreas === undefined) {
    return undefined;
  }
  // filter
  const serviceAreas = filterServiceAreas(props.filteringText, props.serviceAreas);
  // sort
  const comparatorFunc = props.comparatorFunc;
  if (comparatorFunc !== undefined) {
    serviceAreas.sort((i1, i2) => {
      return props.sortingDescending ? comparatorFunc(i2, i1) : comparatorFunc(i1, i2);
    });
  }
  return serviceAreas;
}

interface ScoredArea {
  area: ServiceArea;
  score: number;
}

/**
 * Affinity based sorting.
 * Each service area gets a score. The score is the maximum score of any of its text fields.
 * To score a text field:
 *    If the query is contained within the text,
 *      add score equal to the percent of the text that the query represents.
 *    If the query is a prefix of the text,
 *      add an additional 20%
 * For example, if any field exactly matches the query, that service area would score the maximum of 120%.
 *    100% of the query is contained within the field
 *    +20% for the query being a prefix of the field
 *
 * Areas are returned sorted from most to least affinity.
 * Also, areas with 0% affinity (where no field contained the query) are filtered out.
 *
 * @param filterText the user provided query
 * @param serviceAreas the list to sort
 * @returns the sorted and filtered list
 */
export function filterServiceAreas(filterText: string, serviceAreas: ReadonlyArray<ServiceArea>): ServiceArea[] {
  filterText = filterText.toLowerCase();

  if (filterText.length < 1) {
    return Array.from(serviceAreas);
  }

  function filterAffinity(serviceArea: ServiceArea, filterText: string): number {
    function matchPercent(text: string): number {
      let score = 0;
      if (text.toLowerCase().includes(filterText)) {
        score += filterText.length / text.length;
      }
      if (text.toLowerCase().startsWith(filterText)) {
        score += 0.2;
      }
      return score;
    }

    let scores = [matchPercent(serviceArea.serviceAreaId), matchPercent(serviceArea.serviceAreaName), matchPercent(serviceArea.stationCode)];
    return Math.max.apply(0, scores);
  }

  let scoredAreas: Array<ScoredArea> = serviceAreas.map((area) => {
    return { area, score: filterAffinity(area, filterText) };
  });
  return scoredAreas
    .sort((s1, s2) => s2.score - s1.score)
    .filter((s) => s.score > 0)
    .map((s) => s.area);
}
