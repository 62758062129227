import React from 'react';
import Alert from '@amzn/awsui-components-react-v3/polaris/alert';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Tabs from '@amzn/awsui-components-react-v3/polaris/tabs';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import { ConfigUpdateConfirmModal } from './config-update-confirm-modal';
import { ServiceAreaInfo } from './service-area-info';
import { RoutingConfigFormEditor } from './routing-config-form-editor';
import { GlobalContext } from '../../main-app/global-context';
import { AlgorithmSettings, RoutingServiceArea, ServiceAreaConfig, RoutingServiceAreaMetadata, RoutingCommitRecord, GSFRoutingConfigurations, AccessPoint } from '../../models/routing-models';
import ConfigJSONEditor from '../../common-components/config-json-editor';
import { ConfigAuditingTable, ConfigAuditingTableItem } from '../../common-components/config-auditing-table';
import { readable, deepClone } from '../../utilities';
import { routingClient } from '../../http-clients';

interface RoutingServiceAreaDetailsProps {
  readonly serviceAreaId: string;
  readonly activeTabId?: string;
  readonly switchTab: (tabId: string) => void;
}

interface RoutingServiceAreaDetailsState {
  readonly showConfirmationModal: boolean;
  readonly errorMessage?: string;
  readonly planExecutorErrorMessage?: string;

  readonly isSaving: boolean;
  readonly isLoading: boolean;

  /**
   * Indicate if configurations are in editing. We have to "forms" that can edit configurations.
   * The top summary can edit the service area fleets and cadence, the bottom form can edit the
   * ServiceAreaConfigs and AlgorithmSettings.
   */
  readonly isFormInEditing: boolean;
  readonly isInfoInEditing: boolean;

  readonly serviceArea?: RoutingServiceArea;
  readonly serviceAreaMetadata?: RoutingServiceAreaMetadata;
  readonly serviceAreaConfig?: ServiceAreaConfig;
  readonly algorithmSettings?: AlgorithmSettings;
  readonly accessPoints?: ReadonlyArray<AccessPoint>;

  readonly isReverting: boolean;
  readonly hasMoreCommitRecords: boolean;
  readonly commitRecords?: ReadonlyArray<ConfigAuditingTableItem<RoutingCommitRecord>>;
}

const COMMIT_RECORDS_PER_REQUEST = 10;

export class RoutingServiceAreaDetails extends React.Component<RoutingServiceAreaDetailsProps, RoutingServiceAreaDetailsState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  /**
   * Copy of the previous configurations. Reset state to the previous configurations when the user clicks "Discard" button.
   */
  private prevServiceAreaMetadata?: RoutingServiceAreaMetadata;
  private prevServiceAreaConfig?: ServiceAreaConfig;
  private prevAlgorithmSettings?: AlgorithmSettings;

  constructor(props: RoutingServiceAreaDetailsProps) {
    super(props);
    this.state = {
      showConfirmationModal: false,
      isSaving: false,
      isLoading: false,
      isFormInEditing: false,
      isInfoInEditing: false,
      isReverting: false,
      hasMoreCommitRecords: false,
    };
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.setTools('info-panel');

    this.context.updateBreadcrumbItems([
      {
        text: 'Routing Service Areas',
        href: '/service-areas',
      },
      {
        text: this.props.serviceAreaId,
        href: `/service-area/${this.props.serviceAreaId}`,
      },
    ]);

    this.refreshConfigs();
    this.loadCommitRecords(true);
  }

  private async refreshConfigs() {
    try {
      this.setState({ isLoading: true });

      const resp = await routingClient.getRoutingServiceAreaDetails({
        serviceAreaId: this.props.serviceAreaId,
        type: 'ALL',
      });

      this.setState({
        serviceArea: resp.serviceArea,
        serviceAreaMetadata: resp.serviceAreaMetadata,
        serviceAreaConfig: resp.serviceAreaConfig,
        algorithmSettings: resp.algorithmSettings,
        accessPoints: resp.accessPoints,
      });
    } catch (err) {
      this.setState({ errorMessage: `Failed to load configurations due to ${readable(err)}` });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  private async saveConfigurations(description: string) {
    const serviceAreaMetadata = this.state.serviceAreaMetadata;
    const serviceAreaConfig = this.state.serviceAreaConfig;
    const algorithmSettings = this.state.algorithmSettings;

    if (serviceAreaMetadata !== undefined && serviceAreaConfig !== undefined && algorithmSettings !== undefined) {
      try {
        this.setState({ isSaving: true });
        const resp = await routingClient.putRoutingServiceAreaDetails({
          serviceAreaId: this.props.serviceAreaId,
          description: description,
          serviceAreaMetadata: serviceAreaMetadata,
          serviceAreaConfig: serviceAreaConfig,
          algorithmSettings: algorithmSettings,
        });

        this.setState({ showConfirmationModal: false, isFormInEditing: false, isInfoInEditing: false });

        /**
         * the call putServiceAreaDetails can succeed even if the PE call has failure, and the PE
         * exception is returned from the exceptionMessage property
         */
        if (typeof resp.exceptionMessage === 'string') {
          this.setState({ planExecutorErrorMessage: resp.exceptionMessage });
        } else {
          this.setState({ planExecutorErrorMessage: undefined });
        }

        // No longer need the previous configurations because the current configurations is saved.
        this.prevServiceAreaMetadata = undefined;
        this.prevServiceAreaConfig = undefined;
        this.prevAlgorithmSettings = undefined;

        this.refreshConfigs();
        this.loadCommitRecords(true);
      } catch (err) {
        this.setState({ errorMessage: `Failed to save configurations due to ${readable(err)}` });
      } finally {
        this.setState({ isSaving: false, showConfirmationModal: false });
      }
    }
  }

  private async loadCommitRecords(refresh: boolean) {
    try {
      let records: RoutingCommitRecord[] = [];
      let previousCommitId: string | undefined = undefined;
      if (!refresh && this.state.commitRecords !== undefined) {
        records = this.state.commitRecords.map((r) => r.commit);
        previousCommitId = this.state.commitRecords !== undefined && this.state.commitRecords.length > 0 ? this.state.commitRecords[0].commit.clientId : undefined;
      }

      if (refresh) {
        this.setState({ commitRecords: undefined });
      }

      const resp = await routingClient.getRoutingCommitRecords({
        serviceAreaId: this.props.serviceAreaId,
        numberOfRecords: COMMIT_RECORDS_PER_REQUEST,
        commitId: previousCommitId,
      });

      records = records.concat(resp.commitRecords);
      records.sort((r1, r2) => r2.lastUpdateAt - r1.lastUpdateAt);

      this.setState({
        commitRecords: records.map((record, index) => ({
          commit: record,
          label: index === 0 ? 'latest' : undefined,
        })),
        hasMoreCommitRecords: resp.commitRecords.length >= COMMIT_RECORDS_PER_REQUEST,
      });
    } catch (err) {
      this.setState({ errorMessage: `Failed to load change history due to ${readable(err)}` });
    }
  }

  private async revertConfigs(commit: RoutingCommitRecord) {
    try {
      this.setState({ isReverting: true });
      await routingClient.revertRoutingConfigurations({
        commitId: commit.commitId,
        description: `Revert configuration to [${commit.commitId}] "${commit.description}"`,
      });

      // refresh commit records and configs after revert.
      await this.loadCommitRecords(true);
      await this.refreshConfigs();
    } catch (err) {
      this.setState({ errorMessage: `Failed to revert configurations to ${commit.commitId} due to ${readable(err)}` });
    } finally {
      this.setState({ isReverting: false });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderConfirmationModal()}
        <SpaceBetween direction="vertical" size="m">
          {this.renderErrorMessage()}
          {this.renderPlanExecutorErrorMessage()}

          <SpaceBetween direction="vertical" size="l">
            <ServiceAreaInfo
              serviceAreaId={this.props.serviceAreaId}
              serviceArea={this.state.serviceArea}
              serviceAreaMetadata={this.state.serviceAreaMetadata}
              isSaving={this.state.isSaving}
              isEditing={this.state.isInfoInEditing}
              onDiscard={() => {
                this.setState({
                  serviceAreaMetadata: this.prevServiceAreaMetadata,
                  isInfoInEditing: false,
                });
                this.prevServiceAreaMetadata = undefined;
              }}
              onEdit={() => {
                this.prevServiceAreaMetadata = this.state.serviceAreaMetadata;

                this.setState({
                  serviceAreaMetadata: this.prevServiceAreaMetadata ? deepClone(this.prevServiceAreaMetadata) : undefined,
                  isInfoInEditing: true,
                });
              }}
              onUpdate={(serviceAreaMetadata) => {
                this.setState({
                  serviceAreaMetadata: serviceAreaMetadata,
                });
              }}
              onSave={() => {
                this.setState({ showConfirmationModal: true });
              }}
            />
            {this.renderTabs()}
          </SpaceBetween>
        </SpaceBetween>
      </React.Fragment>
    );
  }

  private renderErrorMessage() {
    if (typeof this.state.errorMessage === 'string') {
      return (
        <Alert header="Error" type="error" dismissible={true} action={this.renderErrorAction()} dismissAriaLabel="Close Alert" onDismiss={() => this.setState({ errorMessage: undefined })}>
          {this.state.errorMessage}
        </Alert>
      );
    }
  }

  private renderErrorAction() {
    if (this.context.operatingMode === 'developer') {
      return (
        <Button
          onClick={async () => {
            try {
              await routingClient.disableRoutingServiceAreas({
                serviceAreaIds: [this.props.serviceAreaId],
              });
            } catch (err) {
              this.setState({ errorMessage: `Failed to disable service area due to ${readable(err)}` });
            }
          }}
        >
          Disable service area
        </Button>
      );
    }
  }

  private renderPlanExecutorErrorMessage() {
    if (typeof this.state.planExecutorErrorMessage === 'string') {
      return (
        <Alert header="PlanExecutor Error" type="error" dismissible dismissAriaLabel="Close Alert" onDismiss={() => this.setState({ planExecutorErrorMessage: undefined })}>
          {this.state.planExecutorErrorMessage}
        </Alert>
      );
    }
  }

  private renderConfirmationModal() {
    if (this.state.showConfirmationModal) {
      return (
        <ConfigUpdateConfirmModal
          serviceAreaId={this.props.serviceAreaId}
          isSaving={this.state.isSaving}
          onCancel={() => {
            this.setState({ showConfirmationModal: false });
          }}
          onUpdate={async (description) => await this.saveConfigurations(description)}
        />
      );
    }
  }

  private renderTabs() {
    const activeTabId = this.props.activeTabId ? this.props.activeTabId : 'service-area-config-forms';

    return (
      <Tabs
        activeTabId={activeTabId}
        tabs={[
          {
            label: 'Forms Editor',
            id: 'service-area-config-forms',
            content: (
              <RoutingConfigFormEditor
                isSaving={this.state.isSaving}
                serviceAreaConfig={this.state.serviceAreaConfig}
                algorithmSettings={this.state.algorithmSettings}
                operatingMode={this.context.operatingMode}
                accessPoints={this.state.accessPoints}
                userAccesses={this.context.userAccesses}
                isEditing={this.state.isFormInEditing}
                onDiscard={() => {
                  this.setState({
                    serviceAreaConfig: this.prevServiceAreaConfig,
                    algorithmSettings: this.prevAlgorithmSettings,
                    isFormInEditing: false,
                  });
                  this.prevServiceAreaConfig = undefined;
                  this.prevAlgorithmSettings = undefined;
                }}
                onEdit={() => {
                  this.prevServiceAreaConfig = this.state.serviceAreaConfig;
                  this.prevAlgorithmSettings = this.state.algorithmSettings;

                  this.setState({
                    serviceAreaConfig: this.prevServiceAreaConfig ? deepClone(this.prevServiceAreaConfig) : undefined,
                    algorithmSettings: this.prevAlgorithmSettings ? deepClone(this.prevAlgorithmSettings) : undefined,
                    isFormInEditing: true,
                  });
                }}
                onUpdate={(serviceAreaConfig, algorithmSettings) => {
                  this.setState({
                    serviceAreaConfig: serviceAreaConfig,
                    algorithmSettings: algorithmSettings,
                  });
                }}
                onSave={() => {
                  this.setState({ showConfirmationModal: true });
                }}
              />
            ),
          },
          {
            label: 'Update History',
            id: 'update-history',
            content: (
              <ConfigAuditingTable<RoutingCommitRecord, GSFRoutingConfigurations>
                serviceAreaId={this.props.serviceAreaId}
                timezonePreference={this.context.timezonePreference}
                itemsPerPage={COMMIT_RECORDS_PER_REQUEST}
                items={this.state.commitRecords}
                onRefresh={() => this.loadCommitRecords(true)}
                isReverting={this.state.isReverting}
                onRevert={async (commitId) => await this.revertConfigs(commitId)}
                hasMore={this.state.hasMoreCommitRecords}
                onLoadMore={() => this.loadCommitRecords(false)}
                loadConfig={async (commitId: string) => {
                  try {
                    const resp = await routingClient.getRoutingServiceAreaDetailsByCommit({
                      commitId: commitId,
                    });
                    return {
                      serviceAreaConfig: resp.serviceAreaConfig,
                      algorithmSettings: resp.algorithmSettings,
                    };
                  } catch (err) {
                    this.setState({ errorMessage: `Failed to load configurations ${commitId} due to ${readable(err)}` });
                    return undefined;
                  }
                }}
              />
            ),
          },
          {
            label: 'Service Area Config JSON Viewer',
            id: 'service-area-config-json',
            content: <ConfigJSONEditor headerText="JSON Viewer" isLoading={this.state.isLoading} data={this.state.serviceAreaConfig ?? 'loading...'} />,
          },
          {
            label: 'Algorithm Settings JSON Viewer',
            id: 'algorithm-settings-json',
            content: <ConfigJSONEditor headerText="JSON Viewer" isLoading={this.state.isLoading} data={this.state.algorithmSettings ?? 'loading...'} />,
          },
        ]}
        onChange={(evt) => this.props.switchTab(evt.detail.activeTabId)}
      />
    );
  }
}
