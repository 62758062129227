import React from 'react';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import HelpPanel from '@amzn/awsui-components-react-v3/polaris/help-panel';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import ColumnLayout from '@amzn/awsui-components-react-v3/polaris/column-layout';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';

export class RoutingSiteProgramHelpPanel extends React.Component<{}, {}> {
  render() {
    return (
      <HelpPanel header={<Header variant="h2">Routing Site Programs</Header>}>
        <ColumnLayout columns={1} borders="horizontal">
          <Box variant="p">
            The page provides a quick way to find GSF routing enabled service area Ids for a specific program type. It achieves this with a naive apporach, where it looks at the first letter of the
            station code based on the following rules.
          </Box>

          <SpaceBetween direction="vertical" size="s">
            <Box variant="h4">NA</Box>
            <ul>
              <li>C - Whole Foods</li>
              <li>F - Fresh</li>
              <li>R - Community Flex</li>
              <li>U - UFF</li>
            </ul>
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="s">
            <Box variant="h4">EU</Box>
            <ul>
              <li>C - 3rd Party</li>
              <li>F - Fresh</li>
              <li>U - UFF</li>
            </ul>
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="s">
            <Box variant="h4">FE</Box>
            <ul>
              <li>C - 3rd Party</li>
              <li>F - Fresh</li>
              <li>R - Community Flex</li>
              <li>U - UFF</li>
            </ul>
          </SpaceBetween>
        </ColumnLayout>
      </HelpPanel>
    );
  }
}
