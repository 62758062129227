import { validatorFactory } from '../../common-components/input-cell/input-validators';
import { ItemConfig } from '../../models';
import { InputTableItem } from '../../common-components/input-table';
import { ValidationInput, ValidationResult, ValueType } from '../../common-components/input-cell';
import { DriaConfig } from '../../models/dria-models';

/**
 *
 * @param itemConfig
 * @param assignmentConfig
 * @returns note undefined and null are treated different.
 *  undefined indicates the parameter is still loading
 *  null indicates the parameter is null (never set)
 */
function findDriaConfigValue(itemConfig: ItemConfig, config?: DriaConfig | null): ValueType | undefined {
  if (itemConfig.scope === 'driaConfig' && config) {
    let value: any = config;
    const fields = itemConfig.field.split('.');
    for (let i = 0; i < fields.length; i++) {
      if (value === null || value === undefined) {
        return null;
      } else {
        value = value[fields[i]];
      }
    }
    return value === undefined ? null : value;
  }
  return undefined;
}

export function convertDriaConfigToItem(itemConfig: ItemConfig, config?: DriaConfig | null): InputTableItem {
  let inputValidator: ((input: ValidationInput) => ValidationResult) | undefined = undefined;
  if (itemConfig.validators && itemConfig.validators.length > 0) {
    if (itemConfig.validators.length > 1) {
      console.warn(`Don't support multiple validators yet. ${itemConfig.validators[0].name} will be used.`);
    }
    if (validatorFactory[itemConfig.validators[0].name]) {
      inputValidator = validatorFactory[itemConfig.validators[0].name];
    } else {
      console.warn(`Unknown validator ${itemConfig.validators[0].name}`);
    }
  }

  let itemName: string;
  if (itemConfig.name === undefined) {
    const fields = itemConfig.field.split('.');
    itemName = fields[fields.length - 1];
  } else {
    itemName = itemConfig.name;
  }

  return {
    field: itemConfig.field,
    name: itemName,
    description: itemConfig.description,
    value: findDriaConfigValue(itemConfig, config),
    scope: itemConfig.scope,
    representationType: itemConfig.representationType,
    dataType: itemConfig.dataType,
    inputValidator: inputValidator,
  };
}
