import React from 'react';
import Alert from '@amzn/awsui-components-react-v3/polaris/alert';
import { GlobalContext } from '../../main-app/global-context';

interface NotFoundPageProps {
  readonly path: string;
}

interface NotFoundPageState {}

export class NotFoundPage extends React.Component<NotFoundPageProps, NotFoundPageState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  componentDidMount() {
    this.context.resetLayout();
  }

  render() {
    return (
      <Alert type="warning" header="404">
        The page "{this.props.path}" can't be found.
      </Alert>
    );
  }
}
