import { CapacityConstraint, ServiceAreaConfig } from '../../../../models/routing-models';
import { CapacityConstraintUpdate } from './models';

export function handleCapacityConstraintUpdate(serviceAreaConfig: ServiceAreaConfig, capacityConstraintUpdate: CapacityConstraintUpdate): ServiceAreaConfig {
  let capacityConstraints: CapacityConstraint[] = [];
  if (serviceAreaConfig.capacityConstraints) {
    // keep the existing fields that haven't just been updated
    capacityConstraints = serviceAreaConfig.capacityConstraints.filter((constraint) => constraint.type !== capacityConstraintUpdate.type);
  }

  if (capacityConstraintUpdate.enabled) {
    capacityConstraints.push({
      value: capacityConstraintUpdate.value,
      unit: capacityConstraintUpdate.unit,
      type: capacityConstraintUpdate.type,
    });
  }
  return {
    ...serviceAreaConfig,
    capacityConstraints: capacityConstraints,
  };
}
