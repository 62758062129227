import React from 'react';
import MarkdownRender from '../../common-components/markdown-render';

export interface PermissionTableProps {
  readonly access: 'all' | 'routing' | 'assignment' | 'dria';
}

export function PermissionTable(props: PermissionTableProps) {
  const markdownSegements: string[] = [
    `| Permission | Comments | SIM link |
    | --- | --- | --- |`,
  ];

  if (props.access === 'all' || props.access === 'routing') {
    markdownSegements.push(`| Routing configurations CO view | Managed by [CO-Tech-CON](https://permissions.amazon.com/a/team/CO-Tech-Con) team | N/A |
    | Routing configurations Full view | | https://sim.amazon.com/issues/create?template=808c2e6d-335a-4848-ac18-9775a0571fb2 |
    | Update routing configurations | | https://sim.amazon.com/issues/create?template=50e8e027-7850-40c1-91d9-58721a111e70 |`);
  }

  if (props.access === 'all' || props.access === 'assignment') {
    markdownSegements.push(`| Update assignment configurations | | https://sim.amazon.com/issues/create?template=63be3d93-f38b-4756-bcdd-858f3e40bfa2 |`);
  }

  if (props.access === 'all' || props.access === 'dria') {
    markdownSegements.push(`| Update DRIA configurations | Internal only | N/A |`);
  }

  return <MarkdownRender markdownText={markdownSegements.join('\n')} />;
}
