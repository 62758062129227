import { DriaWorkType } from '../../http-clients/dria-client';

export const WORK_TYPE_TO_LABEL: Record<DriaWorkType, string> = {
  SNAPSHOT_ROUTES: 'Route Snapshot',
  SNAPSHOT_PROVIDERS: 'Provider Snapshot',
  SNAPSHOT_SERVICE_AREA: 'Service Area Snapshot',
  SNAPSHOT_STATION_UTILIZATION: 'Station Utilization Snapshot',
  SNAPSHOT_PACKAGES: 'Package Snapshot',
  SNAPSHOT_CONSOLIDATED_PACKAGE: 'Consolidated Package Snapshot',
  PROVIDER_AVAILABILITY_CACHE_UPDATE: 'Provider Availability Cache',
  PROVIDER_TO_CASPIAN_SNAPSHOT_COMPARISON: 'Provider Comparison',
  SNAPSHOT_POPULARITY: 'Popularity Snapshot',
  SNAPSHOT_REGION: 'Region Snapshot',
  SNAPSHOT_ROUTE_LABOR_ADJUSTMENT: 'RLAS Snapshot',
};
