import React, { Component, ReactNode } from 'react';
import { Differ, Viewer as SideBySideViewer } from 'json-diff-kit';
import memoize from 'memoize-one';
import stringify from 'json-stable-stringify';
import { diff as DiffEditor } from 'react-ace';
import './diff-viewer.css';

/**
 * {@see DiffViewer} properties
 */
export interface DiffViewerProps {
  /**
   * Object to render on left side
   */
  readonly left: any;
  /**
   * Object to render on right side
   */
  readonly right: any;
  /**
   * Render mode. See {@see DiffViewer} for more details
   */
  readonly sideBySide?: boolean;
  /**
   * Indent size used to render JSON.
   * @default 2
   */
  readonly indent?: number;
}

const DEFAULT_INDENT = 2;

/**
 * Diff viewer to compare two objects as JSON. Can operate in two modes.
 * * Side by Side mode. Scrolls both JSON views in sync with changes aligned and highlighted. Implemented using https://www.npmjs.com/package/json-diff-kit
 * * Legacy mode. Renders each JSON in its own editor. Implemented using https://www.npmjs.com/package/react-ace
 */
export class DiffViewer extends Component<DiffViewerProps, {}> {
  private readonly differ = new Differ({
    detectCircular: true,
    maxDepth: Infinity,
    showModifications: true,
    arrayDiffMethod: 'lcs',
  });

  private readonly sideBySideDiff = memoize((left: any, right: any) => this.differ.diff(left, right));

  private readonly jsonDiff = memoize((left: any, right: any) => [stringify(left, { space: this.getIndent() }), stringify(right, { space: this.getIndent() })]);

  override render(): ReactNode {
    if (this.props.left === undefined || this.props.right === undefined) {
      return null;
    } else {
      return this.props.sideBySide ? (
        <SideBySideViewer
          diff={this.sideBySideDiff(this.props.left, this.props.right)}
          indent={this.getIndent()}
          lineNumbers={true}
          highlightInlineDiff={true}
          inlineDiffOptions={{
            mode: 'word',
            wordSeparator: ' ',
          }}
        />
      ) : (
        <DiffEditor
          value={this.jsonDiff(this.props.left, this.props.right)}
          mode="json"
          theme="xcode"
          showPrintMargin={false} // if true, there is a vertical line indicate #chars on a line
          focus={false}
          width={'100%'}
          name="COMPARE_DIFF_ASSIGNMENT"
          maxLines={30}
          minLines={29}
          editorProps={{ $blockScrolling: true }}
        />
      );
    }
  }

  private getIndent() {
    return this.props.indent ?? DEFAULT_INDENT;
  }
}
