import React from 'react';
import JSONEditor from './json-editor';
import Container from '@amzn/awsui-components-react-v3/polaris/container';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import StatusIndicator from '@amzn/awsui-components-react-v3/polaris/status-indicator';

export declare namespace ConfigJSONEditor {
  export interface Props {
    data: any;
    isLoading?: boolean; // false
    headerText?: string; // JSON Editor
    errorMessage?: string;
    hint?: string;
    discard?: () => void;
    save?: () => void;
    edit?: () => void;
  }
}
// eslint-disable-next-line no-redeclare
export class ConfigJSONEditor extends React.Component<ConfigJSONEditor.Props> {
  render() {
    return (
      <Container
        header={
          <Header variant="h2" description={this.props.hint ? <StatusIndicator type="info">{this.props.hint}</StatusIndicator> : null}>
            {this.props.headerText ?? 'JSON Editor'}
          </Header>
        }
      >
        <JSONEditor readOnly={true} showGutter={true} data={this.props.data} />
      </Container>
    );
  }
}

export default ConfigJSONEditor;
