import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table';
import { ConfigScheduleInfo } from '../../http-clients/config-schedule-client';

interface SortOverrideProps {
  readonly isDescending?: boolean;
  readonly sortingColumn?: TableProps.SortingColumn<ConfigScheduleInfo>;
}

const dayOrder: { [key: string]: number } = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

export function sortConfigScheduleItems(overrides: ReadonlyArray<ConfigScheduleInfo>, props: SortOverrideProps): ReadonlyArray<ConfigScheduleInfo> {
  const comparator = props.sortingColumn?.sortingComparator;
  if (comparator) {
    const items = Array.from(overrides);
    return items.sort((i1, i2) => {
      return props.isDescending ? comparator(i2, i1) : comparator(i1, i2);
    });
  }
  return overrides;
}

export function sortingComparatorUtility(i1: ConfigScheduleInfo, i2: ConfigScheduleInfo): number {
  const day1 = i1.day ?? '-';
  const day2 = i2.day ?? '-';
  const hour1 = i1.hour ? parseInt(i1.hour.split(':')[0], 10) : NaN;
  const hour2 = i2.hour ? parseInt(i2.hour.split(':')[0], 10) : NaN;
  const dayDiff = dayOrder[day1] - dayOrder[day2];
  if (dayDiff !== 0) {
    return dayDiff;
  }
  return Number(hour1) - Number(hour2);
}
