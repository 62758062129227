import React from 'react';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table';
import { PickupStartOffsetConfigItem } from './models';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import { ORDER_TYPE_TO_LABEL } from '../constants';
import { IntegerInput } from '../../../../common-components/numeric-input';

interface TableDefinitionProps {
  readonly isEditing: boolean;
  readonly onUpdateSlammedTRPickupOffset: (accessPointId: string, orderType: string | undefined, value: number) => void;
  readonly onUpdateNonSlammedTRPickupOffset: (accessPointId: string, orderType: string | undefined, value: number) => void;
}

export function buildTableDefinition(props: TableDefinitionProps): ReadonlyArray<TableProps.ColumnDefinition<PickupStartOffsetConfigItem>> {
  const columns: TableProps.ColumnDefinition<PickupStartOffsetConfigItem>[] = [];
  columns.push({
    id: 'accessPointId',
    header: 'Access Point Id',
    cell: (item) => <Box>{item.accessPointId.substring(0, 8) + '...'}</Box>,
  });

  columns.push({
    id: 'accessPointName',
    header: 'Access Point Name',
    cell: (item) => <Box>{item.accessPointName ?? '-'}</Box>,
    sortingField: 'accessPointName',
    sortingComparator: (i1, i2) => {
      const ap1 = i1.accessPointName ?? '-';
      const ap2 = i2.accessPointName ?? '-';
      return ap1.localeCompare(ap2);
    },
  });

  columns.push({
    id: 'orderType',
    header: 'Order Type',
    cell: (item) => <Box>{item.orderType ? ORDER_TYPE_TO_LABEL[item.orderType] : '-'}</Box>,
    sortingField: 'orderType',
    sortingComparator: (i1, i2) => {
      const ap1 = i1.orderType ?? '-';
      const ap2 = i2.orderType ?? '-';
      return ap1.localeCompare(ap2);
    },
  });

  columns.push({
    id: 'slammedOffsetMinutes',
    header: 'Slammed TR Pickup Offset (Minutes)',
    cell: (item) => {
      if (props.isEditing) {
        return <IntegerInput startingText={item.slammedTRPickupStartOffset.toString()} onChange={(value) => props.onUpdateSlammedTRPickupOffset(item.accessPointId, item.orderType, value)} />;
      } else {
        return <Box>{item.slammedTRPickupStartOffset}</Box>;
      }
    },
  });

  columns.push({
    id: 'nonSlammedOffsetMinutes',
    header: 'Non-Slammed TR Pickup Offset (Minutes)',
    cell: (item) => {
      if (props.isEditing) {
        return <IntegerInput startingText={item.nonSlammedTRPickupStartOffSet.toString()} onChange={(value) => props.onUpdateNonSlammedTRPickupOffset(item.accessPointId, item.orderType, value)} />;
      } else {
        return <Box>{item.nonSlammedTRPickupStartOffSet}</Box>;
      }
    },
  });

  return columns;
}
