import React from 'react';

import Box from '@amzn/awsui-components-react-v3/polaris/box';
import HelpPanel from '@amzn/awsui-components-react-v3/polaris/help-panel';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import ColumnLayout from '@amzn/awsui-components-react-v3/polaris/column-layout';
import Link from '@amzn/awsui-components-react-v3/polaris/link';

export default class WelcomePanel extends React.Component<{}, {}> {
  render() {
    return (
      <HelpPanel header={<Header variant="h2">Introduction</Header>}>
        <ColumnLayout columns={1} borders="horizontal">
          <Box variant="p">
            Configurations manager is a self-service tool for accessing and updating UFRAA (GSF Routing and Flex Assignment) Configurations. It's developed by ufraa (ultra-fast routing and assignment)
            team.
          </Box>

          <SpaceBetween direction="vertical" size="xxs">
            <Box variant="h4" margin={{ bottom: 's' }}>
              Learn more &amp; Contacts
            </Box>
            <Link href="https://w.amazon.com/bin/view/GSFRouting/Development/ConfigurationsManager" external={true}>
              Configurations Manager Wiki
            </Link>
            <Link href="https://oncall.corp.amazon.com/#/view/flex-routing/schedule" external={true}>
              Current oncall
            </Link>
            <Link href="https://email-list.corp.amazon.com/email-list/expand-list/config-manager-interest" external={true}>
              Subscribe to config-manager-interest
            </Link>
          </SpaceBetween>
        </ColumnLayout>
      </HelpPanel>
    );
  }
}
