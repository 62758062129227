import React from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-xcode';
import { isNil } from '../utilities';

export declare namespace TextEditor {
  export interface Props {
    showGutter?: boolean;
    maxLine?: number;
    minLine?: number;
    data: string;
    onChange?: (value: string, event?: any) => void; // if undef, is read-only editor
  }
}

// eslint-disable-next-line no-redeclare
export class TextEditor extends React.Component<TextEditor.Props> {
  render() {
    const readOnly = isNil(this.props.onChange);
    const showGutter = typeof this.props.showGutter === 'boolean' ? this.props.showGutter : true;
    const maxLine = typeof this.props.maxLine === 'number' ? this.props.maxLine : 70;
    const minLine = typeof this.props.minLine === 'number' ? this.props.minLine : 10;

    return (
      <AceEditor
        setOptions={{
          useWorker: false,
        }}
        theme="xcode"
        showPrintMargin={false} // if true, there is a vertical line indicate #chars on a line
        readOnly={readOnly}
        focus={false}
        width={'100%'}
        onChange={this.props.onChange}
        name="UNIQUE_ID_OF_DIV"
        maxLines={maxLine}
        minLines={minLine}
        showGutter={showGutter}
        value={this.props.data}
        editorProps={{ $blockScrolling: true }}
      />
    );
  }
}

export default TextEditor;
