import { config } from '../utilities';

/**
 * Gets the marketplace and stage to generate a corresponding ufraa-viz url.
 * For example "cm-eu-beta.last-mile..." -> "viz-eu-beta.ufraa.last-mile..."
 * @param path non-domain part of the url
 * @returns complete url
 */
export function externalUfraaVizUrl(path: string): string {
  if (path.startsWith('/')) {
    path = path.slice(1);
  }

  const MARKETPLACE = config.marketplace.toLowerCase();
  let stageLabel: string = config.stage as string;

  if (stageLabel === 'prod') {
    stageLabel = '';
  }

  if (stageLabel.length > 0) {
    stageLabel = `-${stageLabel}`;
  }

  return `https://viz-${MARKETPLACE}${stageLabel}.ufraa.last-mile.amazon.dev/${path}`;
}
