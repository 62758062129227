//reference https://github.com/jamesmbourne/aws4-axios
// 1. integrate with Midway credential provider.
// 2. support browser environment.

import { AxiosTransformer, AxiosRequestConfig } from 'axios';
import { sign } from 'aws4';
import { MidwayIdentityCredentialProvider } from '@amzn/midway-identity-credential-provider';

export interface InterceptorOptions {
  readonly region: string;
}

function getTransformer(config: AxiosRequestConfig): AxiosTransformer {
  if (config.transformRequest) {
    if (typeof config.transformRequest === 'function') {
      return config.transformRequest;
    } else if (config.transformRequest.length !== 0) {
      return config.transformRequest[0];
    }
  }

  throw new Error('Could not get default transformRequest function from Axios defaults');
}

export function axiosSigV4Interceptor(props: InterceptorOptions, credential: MidwayIdentityCredentialProvider): (config: AxiosRequestConfig) => Promise<AxiosRequestConfig> {
  return async function (config: AxiosRequestConfig) {
    if (!config.url) {
      throw new Error('No URL present in request config, unable to sign request');
    }
    const url = config.baseURL ? config.baseURL + config.url : config.url;
    const { host, pathname, search } = new URL(url); // search is the query part.
    const { params, data, headers, method } = config;
    if (params) {
      console.warn('You are putting query parameter inside params argument.', params, ' You shoud add them to url. Otherwise, aws sign may not work properly.');
    }

    const {
      common,
      delete: _delete, // 'delete' is a reserved word
      get,
      head,
      post,
      put,
      patch,
      ...headersToSign
    } = headers;

    await credential.getPromise();
    const signingOptions = {
      method: method && method.toUpperCase(),
      host,
      path: pathname + search,
      region: props.region,
      service: 'execute-api',
      signQuery: false,
      body: getTransformer(config)(data, headers),
      headers: headersToSign,
    };

    const newHeaders = sign(signingOptions, credential).headers;

    // remove Forbidden header name, these headers should be set by browser rather than the "sign" function.
    // https://developer.mozilla.org/en-US/docs/Glossary/Forbidden_header_name
    if (newHeaders !== undefined) {
      delete newHeaders['Host'];
      delete newHeaders['Content-Length'];
    }

    config.headers = newHeaders;
    return config;
  };
}
