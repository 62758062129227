import React from 'react';
import ColumnLayout from '@amzn/awsui-components-react-v3/polaris/column-layout';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Checkbox from '@amzn/awsui-components-react-v3/polaris/checkbox';
import Container from '@amzn/awsui-components-react-v3/polaris/container';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import { TransporterType } from '../../../models/assignment-models';
import { SupportedTransporterTypeUpdate } from './models';
import { SUPPORTED_TRANSPORTER_TYPE_OPTIONS, SUPPORTED_TRANSPORTER_TYPE_TO_LABEL } from './constants';

interface SupportedTransporterTypesTableProps {
  readonly supportedTransporterTypes: ReadonlyArray<TransporterType>;
  readonly isEditing: boolean;
  readonly onUpdate: (supportedTransporterTypeUpdate: SupportedTransporterTypeUpdate) => void;
}

interface SupportedTransporterTypesTableState {}

export class SupportedTransporterTypesTable extends React.Component<SupportedTransporterTypesTableProps, SupportedTransporterTypesTableState> {
  render() {
    return (
      <Container disableContentPaddings header={<Header variant="h3">Supported Transporter Types</Header>}>
        {this.props.isEditing ? this.renderInputsContent() : this.renderContent()}
      </Container>
    );
  }

  private renderInputsContent() {
    return (
      <ColumnLayout columns={1} borders={'all'} disableGutters={true}>
        {SUPPORTED_TRANSPORTER_TYPE_OPTIONS.map((option, i) => {
          return (
            <Box key={i} padding={{ vertical: 'xs', horizontal: 'l' }}>
              <Checkbox
                checked={this.props.supportedTransporterTypes.includes(option.id)}
                onChange={(evt) => {
                  this.props.onUpdate({
                    transporterType: option.id,
                    select: evt.detail.checked,
                  });
                }}
              >
                {option.text}
              </Checkbox>
            </Box>
          );
        })}
      </ColumnLayout>
    );
  }

  private renderContent() {
    return (
      <ColumnLayout columns={1} borders={'all'} disableGutters={true}>
        {this.props.supportedTransporterTypes.map((transporterType, i) => {
          return (
            <Box key={i} padding={{ vertical: 'xs', horizontal: 'l' }}>
              {SUPPORTED_TRANSPORTER_TYPE_TO_LABEL[transporterType]}
            </Box>
          );
        })}
      </ColumnLayout>
    );
  }
}
