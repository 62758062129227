import React from 'react';
import { useHistory } from 'react-router-dom';
import SideNavigation, { SideNavigationProps } from '@amzn/awsui-components-react-v3/polaris/side-navigation';
import { externalUfraaVizUrl } from '../utilities/external-url-builder';

/* the left main side navgiation */

export function Navigation() {
  const history = useHistory();
  const navHeader = { text: 'UFRAA Configs', href: '/' };
  const navItems: SideNavigationProps.Item[] = [
    {
      text: 'Routing Configurations',
      type: 'section',
      defaultExpanded: true,
      items: [
        { type: 'link', text: 'Routing Service Areas', href: '/service-areas' },
        { type: 'link', text: 'Launch New Sites', href: '/launch-service-areas' },
        { type: 'link', text: 'Apply Config Profiles', href: '/config-profiles' },
        { type: 'link', text: 'Configurations Auditing', href: '/config-auditing' },
        { type: 'link', text: 'Site Program Type', href: '/site-program-type' },
        { type: 'link', text: 'Feature Flag Flipper', href: '/routing-feature-flag-flipper' },
      ],
    },
  ];

  navItems.push({
    text: 'Assignment Configurations',
    type: 'section',
    defaultExpanded: true,
    items: [
      { type: 'link', text: 'Assignment Service Areas', href: '/assignment-service-areas' },
      { type: 'link', text: 'Launch/Disable Sites', href: '/assignment-launch-service-areas' },
    ],
  });

  // navItems.push({
  //   text: 'Labor Planning Configurations',
  //   type: 'section',
  //   defaultExpanded: true,
  //   items: [{ type: 'link', text: 'Labor Planning Service Areas', href: '/labor-planning-service-areas' }],
  // });

  navItems.push({
    text: 'DRIA Configurations',
    type: 'section',
    defaultExpanded: true,
    items: [{ type: 'link', text: 'DRIA Regions and Service Areas', href: '/dria-scopes' }],
  });

  navItems.push({
    text: 'Utilities',
    type: 'section',
    defaultExpanded: true,
    items: [
      { type: 'link', text: 'Service Area Conversion', href: externalUfraaVizUrl('/service-area-conversion'), external: true },
      { type: 'link', text: 'Config Schedules', href: '/config-schedules' },
    ],
  });

  return (
    <SideNavigation
      items={navItems}
      header={navHeader}
      activeHref="#/"
      onFollow={(event) => {
        if (!event.detail.external) {
          event.preventDefault();
          history.push(event.detail.href);
        }
      }}
    />
  );
}
