import React from 'react';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import InputTable from '../../common-components/input-table';
import ConfigGrid from '../../common-components/config-grid';
import {
  BLOCK_EXTENSION_CONFIGURATIONS,
  CYCLE_RELATED_CONFIGURATIONS,
  EXECUTION_CONFIGURATIONS,
  FDP_CONFIGURATIONS,
  FUNGIBLE_CONFIGURATIONS,
  NURSERY_ASSIGNMENT_CONFIGURATIONS,
  ROUTE_CONFIGURATIONS,
  TRANSPORTER_CONFIGURATIONS,
} from './table-definitions';

import Header from '@amzn/awsui-components-react-v3/polaris/header';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import StatusIndicator from '@amzn/awsui-components-react-v3/polaris/status-indicator';
import { convertAssignmentConfigToItem } from './utilities';
import { updateAssignmentConfigurations } from './table-definitions/state-utilities';
import { ConfigTableDefinition } from '../../models';
import { AssignmentConfig, TransporterType } from '../../models/assignment-models';
import { SupportedTransporterTypesTable } from './supported-transporter-types';
import { OperatingModeSelector } from './operating-mode-selector';
import { GlobalContext } from '../../main-app/global-context';
import { InputUpdate } from '../../common-components/input-cell';
import { InputBox } from '../../common-components/input-box';
import { updateSupportedTransporterTypes } from './utilities';

export const NUMBER_ROWS = 15;

export interface AssignmentConfigFormEditorProps {
  readonly isSaving: boolean;
  readonly isEditing: boolean;
  readonly assignmentConfig?: AssignmentConfig;

  /**
   * Method is called when a user is typing to change configurations
   * @param serviceAreaConfig
   * @param algorithmSettings
   * @returns
   */
  readonly onUpdate: (assignmentConfig: AssignmentConfig) => void;

  /**
   * Method is called when the "Save" button is clicked.
   * @returns
   */
  readonly onSave: () => void;

  /**
   * Method is called when the "Edit" button is clicked
   * @returns
   */
  readonly onEdit: () => void;

  /**
   * Method is called when the "Discard" button is clicked.
   */
  readonly onDiscard: () => void;
}

export interface AssignmentConfigFormEditorState {
  readonly invalidKeyCounter: number;
  readonly invalidTransportType: boolean;
}

export class AssignmentConfigFormEditor extends React.Component<AssignmentConfigFormEditorProps, AssignmentConfigFormEditorState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;
  private invalidParameterKeys: Set<string>;

  constructor(props: AssignmentConfigFormEditorProps) {
    super(props);
    this.state = {
      invalidKeyCounter: 0,
      invalidTransportType: false,
    };
    this.invalidParameterKeys = new Set();
  }

  /**
   * DispatchPlannerType is used to control what is rendered/displayed in the frontend. This is because we do not
   * want to display a bunch of unnecessary tables/parameters to a user that have N/A Values. For example,
   * a SSD site does not need to see any exposed algorithmParametersMap parameters for DPT of FLEX_DISPATCH_PLANNER type as
   * none of those are necessary (as of this writing SSD uses the FUNGIBLE_ASSIGNMENT_ALGORITHM). If a use case existed
   * where a AlgorithmParametersMap contained two DispatchPlannerTypes then parameters + values for both would be displayed
   * for the respective service area.
   */
  render() {
    if (this.props.assignmentConfig === undefined) {
      return (
        <Box textAlign="center">
          <StatusIndicator type="loading">Loading</StatusIndicator>
        </Box>
      );
    } else {
      return (
        <SpaceBetween direction="vertical" size="s">
          <Header actions={this.renderHeaderActions()}></Header>
          {this.props.assignmentConfig?.dispatchPlannerType === 'FLEX_DISPATCH_PLANNER' ? this.renderFlexDispatchPlannerParameters() : null}
          {this.props.assignmentConfig?.dispatchPlannerType === 'FUNGIBLE_ASSIGNMENT_ALGORITHM' ? this.renderFungibleAssignmentAlgorithmParameters() : null}
        </SpaceBetween>
      );
    }
  }

  private renderHeaderActions() {
    if (this.props.isEditing) {
      return (
        <SpaceBetween direction="horizontal" size="xs">
          <Button disabled={this.disableBtn()} onClick={() => this.props.onDiscard()}>
            Discard
          </Button>
          <Button disabled={this.disableBtn() || this.state.invalidKeyCounter > 0 || this.state.invalidTransportType} variant="primary" onClick={() => this.props.onSave()}>
            Save
          </Button>
        </SpaceBetween>
      );
    } else {
      return (
        <Button disabled={this.disableBtn()} onClick={() => this.props.onEdit()}>
          Edit
        </Button>
      );
    }
  }

  private disableBtn() {
    return this.props.assignmentConfig === undefined || this.props.isSaving;
  }

  private renderFungibleAssignmentAlgorithmParameters() {
    return (
      <ConfigGrid>
        {this.generateInputTable(ROUTE_CONFIGURATIONS)}
        {this.generateInputTable(TRANSPORTER_CONFIGURATIONS)}
        {this.renderBlockExtensionOptedInProvidersList()}
        {this.generateInputTable(BLOCK_EXTENSION_CONFIGURATIONS)}
        {this.generateInputTable(NURSERY_ASSIGNMENT_CONFIGURATIONS)}
        {this.generateInputTable(FUNGIBLE_CONFIGURATIONS)}
        {this.generateInputTable(EXECUTION_CONFIGURATIONS)}
      </ConfigGrid>
    );
  }

  private renderFlexDispatchPlannerParameters() {
    return (
      <ConfigGrid>
        {this.generateInputTable(ROUTE_CONFIGURATIONS)}
        {this.context.operatingMode === 'developer' ? this.renderRouteDenyList() : null}
        {this.generateInputTable(TRANSPORTER_CONFIGURATIONS)}
        {this.context.operatingMode === 'developer' ? this.renderTransporterDenyList() : null}
        {this.context.operatingMode === 'developer' ? this.renderSupportedTransporterTypes() : null}
        {this.generateInputTable(CYCLE_RELATED_CONFIGURATIONS)}
        {this.generateInputTable(FDP_CONFIGURATIONS)}
        {this.generateInputTable(EXECUTION_CONFIGURATIONS)}
        {this.context.operatingMode === 'developer' ? this.renderAssignmentOperatingMode() : null}
      </ConfigGrid>
    );
  }

  private renderAssignmentOperatingMode() {
    return (
      <OperatingModeSelector
        operatingMode={this.props.assignmentConfig?.assignmentMode}
        isEditing={this.props.isEditing}
        onUpdate={(update) => {
          const assignmentConfig = this.props.assignmentConfig;
          if (assignmentConfig !== undefined) {
            this.props.onUpdate({
              ...assignmentConfig,
              assignmentMode: update,
            });
          }
        }}
      />
    );
  }

  private renderSupportedTransporterTypes() {
    return (
      <SupportedTransporterTypesTable
        supportedTransporterTypes={this.props.assignmentConfig?.supportedTransporterTypes ? this.props.assignmentConfig.supportedTransporterTypes.map((type) => type as TransporterType) : []}
        isEditing={this.props.isEditing}
        onUpdate={(update) => {
          const assignmentConfig = this.props.assignmentConfig;
          if (assignmentConfig !== undefined) {
            this.props.onUpdate(updateSupportedTransporterTypes(assignmentConfig, update));
          }
        }}
      />
    );
  }

  private renderBlockExtensionOptedInProvidersList() {
    return (
      <InputBox
        header="Block Extension Opted In Providers List"
        description="Enter a list of transporter Ids separated by newlines"
        textList={this.props.assignmentConfig?.blockExtensionOptedInProvidersList}
        isEditing={this.props.isEditing}
        numberRows={NUMBER_ROWS}
        notifyUpdate={(transporterList) => {
          const assignmentConfig = this.props.assignmentConfig;
          if (assignmentConfig !== undefined) {
            this.props.onUpdate({
              ...assignmentConfig,
              blockExtensionOptedInProvidersList: transporterList,
            });
          }
        }}
      />
    );
  }

  private renderTransporterDenyList() {
    return (
      <InputBox
        header="Transporter Deny List"
        description="Enter a list of transporter Ids separated by newlines"
        textList={this.props.assignmentConfig?.autoAssignRouteDenylist}
        isEditing={this.props.isEditing}
        numberRows={NUMBER_ROWS}
        notifyUpdate={(transporterList) => {
          const assignmentConfig = this.props.assignmentConfig;
          if (assignmentConfig !== undefined) {
            this.props.onUpdate({
              ...assignmentConfig,
              autoAssignDenylist: transporterList,
            });
          }
        }}
      />
    );
  }

  private renderRouteDenyList() {
    return (
      <InputBox
        header="Route Deny List"
        description="Enter a list of route Ids separated by newlines"
        textList={this.props.assignmentConfig?.autoAssignRouteDenylist}
        isEditing={this.props.isEditing}
        numberRows={NUMBER_ROWS}
        notifyUpdate={(routeList) => {
          const assignmentConfig = this.props.assignmentConfig;
          if (assignmentConfig !== undefined) {
            this.props.onUpdate({
              ...assignmentConfig,
              autoAssignRouteDenylist: routeList,
            });
          }
        }}
      />
    );
  }

  private generateInputTable(tableDefinition: ConfigTableDefinition) {
    if (tableDefinition.visibility === 'developer-only' && this.context.operatingMode !== 'developer') {
      return null;
    }

    return (
      <InputTable
        headerText={tableDefinition.headerText}
        showField={this.context.operatingMode === 'developer'}
        description={tableDefinition.description}
        valueColMinWidth={tableDefinition.valueColMinWidth}
        valueColHeaderText={tableDefinition.valueColHeaderText}
        nameColHeaderText={tableDefinition.nameColHeaderText}
        nameColMinWidth={tableDefinition.nameColMinWidth}
        sizeOnGrid={tableDefinition.sizeOnGrid ?? 'default'}
        items={tableDefinition.items
          .filter((itemConfig) => {
            if (itemConfig.visibility === 'developer-only') {
              return this.context.operatingMode === 'developer';
            } else {
              return true;
            }
          })
          .map((itemConfig) => convertAssignmentConfigToItem(itemConfig, this.props.assignmentConfig))}
        notifyUpdate={(input: InputUpdate) => {
          const assignmentConfig = this.props.assignmentConfig;
          if (assignmentConfig !== undefined) {
            this.props.onUpdate(updateAssignmentConfigurations(input, assignmentConfig));
          }
        }}
        notifyValidationResult={(result) => {
          if (result.isValid) {
            this.invalidParameterKeys.delete(`${result.scope}#${result.field}`);
          } else {
            this.invalidParameterKeys.add(`${result.scope}#${result.field}`);
          }
          this.setState({ invalidKeyCounter: this.invalidParameterKeys.size });
        }}
        isEditing={this.props.isEditing}
      />
    );
  }
}
