import React from 'react';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import TimeInput from '@amzn/awsui-components-react-v3/polaris/time-input';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table';
import { PlanningHorizonConfigOverride } from '../../../../models/routing-models';
import { getEffectiveTimeForItem, getOrderTypeForItem, inferPlanningHorizonType } from './utilities';
import { IntegerInput } from '../../../../common-components/numeric-input';
import { PlanningHorizonType } from './models';

interface TableDefinitionProps {
  readonly isEditing: boolean;
  readonly onUpdateStagedPlanningHorizon: (inputType: PlanningHorizonType, effectiveTime: string, orderType: string | null, value: number | string) => void;
  readonly onUpdateNonStagedPlanningHorizon: (inputType: PlanningHorizonType, effectiveTime: string, orderType: string | null, value: number | string) => void;
  readonly onUpdateLocking: (inputType: PlanningHorizonType, effectiveTime: string, orderType: string | null, value: number | string) => void;
}

export function buildTableDefinition(props: TableDefinitionProps): ReadonlyArray<TableProps.ColumnDefinition<PlanningHorizonConfigOverride>> {
  const columns: TableProps.ColumnDefinition<PlanningHorizonConfigOverride>[] = [];
  columns.push({
    id: 'mode',
    header: 'Mode',
    cell: (item) => {
      const type = inferPlanningHorizonType(item);
      switch (type) {
        case 'normal':
          return <Box>Normal</Box>;
        case 'timebased':
          return <Box>Timebased</Box>;
        default:
          return <Box>Invalid</Box>;
      }
    },
    sortingField: 'mode',
    sortingComparator: (i1, i2) => {
      const s1 = inferPlanningHorizonType(i1) ?? '';
      const s2 = inferPlanningHorizonType(i2) ?? '';
      return s1.localeCompare(s2);
    },
  });

  columns.push({
    id: 'orderType',
    header: 'Order Type',
    cell: (item) => <Box>{getOrderTypeForItem(item)}</Box>,
    sortingField: 'orderType',
    sortingComparator: (i1, i2) => {
      return getOrderTypeForItem(i1).localeCompare(getOrderTypeForItem(i2));
    },
  });

  columns.push({
    id: 'effectiveTime',
    header: 'Effective Time',
    cell: (item) => <Box>{getEffectiveTimeForItem(item)}</Box>,
    sortingField: 'effectiveTime',
    sortingComparator: (i1, i2) => {
      return getEffectiveTimeForItem(i1).localeCompare(getEffectiveTimeForItem(i2));
    },
  });

  columns.push({
    id: 'stagedPlanningHorizon',
    header: 'Staged Planning Horizon',
    cell: (item) => {
      const inputType = inferPlanningHorizonType(item);
      if (inputType === 'normal') {
        const data = item.horizonConfig?.stagedPlanningHorizonMinutes;
        if (props.isEditing) {
          return (
            <IntegerInput
              startingText={data?.toString() ?? ''}
              minimum={0}
              onChange={(value) => {
                props.onUpdateStagedPlanningHorizon(inputType, getEffectiveTimeForItem(item), item.orderType, value);
              }}
            />
          );
        } else {
          return <Box>{data ?? ''}</Box>;
        }
      } else if (inputType === 'timebased') {
        const data = item.timeBasedHorizonConfig?.stagedDeliveryWindowCutOffLocalTime;
        if (props.isEditing) {
          return (
            <TimeInput
              value={data ?? ''}
              format="hh:mm"
              placeholder="hh:mm"
              onChange={(evt) => {
                props.onUpdateStagedPlanningHorizon(inputType, getEffectiveTimeForItem(item), item.orderType, evt.detail.value);
              }}
            />
          );
        } else {
          return <Box>{data ?? ''}</Box>;
        }
      } else {
        return <Box>Invalid input</Box>;
      }
    },
    sortingField: 'stagedPlanningHorizon',
    sortingComparator: (i1, i2) => {
      // todo
      return -1;
    },
  });

  columns.push({
    id: 'nonStagedPlanningHorizon',
    header: 'Non-staged Planning Horizon',
    cell: (item) => {
      const inputType = inferPlanningHorizonType(item);
      if (inputType === 'normal') {
        const data = item.horizonConfig?.nonStagedPlanningHorizonMinutes;
        if (props.isEditing) {
          return (
            <IntegerInput
              startingText={data?.toString() ?? ''}
              minimum={-360}
              onChange={(value) => {
                props.onUpdateNonStagedPlanningHorizon(inputType, getEffectiveTimeForItem(item), item.orderType, value);
              }}
            />
          );
        } else {
          return <Box>{data ?? ''}</Box>;
        }
      } else if (inputType === 'timebased') {
        // timebased config doesn't support non-staged planning horizon.
        return <Box>-</Box>;
      } else {
        return <Box>Invalid input</Box>;
      }
    },
    sortingField: 'nonStagedPlanningHorizon',
    sortingComparator: (i1, i2) => {
      // todo
      return -1;
    },
  });

  columns.push({
    id: 'locking',
    header: 'Locking',
    cell: (item) => {
      const inputType = inferPlanningHorizonType(item);
      if (inputType === 'normal') {
        const data = item.horizonConfig?.lockOffsetMinutes;
        if (props.isEditing) {
          return (
            <IntegerInput
              startingText={data?.toString() ?? ''}
              minimum={0}
              onChange={(value) => {
                props.onUpdateLocking(inputType, getEffectiveTimeForItem(item), item.orderType, value);
              }}
            />
          );
        } else {
          return <Box>{data ?? ''}</Box>;
        }
      } else if (inputType === 'timebased') {
        const data = item.timeBasedHorizonConfig?.lockCutOffLocalTime;
        if (props.isEditing) {
          return (
            <TimeInput
              value={data ?? ''}
              format="hh:mm"
              placeholder="hh:mm"
              onChange={(evt) => {
                props.onUpdateLocking(inputType, getEffectiveTimeForItem(item), item.orderType, evt.detail.value);
              }}
            />
          );
        } else {
          return <Box>{data ?? ''}</Box>;
        }
      } else {
        return <Box>Invalid input</Box>;
      }
    },
    sortingField: 'locking',
    sortingComparator: (i1, i2) => {
      // todo
      return -1;
    },
  });
  return columns;
}
