/**
 * replicate ServiceAreaConfig
 * https://code.amazon.com/packages/LastMileExecutionSnapshotServiceModel/blobs/022d30d69547901d39b1094f03c260a1ff5fc65b/--/model/main.xml#L288
 */

export const orderTypes = ['OTHER', 'ONE_HOUR_RUSH', 'ONE_HOUR_FIXED', 'TWO_HOUR_FIXED', 'FRESH', 'FSM', 'HOTWHEELS', 'ROAR', 'ROAR_ONE_DAY_CORE'] as const;

export type OrderType = (typeof orderTypes)[number];

export type CapacityType = 'VOLUME' | 'LENGTH' | 'WEIGHT' | 'COUNT' | 'WIDTH' | 'HEIGHT' | 'TRAVEL_DISTANCE';

export interface CapacityConstraint {
  readonly value: number; // integer only.
  readonly unit: string;
  readonly type: CapacityType;
}

export interface CubicVolume {
  readonly value: number;
  readonly unit: 'CU_FT' | 'CU_IN' | 'CU_CM';
}

export interface Weight {
  readonly value: number;
  readonly unit: 'LB' | 'KG';
}

export interface PlaceholderTRDefaults {
  readonly cubicVolumeEstimateOverride?: CubicVolume;
  readonly weightEstimateOverride?: Weight;
}

export interface PickUpStartOffsetConfig {
  readonly slammedOffsetMinutes: number;
  readonly nonSlammedOffsetMinutes: number;
}

export interface PickUpStartOffsetOverride {
  readonly orderType: OrderType;
  readonly offsetConfig: PickUpStartOffsetConfig;
}

export interface AccessPointConfiguration {
  readonly addressId: string;
  readonly accessPointId: string;
  readonly pickUpStartOffsetConfigs: PickUpStartOffsetConfig[];
  readonly pickUpStartOffsetOverrides: PickUpStartOffsetOverride[];
}

export interface TimeBasedPlanningHorizonConfig {
  readonly stagedDeliveryWindowCutOffLocalTime: string;
  readonly lockCutOffLocalTime: string;
}

export interface PlanningHorizonConfig {
  readonly lockOffsetMinutes: number;
  readonly nonStagedPlanningHorizonMinutes: number;
  readonly stagedPlanningHorizonMinutes: number;
}

export interface PlanningHorizonConfigOverride {
  readonly effectiveForAllTypes: boolean;
  readonly orderType: OrderType | null;
  readonly effectiveAllDay: boolean;
  readonly startLocalTime: string | null;
  readonly endLocalTime: string | null;
  readonly timeBasedHorizonConfig: TimeBasedPlanningHorizonConfig | null;
  readonly horizonConfig: PlanningHorizonConfig | null;
}

export type PlanningHorizonConfigOverrideList = PlanningHorizonConfigOverride[];

export type DayOfWeek = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';

export interface DeliveryWindowOverride {
  readonly orderType: string | null;
  readonly daysOfWeek: string | null;
  readonly startLocalTime: string | null;
  readonly endLocalTime: string | null;
}

export interface ExecutorNameToRouteConstraint {
  [key: string]: RouteConstraint;
}

export interface RouteConstraint {
  readonly executorName: string;
  readonly capacityConstraints: CapacityConstraint[];
  readonly serviceFactor: number;
  readonly transitFactor: number;
  readonly overflowEligible: boolean;
}

/**
 * todo: replicated ServiceAreaConfig
 */
export interface ServiceAreaConfig {
  readonly isolatedOrderTypes?: OrderType[];
  readonly planningHorizonConfig?: Partial<PlanningHorizonConfig>;
  readonly horizonConfigOverrides?: PlanningHorizonConfigOverrideList;
  readonly placeholderTRDefaults?: PlaceholderTRDefaults;
  readonly accessPointConfigs?: AccessPointConfiguration[];
  readonly capacityConstraints?: CapacityConstraint[] | null;
  readonly deliveryWindowOverrides?: DeliveryWindowOverride[];
  readonly routeConstraintsConfig?: ExecutorNameToRouteConstraint;
  readonly featureFlags?: { [key: string]: boolean };
  [key: string]: any;
}
