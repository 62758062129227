/**
 * path /config-auditing
 * This component shows a wholistic view of the latest configuration updates on all sites.
 */

import React from 'react';
import Table from '@amzn/awsui-components-react-v3/polaris/table';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Alert from '@amzn/awsui-components-react-v3/polaris/alert';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import Pagination from '@amzn/awsui-components-react-v3/polaris/pagination';
import ProgressBar from '@amzn/awsui-components-react-v3/polaris/progress-bar';
import TextFilter from '@amzn/awsui-components-react-v3/polaris/text-filter';
import lodash from 'lodash';
import { EnrichedCommitRecord } from './models';
import { GlobalContext } from '../../main-app/global-context';
import { readable } from '../../utilities';
import { buildTableDefinition } from './build-table-definition';
import { filterRecords } from './utilities';
import { commonClient, routingClient } from '../../http-clients';
import { ServiceAreaInfo } from '../../models';

const ITEMS_PER_PAGE = 30;

export interface RoutingConfigAuditingProps {}

export interface RoutingConfigAuditingState {
  readonly commitRecords?: ReadonlyArray<EnrichedCommitRecord>;
  readonly filteredCommitRecords?: ReadonlyArray<EnrichedCommitRecord>;
  readonly filteringText: string;
  readonly currentPage: number;
  readonly loadingProgress?: number;
}

export class RoutingConfigAuditing extends React.Component<RoutingConfigAuditingProps, RoutingConfigAuditingState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;
  private serviceAreas?: ReadonlyArray<ServiceAreaInfo>;

  constructor(props: RoutingConfigAuditingProps) {
    super(props);
    this.state = {
      filteringText: '',
      currentPage: 1,
      loadingProgress: undefined,
    };
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.setTools('info-panel');
    this.context.updateBreadcrumbItems([{ href: '/config-auditing', text: 'Configurations Auditing' }]);
    this.serviceAreas = await this.loadServiceAreas();
    await this.loadLatestCommitRecords(this.serviceAreas);
  }

  filteringCountTextFunction(count: number) {
    return `${count} ${count === 1 ? 'match' : 'matches'}`;
  }

  private async loadServiceAreas(): Promise<ReadonlyArray<ServiceAreaInfo>> {
    try {
      const routingServiceAreasResp = await routingClient.listRoutingServiceAreas();
      const routingSaIds = new Set(routingServiceAreasResp.serviceAreaIds);
      const serviceAreaListResp = await commonClient.listServiceAreas();
      return serviceAreaListResp.serviceAreas.filter((sa) => routingSaIds.has(sa.serviceAreaId)).sort((s1, s2) => s1.stationCode.localeCompare(s2.stationCode));
    } catch (err) {
      this.context.addNotification({
        header: 'Error',
        dismissible: true,
        type: 'error',
        content: `Failed to load service areas due to ${readable(err)}`,
      });
      return [];
    }
  }

  private async loadLatestCommitRecords(serviceAreas: ReadonlyArray<ServiceAreaInfo>) {
    const partitionedServiceAreas = lodash.chunk(serviceAreas, ITEMS_PER_PAGE);

    this.setState({ loadingProgress: 0 });

    for (let i = 0; i < partitionedServiceAreas.length; i++) {
      try {
        const serviceAreaIdToServiceArea: Map<string, ServiceAreaInfo> = new Map();
        partitionedServiceAreas[i].forEach((sa) => serviceAreaIdToServiceArea.set(sa.serviceAreaId, sa));
        const resp = await routingClient.getLatestRoutingCommitRecords({ serviceAreaIds: partitionedServiceAreas[i].map((sa) => sa.serviceAreaId) });
        const newEnrichedRecords: EnrichedCommitRecord[] = [];
        Object.entries(resp.commitRecords).forEach((entry) => {
          const serviceArea = serviceAreaIdToServiceArea.get(entry[0]);
          if (serviceArea !== undefined) {
            newEnrichedRecords.push({
              ...entry[1],
              serviceAreaName: serviceArea.serviceAreaName,
              stationCode: serviceArea.stationCode,
            });
          }
        });

        const commitRecords = this.state.commitRecords ? Array.from(this.state.commitRecords).concat(newEnrichedRecords) : newEnrichedRecords;
        this.setState({
          commitRecords: commitRecords,
          filteredCommitRecords: filterRecords(commitRecords, this.state.filteringText),
        });
      } catch (err) {
        this.context.addNotification({
          header: 'Error',
          dismissible: true,
          type: 'error',
          content: `Failed to load service areas due to ${readable(err)}`,
        });

        this.setState({ commitRecords: this.state.commitRecords ?? [] });
      }

      this.setState({ loadingProgress: Math.ceil(((i + 1) / partitionedServiceAreas.length) * 100) });
    }
    this.setState({ loadingProgress: 100 });
  }

  render() {
    const columns = buildTableDefinition({ timezonePreference: this.context.timezonePreference });
    const commitRecordsCount = this.state.filteredCommitRecords?.length ?? 0;
    const pageCount = Math.max(1, Math.ceil(commitRecordsCount / ITEMS_PER_PAGE));
    const displayItems = (this.state.filteredCommitRecords ?? []).slice((this.state.currentPage - 1) * ITEMS_PER_PAGE, this.state.currentPage * ITEMS_PER_PAGE);

    return (
      <SpaceBetween direction="vertical" size="m">
        {this.renderLoadingProgress()}
        <Table<EnrichedCommitRecord>
          loadingText="Loading ..."
          stickyHeader={true}
          columnDefinitions={columns}
          items={displayItems}
          header={this.renderHeader()}
          pagination={
            <Pagination
              currentPageIndex={this.state.currentPage}
              pagesCount={pageCount}
              openEnd={typeof this.state.loadingProgress === 'number' && this.state.loadingProgress < 100}
              onChange={(evt) => {
                this.setState({ currentPage: evt.detail.currentPageIndex });
              }}
              ariaLabels={{
                pageLabel: (idx) => `Page ${idx} of ${pageCount}.`,
              }}
            />
          }
          filter={
            <TextFilter
              filteringPlaceholder="Filter by service area, user alias, description, etc."
              filteringText={this.state.filteringText}
              filteringAriaLabel="Filter instances"
              onChange={({ detail }) => {
                this.setState({
                  filteringText: detail.filteringText,
                  currentPage: 1,
                });
              }}
              onDelayedChange={(evt) => {
                this.setState({
                  filteredCommitRecords: this.state.commitRecords ? filterRecords(this.state.commitRecords, evt.detail.filteringText) : undefined,
                });
              }}
            />
          }
          loading={this.state.commitRecords === undefined}
        />
      </SpaceBetween>
    );
  }

  private renderLoadingProgress() {
    if (typeof this.state.loadingProgress === 'number') {
      if (this.state.loadingProgress === 100) {
        return (
          <Alert type="success" header="Complete" dismissible={true} onDismiss={() => this.setState({ loadingProgress: undefined })}>
            <Box>The latest configuration changes have been successfully loaded on all routing service areas.</Box>
            <ProgressBar value={this.state.loadingProgress} />
          </Alert>
        );
      } else {
        return (
          <Alert type="info" header="Loading Progress" dismissible={true} onDismiss={() => this.setState({ loadingProgress: undefined })}>
            <ProgressBar value={this.state.loadingProgress} />
          </Alert>
        );
      }
    }
  }

  private renderHeader() {
    return (
      <Header variant="h2" description="The latest configuration change on each routing service area.">
        Configurations Auditing
      </Header>
    );
  }
}
