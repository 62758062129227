import React from 'react';
import AppLayout from '@amzn/awsui-components-react-v3/polaris/app-layout';
import BreadcrumbGroup from '@amzn/awsui-components-react-v3/polaris/breadcrumb-group';
import Flashbar, { FlashbarProps } from '@amzn/awsui-components-react-v3/polaris/flashbar';
import Alert from '@amzn/awsui-components-react-v3/polaris/alert';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import AnnotationContext from '@amzn/awsui-components-react-v3/polaris/annotation-context';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import { I18nStrings, findTutorial } from '../tutorials';
import { GlobalContext } from './global-context';
import { Navigation } from './service-navigation';
import { Routes } from './routes';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import InfoPanel from '../tool-components/info-panel/info-panel';
import WelcomePanel from '../tool-components/welcome-panel/welcome-panel';
import CodeFreezeWarning from '../common-components/code-freeze-warning';
import { RoutingSiteProgramHelpPanel } from '../components/routing-site-program-page';

export class App extends React.Component<{}, {}> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  async componentDidMount() {}

  render() {
    if (this.context.tutorialOpen && this.context.tutorial !== undefined) {
      const tutorial = findTutorial(this.context.tutorial);
      if (tutorial !== undefined) {
        return (
          <AnnotationContext
            currentTutorial={tutorial}
            i18nStrings={I18nStrings}
            onFinish={() => {
              this.context.closeTutorial();
            }}
            onStartTutorial={() => {}}
            onExitTutorial={() => {}}
          >
            {this.renderAppLayout()}
          </AnnotationContext>
        );
      }
    }

    return this.renderAppLayout();
  }

  private renderBreadcrumbs() {
    if (Array.isArray(this.context.breadcrumbItems)) {
      return (
        <BreadcrumbGroup
          items={this.context.breadcrumbItems}
          onFollow={(evt) => {
            // prevent browser from sending request to backend, instead, handle the request in browser (a.k.a in-browser navigation)
            evt.preventDefault();
            this.context.onGoto(evt.detail.href);
          }}
        />
      );
    }
  }

  private renderNotifications() {
    const items: FlashbarProps.MessageDefinition[] = [];
    for (let item of this.context.notifications) {
      items.push({
        ...item,
        dismissible: typeof item.dismissible === 'boolean' ? item.dismissible : true,
        onDismiss: () => {
          if (typeof item.notificationId === 'string') {
            this.context.removeNotification(item.notificationId);
          }
        },
      });
    }
    return <Flashbar items={items} />;
  }

  private renderTools() {
    switch (this.context.tools) {
      case 'welcome-panel':
        return <WelcomePanel />;
      case 'info-panel':
        return <InfoPanel />;
      case 'routing-site-program-type':
        return <RoutingSiteProgramHelpPanel />;
      default:
        return <Box></Box>;
    }
  }

  private renderAppLayout() {
    return (
      <AppLayout
        contentType={this.context.contentType}
        navigation={<Navigation />}
        disableContentPaddings={this.context.disableContentPaddings}
        navigationOpen={this.context.navigationOpen}
        breadcrumbs={this.renderBreadcrumbs()}
        notifications={this.renderNotifications()}
        content={this.renderContent()}
        toolsOpen={this.context.toolsOpen}
        toolsHide={this.context.tools === undefined}
        tools={this.renderTools()}
        onToolsChange={({ detail }) => {
          if (detail.open) {
            this.context.openTools();
          } else {
            this.context.closeTools();
          }
        }}
        onNavigationChange={(evt) => {
          this.context.updateNavigationOpen(evt.detail.open);
        }}
      />
    );
  }

  private renderContent() {
    return (
      <SpaceBetween direction="vertical" size="l">
        {this.renderCodeFreezeWarning()}
        {this.renderTutorialPrompt()}
        <Routes />
      </SpaceBetween>
    );
  }

  private renderCodeFreezeWarning() {
    if (!this.context.hideCodeFreezeWarning) {
      return (
        <Box padding={{ top: 'm' }}>
          <CodeFreezeWarning codeFreezes={this.context.activeCodeFreezes} />
        </Box>
      );
    }
  }

  private renderTutorialPrompt() {
    if (this.context.tutorial !== undefined && !this.context.tutorialOpen && !this.context.tutorialComplete) {
      <Alert
        type="info"
        action={
          <Button
            onClick={() => {
              this.context.openTutorial();
            }}
          >
            Start tutorial
          </Button>
        }
      >
        A tutorial is available for the page.
      </Alert>;
    }
  }
}
