import React from 'react';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Spinner from '@amzn/awsui-components-react-v3/polaris/spinner';
import { Clock } from '../clock';
import { CopyText } from '../copy-text';

function Text(props: { readonly text?: string; readonly copyable?: boolean }) {
  if (typeof props.text === 'string') {
    return props.copyable ? <CopyText copyText={props.text} /> : <Box>{props.text}</Box>;
  } else {
    return <Spinner />;
  }
}

export function ServiceAreaName(props: { readonly serviceAreaName?: string }) {
  return (
    <Box>
      <Box margin={{ bottom: 'xxxs' }} color="text-label" data-testid="label">
        Service Area Name
      </Box>
      <Box margin={{ bottom: 'xxxs' }}>
        <Text text={props.serviceAreaName} />
      </Box>
    </Box>
  );
}

export function ServiceAreaId(props: { readonly serviceAreaId?: string }) {
  return (
    <Box>
      <Box margin={{ bottom: 'xxxs' }} color="text-label" data-testid="label">
        Service Area Id
      </Box>
      <Box margin={{ bottom: 'xxxs' }}>
        <Text text={props.serviceAreaId} copyable={true} />
      </Box>
    </Box>
  );
}

export function StationTimezone(props: { readonly timezone?: string }) {
  return (
    <Box>
      <Box margin={{ bottom: 'xxxs' }} color="text-label" data-testid="label">
        Station Timezone
      </Box>
      <Box margin={{ bottom: 'xxxs' }}>
        <Text text={props.timezone} />
      </Box>
    </Box>
  );
}

export function StationTime(props: { readonly timezone?: string }) {
  return (
    <Box>
      <Box margin={{ bottom: 'xxxs' }} color="text-label" data-testid="label">
        Station Time
      </Box>
      <Box margin={{ bottom: 'xxxs' }}>{typeof props.timezone === 'string' ? <Clock timezone={props.timezone} /> : <Spinner />}</Box>
    </Box>
  );
}
