import { CapacityType } from '../../../../models/routing-models';
import { CapacityOption } from './models';

export const CAPACITY_TYPE_TO_CAPACITY_OPTION: Partial<Record<CapacityType, CapacityOption>> = {
  VOLUME: {
    value: 'VOLUME',
    label: 'Volume',
    defaultUnit: 'CF',
    units: [
      {
        value: 'CF',
        alias: ['CUFT', 'CU_FT'],
        label: 'Cubic feet',
      },
      {
        value: 'L',
        label: 'Liters',
        deprecated: true,
      },
      {
        value: 'CU_CM',
        alias: ['CUCM', 'CU_CM'],
        label: 'Cubic cm',
      },
    ],
  },
  WEIGHT: {
    value: 'WEIGHT',
    label: 'Weight',
    defaultUnit: 'LB',
    units: [
      {
        value: 'LB',
        label: 'Lb',
      },
      {
        value: 'KG',
        label: 'Kg',
      },
    ],
  },
  LENGTH: {
    value: 'LENGTH',
    label: 'Length',
    defaultUnit: 'CM',
    units: [
      {
        value: 'CM',
        label: 'Centimeters',
      },
      {
        value: 'IN',
        label: 'Inches',
      },
    ],
  },
  COUNT: {
    value: 'COUNT',
    label: 'Package Count',
    defaultUnit: 'COUNT',
    units: [
      {
        value: 'COUNT',
        alias: ['COUNT'],
        label: 'TRs',
      },
    ],
  },
};
