import React from 'react';
import Modal from '@amzn/awsui-components-react-v3/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import FormField from '@amzn/awsui-components-react-v3/polaris/form-field';
import Input from '@amzn/awsui-components-react-v3/polaris/input';
import { configScheduleClient } from '../../http-clients';
import { DeleteConfigSchedulesResponse } from '../../http-clients/config-schedule-client';
import Alert from '@amzn/awsui-components-react-v3/polaris/alert';
import { ConfigScheduleInfo } from '../../http-clients/config-schedule-client';

export interface DeleteConfirmationModalProps {
  readonly onDismiss: () => void;
  readonly selectedItems: ReadonlyArray<ConfigScheduleInfo>;
  readonly onSubmitSuccess: (scopeId: string) => void;
}

export interface DeleteConfirmationModalState {
  readonly value: string;
  readonly errorMessage?: string;
  readonly loading: boolean;
}

export class DeleteConfirmationModal extends React.Component<DeleteConfirmationModalProps, DeleteConfirmationModalState> {
  constructor(props: DeleteConfirmationModalProps) {
    super(props);
    this.state = {
      value: '',
      errorMessage: undefined,
      loading: false,
    };
  }

  handleDeleteSchedules = async () => {
    try {
      this.setState({ loading: true });
      const res = await this.deleteSelectedSchedules();
      if (res) {
        this.handleDeleteSuccess(res);
      }
    } catch (error) {
      this.setState({ errorMessage: `Error deleting schedules for scope due to ${error}` });
    } finally {
      this.setState({ loading: false });
    }
  };

  deleteSelectedSchedules = async () => {
    const { selectedItems } = this.props;
    const scopeId = selectedItems[0].scopeId;
    const schedules = selectedItems.map((obj) => obj.schedule);

    return configScheduleClient.deleteConfigSchedules({
      scopeId,
      schedule: schedules as string[],
    });
  };

  handleDeleteSuccess = (res: DeleteConfigSchedulesResponse) => {
    const { schedule } = res;
    if (schedule) {
      this.setState({ errorMessage: undefined });
      this.props.onSubmitSuccess(Object.keys(schedule)[0]);
      this.props.onDismiss();
    } else {
      this.setState({ errorMessage: `Error deleting schedules for station ${this.props.selectedItems[0].stationCode}` });
    }
  };

  render() {
    const canDeleteSchedules = this.state.value === 'delete' && this.props.selectedItems.length > 0;
    return (
      <Modal
        visible={true}
        onDismiss={() => this.props.onDismiss()}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button disabled={this.state.loading} onClick={() => this.props.onDismiss()}>
                Cancel
              </Button>
              <Button onClick={this.handleDeleteSchedules} disabled={!canDeleteSchedules} loading={this.state.loading}>
                Delete
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Delete Config"
      >
        {this.state.errorMessage && (
          <Alert
            header="Error"
            type="error"
            dismissible={true}
            dismissAriaLabel="Close Alert"
            onDismiss={() => {
              this.setState({ errorMessage: undefined });
            }}
          >
            {this.state.errorMessage}
          </Alert>
        )}

        <FormField label="Type delete to delete these configs" description="">
          <Input onChange={(evt: { detail: { value: any } }) => this.setState({ value: evt.detail.value })} value={this.state.value} placeholder="delete" />
        </FormField>
      </Modal>
    );
  }
}
