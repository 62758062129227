import { ConfigTableDefinition, ItemConfig } from '../../../models';
import { orderTypes } from '../../../models/routing-models';

const anomalyConfigItems: ItemConfig[] = [];
anomalyConfigItems.push({
  field: 'anomalyConfig.newLaputDetectionLogicEnabled',
  representationType: 'boolean',
  scope: 'serviceAreaConfig',
});

anomalyConfigItems.push({
  field: 'anomalyConfig.customerPromiseLaputBufferSec',
  representationType: 'number',
  scope: 'serviceAreaConfig',
});

anomalyConfigItems.push({
  field: 'anomalyConfig.blockLengthLaputBufferSec',
  representationType: 'number',
  scope: 'serviceAreaConfig',
});

anomalyConfigItems.push({
  field: 'anomalyConfig.laputAssignmentUpdateBufferSec',
  representationType: 'number',
  scope: 'serviceAreaConfig',
});

anomalyConfigItems.push({
  field: 'anomalyConfig.ioAdditionalLaputBufferSec',
  representationType: 'number',
  scope: 'serviceAreaConfig',
});

anomalyConfigItems.push({
  field: 'anomalyConfig.nonAckBufferSec',
  representationType: 'number',
  scope: 'serviceAreaConfig',
});

anomalyConfigItems.push({
  field: 'anomalyConfig.automatedBlockOfferRevokeEnabled',
  representationType: 'boolean',
  scope: 'serviceAreaConfig',
});

orderTypes.forEach((value) => {
  anomalyConfigItems.push({
    name: `Pickup risk of ${value}`,
    representationType: 'number',
    scope: 'serviceAreaConfig',
    field: `anomalyConfig.pickupRiskConfig.${value}`,
  });
});

export const anomalyConfig: ConfigTableDefinition = {
  headerText: 'Anomaly Config',
  valueColMinWidth: '150px',
  items: anomalyConfigItems,
};
