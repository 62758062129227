import { validatorFactory } from '../../common-components/input-cell/input-validators';
import { ItemConfig } from '../../models';
import { InputTableItem } from '../../common-components/input-table';
import { ValueType } from '../../common-components/input-cell';
import { SupportedTransporterTypeUpdate } from './supported-transporter-types';
import { AssignmentConfig } from '../../models/assignment-models';

/**
 *
 * @param itemConfig
 * @param assignmentConfig
 * @returns note undefined and null are treated different.
 *  undefined indicates the parameter is still loading
 *  null indicates the parameter is null (never set)
 */
function findAssignmentValue(itemConfig: ItemConfig, assignmentConfig?: AssignmentConfig): ValueType | undefined {
  if (itemConfig.scope === 'assignmentConfig' && assignmentConfig !== undefined) {
    let value: any = assignmentConfig;
    const fields = itemConfig.field.split('.');
    for (let i = 0; i < fields.length; i++) {
      if (value === null || value === undefined) {
        return null;
      } else {
        value = value[fields[i]];
      }
    }
    return value === undefined ? null : value;
  }
  return undefined;
}

export function convertAssignmentConfigToItem(itemConfig: ItemConfig, assignmentConfig?: AssignmentConfig): InputTableItem {
  let inputValidator = undefined;
  if (itemConfig.validators && itemConfig.validators.length > 0) {
    if (itemConfig.validators.length > 1) {
      console.warn(`Don't support multiple validators yet. ${itemConfig.validators[0].name} will be used.`);
    }
    if (validatorFactory[itemConfig.validators[0].name]) {
      inputValidator = validatorFactory[itemConfig.validators[0].name];
    } else {
      console.warn(`Unknown validator ${itemConfig.validators[0].name}`);
    }
  }

  let itemName: string;
  if (itemConfig.name === undefined) {
    let fields = itemConfig.field.split('.');
    itemName = fields[fields.length - 1];
  } else {
    itemName = itemConfig.name;
  }

  return {
    field: itemConfig.field,
    name: itemName,
    description: itemConfig.description,
    value: findAssignmentValue(itemConfig, assignmentConfig),
    scope: itemConfig.scope,
    representationType: itemConfig.representationType,
    dataType: itemConfig.dataType,
    inputValidator: inputValidator,
  };
}

export function updateSupportedTransporterTypes(assignmentConfig: AssignmentConfig, update: SupportedTransporterTypeUpdate): AssignmentConfig {
  let existingSupportedTransporterTypes = assignmentConfig.supportedTransporterTypes ? Array.from(assignmentConfig.supportedTransporterTypes) : [];
  if (update.select && !existingSupportedTransporterTypes.includes(update.transporterType)) {
    existingSupportedTransporterTypes.push(update.transporterType);
  } else if (!update.select && existingSupportedTransporterTypes.includes(update.transporterType)) {
    existingSupportedTransporterTypes = existingSupportedTransporterTypes.filter((type) => type !== update.transporterType);
  }

  return {
    ...assignmentConfig,
    supportedTransporterTypes: existingSupportedTransporterTypes,
  };
}
