import React from 'react';
import Header from '@amzn/awsui-components-react-v3/polaris/header';

import { ExecutorNameToRouteConstraint } from '../../../../models/routing-models';
import { ToggleStatus, ToggleWithLabel } from '../../../../common-components/toggle-with-label';
import { GridSizeDefaultProp } from '../../../../common-components/config-grid';

import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Table from '@amzn/awsui-components-react-v3/polaris/table';

import { MMOTConstraintUpdate, MMOTTableRow } from './models';
import { EditCapacityModal } from './edit-capacity-modal';
import { copyToClipboard } from '../../../../utilities';

export const DEFAULT_EXECUTOR_NAME = 'DEFAULT';

interface Props {
  readonly mmotConstraints: ExecutorNameToRouteConstraint;
  readonly mmotFlag: boolean;
  readonly isEditing: boolean;
  readonly onUpdate: (MMOTConstraintUpdate: MMOTConstraintUpdate) => void;
  readonly onUpdateFlag: (mmotFlag: boolean) => void;
}

interface State {
  readonly rowForModal: MMOTTableRow | undefined;
}

export class MMOTConstraintsTable extends React.Component<Props, State> {
  static defaultProps: GridSizeDefaultProp = { sizeOnGrid: 'wide' };

  constructor(props: Props) {
    super(props);
    this.state = {
      rowForModal: undefined,
    };
  }

  translatePropsToTableRows(): MMOTTableRow[] {
    const keys = Object.keys(this.props.mmotConstraints);
    keys.sort();

    if (keys.length === 0) {
      return [];
    }

    let defaultExecutor: string | undefined = undefined;
    for (const key of keys) {
      if (key === DEFAULT_EXECUTOR_NAME) {
        defaultExecutor = this.props.mmotConstraints[key].executorName;
      }
    }

    if (defaultExecutor === undefined) {
      // todo: show error better
      return [];
    }

    const tableRows = new Array<MMOTTableRow>();
    for (const key of keys) {
      if (key == DEFAULT_EXECUTOR_NAME) {
        continue;
      }
      const constraint = this.props.mmotConstraints[key];
      tableRows.push({
        default: constraint.executorName === defaultExecutor,
        overflow: constraint.overflowEligible,
        executorName: constraint.executorName,
        serviceFactor: constraint.serviceFactor,
        transitFactor: constraint.transitFactor,
        capacityConstraints: constraint.capacityConstraints,
      });
    }
    return tableRows;
  }

  keepChangeFromCapacityModal(rowToUpdate: MMOTTableRow) {
    if (this.state.rowForModal === undefined) {
      return;
    }

    if (this.state.rowForModal?.executorName === '') {
      this.props.onUpdate({ updateType: 'UPDATE', tableRow: rowToUpdate });
    } else {
      this.props.onUpdate({ updateType: 'REMOVE', tableRow: this.state.rowForModal });
      this.props.onUpdate({ updateType: 'UPDATE', tableRow: rowToUpdate });
    }
    this.setState({ rowForModal: undefined });
  }

  renderCapacityModal() {
    if (this.state.rowForModal !== undefined) {
      return <EditCapacityModal onCommit={(row) => this.keepChangeFromCapacityModal(row)} onClose={() => this.setState({ rowForModal: undefined })} tableRow={this.state.rowForModal} />;
    }
  }

  renderHeader() {
    return (
      <Header
        variant="h3"
        actions={
          <SpaceBetween direction="horizontal" size="xl">
            <ToggleWithLabel checked={this.props.mmotFlag} onChange={this.props.onUpdateFlag} whatDisabled={this.props.isEditing ? 'NOTHING' : 'SWITCH_ONLY'} label="MMOT Enabled" />
            {this.props.isEditing ? (
              <Button
                variant="primary"
                onClick={() => {
                  this.setState({
                    rowForModal: {
                      default: false,
                      overflow: false,
                      executorName: '',
                      serviceFactor: 1.0,
                      transitFactor: 1.0,
                      capacityConstraints: [],
                    },
                  });
                }}
              >
                Add Row
              </Button>
            ) : undefined}
          </SpaceBetween>
        }
      >
        MMOT Constraints
      </Header>
    );
  }

  getToggleDisableStatus(item: MMOTTableRow): ToggleStatus {
    if (!this.props.isEditing) {
      return 'SWITCH_ONLY';
    } else if (item.default) {
      return 'SWITCH_AND_LABEL';
    } else {
      return 'NOTHING';
    }
  }

  buildColumnDefinitions() {
    const columnDefinitions = [
      {
        id: 'status',
        header: 'Status',
        cell: (item: MMOTTableRow) => {
          const viewMode = !this.props.isEditing;
          return (
            <SpaceBetween direction="vertical" size="s">
              <ToggleWithLabel
                checked={item.default}
                whatDisabled={this.getToggleDisableStatus(item)}
                onChange={(checked) => {
                  item.default = checked;
                  item.overflow = checked;
                  this.props.onUpdate({ updateType: 'UPDATE', tableRow: item });
                }}
                label="default"
              />
              <ToggleWithLabel
                checked={item.overflow}
                whatDisabled={this.getToggleDisableStatus(item)}
                onChange={(checked) => {
                  item.overflow = checked;
                  this.props.onUpdate({ updateType: 'UPDATE', tableRow: item });
                }}
                label="overflow"
              />
            </SpaceBetween>
          );
        },
      },
      {
        id: 'executor',
        header: 'Executor Name (click-copy)',
        cell: (item: MMOTTableRow) => {
          const lastPeriod = item.executorName.lastIndexOf('.') + 1;
          return (
            <SpaceBetween direction="vertical" size="xxs">
              <Button
                variant={'link'}
                onClick={() => {
                  copyToClipboard(item.executorName);
                }}
              >
                <Box>{item.executorName.substring(0, lastPeriod)}</Box>
                <Box>{item.executorName.substring(lastPeriod)}</Box>
              </Button>
            </SpaceBetween>
          );
        },
      },
      {
        id: 'servicefactor',
        header: 'Service Factor',
        cell: (item: MMOTTableRow) => {
          return <Box>{item.serviceFactor.toFixed(3)}</Box>;
        },
      },
      {
        id: 'transitFactor',
        header: 'Transit Factor',
        cell: (item: MMOTTableRow) => {
          return <Box>{item.transitFactor.toFixed(3)}</Box>;
        },
      },
      {
        id: 'constraints',
        header: 'Constraints',
        cell: (item: MMOTTableRow) => {
          return (
            <SpaceBetween direction="vertical" size="xxxs">
              {item.capacityConstraints.map((constraint) => {
                const text = `${constraint.value} ${constraint.unit}`;
                return <Box key={constraint.type}>{text}</Box>;
              })}
            </SpaceBetween>
          );
        },
      },
    ];

    if (this.props.isEditing) {
      columnDefinitions.push({
        id: 'controls',
        header: 'Controls',
        cell: (item: MMOTTableRow) => (
          <SpaceBetween direction="vertical" size="s">
            <Button variant="inline-link" onClick={() => this.setState({ rowForModal: item })}>
              Edit Values
            </Button>
            <Button variant="inline-link" disabled={item.default && Object.keys(this.props.mmotConstraints).length > 2} onClick={() => this.props.onUpdate({ updateType: 'REMOVE', tableRow: item })}>
              Remove Row
            </Button>
          </SpaceBetween>
        ),
      });
    }

    return columnDefinitions;
  }

  render() {
    return (
      <React.Fragment>
        {this.renderCapacityModal()}
        <Table header={this.renderHeader()} columnDefinitions={this.buildColumnDefinitions()} items={this.translatePropsToTableRows()} />
      </React.Fragment>
    );
  }
}
