import React from 'react';
import Toggle from '@amzn/awsui-components-react-v3/polaris/toggle';

interface Props<T> {
  readonly message: string; // message to display next to toggle
  readonly enabledChoice: T; // the enum value we consider as enabled toggle
  readonly disabledChoice: T; // the enum value we consider as disabled toggle
  readonly isEnabled: boolean;
  readonly notifyUpdate: (selectedChoice: T) => void; // update choice
}

interface State<T> {
  readonly selectedChoice: T;
  readonly checked: boolean;
}

/**
 * StrictBoolean is a component that takes 2 enums and allows to toggle between the two.
 * Use case for StrictBoolean is to only display 2 options for users when there may exist multiple options behind the scenes.
 */
export class StrictBoolean<T> extends React.Component<Props<T>, State<T>> {
  constructor(props: Props<T>) {
    super(props);
    this.state = {
      selectedChoice: this.props.isEnabled ? this.props.enabledChoice : this.props.disabledChoice,
      checked: this.props.isEnabled,
    };
  }

  private renderToggle() {
    return (
      <Toggle
        onChange={({ detail }) => {
          this.setState({
            checked: detail.checked,
            selectedChoice: detail.checked ? this.props.enabledChoice : this.props.disabledChoice,
          });
        }}
        onBlur={() => this.props.notifyUpdate(this.state.selectedChoice)}
        checked={this.state.checked}
      >
        {this.props.message}
      </Toggle>
    );
  }

  render() {
    return this.renderToggle();
  }
}
