import React from 'react';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table';
import Select from '@amzn/awsui-components-react-v3/polaris/select';
import { sortingComparatorUtility } from './utilities';
import { ConfigScheduleInfo } from '../../http-clients/config-schedule-client';
import { timezoneManager } from '../../utilities';
import Box from '@amzn/awsui-components-react-v3/polaris/box';

interface TableDefinitionProps {
  readonly onValueEdited: (id: string, newValue: string, item: ConfigScheduleInfo) => void;
}

interface EditingCellProps {
  readonly currentValue: string;
  readonly setValue: (value: string) => void;
}

function constructTimeOptions() {
  const optionsInDisplay: { label: string; value: string }[] = [];
  for (let i = 0; i < 24; i++) {
    const hour = i;
    const label = `${hour.toString().padStart(2, '0')}:00`;
    const value = label;
    optionsInDisplay.push({ label, value });
  }
  return optionsInDisplay;
}

const settings = [
  { label: 'MODERATE', value: 'MODERATE' },
  { label: 'CONSERVATIVE', value: 'CONSERVATIVE' },
  { label: 'ULTRACONSERVATIVE', value: 'ULTRACONSERVATIVE' },
];

export function buildTableDefinition(props: TableDefinitionProps): ReadonlyArray<TableProps.ColumnDefinition<ConfigScheduleInfo>> {
  const columns: TableProps.ColumnDefinition<ConfigScheduleInfo>[] = [];
  const optionsInDisplay = constructTimeOptions();

  columns.push({
    id: 'day',
    header: 'Day',
    cell: (item: { day: string }) => {
      return item.day ?? '-';
    },
    sortingField: 'day',
    isRowHeader: true,
    sortingComparator: sortingComparatorUtility,
  });

  columns.push({
    id: 'setting',
    header: 'Setting',
    cell: (item: { setting: string }) => {
      return item.setting ?? '-';
    },
    sortingField: 'setting',
    isRowHeader: true,
    sortingComparator: (i1: { setting: string }, i2: { setting: string }) => {
      const ap1 = i1.setting ?? '-';
      const ap2 = i2.setting ?? '-';
      return ap1.localeCompare(ap2);
    },
    editConfig: {
      ariaLabel: 'Setting',
      editIconAriaLabel: 'editable',
      editingCell: (item: ConfigScheduleInfo, { currentValue, setValue }: EditingCellProps) => {
        const value = currentValue ?? item.setting;
        return (
          <Select
            autoFocus={true}
            expandToViewport={true}
            selectedOption={settings.find((option) => option.value === value) ?? null}
            onChange={(event) => {
              setValue(event.detail.selectedOption.value ?? item.setting);
              if (props.onValueEdited) {
                props.onValueEdited('setting', event.detail.selectedOption.value ?? item.setting, item); // Pass the edited value to the parent component
              }
            }}
            options={settings}
          />
        );
      },
    },
  });

  columns.push({
    id: 'time',
    header: 'Station Time',
    cell: (item: { hour: string }) => {
      return item.hour ?? '-';
    },
    sortingField: 'time',
    isRowHeader: true,
    sortingComparator: (i1: { hour: string }, i2: { hour: string }) => {
      const ap1 = i1.hour ?? '-';
      const ap2 = i2.hour ?? '-';
      return ap1.localeCompare(ap2);
    },
    editConfig: {
      ariaLabel: 'Time',
      editIconAriaLabel: 'editable',
      errorIconAriaLabel: 'Time Error',
      editingCell: (item: ConfigScheduleInfo, { currentValue, setValue }: EditingCellProps) => {
        const value = currentValue ?? item.hour;
        return (
          <Select
            autoFocus={true}
            expandToViewport={true}
            selectedOption={optionsInDisplay.find((option) => option.value === value) ?? null}
            onChange={(event) => {
              setValue(event.detail.selectedOption.value ?? item.hour);
              // setEditedValueState(event.detail.value)
              if (props.onValueEdited) {
                props.onValueEdited('time', event.detail.selectedOption.value ?? item.hour, item);
              }
            }}
            options={optionsInDisplay}
          />
        );
      },
    },
  });

  columns.push({
    id: 'createdBy',
    header: 'Created By',
    cell: (item: { createdBy: string }) => {
      return item.createdBy ?? '-';
    },
    sortingField: 'createdBy',
    isRowHeader: true,
    sortingComparator: (i1: { createdBy: string }, i2: { createdBy: string }) => {
      const ap1 = i1.createdBy ?? '-';
      const ap2 = i2.createdBy ?? '-';
      return ap1.localeCompare(ap2);
    },
  });

  columns.push({
    id: 'updatedBy',
    header: 'Updated By',
    cell: (item: { updatedBy: string }) => {
      return item.updatedBy ?? '-';
    },
    sortingField: 'updatedBy',
    isRowHeader: true,
    sortingComparator: (i1: { updatedBy: string }, i2: { updatedBy: string }) => {
      const ap1 = i1.updatedBy ?? '-';
      const ap2 = i2.updatedBy ?? '-';
      return ap1.localeCompare(ap2);
    },
  });

  columns.push({
    id: 'updatedTime',
    header: 'Last Updated At',
    cell: (item: ConfigScheduleInfo) => {
      return timezoneManager.convertTimestampToString(item.recordUpdatedTime, {
        tz: item.timeZone ?? 'UTC',
      });
    },
    sortingField: 'updatedTime',
    isRowHeader: true,
    sortingComparator: (i1: ConfigScheduleInfo, i2: ConfigScheduleInfo) => {
      const ap1 = i1.recordUpdatedTime ?? 0;
      const ap2 = i2.recordUpdatedTime ?? 0;
      return ap1 - ap2;
    },
  });

  columns.push({
    id: 'lastAppliedAt',
    header: 'Last Applied At',
    cell: (item: ConfigScheduleInfo) => {
      if (typeof item.lastAppliedAt === 'number') {
        return timezoneManager.convertTimestampToString(item.lastAppliedAt, {
          tz: item.timeZone ?? 'UTC',
        });
      } else {
        return <Box>-</Box>;
      }
    },
    sortingField: 'lastAppliedAt',
    isRowHeader: true,
    sortingComparator: (i1: ConfigScheduleInfo, i2: ConfigScheduleInfo) => {
      const ap1 = i1.lastAppliedAt ?? 0;
      const ap2 = i2.lastAppliedAt ?? 0;
      return ap1 - ap2;
    },
  });

  return columns;
}
