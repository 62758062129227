import ReactMarkdown from 'react-markdown';
import React from 'react';
import styles from './markdown.module.scss';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/theme-xcode';
/**
 * Implement a customized markdown displayer based on react-markdown.
 * customization on
 *  1. table style
 *  2. code style
 */
export declare namespace Markdown {
  export interface Props {
    markdownText: string;
  }
}
interface CodeRenderProps {
  readonly language: string | null;
  readonly value: string;
}
class CodeRender extends React.Component<CodeRenderProps> {
  render() {
    return (
      <AceEditor
        setOptions={{
          useWorker: false,
        }}
        mode={this.props.language !== null ? this.props.language : undefined}
        theme="xcode"
        showPrintMargin={false} // if true, there is a vertical line indicate #chars on a line
        readOnly={true}
        focus={false}
        width={'80%'}
        maxLines={40}
        minLines={10}
        showGutter={true}
        value={this.props.value}
        editorProps={{ $blockScrolling: true }}
      />
    );
  }
}

// interface LinkRenderProps {
//   readonly href: string
//   readonly children: {props: {value: string}}[]
// }

// class LinkRender extends React.Component<LinkRenderProps> {
//   render() {
//     let text = ''
//     console.log(this.props)
//     if (Array.isArray(this.props.children) && this.props.children.length > 0) {
//       const child = this.props.children[0]
//       if (child.props != null && typeof child.props.value === 'string') {
//         text = child.props.value + ' ';
//       }
//     }

//     console.log(this.props)
//   return <a href={this.props.href}><span>{text}</span><Icon name="external" /></a>
//   }
// }

// eslint-disable-next-line no-redeclare
class MarkdownRender extends React.Component<Markdown.Props> {
  render() {
    return (
      <ReactMarkdown
        className={styles['markdown-body']}
        source={this.props.markdownText}
        escapeHtml={false}
        renderers={{
          code: CodeRender,
          //link: LinkRender,
        }}
      />
    );
  }
}

export default MarkdownRender;
