import React from 'react';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Link from '@amzn/awsui-components-react-v3/polaris/link';
import { ServiceArea } from './models';

export interface BuildTableDefinitionProps {
  readonly onServiceAreaClick: (serviceArea: ServiceArea) => void;
  readonly buildHref: (serviceArea: ServiceArea) => string;
}

export function buildTableDefinition(props: BuildTableDefinitionProps): ReadonlyArray<TableProps.ColumnDefinition<ServiceArea>> {
  const columns: TableProps.ColumnDefinition<ServiceArea>[] = [];

  columns.push({
    id: 'stationCode',
    header: 'SA Code',
    cell: (item) => <Box>{item.stationCode}</Box>,
    sortingField: 'stationCode',
    sortingComparator: (i1, i2) => i1.stationCode.localeCompare(i2.stationCode),
  });

  columns.push({
    id: 'serviceAreaName',
    header: 'Name',
    cell: (item) => {
      return (
        <Link
          href={props.buildHref(item)}
          onFollow={(evt) => {
            evt.preventDefault();
            props.onServiceAreaClick(item);
          }}
        >
          {item.serviceAreaName}
        </Link>
      );
    },
    sortingField: 'serviceAreaName',
    sortingComparator: (i1, i2) => i1.serviceAreaName.localeCompare(i2.serviceAreaName),
  });

  columns.push({
    id: 'serviceAreaId',
    header: 'SA Id',
    cell: (item) => <Box>{item.serviceAreaId}</Box>,
    sortingField: 'serviceAreaId',
    sortingComparator: (i1, i2) => i1.serviceAreaId.localeCompare(i2.serviceAreaId),
  });

  return columns;
}
