import React from 'react';

import Box from '@amzn/awsui-components-react-v3/polaris/box';
import HelpPanel from '@amzn/awsui-components-react-v3/polaris/help-panel';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import Icon from '@amzn/awsui-components-react-v3/polaris/icon';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Toggle from '@amzn/awsui-components-react-v3/polaris/toggle';
import ColumnLayout from '@amzn/awsui-components-react-v3/polaris/column-layout';
import { GlobalContext } from '../../main-app/global-context';

/**
 * A common help panel displays on the right side of the website.
 */
export default class InfoPanel extends React.Component<{}, {}> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <HelpPanel header={<Header variant="h2">Understanding Configurations</Header>}>
        <ColumnLayout columns={1} borders="horizontal">
          <SpaceBetween direction="horizontal" size="s">
            <Box variant="p">Routing team has a work-in-progress document that introduces the meanings of each configuration parameter.</Box>
            <Box variant="p">
              <a href="https://quip-amazon.com/IUUDAgsXxQd9/Flex-Routing-Assignment-Configuration-Reference-in-progress">
                Flex Routing &amp; Assignment Configuration Reference <Icon name="external" />
              </a>
            </Box>
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="s">
            <Box variant="h4">Settings</Box>
            <SpaceBetween direction="horizontal" size="l">
              <Box variant="p">Developer Mode</Box>
              <Box variant="p">
                <Toggle
                  checked={this.context.operatingMode === 'developer'}
                  onChange={(evt) => {
                    this.context.setOperatingMode(evt.detail.checked ? 'developer' : 'regular');
                  }}
                />
              </Box>
            </SpaceBetween>
          </SpaceBetween>
        </ColumnLayout>
      </HelpPanel>
    );
  }
}
