import { ConfigTableDefinition } from '../../../models';

export const FUNGIBLE_CONFIGURATIONS: ConfigTableDefinition = {
  headerText: 'Fungible Algorithm Configurations',
  description: 'Control the core fungible assignment algorithm.',
  valueColMinWidth: '150px',
  sizeOnGrid: 'default',
  items: [
    {
      field: 'algorithmParametersMap.FUNGIBLE_ASSIGNMENT_ALGORITHM.prioritizationTime',
      scope: 'assignmentConfig',
      name: 'Prioritization Time',
      description: 'Prioritize route assignment if the route dispatch window end time is before the relative prioritization time.',
      representationType: 'number',
      validators: [
        {
          name: 'ValueBetween0And120Validator',
        },
      ],
    },
    {
      field: 'algorithmParametersMap.FUNGIBLE_ASSIGNMENT_ALGORITHM.useDispatchWindowEndForRoutePrioritization',
      scope: 'assignmentConfig',
      name: 'Enable route prioritization',
      description: 'Prioritize routes from the same assignment group by its stage status and dispatch window end time.',
      representationType: 'boolean',
    },
    {
      field: 'algorithmParametersMap.FUNGIBLE_ASSIGNMENT_ALGORITHM.useStagingAreaConstraintAwarePrioritization',
      scope: 'assignmentConfig',
      name: 'Enable staging area constraint aware route prioritization',
      description: 'Prioritize routes that are more staging area constrained, both in route to transporter planning and in the same assignment group',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'algorithmParametersMap.FUNGIBLE_ASSIGNMENT_ALGORITHM.nonStagingConstrainedFixedCost',
      scope: 'assignmentConfig',
      name: 'Non staging constrained fixed cost',
      description: 'Additional cost to non staging constrained routes',
      representationType: 'number',
      validators: [
        {
          name: 'ValueBetween0And5000Validator',
        },
      ],
      visibility: 'developer-only',
    },
    {
      field: 'algorithmParametersMap.FUNGIBLE_ASSIGNMENT_ALGORITHM.enableMaxDeliveriesPerPaidHourCost',
      scope: 'assignmentConfig',
      name: 'Enable max DPPH limit',
      description: 'Enable the maximum DPPH limit per assignment.',
      representationType: 'boolean',
    },
    {
      field: 'algorithmParametersMap.FUNGIBLE_ASSIGNMENT_ALGORITHM.maxDeliveriesPerPaidHour',
      scope: 'assignmentConfig',
      name: 'Max DPPH limit value',
      description: 'Limit the maximum DPPH per assignment.',
      representationType: 'number',
      validators: [
        {
          name: 'PositiveNumberValidator',
        },
      ],
    },
  ],
};
