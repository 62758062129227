/**
 * parseFloat is a javascript built-in function that convert string to number.
 *      parseFloat('text') returns NaN
 *      parseFloat('123') retturns 123
 *      parseFloat('123.23') returns 123.23
 *      parseFloat('123.23text') returns 123.23
 *
 * The strictParseFloat eliminates the last example. If the input is '123.23text', the function will return undefined.
 * For more examples, see unittests.
 *
 * @param numericText any string or number
 * @returns a number or undefined
 */

const NUMBER_REGEX: RegExp = /^-?\d+(\.\d+)?$/;

export function strictParseFloat(numericText: string): number | undefined {
  if (numericText?.match(NUMBER_REGEX)) {
    const num = parseFloat(numericText);
    if (!isNaN(num)) {
      return num;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}

export function strictParseBoolean(text: string): boolean | undefined {
  if (text === 'true') {
    return true;
  } else if (text === 'false') {
    return false;
  } else {
    return undefined;
  }
}
