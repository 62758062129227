export function applyTheme(theme: 'regular' | 'visual-refresh') {
  if (theme === 'regular') {
    document.body.classList.add('awsui');
    document.body.classList.remove('awsui-visual-refresh');
  } else if (theme === 'visual-refresh') {
    document.body.classList.add('awsui-visual-refresh');
    document.body.classList.remove('awsui');
  } else {
    throw new Error(`invalid theme ${theme}`);
  }
}
