import React from 'react';
import Icon from '@amzn/awsui-components-react-v3/polaris/icon';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Grid from '@amzn/awsui-components-react-v3/polaris/grid';
import Container from '@amzn/awsui-components-react-v3/polaris/container';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import Link from '@amzn/awsui-components-react-v3/polaris/link';
import './welcome-page.scss';
import { GlobalContext } from '../../main-app/global-context';
import Tabs from '@amzn/awsui-components-react-v3/polaris/tabs';
import PermissionTable from '../../common-components/permission-table';

type ActiveTabId = 'changelog' | 'permission';

interface WelcomePageState {
  readonly activeTabId: ActiveTabId;
}

/**
 * Example
 * https://cloudscape.aws.dev/examples/react/landing-page.html
 * https://code.amazon.com/packages/AWS-UI-Examples/blobs/mainline-3.0/--/src/pages/landing-page/index.jsx
 */
export class WelcomePage extends React.Component<{}, WelcomePageState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: {}) {
    super(props);

    this.state = {
      activeTabId: 'changelog',
    };
  }

  componentDidMount() {
    this.context.resetLayout();
    this.context.setTools('welcome-panel');
    this.context.setDisableContentPaddings(true);
    this.context.setHideCodeFreezeWarning(true);
  }

  render() {
    return (
      <Box margin={{ bottom: 'l' }}>
        <div className="custom-home__header">
          <Box padding={{ vertical: 'xxxl', horizontal: 's' }}>
            <Grid
              gridDefinition={[
                { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
                { colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } },
                { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } },
              ]}
            >
              <Box fontWeight="light" padding={{ top: 'xs' }}>
                <span className="custom-home__category">Configurations Management Powered by Ultra Fast Routing and Assignment (UFRAA) team</span>
              </Box>
              <div className="custom-home__header-title">
                <Box variant="h1" fontWeight="heavy" padding="n" fontSize="display-l" color="inherit">
                  Configurations Manager
                </Box>
                <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
                  Self-service for routing and assignment configurations
                </Box>
                <Box variant="p" fontWeight="light">
                  <span className="custom-home__header-sub-title">Configurations Manager aims to provide the best developer and ops experiences in configurations management.</span>
                </Box>
              </div>
            </Grid>
          </Box>
        </div>
        <Box margin={'xxxl'}>{this.renderTabs()}</Box>
      </Box>
    );
  }

  private renderTabs() {
    return (
      <Tabs
        tabs={[
          {
            id: 'changelog',
            label: 'Changelog',
            content: this.renderChangelog(),
          },
          {
            id: 'permission',
            label: 'Request permissions',
            content: this.renderRequestPermissions(),
          },
        ]}
        onChange={(evt) => {
          this.setState({ activeTabId: evt.detail.activeTabId as ActiveTabId });
        }}
        activeTabId={this.state.activeTabId}
      />
    );
  }

  private renderChangelog() {
    return (
      <Container header={<Header variant="h2">Changelog</Header>}>
        <SpaceBetween direction="vertical" size="m">
          {this.renderEmailList()}
          {this.renderUIRefreshChangeLog()}
          {this.renderChangeComparisonFeatureChangeLog()}
          {this.renderWebsiteWikiChangeLog()}
        </SpaceBetween>
      </Container>
    );
  }

  private renderRequestPermissions() {
    return (
      <Container header={<Header variant="h2">Request permissions</Header>}>
        <SpaceBetween direction="vertical" size="m">
          <Box>For individuals, use the SIM links below to request access to configurations. Permission requests are reviewed weekly.</Box>
          <PermissionTable access="all" />
        </SpaceBetween>
      </Container>
    );
  }

  private renderEmailList() {
    return (
      <Box>
        <Header>2023-06-21</Header>
        <Box>
          Subscribe to{' '}
          <Link href="https://email-list.corp.amazon.com/email-list/expand-list/config-manager-interest" external={true}>
            config-manager-interest
          </Link>{' '}
          to stay update to date.
        </Box>
      </Box>
    );
  }

  private renderUIRefreshChangeLog() {
    return (
      <Box>
        <Header>2023-06-12</Header>
        <Box>
          <Box>Effective 2023-06-12</Box>
          <Box>1. We extended the GSF Routing Configurations Manager website scope to include Flex Assignment configurations.</Box>
          <Box>
            2. The change also provides more features to UFRAA Configs, including dark mode, compact mode, timezone conversion, and a new theme (visual refresh). Click <Icon name="settings" /> on the
            top right corner to check out the new features.
          </Box>
          <Box>
            3. The assignment workflow page <Link>/assignment-workflow</Link> function has been integrated into assignment service areas details page.
          </Box>
        </Box>
      </Box>
    );
  }

  private renderChangeComparisonFeatureChangeLog() {
    return (
      <Box>
        <Header>2022-12-01</Header>
        <Box>
          <Box>Effective 2022-12-01</Box>
          <Box>
            Configurations Manager now supports change comparison in the both assignment and routing configurations page. Navigate to the "update history" tab and select two changes, the differences
            will be highlighted.
          </Box>
        </Box>
      </Box>
    );
  }

  private renderWebsiteWikiChangeLog() {
    return (
      <Box>
        <Header>2020-08-24</Header>
        <Box>
          Checkout the configurations manager wiki page to find out how to use configurations management tool{' '}
          <Link external href="https://w.amazon.com/bin/view/GSFRouting/Development/ConfigurationsManager" target="_blank">
            User Guide
          </Link>
          .
        </Box>
      </Box>
    );
  }
}
