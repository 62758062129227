import React from 'react';

import Header from '@amzn/awsui-components-react-v3/polaris/header';
import Container from '@amzn/awsui-components-react-v3/polaris/container';
import ColumnLayout from '@amzn/awsui-components-react-v3/polaris/column-layout';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Spinner from '@amzn/awsui-components-react-v3/polaris/spinner';
import Grid from '@amzn/awsui-components-react-v3/polaris/grid';
import StatusIndicator from '@amzn/awsui-components-react-v3/polaris/status-indicator';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import { WORK_TYPE_TO_LABEL } from '../dria-scope-list-page';
import { formatDuration } from '../../utilities';
import { Region, ServiceArea } from '../../models';
import { CopyText } from '../../common-components/copy-text';
import { ServiceAreaId, ServiceAreaName, StationTime, StationTimezone } from '../../common-components/service-area-items';
import { DriaWorkCadence } from '../../http-clients/dria-client';

export interface DriaScopeSummaryProps {
  readonly serviceArea?: ServiceArea;
  readonly region?: Region;
  readonly workCadences?: ReadonlyArray<DriaWorkCadence>;
}

export interface DriaScopeSummaryState {}

export class DriaScopeSummary extends React.Component<DriaScopeSummaryProps, DriaScopeSummaryState> {
  render() {
    return (
      <Grid gridDefinition={[{ colspan: { default: 12, xs: 6, m: 8 } }, { colspan: { default: 12, xs: 6, m: 4 } }]}>
        <Container header={<Header variant="h2">Summary</Header>} data-testid="scope">
          {this.renderScope()}
        </Container>
        <Container
          header={
            <Header variant="h2" actions={null /** support changing cadence in the UI */}>
              Work Cadences
            </Header>
          }
          data-testid="cadences"
        >
          {this.renderWorkCadences()}
        </Container>
      </Grid>
    );
  }

  private renderScope() {
    if (this.props.serviceArea) {
      return this.renderServiceArea(this.props.serviceArea);
    } else if (this.props.region) {
      return this.renderRegion(this.props.region);
    } else {
      return (
        <Box textAlign="center">
          <StatusIndicator type="loading">Loading...</StatusIndicator>
        </Box>
      );
    }
  }

  private renderServiceArea(serviceArea: ServiceArea) {
    return (
      <ColumnLayout columns={4} variant="text-grid">
        <ServiceAreaName serviceAreaName={serviceArea.serviceAreaName} />
        <ServiceAreaId serviceAreaId={serviceArea.serviceAreaId} />
        <StationTimezone timezone={serviceArea.timeZone} />
        <StationTime timezone={serviceArea.timeZone} />
      </ColumnLayout>
    );
  }

  private renderRegion(region: Region) {
    return (
      <ColumnLayout columns={4} variant="text-grid">
        <Box>
          <Box margin={{ bottom: 'xxxs' }} color="text-label">
            Region Id
          </Box>
          <Box margin={{ bottom: 'xxxs' }}>{this.renderText(region.regionId, true)}</Box>
        </Box>
      </ColumnLayout>
    );
  }

  private renderText(text?: string, copyable?: boolean) {
    if (typeof text === 'string') {
      if (copyable) {
        return <CopyText copyText={text} />;
      } else {
        return <Box>{text}</Box>;
      }
    } else {
      return <Spinner />;
    }
  }

  private renderWorkCadences() {
    if (!this.props.workCadences) {
      return (
        <Box textAlign="center">
          <StatusIndicator type="loading">Loading...</StatusIndicator>
        </Box>
      );
    }
    const workCadences = this.props.workCadences
      .map((entry) => {
        const name = WORK_TYPE_TO_LABEL[entry.workType] ?? entry.workType;
        const cadence = formatDuration(entry.cadenceInSeconds);
        return {
          name,
          cadence,
          enabled: entry.isEnabled,
          mode: entry.mode,
        };
      })
      .sort((item1, item2) => item1.name.localeCompare(item2.name));
    return (
      <SpaceBetween direction="vertical" size="s">
        {workCadences.map((item, index) => {
          return (
            <SpaceBetween key={index} direction="horizontal" size="xxs">
              <Box variant="span">
                {item.name} @ {item.cadence}{' '}
              </Box>
              {!item.enabled ? <StatusIndicator type="stopped">Disabled</StatusIndicator> : null}
              {item.mode !== null && item.mode !== 'PRODUCTION' ? <StatusIndicator type="info">{item.mode}</StatusIndicator> : null}
            </SpaceBetween>
          );
        })}
      </SpaceBetween>
    );
  }
}
