import {
  ApplyRoutingConfigProfileRequest,
  ApplyRoutingConfigProfileResponse,
  DisableRoutingServiceAreasRequest,
  DisableRoutingServiceAreasResponse,
  GetLatestRoutingCommitRecordsRequest,
  GetLatestRoutingCommitRecordsResponse,
  GetRoutingCommitRecordsRequest,
  GetRoutingCommitRecordsResponse,
  GetRoutingConfigProfilesRequest,
  GetRoutingConfigProfilesResponse,
  GetRoutingServiceAreaDetailsByCommitRequest,
  GetRoutingServiceAreaDetailsByCommitResponse,
  GetRoutingServiceAreaDetailsRequest,
  GetRoutingServiceAreaDetailsResponse,
  LaunchRoutingServiceAreasRequest,
  LaunchRoutingServiceAreasResponse,
  ListRoutingServiceAreasResponse,
  PutRoutingServiceAreaDetailsResponse,
  PutRoutingServiceAreaDetailsResquest,
  RevertRoutingConfigurationsRequest,
  RevertRoutingConfigurationsResponse,
} from './request-response';
import { RoutingClient } from './routing-client';
import { AxiosInstance } from 'axios';

interface Query {
  [key: string]: string;
}

export class RoutingClientImpl implements RoutingClient {
  private readonly httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  async listRoutingServiceAreas(): Promise<ListRoutingServiceAreasResponse> {
    const resp = await this.httpClient.get<ListRoutingServiceAreasResponse>('/routing-service-areas');
    return resp.data;
  }

  async disableRoutingServiceAreas(request: DisableRoutingServiceAreasRequest): Promise<DisableRoutingServiceAreasResponse> {
    const resp = await this.httpClient.post<DisableRoutingServiceAreasResponse>(`/disable-service-areas`, request, {
      headers: {
        'content-type': 'application/json',
      },
    });
    return resp.data;
  }

  async getRoutingServiceAreaDetails(request: GetRoutingServiceAreaDetailsRequest): Promise<GetRoutingServiceAreaDetailsResponse> {
    const query: Query = {
      serviceAreaId: request.serviceAreaId,
    };

    if (typeof request.type === 'string') {
      query['type'] = request.type;
    }

    const resp = await this.httpClient.get<GetRoutingServiceAreaDetailsResponse>(`/service-area-details?${new URLSearchParams(query).toString()}`);
    return resp.data;
  }

  async getRoutingServiceAreaDetailsByCommit(request: GetRoutingServiceAreaDetailsByCommitRequest): Promise<GetRoutingServiceAreaDetailsByCommitResponse> {
    const resp = await this.httpClient.get<GetRoutingServiceAreaDetailsByCommitResponse>(`/service-area-details-by-commit-id?commitId=${request.commitId}`);
    return resp.data;
  }

  async putRoutingServiceAreaDetails(request: PutRoutingServiceAreaDetailsResquest): Promise<PutRoutingServiceAreaDetailsResponse> {
    const resp = await this.httpClient.put<PutRoutingServiceAreaDetailsResponse>(
      `/service-area-details?serviceAreaId=${request.serviceAreaId}`,
      {
        description: request.description,
        serviceAreaMetadata: request.serviceAreaMetadata,
        algorithmSettings: request.algorithmSettings,
        serviceAreaConfig: request.serviceAreaConfig,
      },
      {
        headers: {
          'content-type': 'application/json',
        },
      },
    );
    return resp.data;
  }

  async getRoutingCommitRecords(request: GetRoutingCommitRecordsRequest): Promise<GetRoutingCommitRecordsResponse> {
    const query: Query = {
      serviceAreaId: request.serviceAreaId,
    };
    if (typeof request.numberOfRecords === 'number') {
      query['numberOfRecords'] = request.numberOfRecords.toString();
    }
    if (typeof request.commitId === 'string') {
      query['commitId'] = request.commitId;
    }
    const resp = await this.httpClient.get<GetRoutingCommitRecordsResponse>(`/commit-records?${new URLSearchParams(query).toString()}`);
    return resp.data;
  }

  async revertRoutingConfigurations(request: RevertRoutingConfigurationsRequest): Promise<RevertRoutingConfigurationsResponse> {
    const resp = await this.httpClient.post<RevertRoutingConfigurationsResponse>('/revert-configurations', request, {
      headers: {
        'content-type': 'application/json',
      },
    });
    return resp.data;
  }

  async getLatestRoutingCommitRecords(request: GetLatestRoutingCommitRecordsRequest): Promise<GetLatestRoutingCommitRecordsResponse> {
    const resp = await this.httpClient.post<GetLatestRoutingCommitRecordsResponse>('/latest-commit-records', request, {
      headers: {
        'content-type': 'application/json',
      },
    });
    return resp.data;
  }

  async getRoutingConfigProfiles(request: GetRoutingConfigProfilesRequest): Promise<GetRoutingConfigProfilesResponse> {
    const resp = await this.httpClient.get<GetRoutingConfigProfilesResponse>(`/config-profiles?marketplace=${request.marketplace}&scope=${request.scope}`);
    return resp.data;
  }

  async applyRoutingConfigProfile(request: ApplyRoutingConfigProfileRequest): Promise<ApplyRoutingConfigProfileResponse> {
    const resp = await this.httpClient.post<ApplyRoutingConfigProfileResponse>('/apply-config-profile', request, {
      headers: {
        'content-type': 'application/json',
      },
    });
    return resp.data;
  }

  async launchRoutingServiceAreas(request: LaunchRoutingServiceAreasRequest): Promise<LaunchRoutingServiceAreasResponse> {
    const resp = await this.httpClient.post<LaunchRoutingServiceAreasResponse>('/launch-service-areas', request, {
      headers: {
        'content-type': 'application/json',
      },
    });
    return resp.data;
  }
}
