import { ConfigTableDefinition } from '../../../models';

export const BLOCK_EXTENSION_CONFIGURATIONS: ConfigTableDefinition = {
  headerText: 'Block Extension Configurations',
  valueColMinWidth: '150px',
  sizeOnGrid: 'default',
  visibility: 'developer-only',
  items: [
    {
      field: 'enableBlockExtension',
      scope: 'assignmentConfig',
      name: 'Enable Block Extension',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'algorithmParametersMap.FUNGIBLE_ASSIGNMENT_ALGORITHM.maxBlockExtensionMinutes',
      scope: 'assignmentConfig',
      name: 'Max Block Extension Minutes',
      representationType: 'number',
      validators: [
        {
          name: 'ValueBetween0And900Validator',
        },
      ],
      visibility: 'developer-only',
    },
    {
      field: 'minBlockExtensionScheduleBufferMinutes',
      scope: 'assignmentConfig',
      name: 'Min Block Extension Schedule Buffer Minutes',
      representationType: 'number',
      validators: [
        {
          name: 'ValueBetween0And900Validator',
        },
      ],
      visibility: 'developer-only',
    },
  ],
};
