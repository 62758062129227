import React from 'react';

import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import FormField from '@amzn/awsui-components-react-v3/polaris/form-field';
import Modal from '@amzn/awsui-components-react-v3/polaris/modal';
import Select from '@amzn/awsui-components-react-v3/polaris/select';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import { AccessPoint, OrderType, orderTypes } from '../../../../models/routing-models';
import { IntegerInput } from '../../../../common-components/numeric-input';
import { PickupStartOffsetConfigItem } from './models';
import { ORDER_TYPE_TO_LABEL } from '../constants';

interface CreateNewPickupStartOffsetModalProps {
  readonly accessPoints: ReadonlyArray<AccessPoint>;
  // pass in current configuration to check and avoid duplication.
  readonly items: ReadonlyArray<PickupStartOffsetConfigItem>;
  readonly onCreate: (item: PickupStartOffsetConfigItem) => void;
  readonly onDismiss: () => void;
}

interface CreateNewPickupStartOffsetModalState {
  readonly errorHint?: string;
  readonly accessPointId?: string;
  readonly orderType?: OrderType;
  readonly slammedTRPickupStartOffset?: number;
  readonly nonSlammedTRPickupStartOffset?: number;
}

export class CreateNewPickupStartOffsetModal extends React.Component<CreateNewPickupStartOffsetModalProps, CreateNewPickupStartOffsetModalState> {
  constructor(props: CreateNewPickupStartOffsetModalProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal
        visible={true}
        header="Create Pickup Start Time Offset"
        onDismiss={this.props.onDismiss}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={this.props.onDismiss}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  const accessPointId = this.state.accessPointId;

                  if (typeof accessPointId !== 'string' || typeof this.state.nonSlammedTRPickupStartOffset !== 'number' || typeof this.state.slammedTRPickupStartOffset !== 'number') {
                    this.setState({ errorHint: 'Missing input' });
                    return;
                  }
                  const newItem: PickupStartOffsetConfigItem = {
                    accessPointId: accessPointId,
                    addressId: this.props.accessPoints.find((ap) => ap.accessPointId === accessPointId)?.addressId,
                    orderType: this.state.orderType,
                    slammedTRPickupStartOffset: this.state.slammedTRPickupStartOffset,
                    nonSlammedTRPickupStartOffSet: this.state.nonSlammedTRPickupStartOffset,
                  };

                  if (newItem.nonSlammedTRPickupStartOffSet < newItem.slammedTRPickupStartOffset) {
                    this.setState({
                      errorHint: 'Non-slammed TR pickup start time should not be before slammed TR pickup start time.',
                    });
                    return;
                  }

                  const index = this.props.items.findIndex((item) => item.accessPointId === newItem.accessPointId && item.orderType === newItem.orderType);
                  if (index !== -1) {
                    this.setState({
                      errorHint: 'The access point and order type combination already existed.',
                    });
                    return;
                  }

                  this.props.onCreate(newItem);
                  this.props.onDismiss();
                }}
              >
                Add
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        {this.renderModalContent()}
      </Modal>
    );
  }

  private renderModalContent() {
    const accessPointOptions = this.props.accessPoints.map((ap) => {
      return {
        label: ap.name,
        description: ap.accessPointId,
        value: ap.accessPointId,
      };
    });

    const selectedAP = accessPointOptions.find((ap) => ap.value === this.state.accessPointId);

    const orderTypeOptions: { readonly label: string; readonly value?: OrderType }[] = orderTypes.map((orderType) => {
      return {
        label: ORDER_TYPE_TO_LABEL[orderType],
        value: orderType,
      };
    });

    orderTypeOptions.unshift({ label: 'Default' });
    const selectedOrderType = orderTypeOptions.find((o) => o.value === this.state.orderType);

    return (
      <SpaceBetween direction="vertical" size="m">
        <FormField label="Access Point">
          <Select selectedOption={selectedAP ?? null} options={accessPointOptions} onChange={(evt) => this.setState({ accessPointId: evt.detail.selectedOption.value! })} />
        </FormField>
        <FormField label="Order Type">
          <Select selectedOption={selectedOrderType ?? null} options={orderTypeOptions} onChange={(evt) => this.setState({ orderType: evt.detail.selectedOption.value as OrderType })} />
        </FormField>
        <IntegerInput
          fieldLabel="Slammed TR Pickup Start Time Offset (Minutes)"
          startingText={this.state.slammedTRPickupStartOffset?.toString() ?? ''}
          onChange={(value) => this.setState({ slammedTRPickupStartOffset: value })}
        />
        <IntegerInput
          fieldLabel="Non-Slammed TR Pickup Start Time Offset (Minutes)"
          startingText={this.state.nonSlammedTRPickupStartOffset?.toString() ?? ''}
          onChange={(value) => this.setState({ nonSlammedTRPickupStartOffset: value })}
        />
        <FormField errorText={this.state.errorHint} />
      </SpaceBetween>
    );
  }
}
