import { Mutable } from '../../../../models';
import { ServiceAreaConfig, PlanningHorizonConfigOverride, PlanningHorizonConfigOverrideList, TimeBasedPlanningHorizonConfig, PlanningHorizonConfig } from '../../../../models/routing-models';
import { PlanningHorizonType } from './models';

export function addOverride(serviceAreaConfig: ServiceAreaConfig, override: PlanningHorizonConfigOverride): ServiceAreaConfig {
  const existingOverrides = serviceAreaConfig.horizonConfigOverrides ? Array.from(serviceAreaConfig.horizonConfigOverrides) : [];
  existingOverrides.push(override);
  return {
    ...serviceAreaConfig,
    horizonConfigOverrides: existingOverrides,
  };
}

/**
 * Attention: the method deletes overrides by comparing object reference not by value. Ideally, we should encode the object into some hash value and comparing hash. The reason is
 * ServiceAreaConfig and its properties are immutable, we often needs to create new object (new reference) to update them, which will change the object reference.
 * @param serviceAreaConfig
 * @param overrides
 * @returns
 */
export function deleteOverrides(serviceAreaConfig: ServiceAreaConfig, overrides: ReadonlyArray<PlanningHorizonConfigOverride>): ServiceAreaConfig {
  return {
    ...serviceAreaConfig,
    horizonConfigOverrides: serviceAreaConfig.horizonConfigOverrides?.filter((override) => !overrides.includes(override)),
  };
}

export function updateStagedPlanningHorizon(
  serviceAreaConfig: ServiceAreaConfig,
  inputType: PlanningHorizonType,
  effectiveTime: string,
  orderType: string | null,
  value: number | string,
): ServiceAreaConfig {
  const config = retrieveHorizonConfigOverrideToUpdate(effectiveTime, orderType, serviceAreaConfig.horizonConfigOverrides ?? []);
  if (config !== undefined) {
    if (inputType === 'timebased' && config.timeBasedHorizonConfig && typeof value === 'string') {
      /**
       * Ideally, we should create a new object rather than modifying the object directly. However, because the select feature on the table and deleteOverrides method
       * are using references comparison, if we create new objects, these reference comparison will stop working.
       */
      (config.timeBasedHorizonConfig as Mutable<TimeBasedPlanningHorizonConfig>).stagedDeliveryWindowCutOffLocalTime = value;
    } else if (inputType === 'normal' && config.horizonConfig && typeof value === 'number') {
      (config.horizonConfig as Mutable<PlanningHorizonConfig>).stagedPlanningHorizonMinutes = value;
    }
  }
  return {
    ...serviceAreaConfig,
  };
}

export function updateNonStagedPlanningHorizon(
  serviceAreaConfig: ServiceAreaConfig,
  inputType: PlanningHorizonType,
  effectiveTime: string,
  orderType: string | null,
  value: number | string,
): ServiceAreaConfig {
  const config = retrieveHorizonConfigOverrideToUpdate(effectiveTime, orderType, serviceAreaConfig.horizonConfigOverrides ?? []);
  if (config !== undefined) {
    if (inputType === 'normal' && config.horizonConfig && typeof value === 'number') {
      (config.horizonConfig as Mutable<PlanningHorizonConfig>).nonStagedPlanningHorizonMinutes = value;
    }
  }
  return {
    ...serviceAreaConfig,
  };
}

export function updateLocking(serviceAreaConfig: ServiceAreaConfig, inputType: PlanningHorizonType, effectiveTime: string, orderType: string | null, value: number | string): ServiceAreaConfig {
  const config = retrieveHorizonConfigOverrideToUpdate(effectiveTime, orderType, serviceAreaConfig.horizonConfigOverrides ?? []);
  if (config !== undefined) {
    if (inputType === 'timebased' && config.timeBasedHorizonConfig && typeof value === 'string') {
      (config.timeBasedHorizonConfig as Mutable<TimeBasedPlanningHorizonConfig>).lockCutOffLocalTime = value;
    } else if (inputType === 'normal' && config.horizonConfig && typeof value === 'number') {
      (config.horizonConfig as Mutable<PlanningHorizonConfig>).lockOffsetMinutes = value;
    }
  }
  return {
    ...serviceAreaConfig,
  };
}

/**
 * A helper method to retrieve the horizonConfigOverride that needs to be updated.
 * A horizonConfigOverride is unique based off of a) [orderType + effectiveAllDay]
 * when effectiveAllDay = true || b) [orderType + startLocalTime + endLocalTime]
 * when effectiveAllDay = false.
 * @param effectiveTime is a frontEnd string representation of "Effective Time".
 * It is used to determine whether effectiveAllDay is true. If effectiveAllDay is
 * false it is used to retrieve startLocalTime and endLocalTime.
 * @param orderType is part of what determines an unique horizonConfigOverride.
 * @param horizonConfigOverrides that currently exist in State.
 * @returns {*} return the horizonConfigOverride that needs to be updated based
 * off the effectiveTime and orderType passed in.
 */
function retrieveHorizonConfigOverrideToUpdate(effectiveTime: string, orderType: string | null, horizonConfigOverrides: PlanningHorizonConfigOverrideList) {
  if (effectiveTime === 'All Day Effective') {
    const planningHorizonOverride = horizonConfigOverrides.find((horizonConfigOverride) => horizonConfigOverride.effectiveAllDay && horizonConfigOverride.orderType === orderType);
    return planningHorizonOverride;
  } else {
    const startAndEndLocalTimes = effectiveTime.split(' - ');
    const planningHorizonOverride = horizonConfigOverrides.find(
      (horizonConfigOverride) =>
        horizonConfigOverride.startLocalTime === startAndEndLocalTimes[0] && horizonConfigOverride.endLocalTime === startAndEndLocalTimes[1] && horizonConfigOverride.orderType === orderType,
    );
    return planningHorizonOverride;
  }
}
