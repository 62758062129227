import { AxiosInstance } from 'axios';
import { DriaClient } from './dria-client';
import { DriaScopeInfo, GetDriaScopeInfoListRequest, GetDriaScopeInfoListResponse } from './request-response';

export class DriaClientImpl implements DriaClient {
  private readonly httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  async getDriaScopeInfoList(request: GetDriaScopeInfoListRequest): Promise<GetDriaScopeInfoListResponse> {
    const resp = await this.httpClient.get<GetDriaScopeInfoListResponse>('/dria-scope-info-list');
    return resp.data;
  }
}
