import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import ConfigProfilePanel, { ApplyProfileResults, ConfigProfile, ServiceArea } from '../../common-components/config-profile-panel';
import { asleep, config, readable } from '../../utilities';
import ASSIGNMENT_PROFILE_SORTING_GROUPS from './assignment-profile-sorting-groups';
import { AssignmentConfigProfile } from '../../models/assignment-models';
import { LaunchAssignmentServiceAreasResponse } from '../../http-clients/assignment-client';
import { assignmentClient, commonClient } from '../../http-clients';
import { ServiceAreaInfo } from '../../models';

const breadcrumbs = [
  {
    text: 'Launch/Disable Sites',
    href: '/assignment-launch-service-areas',
  },
];

interface AssignmentLaunchServiceAreasProps {}

interface AssignmentLaunchServiceAreasState {
  /**
   * We don't have an assignment service area info api yet, so calling getRoutingServiceAreas to get all the service areas in last mile.
   */
  readonly serviceAreas?: ReadonlyArray<ServiceAreaInfo>;
  readonly configProfiles?: ReadonlyArray<AssignmentConfigProfile>;
  readonly isLoadingServiceAreas: boolean;
  readonly isLoadingConfigProfiles: boolean;
}

export class AssignmentLaunchServiceAreas extends React.Component<AssignmentLaunchServiceAreasProps, AssignmentLaunchServiceAreasState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: AssignmentLaunchServiceAreasProps) {
    super(props);
    this.state = {
      isLoadingServiceAreas: false,
      isLoadingConfigProfiles: false,
    };
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.setTools('info-panel');
    this.context.updateBreadcrumbItems(breadcrumbs);
    this.loadingServiceAreas();
    this.loadingConfigProfiles();
  }

  private async loadingServiceAreas() {
    try {
      this.setState({ isLoadingServiceAreas: true });
      const resp = await commonClient.listServiceAreas();
      this.setState({
        serviceAreas: resp.serviceAreas,
      });
    } catch (err) {
      this.context.addNotification({
        header: 'Error',
        dismissible: true,
        type: 'error',
        content: `Failed to load service areas due to ${readable(err)}`,
      });
    } finally {
      this.setState({ isLoadingServiceAreas: false });
    }
  }

  private async loadingConfigProfiles() {
    try {
      this.setState({ isLoadingConfigProfiles: true });
      const resp = await assignmentClient.getAssignmentConfigProfiles({
        marketplace: config.marketplace,
      });
      this.setState({
        configProfiles: resp.assignmentConfigProfiles,
      });
    } catch (err) {
      this.context.addNotification({
        header: 'Error',
        dismissible: true,
        type: 'error',
        content: `Failed to load assignment config profiles due to ${readable(err)}`,
      });
    } finally {
      this.setState({ isLoadingConfigProfiles: false });
    }
  }

  render() {
    return (
      <ConfigProfilePanel
        profileType="assignment"
        operatingMode="launch"
        configProfiles={this.state.configProfiles?.map((profile) => this.convertAssignmentConfigProfileToConfigProfile(profile))}
        isLoadingConfigProfiles={this.state.isLoadingConfigProfiles}
        isLoadingServiceAreas={this.state.isLoadingServiceAreas}
        serviceAreasPerRequest={1}
        apply={async (request) => {
          if (request.profileType === 'assignment') {
            const resp = await assignmentClient.launchAssignmentServiceAreas({
              serviceAreaIds: request.serviceAreaIds,
              assignmentProfileName: request.configProfileKey,
              launchDescription: request.description,
              useExistingAssignmentConfig: request.useExistingAssignmentConfig,
            });
            const result = this.convertResult(resp);
            /**
             * For each launch, it updates DRIA cadence config in DRAS. Considering
             * DRAS is eventual consistency, we have to wait for a few seconds until the
             * DRIA cadence config update has been populated in DRAS, otherwise, the previous
             * change will be overridden by the next service area launch.
             */
            await asleep(10_000);
            return result;
          }
          throw new Error(`Assignment config profile UI can't process ${request.profileType} profile`);
        }}
        serviceAreas={this.state.serviceAreas?.map((sa) => ({
          serviceAreaId: sa.serviceAreaId,
          serviceAreaName: sa.serviceAreaName,
          stationCode: sa.stationCode,
        }))}
        filterServiceAreas={(filteringText, serviceAreas) => {
          const text = filteringText.trim().toLowerCase();
          if (text.length < 2) {
            // Starting filter after user enters 2 letters. We could return too many service areas if we don't have the restriction.
            return [];
          } else {
            return serviceAreas?.filter((sa) => this.match(sa, text));
          }
        }}
        serviceAreaFilteringPlaceholder="Enter 2 letters to start search"
        profileSortingGroups={ASSIGNMENT_PROFILE_SORTING_GROUPS}
        hasPermission={this.context.userAccesses ? this.context.userAccesses.includes('ASSIGNMENT_CONFIG_WRITE_ACCESS') : true}
        requestPermissionMessage={`You don't have permission to launch auto assign. Request "Update assignment configurations" permission.`}
        accessPermission="assignment"
      />
    );
  }

  private match(serviceArea: ServiceArea, text: string): boolean {
    return serviceArea.serviceAreaId.includes(text) || serviceArea.serviceAreaName.toLowerCase().includes(text) || serviceArea.stationCode.toLowerCase().startsWith(text);
  }

  private convertAssignmentConfigProfileToConfigProfile(profile: AssignmentConfigProfile): ConfigProfile {
    const lines: string[] = [];
    if (typeof profile.routingConfigDescription === 'string') {
      lines.push(profile.routingConfigDescription);
    }
    lines.push(profile.description);

    return {
      name: profile.name,
      description: lines.join('\n'),
      assignmentConfig: profile.assignmentConfig,
    };
  }

  private convertResult(resp: LaunchAssignmentServiceAreasResponse): ApplyProfileResults {
    const failedServiceAreaIdAndReasons: { [key: string]: string } = {};
    Object.entries(resp.failedReasonToServiceAreaIds).forEach((entry) => {
      entry[1].forEach((serviceAreaId) => {
        failedServiceAreaIdAndReasons[serviceAreaId] = entry[0];
      });
    });
    return {
      succeededServiceAreaIds: resp.succeededServiceAreaIds,
      failedServiceAreaIdAndReasons: failedServiceAreaIdAndReasons,
    };
  }
}
