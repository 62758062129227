import React from 'react';

import Alert from '@amzn/awsui-components-react-v3/polaris/alert';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import { CodeFreezeEventStatus, CodeFreezeSchedule } from '../models';
import Link from '@amzn/awsui-components-react-v3/polaris/link';
import Icon from '@amzn/awsui-components-react-v3/polaris/icon';

export interface CodeFreezeWarningProps {
  /**
   * multiple code freeze could be active at the same time, so it's an array.
   */
  readonly codeFreezes?: ReadonlyArray<CodeFreezeSchedule>;
}

export interface CodeFreezeWarningState {}

export default class CodeFreezeWarning extends React.Component<CodeFreezeWarningProps, CodeFreezeWarningState> {
  render() {
    if (this.props.codeFreezes === undefined || this.props.codeFreezes.length === 0) {
      return null;
    }

    let status: CodeFreezeEventStatus = 'restricted';
    for (let i = 0; i < this.props.codeFreezes.length; i++) {
      if (this.props.codeFreezes[i].status === 'blocked') {
        status = 'blocked';
        break;
      }
    }

    let endDate = this.props.codeFreezes[0].endDate;
    this.props.codeFreezes.forEach((codefreeze) => {
      endDate = codefreeze.endDate.localeCompare(endDate) < 0 ? endDate : codefreeze.endDate;
    });

    return (
      <Alert header={<Header variant="h2">{this.props.codeFreezes.map((freeze) => freeze.name).join(', ')} - Code Freeze in Effect</Header>} type="warning">
        <SpaceBetween direction="vertical" size="s">
          <Box fontWeight="bold" margin={{ top: 's' }}>
            <Icon name="status-stopped" variant="error" /> Absolutely NO site launches without L10 approval per{' '}
            <Link external={true} href="https://w.amazon.com/bin/view/LastMile/LMT_ChangeControl" target="_blank">
              the change control calendar!
            </Link>
          </Box>
          <Box>
            All config changes are currently{' '}
            <Box variant="span" fontWeight="bold">
              {status}
            </Box>{' '}
            through {endDate}. Making config changes during code freeze is{' '}
            <Box fontWeight="bold" variant="span">
              highly dangerous
            </Box>{' '}
            and{' '}
            <Box fontWeight="bold" variant="span">
              highly unadvised
            </Box>
            .
          </Box>
          <Box>
            If you{' '}
            <Box fontWeight="bold" variant="span">
              must
            </Box>{' '}
            make new changes, make sure you have your{' '}
            <Box variant="span" fontWeight="bold">
              {status === 'blocked' ? 'L10' : 'L8'}'s
            </Box>{' '}
            support and are ready to speak to why this change is critical.{' '}
            <Box fontWeight="bold" variant="span">
              The change along with your alias will be recorded.
            </Box>
          </Box>
          <Box>Weekly drive time updates and other scheduled changes that have been made successfully many times before should continue during code freeze.</Box>
          <Box>
            <Link external={true} href="https://w.amazon.com/bin/view/LastMile/LMT_ChangeControl" target="_blank">
              Learn more
            </Link>
          </Box>
        </SpaceBetween>
      </Alert>
    );
  }
}
