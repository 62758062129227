import React from 'react';

import Badge from '@amzn/awsui-components-react-v3/polaris/badge';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table';
import Popover from '@amzn/awsui-components-react-v3/polaris/popover';
import { ConfigAuditingTableItem } from './models';
import { BaseCommitRecord } from '../../models';
import { TIMEZONE_PREFERENCE_TO_LABEL, TimezonePreference, timezoneManager } from '../../utilities';

export interface BuildTableDefinitionProps {
  readonly timezonePreference: TimezonePreference;
}

export function buildTableDefinition(props: BuildTableDefinitionProps): ReadonlyArray<TableProps.ColumnDefinition<ConfigAuditingTableItem<BaseCommitRecord>>> {
  const columns: TableProps.ColumnDefinition<ConfigAuditingTableItem<BaseCommitRecord>>[] = [];

  columns.push({
    id: 'commitId',
    header: 'CommitId Prefix',
    cell: (item) => {
      const artfiactId = item.commit.commitId;
      return (
        <SpaceBetween direction="horizontal" size="xxs">
          <Popover dismissAriaLabel="Close" content={artfiactId}>
            {artfiactId.substring(0, 6)}
          </Popover>
          {item.label ? <Badge color="blue">{item.label}</Badge> : null}
        </SpaceBetween>
      );
    },
  });

  columns.push({
    id: 'lastUpdateAt',
    sortingField: 'lastUpdateAt',
    header: `Time (${TIMEZONE_PREFERENCE_TO_LABEL[props.timezonePreference]})`,
    cell: (item) => {
      return <Box>{timezoneManager.convertTimestampToString(item.commit.lastUpdateAt)}</Box>;
    },
    sortingComparator: (i1, i2) => i1.commit.lastUpdateAt - i2.commit.lastUpdateAt,
  });

  columns.push({
    id: 'userAlias',
    header: 'User',
    cell: (item) => {
      return <Box>{item.commit.userAlias ?? '-'}</Box>;
    },
  });

  columns.push({
    id: 'description',
    header: 'Description',
    cell: (item) => {
      return <Box>{item.commit.description ?? '-'}</Box>;
    },
  });

  columns.push({
    id: 'clientId',
    header: 'Client',
    cell: (item) => {
      let name = item.commit.clientId ?? '-';
      switch (item.commit.clientId) {
        case 'CONFIG_MANAGER':
        case 'ConfigManger':
        case 'ConfigManager':
          name = 'Config Manager';
          break;
      }
      return <Box>{name}</Box>;
    },
  });

  return columns;
}
