import { StageConfiguration } from '../models';
import { WebsiteConfigAccessor } from './website-config-accessor';
import { EnvironmentAccessor } from './environment-accessor';
import { CODE_FREEZE_SCHEDULES, LOCAL_TESTING_DOMAIN, MARKETPLACE_TO_CODE_FREEZE_TIMEZONE, stageConfigurations } from '../constants';
import { TimezoneManager } from './timezone-manager';
import { PreferencesStore } from './preferences-store';
import { MidwayIdentityCredentialProvider } from '@amzn/midway-identity-credential-provider';
import moment from 'moment-timezone';

export * from './timezone-manager';
export * from './formatter';
export * from './clipboard';
export * from './helper-functions';
export * from './apply-theme';

export const configAccessor = new WebsiteConfigAccessor(stageConfigurations);
export const environment = new EnvironmentAccessor().getEnvironment();

let _config: StageConfiguration;

if (environment.domain === LOCAL_TESTING_DOMAIN) {
  if (environment.identifier === undefined) {
    throw new Error(`Running in development mode, but cannot find stage identifier. Do you forget to setup $${EnvironmentAccessor.IDENTIFIER_ENV_KEY} env variable?`);
  }
  _config = configAccessor.getStageConfigurationById(environment.identifier);
} else {
  _config = configAccessor.getStageConfigurationByDomain(environment.domain);
}

export const credentials = new MidwayIdentityCredentialProvider(
  {
    IdentityPoolId: _config.identityPoolId,
  },
  {
    region: _config.awsRegion,
  },
);

export const config = _config;

export const timezoneManager = new TimezoneManager();

export const preferencesStore = new PreferencesStore();

const referenceTimestamp = Date.now();
export const ACTIVE_CODE_FREEZES = CODE_FREEZE_SCHEDULES.filter((codeFreeze) => codeFreeze.marketplace === config.marketplace).filter((codeFreeze) => {
  const timezone = MARKETPLACE_TO_CODE_FREEZE_TIMEZONE[codeFreeze.marketplace];
  const startDate = moment.tz(`${codeFreeze.startDate} 00:00:00`, timezone).toDate();
  const endDate = moment.tz(`${codeFreeze.endDate} 23:59:59`, timezone).toDate();
  return startDate.getTime() <= referenceTimestamp && referenceTimestamp <= endDate.getTime();
});
