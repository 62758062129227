import { PlaceholderTRDefaults, ServiceAreaConfig } from '../../../../models/routing-models';

/**
 * serviceAreaConfig.cubicVolumeEstimateOverride
 * serviceAreaConfig.placeholderTRDefaults
 *
 * example
 *
 * "cubicVolumeEstimateOverride": {
 *   "unit": "CU_FT",
 *   "value": 4.9583
 *  },
 * "placeholderTRDefaults": {
 *   "cubicVolumeEstimateOverride": {
 *     "unit": "CU_FT",
 *     "value": 4.9583
 *   },
 *   "weightEstimateOverride": null
 * },
 */

// assumes: CU_FT and LBs for units

export function updatePlaceholderTRDefaults(serviceAreaConfig: ServiceAreaConfig, update: PlaceholderTRDefaults): ServiceAreaConfig {
  // todo: check if need to update
  return {
    ...serviceAreaConfig,
    placeholderTRDefaults: update,
  };
}
