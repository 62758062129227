/**
 * Format seconds to human readable duration. Examples
 *
 * formatDuration(36.78) => '37s'
 * formatDuration(3602) => '1h 2s'
 * formatDuration(86558) => '1d 3m'
 * formatDuration(86558, 3) => '1d 2m 28s'
 * formatDuration(4334520) => '50d 4h'
 *
 * @param seconds duration in second.
 */

const DAY_IN_SECONDS = 24 * 3600;

export function formatDuration(seconds: number, precision?: number): string {
  if (seconds < 0) {
    return '-';
  }

  const results: string[] = [];
  _durationToSegments(Math.round(seconds), results, precision ?? 2);
  return results.join(' ');
}

function _durationToSegments(seconds: number, segments: string[], precision: number): void {
  if (segments.length >= precision) {
    return;
  }

  if (seconds >= DAY_IN_SECONDS) {
    if (segments.length + 1 === precision) {
      segments.push(`${Math.round(seconds / DAY_IN_SECONDS)}d`);
    } else {
      segments.push(`${Math.floor(seconds / DAY_IN_SECONDS)}d`);
      _durationToSegments(seconds % DAY_IN_SECONDS, segments, precision);
    }
  } else if (seconds >= 3600) {
    if (segments.length + 1 === precision) {
      segments.push(`${Math.round(seconds / 3600)}h`);
    } else {
      segments.push(`${Math.floor(seconds / 3600)}h`);
      _durationToSegments(seconds % 3600, segments, precision);
    }
  } else if (seconds >= 60) {
    if (segments.length + 1 === precision) {
      segments.push(`${Math.round(seconds / 60)}m`);
    } else {
      segments.push(`${Math.floor(seconds / 60)}m`);
      _durationToSegments(seconds % 60, segments, precision);
    }
  } else if (seconds > 0) {
    segments.push(`${seconds}s`);
  }
}

export function formatStringToEpochMillisecond(date: string | null): number | undefined {
  if (date === null) {
    return undefined;
  }
  const epochMs = new Date(date).getTime();
  return isNaN(epochMs) ? undefined : epochMs;
}
