import { DeliveryWindowOverride, ServiceAreaConfig } from '../../../../models/routing-models';
import lodash, { over } from 'lodash';

export function addOverride(serviceAreaConfig: ServiceAreaConfig, override: DeliveryWindowOverride): ServiceAreaConfig {
  const overrides: DeliveryWindowOverride[] = serviceAreaConfig.deliveryWindowOverrides ? Array.from(serviceAreaConfig.deliveryWindowOverrides) : [];
  overrides.push(override);
  return {
    ...serviceAreaConfig,
    deliveryWindowOverrides: overrides,
  };
}

export function deleteOverrides(serviceAreaConfig: ServiceAreaConfig, overrides: ReadonlyArray<DeliveryWindowOverride>): ServiceAreaConfig {
  const newOverrides = serviceAreaConfig.deliveryWindowOverrides?.filter((override) => {
    for (let i = 0; i < overrides.length; i++) {
      if (lodash.isEqual(override, overrides[i])) {
        return false;
      }
    }
    return true;
  });
  return {
    ...serviceAreaConfig,
    deliveryWindowOverrides: newOverrides,
  };
}

export function updateStartTime(serviceAreaConfig: ServiceAreaConfig, orderType: string | null, daysOfWeek: string | null, value: string): ServiceAreaConfig {
  const targetOverrideIndex = serviceAreaConfig.deliveryWindowOverrides?.findIndex((override) => override.daysOfWeek === daysOfWeek && override.orderType === orderType);
  if (targetOverrideIndex === undefined || targetOverrideIndex < 0) {
    return serviceAreaConfig;
  }

  const newOverrides = serviceAreaConfig.deliveryWindowOverrides ? Array.from(serviceAreaConfig.deliveryWindowOverrides) : [];
  newOverrides[targetOverrideIndex] = {
    ...newOverrides[targetOverrideIndex],
    startLocalTime: value,
  };

  return {
    ...serviceAreaConfig,
    deliveryWindowOverrides: newOverrides,
  };
}

export function updateEndTime(serviceAreaConfig: ServiceAreaConfig, orderType: string | null, daysOfWeek: string | null, value: string): ServiceAreaConfig {
  const targetOverrideIndex = serviceAreaConfig.deliveryWindowOverrides?.findIndex((override) => override.daysOfWeek === daysOfWeek && override.orderType === orderType);
  if (targetOverrideIndex === undefined || targetOverrideIndex < 0) {
    return serviceAreaConfig;
  }

  const newOverrides = serviceAreaConfig.deliveryWindowOverrides ? Array.from(serviceAreaConfig.deliveryWindowOverrides) : [];
  newOverrides[targetOverrideIndex] = {
    ...newOverrides[targetOverrideIndex],
    endLocalTime: value,
  };

  return {
    ...serviceAreaConfig,
    deliveryWindowOverrides: newOverrides,
  };
}
