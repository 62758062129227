import React from 'react';

import Header from '@amzn/awsui-components-react-v3/polaris/header';
import Table from '@amzn/awsui-components-react-v3/polaris/table';
import TextFilter from '@amzn/awsui-components-react-v3/polaris/text-filter';
import Pagination from '@amzn/awsui-components-react-v3/polaris/pagination';
import { ServiceArea, ServiceAreaListProps, ServiceAreaListState } from './models';
import { buildTableDefinition } from './build-table-definition';
import { refreshServiceAreaInDisplay } from './utilities';

const ITEMS_PER_PAGE = 30;

export class ServiceAreaList extends React.Component<ServiceAreaListProps, ServiceAreaListState> {
  constructor(props: ServiceAreaListProps) {
    super(props);
    const columns = buildTableDefinition(this.props);
    this.state = {
      filteringText: '',
      sortingColumn: undefined,
      sortColumnDescending: false,
      currentPage: 1,
      columns: columns,
      filteredAndSortedServiceAreas: refreshServiceAreaInDisplay({
        serviceAreas: this.props.serviceAreas,
        filteringText: '',
        sortingDescending: false,
        comparatorFunc: undefined,
      }),
    };
  }

  componentDidUpdate(prevProps: Readonly<ServiceAreaListProps>): void {
    if (this.props.serviceAreas !== prevProps.serviceAreas || this.props.itemsPerPage !== prevProps.itemsPerPage) {
      const serviceAreas = refreshServiceAreaInDisplay({
        serviceAreas: this.props.serviceAreas,
        filteringText: this.state.filteringText,
        sortingDescending: this.state.sortColumnDescending,
        comparatorFunc: this.state.sortingColumn?.sortingComparator,
      });
      this.setState({ filteredAndSortedServiceAreas: serviceAreas });
    }
  }

  render() {
    let itemsPerPage = this.props.itemsPerPage ?? ITEMS_PER_PAGE;
    const serviceAreaCount = this.state.filteredAndSortedServiceAreas?.length ?? 0;
    const pageCount = Math.max(1, Math.ceil(serviceAreaCount / itemsPerPage));

    const currentPage = this.state.currentPage;
    const serviceAreaInDisplay = this.state.filteredAndSortedServiceAreas?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    return (
      <Table<ServiceArea>
        loadingText="Loading service areas ..."
        stickyHeader={true}
        wrapLines={true}
        columnDefinitions={this.state.columns}
        items={serviceAreaInDisplay ?? []}
        header={<Header variant="h2">{this.props.header}</Header>}
        loading={serviceAreaInDisplay === undefined}
        filter={
          <TextFilter
            filteringPlaceholder="Filter by any field"
            filteringText={this.state.filteringText}
            filteringAriaLabel="Filter instances"
            onChange={({ detail }) => {
              this.setState({
                filteringText: detail.filteringText,
                currentPage: 1,
              });
            }}
            onDelayedChange={(evt) => {
              const serviceAreas = refreshServiceAreaInDisplay({
                serviceAreas: this.props.serviceAreas,
                filteringText: this.state.filteringText,
                sortingDescending: this.state.sortColumnDescending,
                comparatorFunc: this.state.sortingColumn?.sortingComparator,
              });
              this.setState({ filteredAndSortedServiceAreas: serviceAreas });
            }}
          />
        }
        onSortingChange={({ detail }) => {
          this.setState({
            sortColumnDescending: detail.isDescending,
            sortingColumn: detail.sortingColumn,
            currentPage: 1,
          });

          const serviceAreas = refreshServiceAreaInDisplay({
            serviceAreas: this.props.serviceAreas,
            filteringText: this.state.filteringText,
            sortingDescending: detail.isDescending,
            comparatorFunc: detail.sortingColumn.sortingComparator,
          });
          this.setState({ filteredAndSortedServiceAreas: serviceAreas });
        }}
        sortingColumn={this.state.sortingColumn}
        sortingDescending={this.state.sortColumnDescending}
        pagination={
          <Pagination
            currentPageIndex={this.state.currentPage}
            pagesCount={pageCount}
            onChange={(evt) => {
              this.setState({ currentPage: evt.detail.currentPageIndex });
            }}
            ariaLabels={{
              pageLabel: (idx) => `Page ${idx} of ${pageCount}.`,
            }}
          />
        }
      />
    );
  }
}
