import React from 'react';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Container from '@amzn/awsui-components-react-v3/polaris/container';
import Spinner from '@amzn/awsui-components-react-v3/polaris/spinner';
import RadioGroup from '@amzn/awsui-components-react-v3/polaris/radio-group';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import styles from './service-area-info.module.scss';
import { RoutingServiceArea, RoutingServiceAreaMetadata } from '../../../models/routing-models';

/**
 * Display ServiceAreaMetadata & ServiceAreaInfo
 */

type FleetKey = 'lmesFleet' | 'lmdpFleet';
type FleetValue = 'Onebox' | 'Prod';
type Cadence = 'CONTINUOUS' | 'ON_DEMAND';

export interface ServiceAreaInfoProps {
  readonly serviceAreaId: string;
  readonly serviceArea?: RoutingServiceArea;
  readonly isSaving: boolean;
  readonly isEditing: boolean;
  readonly serviceAreaMetadata?: RoutingServiceAreaMetadata;

  /**
   * Method is called when a user is typing to change configurations
   * @param serviceAreaConfig
   * @param algorithmSettings
   * @returns
   */
  readonly onUpdate: (serviceAreaMetadata: RoutingServiceAreaMetadata) => void;

  /**
   * Method is called when the "Save" button is clicked.
   * @returns
   */
  readonly onSave: () => void;

  /**
   * Method is called when the "Edit" button is clicked
   * @returns
   */
  readonly onEdit: () => void;

  /**
   * Method is called when the "Discard" button is clicked.
   */
  readonly onDiscard: () => void;
}

export interface ServiceAreaInfoState {}

export class ServiceAreaInfo extends React.Component<ServiceAreaInfoProps, ServiceAreaInfoState> {
  constructor(props: ServiceAreaInfoProps) {
    super(props);
    this.state = {
      isEditing: false,
      serviceAreaMetadata: this.props.serviceAreaMetadata,
    };
  }

  private renderFleetInput(key: FleetKey) {
    if (this.props.isEditing) {
      let items = [
        { label: 'Prod', value: 'Prod' },
        { label: 'OneBox', value: 'OneBox' },
      ];
      return (
        <RadioGroup
          value={this.props.serviceAreaMetadata ? this.props.serviceAreaMetadata[key] : null}
          items={items}
          onChange={(evt) => {
            const newFleet = evt.detail.value as FleetValue;
            if (this.props.serviceAreaMetadata !== undefined) {
              this.props.onUpdate({
                ...this.props.serviceAreaMetadata,
                [key]: newFleet,
              });
            }
          }}
        />
      );
    } else if (this.props.serviceAreaMetadata) {
      return this.props.serviceAreaMetadata[key];
    } else {
      return <Spinner />;
    }
  }

  private renderCadenceInput() {
    if (this.props.isEditing) {
      let items = [
        { label: 'Continue', value: 'CONTINUOUS' },
        { label: 'OnDemand', value: 'ON_DEMAND' },
      ];
      return (
        <RadioGroup
          value={this.props.serviceAreaMetadata ? this.props.serviceAreaMetadata['cadence'] : null}
          items={items}
          onChange={(evt) => {
            const newCadence = evt.detail.value as Cadence;
            if (this.props.serviceAreaMetadata !== undefined) {
              this.props.onUpdate({
                ...this.props.serviceAreaMetadata,
                cadence: newCadence,
              });
            }
          }}
        />
      );
    } else if (this.props.serviceAreaMetadata) {
      return this.props.serviceAreaMetadata['cadence'];
    } else {
      return <Spinner />;
    }
  }

  private renderHeaderBtnGroup() {
    if (this.props.isEditing) {
      return (
        <SpaceBetween direction="horizontal" size="xs">
          <Button disabled={this.disableBtn()} onClick={() => this.props.onDiscard()}>
            Discard
          </Button>
          <Button
            disabled={this.disableBtn()}
            onClick={async () => {
              if (this.props.serviceAreaMetadata !== undefined) {
                await this.props.onSave();
              }
            }}
            variant="primary"
          >
            Save
          </Button>
        </SpaceBetween>
      );
    } else {
      return (
        <Button disabled={this.disableBtn()} onClick={() => this.props.onEdit()}>
          Edit
        </Button>
      );
    }
  }

  private disableBtn() {
    return this.props.serviceAreaMetadata === undefined || this.props.isSaving;
  }

  render() {
    return (
      <Container
        disableContentPaddings
        header={
          <Header variant="h2" actions={this.renderHeaderBtnGroup()} description={this.props.serviceAreaId}>
            Service Area
          </Header>
        }
      >
        <Box className={styles['service-area-info-grid']}>
          <SpaceBetween direction="vertical" size="xxs">
            <Box color="text-body-secondary">Service Area Name</Box>
            {this.props.serviceArea ? <Box>{this.props.serviceArea.serviceAreaName}</Box> : <Spinner />}
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="xxs">
            <Box color="text-body-secondary">Station Code</Box>
            {this.props.serviceArea ? <Box>{this.props.serviceArea.stationCode}</Box> : <Spinner />}
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="xxs">
            <Box color="text-body-secondary">LMES Fleet</Box>
            <Box>{this.renderFleetInput('lmesFleet')}</Box>
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="xxs">
            <Box color="text-body-secondary">LMDP Fleet</Box>
            <Box>{this.renderFleetInput('lmdpFleet')}</Box>
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="xxs">
            <Box color="text-body-secondary">Cadence</Box>
            <Box>{this.renderCadenceInput()}</Box>
          </SpaceBetween>
        </Box>
      </Container>
    );
  }
}
