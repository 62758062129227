import React from 'react';
import lodash from 'lodash';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import { CapacityConstraint } from '../../../../models/routing-models';
import { CapacityConstraintUpdate } from './models';
import Table from '@amzn/awsui-components-react-v3/polaris/table';
import { CapacityConstraintsEditor } from './capacity-constraints-editor';
import { findUnitByName } from './utilities';
import { CAPACITY_TYPE_TO_CAPACITY_OPTION } from './constants';

interface CapacityConstraintsTableProps {
  readonly capacityConstraints: ReadonlyArray<CapacityConstraint>;
  readonly isEditing: boolean;
  readonly onUpdate: (capacityConstraintUpdate: CapacityConstraintUpdate) => void;
}

interface CapacityConstraintsTableState {}

export class CapacityConstraintsTable extends React.Component<CapacityConstraintsTableProps, CapacityConstraintsTableState> {
  constructor(props: CapacityConstraintsTableProps) {
    super(props);
    this.state = {};
  }

  private buildItems() {
    return this.props.capacityConstraints.map((constraint) => {
      const capacityOption = CAPACITY_TYPE_TO_CAPACITY_OPTION[constraint.type];
      const capacityUnit = findUnitByName(constraint.unit, capacityOption);
      return {
        constraintLabel: capacityOption?.label ?? 'Unknown',
        value: constraint.value,
        unitLabel: capacityUnit?.label ?? 'Unknown',
      };
    });
  }

  render() {
    if (this.props.isEditing) {
      return <CapacityConstraintsEditor capacityConstraints={this.props.capacityConstraints} onUpdate={this.props.onUpdate} />;
    }

    return (
      <Table
        header={<Header variant="h3">Route Capacity Limit</Header>}
        columnDefinitions={[
          {
            id: 'constraint',
            header: 'Constraint ',
            cell: (item) => item.constraintLabel,
          },
          {
            id: 'value',
            header: 'Value',
            cell: (item) => `${item.value} ${item.unitLabel}`,
          },
        ]}
        items={lodash.sortBy(this.buildItems(), (item) => item.constraintLabel)}
      />
    );
  }
}
