import React from 'react';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import FormField from '@amzn/awsui-components-react-v3/polaris/form-field';
import Input from '@amzn/awsui-components-react-v3/polaris/input';
import Modal from '@amzn/awsui-components-react-v3/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';

export interface ConfigUpdateConfirmModalProps {
  readonly isSaving: boolean;
  readonly scope: string;
  readonly onCancel: () => void;
  readonly onConfirm: (description: string) => void;
}

export interface ConfigUpdateConfirmModalState {
  readonly configUpdateDescription: string;
  readonly errorHint?: string;
}

export class ConfigUpdateConfirmModal extends React.Component<ConfigUpdateConfirmModalProps, ConfigUpdateConfirmModalState> {
  constructor(props: ConfigUpdateConfirmModalProps) {
    super(props);
    this.state = {
      configUpdateDescription: '',
    };
  }

  private renderFooter() {
    return (
      <Box float="right">
        <SpaceBetween direction="horizontal" size="xs">
          <Button onClick={() => this.props.onCancel()} disabled={this.props.isSaving}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={this.props.isSaving}
            onClick={() => {
              if (this.state.configUpdateDescription.length < 5) {
                this.setState({ errorHint: 'At least 5 characters' });
                return;
              } else if (this.state.configUpdateDescription.length > 200) {
                this.setState({ errorHint: 'At most 200 characters' });
                return;
              } else {
                this.setState({ errorHint: undefined });
              }
              this.props.onConfirm(this.state.configUpdateDescription);
            }}
          >
            Confirm
          </Button>
        </SpaceBetween>
      </Box>
    );
  }

  render() {
    return (
      <Modal visible={true} header="Confirm the change" onDismiss={() => this.props.onCancel()} footer={this.renderFooter()}>
        <FormField label="Description" description="The information will be used for auditing purposes. At least 5 characters" errorText={this.state.errorHint}>
          <Input
            value={this.state.configUpdateDescription}
            onChange={(evt) => {
              this.setState({ configUpdateDescription: evt.detail.value });
            }}
          />
        </FormField>
      </Modal>
    );
  }
}
