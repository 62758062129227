import { ServiceAreaConfig } from '../../../../models/routing-models';
import { OrderTypeUpdate } from './models';

export function updateIsolatedOrderTypes(serviceAreaConfig: ServiceAreaConfig, update: OrderTypeUpdate): ServiceAreaConfig {
  let existingIsolatedOrderTypes = serviceAreaConfig.isolatedOrderTypes ? Array.from(serviceAreaConfig.isolatedOrderTypes) : [];
  if (update.select && !existingIsolatedOrderTypes.includes(update.orderType)) {
    existingIsolatedOrderTypes.push(update.orderType);
  } else if (!update.select && existingIsolatedOrderTypes.includes(update.orderType)) {
    existingIsolatedOrderTypes = existingIsolatedOrderTypes.filter((type) => type !== update.orderType);
  }

  return {
    ...serviceAreaConfig,
    isolatedOrderTypes: existingIsolatedOrderTypes,
  };
}
