/**
 * References
 * https://code.amazon.com/packages/Aws-fsf-ipam-web-ui/blobs/mainline/--/src/components/common/FileUpload.tsx
 * https://v2-1.polaris.a2z.com/components/fileupload/
 */
import * as React from 'react';

import Button from '@amzn/awsui-components-react-v3/polaris/button';
import FormField from '@amzn/awsui-components-react-v3/polaris/form-field';
import StatusIndicator from '@amzn/awsui-components-react-v3/polaris/status-indicator';

export interface FileUploadProps {
  readonly label?: React.ReactNode;
  readonly accept?: string;
  readonly disabled?: boolean;
  readonly description?: React.ReactNode;
  readonly hintStatus?: 'success' | 'error';
  readonly hint?: string;
  readonly onFileUpload: (uploadedFile: File) => void;
}

export interface FileUploadState {
  readonly file?: File;
  readonly errorMessage?: string;
}

class FileUpload extends React.PureComponent<FileUploadProps, FileUploadState> {
  private hiddenFileInput = React.createRef<HTMLInputElement>();

  constructor(props: FileUploadProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <FormField label={this.props.label} description={this.props.description} constraintText={this.renderConstraintText()}>
        <input
          ref={this.hiddenFileInput}
          id="chooseFileInput"
          type="file"
          hidden
          multiple={false}
          accept={this.props.accept}
          // @ts-ignore
          onChange={(event) => this.props.onFileUpload(event.target.files[0])}
        />
        <Button
          iconName="upload"
          formAction="none"
          disabled={typeof this.props.disabled === 'boolean' ? this.props.disabled : false}
          onClick={() => {
            // @ts-ignore
            this.hiddenFileInput.current.value = null; // allow for re-choosing the same file name
            // @ts-ignore
            this.hiddenFileInput.current.click();
          }}
        >
          Upload File
        </Button>
      </FormField>
    );
  }

  private renderConstraintText() {
    if (this.props.hint !== undefined) {
      return <StatusIndicator type={this.props.hintStatus}>{this.props.hint}</StatusIndicator>;
    }
  }
}

export default FileUpload;
