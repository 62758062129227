import React from 'react';
import { GlobalContext } from './global-context';
import { Route, Switch } from 'react-router';
import WelcomePage from '../components/welcome-page';
import RoutingServiceAreaList from '../components/routing-service-area-list';
import NotFoundPage from '../components/not-found-page';
import RoutingLaunchServiceAreas from '../components/routing-launch-service-areas';
import RoutingConfigProfile from '../components/routing-config-profiles';
import RoutingServiceAreaDetails from '../components/routing-service-area-details';
import AssignmentServiceAreaList from '../components/assignment-service-area-list';
import RoutingConfigAuditing from '../components/routing-config-auditing';
import AssignmentLaunchServiceAreas from '../components/assignment-launch-service-areas';
import AssignmentServiceAreaDetails from '../components/assignment-service-area-details';
import RoutingSiteProgramPage from '../components/routing-site-program-page';
import LaborPlanningServiceAreaList from '../components/labor-planning-service-area-list';
import FeatureFlagFlipper from '../components/feature-flag-flipper';
import DriaScopeList from '../components/dria-scope-list-page';
import DriaScopeDetails from '../components/dria-scope-details';
import ConfigSchedules from '../components/config-schedules';

export class Routes extends React.Component<{}, {}> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  render() {
    return (
      <Switch>
        <Route exact path="/" render={() => <WelcomePage />} />

        <Route
          exact
          path="/service-areas"
          render={() => {
            return <RoutingServiceAreaList />;
          }}
        />

        <Route
          exact
          path="/config-profiles"
          render={(props) => {
            return <RoutingConfigProfile />;
          }}
        />

        <Route exact path="/launch-service-areas" render={() => <RoutingLaunchServiceAreas />} />

        <Route exact path="/config-auditing" render={() => <RoutingConfigAuditing />} />

        <Route
          path="/service-areas/:serviceAreaId"
          render={(props) => {
            const params = new URLSearchParams(props.location.search);

            return (
              <RoutingServiceAreaDetails
                serviceAreaId={props.match.params['serviceAreaId']}
                activeTabId={params.get('tab') ?? undefined}
                switchTab={(tabId) => {
                  props.history.push(`${props.location.pathname}?tab=${tabId}`);
                }}
              />
            );
          }}
        />

        <Route
          exact
          path="/site-program-type"
          render={() => {
            return <RoutingSiteProgramPage />;
          }}
        />

        <Route
          path="/assignment-service-areas/:serviceAreaId"
          render={(props) => {
            const params = new URLSearchParams(props.location.search);
            return (
              <AssignmentServiceAreaDetails
                serviceAreaId={props.match.params['serviceAreaId']}
                activeTabId={params.get('tab') ?? undefined}
                switchTab={(tabId: string) => {
                  props.history.push(`${props.location.pathname}?tab=${tabId}`);
                }}
              />
            );
          }}
        />

        <Route exact path="/assignment-launch-service-areas" render={() => <AssignmentLaunchServiceAreas />} />

        <Route exact path="/config-schedules" render={() => <ConfigSchedules />} />

        <Route exact path="/assignment-service-areas" render={() => <AssignmentServiceAreaList />} />

        <Route exact path="/labor-planning-service-areas" render={() => <LaborPlanningServiceAreaList />} />

        <Route exact path="/routing-feature-flag-flipper" render={() => <FeatureFlagFlipper />} />

        <Route exact path="/dria-scopes" render={() => <DriaScopeList />} />

        <Route
          exact
          path="/dria-scopes/:scopeType/:scopeId"
          render={(props) => {
            const params = new URLSearchParams(props.location.search);
            return (
              <DriaScopeDetails
                scopeId={props.match.params.scopeId}
                scopeType={props.match.params.scopeType}
                activeTabId={params.get('tab') ?? undefined}
                switchTab={(tabId: string) => {
                  props.history.push(`${props.location.pathname}?tab=${tabId}`);
                }}
              />
            );
          }}
        />

        <Route
          render={(props) => {
            return <NotFoundPage path={props.location.pathname} />;
          }}
        />
      </Switch>
    );
  }
}
