import { ConfigTableDefinition } from '../../../models';

export const PROVIDER_SNAPSHOT: ConfigTableDefinition = {
  headerText: 'Provider Snapshot Configurations',
  valueColMinWidth: '150px',
  sizeOnGrid: 'default',
  items: [
    {
      field: 'providerReservationTimeRange.lookbackMinutes',
      scope: 'driaConfig',
      name: 'Block Reservation Lookback Horizon (Minutes)',
      representationType: 'number',
      validators: [
        {
          name: 'ValueBetween0And900Validator',
        },
      ],
    },
    {
      field: 'providerReservationTimeRange.lookaheadMinutes',
      scope: 'driaConfig',
      name: 'Block Reservation Lookahead Horizon (Minutes)',
      description: 'Along with lookback horizon, the two parameters define a time window used to query block reservations.',
      representationType: 'number',
      validators: [
        {
          name: 'ValueBetween0And900Validator',
        },
      ],
    },
    {
      field: 'populateNurseryLevel',
      scope: 'driaConfig',
      name: 'Populate Nursery Level',
      description: "If enabled, populate block's serviceTypeId from the daily uploaded nursery file. The flag is for AMZL JP AA.",
      representationType: 'boolean',
    },
    {
      field: 'createOverflowProviders',
      scope: 'driaConfig',
      name: 'Create Overflow Providers',
      description: 'If enabled, create overflow providers from Tywin. The flag is for Grocery RLP.',
      representationType: 'boolean',
    },
  ],
};
