import React from 'react';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Toggle from '@amzn/awsui-components-react-v3/polaris/toggle';

/* Toggle, with two methods to disable.
 * We want some labels clearly visible
 * when viewing page in read-only mode.
 */

export type ToggleStatus = 'NOTHING' | 'SWITCH_ONLY' | 'SWITCH_AND_LABEL';

interface ToggleWithLabelProps {
  readonly label: string;
  readonly checked: boolean;
  readonly whatDisabled: ToggleStatus;
  readonly onChange: (newValue: boolean) => void;
}

interface ToggleWithLabelState {}

export class ToggleWithLabel extends React.Component<ToggleWithLabelProps, ToggleWithLabelState> {
  render() {
    if (this.props.whatDisabled === 'SWITCH_ONLY') {
      return (
        <SpaceBetween direction="horizontal" size="xs">
          <Toggle checked={this.props.checked} disabled={true} />
          <Box>{this.props.label}</Box>
        </SpaceBetween>
      );
    } else {
      return (
        <Toggle checked={this.props.checked} disabled={this.props.whatDisabled === 'SWITCH_AND_LABEL'} onChange={(evt) => this.props.onChange(evt.detail.checked)}>
          {this.props.label}
        </Toggle>
      );
    }
  }
}
