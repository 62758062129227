/**
 * path /config-auditing
 * This component shows a wholistic view of the latest configuration updates on all sites.
 */

import React from 'react';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import { EnrichedCommitRecord } from './models';
import { TIMEZONE_PREFERENCE_TO_LABEL, TimezonePreference, timezoneManager } from '../../utilities';

export interface TableDefinitionProps {
  readonly timezonePreference: TimezonePreference;
}

export function buildTableDefinition(props: TableDefinitionProps): TableProps.ColumnDefinition<EnrichedCommitRecord>[] {
  const columns: TableProps.ColumnDefinition<EnrichedCommitRecord>[] = [];
  columns.push({
    id: 'stationCode',
    header: 'SA Code',
    cell: (item: EnrichedCommitRecord) => <Box>{item.stationCode}</Box>,
  });

  columns.push({
    id: 'lastUpdateAt',
    header: `Time (${TIMEZONE_PREFERENCE_TO_LABEL[props.timezonePreference]})`,
    cell: (item) => (item.lastUpdateAt === 0 ? <Box>-</Box> : <Box>{timezoneManager.convertTimestampToString(item.lastUpdateAt)}</Box>),
  });
  columns.push({
    id: 'userAlias',
    header: 'User alias',
    cell: (item) => {
      return <Box>{item.userAlias}</Box>;
    },
  });
  columns.push({
    id: 'description',
    header: 'Description',
    cell: (item) => {
      return <Box>{item.description}</Box>;
    },
  });
  columns.push({
    id: 'clientId',
    header: 'Client',
    cell: (item) => {
      return <Box>{item.clientId}</Box>;
    },
  });

  return columns;
}
