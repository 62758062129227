import React from 'react';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import FormField from '@amzn/awsui-components-react-v3/polaris/form-field';
import Input from '@amzn/awsui-components-react-v3/polaris/input';
import Modal from '@amzn/awsui-components-react-v3/polaris/modal';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import StatusIndicator from '@amzn/awsui-components-react-v3/polaris/status-indicator';
import Toggle from '@amzn/awsui-components-react-v3/polaris/toggle';
import { ApplyProfileRequest, OperatingMode, ProfileType, ServiceArea } from './models';

export interface ConfigProfileModalProps {
  readonly profileType: ProfileType;
  readonly operatingMode: OperatingMode;

  readonly selectedConfigProfileKey?: string;
  readonly selectedServiceAreas: ReadonlyArray<ServiceArea>;
  readonly onDismiss: () => void;
  readonly onConfirm: (request: ApplyProfileRequest) => void;
}

export interface ConfigProfileModalState {
  readonly description: string;
  readonly errorHint?: string;

  /**
   * Routing profile specific configs
   */
  readonly isPEAutoAssignEnabled: boolean;

  /**
   * Assignment profile specific configs.
   */
  readonly useExistingAssignmentConfig: boolean;
}

export class ConfigProfileModal extends React.Component<ConfigProfileModalProps, ConfigProfileModalState> {
  constructor(props: ConfigProfileModalProps) {
    super(props);
    this.state = {
      description: '',
      isPEAutoAssignEnabled: false,
      useExistingAssignmentConfig: false,
    };
  }

  render() {
    return (
      <Modal
        visible={true}
        header="Confirmation"
        onDismiss={() => this.props.onDismiss()}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={() => this.props.onDismiss()}>Cancel</Button>
              <Button
                variant="primary"
                disabled={this.props.selectedConfigProfileKey === undefined || this.props.selectedServiceAreas.length === 0}
                onClick={() => {
                  const requestOrErrorMessage = this.assertAndAssembleRequest();

                  if (typeof requestOrErrorMessage === 'string') {
                    this.setState({ errorHint: requestOrErrorMessage });
                  } else {
                    this.setState({ errorHint: undefined });
                    this.props.onConfirm(requestOrErrorMessage);
                    this.props.onDismiss();
                  }
                }}
              >
                Confirm
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        {this.renderConfirmationBody()}
      </Modal>
    );
  }

  /**
   * assemble confirmation, or return an error message if it can't assemble the confirmation.
   */
  private assertAndAssembleRequest(): string | ApplyProfileRequest {
    const description = this.state.description;
    if (description.length < 5) {
      return 'At least 5 characters';
    } else if (description.length > 200) {
      return 'At most 200 characters';
    }

    const serviceAreaIds = this.props.selectedServiceAreas.map((sa) => sa.serviceAreaId);
    if (serviceAreaIds.length === 0) {
      return 'At least one service area needs to be selected';
    }

    const configProfile = this.props.selectedConfigProfileKey;
    if (typeof configProfile !== 'string') {
      return 'Need to select a config profile';
    }

    if (this.props.profileType === 'routing') {
      return {
        profileType: 'routing',
        description: description,
        isPEAutoAssignEnabled: this.state.isPEAutoAssignEnabled,
        serviceAreaIds: serviceAreaIds,
        configProfileKey: configProfile,
      };
    } else if (this.props.profileType === 'assignment') {
      return {
        profileType: 'assignment',
        description: description,
        useExistingAssignmentConfig: this.state.useExistingAssignmentConfig,
        serviceAreaIds: serviceAreaIds,
        configProfileKey: configProfile,
      };
    } else {
      return `Invalid profile type ${this.props.profileType}`;
    }
  }

  private renderConfirmationBody() {
    if (this.props.selectedConfigProfileKey === undefined) {
      return <StatusIndicator type="info">You haven't selected a config profile.</StatusIndicator>;
    } else if (this.props.selectedServiceAreas.length === 0) {
      return <StatusIndicator type="info">You haven't selected any service areas.</StatusIndicator>;
    } else {
      return (
        <SpaceBetween direction="vertical" size="l">
          {this.renderSummary()}

          <SpaceBetween direction="vertical" size="s">
            {this.props.selectedServiceAreas.map((serviceArea, index) => (
              <Box key={index}>{`${index + 1}. ${serviceArea.stationCode} - ${serviceArea.serviceAreaId}`}</Box>
            ))}
          </SpaceBetween>

          {this.renderProfileTypeSpecificElements()}

          <FormField label="Description" description="The information will be used for auditing purpose. At least 5 characters" errorText={this.state.errorHint}>
            <Input
              value={this.state.description}
              onChange={(evt) => {
                this.setState({ description: evt.detail.value });
              }}
            />
          </FormField>
        </SpaceBetween>
      );
    }
  }

  private renderSummary() {
    return (
      <Box>
        {this.props.operatingMode === 'launch' ? 'Launch' : 'Apply'} {this.props.profileType} profile{' '}
        <Box display="inline" fontWeight="bold">
          {this.props.selectedConfigProfileKey}
        </Box>{' '}
        on {this.props.selectedServiceAreas.length} site(s)
      </Box>
    );
  }

  private renderProfileTypeSpecificElements() {
    if (this.props.profileType === 'routing' && this.props.operatingMode === 'launch') {
      return (
        <SpaceBetween direction="horizontal" size="s">
          <Box>Enable PE Auto-Assign</Box>
          <Box>
            <Toggle
              checked={this.state.isPEAutoAssignEnabled}
              onChange={(evt) => {
                this.setState({ isPEAutoAssignEnabled: evt.detail.checked });
              }}
            />
          </Box>
        </SpaceBetween>
      );
    } else if (this.props.profileType === 'assignment') {
      return (
        <FormField constraintText="The flag is typically used when re-enabling service areas after a LSE. Contact flex-assignment team if you has questions.">
          <SpaceBetween direction="horizontal" size="s">
            <Box>Keep existing assignment configs</Box>
            <Box>
              <Toggle
                checked={this.state.isPEAutoAssignEnabled}
                onChange={(evt) => {
                  this.setState({ isPEAutoAssignEnabled: evt.detail.checked });
                }}
              />
            </Box>
          </SpaceBetween>
        </FormField>
      );
    }
  }
}
