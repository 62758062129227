/**
 * path /dria-scopes
 */
import React from 'react';
import { GlobalContext } from '../../main-app/global-context';
import { readable } from '../../utilities';
import { commonClient, driaClient } from '../../http-clients';
import { EnrichedDriaScopeInfo } from './models';
import { DriaScopeTable } from './dria-scope-table';
import { buildDriaScopeDetailsUrl } from './utilities';
import { ServiceAreaInfo } from '../../models';

export interface DriaScopeListPageProps {}

export interface DriaScopeListPageState {
  readonly isLoading: boolean;
  readonly scopeInfos?: ReadonlyArray<EnrichedDriaScopeInfo>;
}

export class DriaScopeListPage extends React.Component<DriaScopeListPageProps, DriaScopeListPageState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: DriaScopeListPageProps) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.setTools('info-panel');
    this.context.updateBreadcrumbItems([
      {
        text: 'DRIA Regions and Service Areas',
        href: '/dria-scopes',
      },
    ]);
    await this.loadDriaScopeList();
  }

  private async loadDriaScopeList() {
    try {
      this.setState({ isLoading: true });
      const resp = await driaClient.getDriaScopeInfoList({});
      const serviceAreasResponse = await commonClient.listServiceAreas();
      const serviceAreaIdToServiceArea: Map<string, ServiceAreaInfo> = new Map();
      serviceAreasResponse.serviceAreas.forEach((serviceArea) => serviceAreaIdToServiceArea.set(serviceArea.serviceAreaId, serviceArea));

      // todo: enrich the scope info with name and region code/station code.
      this.setState({
        scopeInfos: resp.scopeInfos.map((info) => {
          if (info.scopeType === 'REGION') {
            // todo: enrich region scope with region name
            return info;
          } else {
            const enriched: EnrichedDriaScopeInfo = {
              ...info,
              name: serviceAreaIdToServiceArea.get(info.scopeId)?.serviceAreaName,
              stationCode: serviceAreaIdToServiceArea.get(info.scopeId)?.stationCode,
            };
            return enriched;
          }
        }),
      });
    } catch (err) {
      this.context.addNotification({
        header: 'Error',
        content: `Failed to load dria scopes due to ${readable(err)}`,
        type: 'error',
        dismissible: true,
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <DriaScopeTable
        items={this.state.scopeInfos}
        onScopeClick={(scope) => {
          this.context.onGoto(buildDriaScopeDetailsUrl(scope));
        }}
      />
    );
  }
}
