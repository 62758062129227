import React from 'react';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import ButtonDropdown from '@amzn/awsui-components-react-v3/polaris/button-dropdown';
import Checkbox from '@amzn/awsui-components-react-v3/polaris/checkbox';
import FormField from '@amzn/awsui-components-react-v3/polaris/form-field';
import Modal from '@amzn/awsui-components-react-v3/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import TimeInput from '@amzn/awsui-components-react-v3/polaris/time-input';
import lodash from 'lodash';
import { PlanningHorizonConfigOverride, OrderType } from '../../../../models/routing-models';
import { IntegerInput } from '../../../../common-components/numeric-input';
import { PlanningHorizonType } from './models';
import { ORDER_TYPE_TO_LABEL, ORDER_TYPE_OPTIONS } from '../constants';
import { isNil } from '../../../../utilities';

const MIN_STAGED_PLANNING_HORIZON = 0;
const MIN_LOCKING = 0;
const MIN_NON_STAGED_PLANNING_HORIZON = -360;

function isEmptyString(value: string | undefined | null) {
  return value === null || value === undefined || value.length === 0;
}

interface PlanningHorizonOverrideModalProps {
  readonly onDismiss: () => void;
  readonly onNewOverride: (override: PlanningHorizonConfigOverride) => void;
  readonly currentOverrides: ReadonlyArray<PlanningHorizonConfigOverride>;
}

interface PlanningHorizonOverrideModalState {
  readonly inputType: PlanningHorizonType;
  readonly inputOverride: PlanningHorizonConfigOverride;
  readonly inputHint?: string;
}

export class PlanningHorizonOverrideModal extends React.Component<PlanningHorizonOverrideModalProps, PlanningHorizonOverrideModalState> {
  constructor(props: PlanningHorizonOverrideModalProps) {
    super(props);
    this.state = {
      inputType: 'normal',
      inputOverride: {
        effectiveForAllTypes: false,
        effectiveAllDay: false,
        orderType: null,
        startLocalTime: null,
        endLocalTime: null,
        horizonConfig: {
          lockOffsetMinutes: 0,
          nonStagedPlanningHorizonMinutes: 0,
          stagedPlanningHorizonMinutes: 0,
        },
        timeBasedHorizonConfig: {
          stagedDeliveryWindowCutOffLocalTime: '',
          lockCutOffLocalTime: '',
        },
      },
    };
  }

  render() {
    return (
      <Modal
        visible={true}
        header="Add an override"
        onDismiss={this.props.onDismiss}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={this.props.onDismiss}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  const override = this.validateAndGetOverride();
                  if (override) {
                    this.props.onNewOverride(this.getNewOverride());
                    this.props.onDismiss();
                  }
                }}
              >
                Add
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        {this.renderModalContent()}
      </Modal>
    );
  }

  private renderModalContent() {
    return (
      <SpaceBetween direction="vertical" size="s">
        <FormField label="Input Format">
          <ButtonDropdown
            items={[
              { text: 'Normal', id: 'normal' },
              { text: 'Time Based', id: 'timebased' },
            ]}
            onItemClick={(evt) => {
              this.setState({ inputType: evt.detail.id === 'normal' ? 'normal' : 'timebased' });
            }}
          >
            {this.state.inputType === 'normal' ? 'Normal' : 'Time Based'}
          </ButtonDropdown>
        </FormField>

        <SpaceBetween direction="horizontal" size="s">
          <Checkbox
            checked={this.state.inputOverride.effectiveForAllTypes}
            onChange={(evt) => {
              let inputOverride = { ...this.state.inputOverride };
              inputOverride.effectiveForAllTypes = evt.detail.checked;
              this.setState({ inputOverride });
            }}
          >
            All order types effective
          </Checkbox>
          <ButtonDropdown
            disabled={this.state.inputOverride.effectiveForAllTypes}
            items={ORDER_TYPE_OPTIONS}
            onItemClick={(evt) => {
              const inputOverride = {
                ...this.state.inputOverride,
                orderType: evt.detail.id as OrderType,
              };
              this.setState({ inputOverride });
            }}
          >
            {this.state.inputOverride.orderType ? ORDER_TYPE_TO_LABEL[this.state.inputOverride.orderType] : 'Select an order type'}
          </ButtonDropdown>
        </SpaceBetween>
        <SpaceBetween direction="horizontal" size="s">
          <Checkbox
            checked={this.state.inputOverride.effectiveAllDay}
            onChange={(evt) => {
              let inputOverride = { ...this.state.inputOverride };
              inputOverride.effectiveAllDay = evt.detail.checked;
              this.setState({ inputOverride });
            }}
          >
            All day effective
          </Checkbox>
          <TimeInput
            disabled={this.state.inputOverride.effectiveAllDay}
            value={this.state.inputOverride.startLocalTime ?? ''}
            format="hh:mm"
            placeholder="start time e.g. 10:00"
            onChange={(evt) => {
              let inputOverride = { ...this.state.inputOverride };
              inputOverride.startLocalTime = evt.detail.value;
              this.setState({ inputOverride });
            }}
          />
          <TimeInput
            disabled={this.state.inputOverride.effectiveAllDay}
            value={this.state.inputOverride.endLocalTime ?? ''}
            format="hh:mm"
            placeholder="end time e.g. 10:05"
            onChange={(evt) => {
              let inputOverride = { ...this.state.inputOverride };
              inputOverride.endLocalTime = evt.detail.value;
              this.setState({ inputOverride });
            }}
          />
        </SpaceBetween>
        {this.renderStagePlanningHorizonInput()}
        {this.renderNonStagePlanningHorizonInput()}
        {this.renderLockOffsetMinutes()}
        {this.state.inputHint ? <FormField errorText={this.state.inputHint} /> : null}
      </SpaceBetween>
    );
  }

  private validateAndGetOverride(): PlanningHorizonConfigOverride | undefined {
    // effectiveForAllTypes and effectiveAllDay can't be both true.
    let inputOverride = this.state.inputOverride;
    if (inputOverride.effectiveForAllTypes && inputOverride.effectiveAllDay) {
      this.setState({ inputHint: "An override can't be both all type effective and all day effective." });
      return;
    }
    // orderType must exist if it's not all type effective.
    if (!inputOverride.effectiveForAllTypes && isNil(inputOverride.orderType)) {
      this.setState({ inputHint: "Order type must exist if it's not all type effective." });
      return;
    }
    // effective time must exist if it's not all day effective.
    if (!inputOverride.effectiveAllDay && (isEmptyString(inputOverride.startLocalTime) || isEmptyString(inputOverride.endLocalTime))) {
      this.setState({ inputHint: "Effective time must exist if it's not all day effective." });
      return;
    }
    //
    if (
      this.state.inputType === 'timebased' &&
      (inputOverride.timeBasedHorizonConfig == null ||
        inputOverride.timeBasedHorizonConfig.lockCutOffLocalTime.length === 0 ||
        inputOverride.timeBasedHorizonConfig.stagedDeliveryWindowCutOffLocalTime.length === 0)
    ) {
      this.setState({ inputHint: 'Make sure staged planning horizon and locking are not empty.' });
      return;
    }

    let override = this.getNewOverride();

    for (let i = 0; i < this.props.currentOverrides.length; i++) {
      if (lodash.isEqual(override, this.props.currentOverrides[i])) {
        this.setState({ inputHint: 'Override already existed.' });
        return;
      }
    }
    this.setState({ inputHint: undefined });
    return override;
  }

  private getNewOverride(): PlanningHorizonConfigOverride {
    let override = { ...this.state.inputOverride };
    if (this.state.inputType === 'normal') {
      override.timeBasedHorizonConfig = null;
    } else if (this.state.inputType === 'timebased') {
      override.horizonConfig = null;
    }
    if (override.effectiveAllDay) {
      override.endLocalTime = null;
      override.startLocalTime = null;
    }
    if (override.effectiveForAllTypes) {
      override.orderType = null;
    }
    return override;
  }

  private renderStagePlanningHorizonInput() {
    return (
      <FormField label="Staged Planning Horizon">
        {this.state.inputType === 'normal' ? (
          <IntegerInput
            startingText={this.state.inputOverride.horizonConfig!.stagedPlanningHorizonMinutes.toString()}
            minimum={MIN_STAGED_PLANNING_HORIZON}
            onChange={(value) => {
              const inputOverride: PlanningHorizonConfigOverride = {
                ...this.state.inputOverride,
                horizonConfig: {
                  ...this.state.inputOverride.horizonConfig!,
                  stagedPlanningHorizonMinutes: value,
                },
              };

              this.setState({ inputOverride });
            }}
          />
        ) : (
          <TimeInput
            format="hh:mm"
            value={this.state.inputOverride.timeBasedHorizonConfig!.stagedDeliveryWindowCutOffLocalTime}
            placeholder="hh:mm"
            onChange={(evt) => {
              const inputOverride = {
                ...this.state.inputOverride,
                timeBasedHorizonConfig: {
                  ...this.state.inputOverride.timeBasedHorizonConfig!,
                  stagedDeliveryWindowCutOffLocalTime: evt.detail.value,
                },
              };
              this.setState({ inputOverride });
            }}
          />
        )}
      </FormField>
    );
  }

  private renderNonStagePlanningHorizonInput() {
    return (
      <FormField label="Non-Staged Planning Horizon">
        <IntegerInput
          disabled={this.state.inputType !== 'normal'}
          startingText={this.state.inputOverride.horizonConfig!.nonStagedPlanningHorizonMinutes.toString()}
          minimum={MIN_NON_STAGED_PLANNING_HORIZON}
          onChange={(value) => {
            const inputOverride = {
              ...this.state.inputOverride,
              horizonConfig: {
                ...this.state.inputOverride.horizonConfig!,
                nonStagedPlanningHorizonMinutes: value,
              },
            };
            this.setState({ inputOverride });
          }}
        />
      </FormField>
    );
  }

  private renderLockOffsetMinutes() {
    return (
      <FormField label="Locking Offset">
        {this.state.inputType === 'normal' ? (
          <IntegerInput
            startingText={this.state.inputOverride.horizonConfig!.lockOffsetMinutes.toString()}
            minimum={MIN_LOCKING}
            onChange={(value) => {
              const inputOverride = {
                ...this.state.inputOverride,
                horizonConfig: {
                  ...this.state.inputOverride.horizonConfig!,
                  lockOffsetMinutes: value,
                },
              };
              this.setState({ inputOverride });
            }}
          />
        ) : (
          <TimeInput
            format="hh:mm"
            value={this.state.inputOverride.timeBasedHorizonConfig!.lockCutOffLocalTime}
            placeholder="hh:mm"
            onChange={(evt) => {
              const inputOverride = {
                ...this.state.inputOverride,
                timeBasedHorizonConfig: {
                  ...this.state.inputOverride.timeBasedHorizonConfig!,
                  lockCutOffLocalTime: evt.detail.value,
                },
              };
              this.setState({ inputOverride });
            }}
          />
        )}
      </FormField>
    );
  }
}
