import React from 'react';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import FormField from '@amzn/awsui-components-react-v3/polaris/form-field';
import FileUpload from '@amzn/awsui-components-react-v3/polaris/file-upload';
import Modal from '@amzn/awsui-components-react-v3/polaris/modal';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import Link from '@amzn/awsui-components-react-v3/polaris/link';
import { commonClient, configScheduleClient } from '../../http-clients';
import { BulkUploadConfigScheduleInfo, ConfigScheduleInfo } from '../../http-clients/config-schedule-client';
import Alert from '@amzn/awsui-components-react-v3/polaris/alert';

const SERVICE_AREA_FILE_EXAMPLE_LINK = 'https://drive.corp.amazon.com/documents/rajurit@/gsf_routing_bulk_upload_example.csv';

export interface BulkUploadModalProps {
  readonly onDismiss: () => void;
  readonly onSubmitSuccess: (scopeId: string) => void;
}

export interface BulkUploadState {
  readonly fileName: ReadonlyArray<File>;
  readonly csvContent: string;
  readonly error?: string;
  readonly loading: boolean;
  readonly dataMap: ReadonlyArray<BulkUploadConfigScheduleInfo>;
}

export class BulkUploadModal extends React.Component<BulkUploadModalProps, BulkUploadState> {
  constructor(props: BulkUploadModalProps) {
    super(props);
    this.state = {
      fileName: [],
      csvContent: '',
      error: undefined,
      loading: false,
      dataMap: [],
    };
  }

  hasDuplicates(items: ConfigScheduleInfo[]): string {
    const seen = new Set();
    for (const item of items) {
      const key = `${item.day}-${item.hour}-${item.scopeId}`;
      if (seen.has(key)) {
        return key;
      }
      seen.add(key);
    }
    return '';
  }

  async validateCsv(text: string, rows: string[]) {
    if (rows.length > 0) {
      const headers = rows[0].split(',');
      const requiredHeaders = ['stationCode', 'setting', 'hour', 'day'];
      const hasAllHeaders = requiredHeaders.every((header) => headers.includes(header));

      if (!hasAllHeaders) {
        this.setState({ error: `CSV file must contain the following columns: ${requiredHeaders.join(', ')}` });
        return;
      }

      try {
        const seenCombinations: { [key: string]: boolean } = {};
        const serviceAreasResp = await commonClient.listServiceAreas();
        const validStationCodes = new Set(serviceAreasResp.serviceAreas.map((area) => area.stationCode));

        const items: BulkUploadConfigScheduleInfo[] = rows.slice(1).map((row, index) => {
          const values = row.split(',');

          const stationCode = values[0].trim();

          // Input validation
          if (values.length !== 4) {
            throw new Error('Invalid number of values in the row.');
          }

          // Validate station code
          if (!validStationCodes.has(stationCode)) {
            throw new Error(`Invalid stationCode at row ${index + 2}: ${stationCode}`);
          }

          // Validate setting
          const setting = values[1].trim();
          if (setting != 'MODERATE' && setting != 'CONSERVATIVE' && setting != 'ULTRACONSERVATIVE') {
            throw new Error(`Invalid setting value ${setting} found for stationCode ${stationCode} in row ${index + 2}. Expected settings: MODERATE, CONSERVATIVE, ULTRACONSERVATIVE`);
          }

          // Validate hour
          const hour = values[2].trim().replace(/^(\d):/, '0$1:');
          if (!this.isValidHourValue(hour)) {
            throw new Error(`Invalid hour value ${hour} found for stationCode ${stationCode} with setting ${setting} in row ${index + 2}. Expected format: 0:00 - 23:00`);
          }

          // Validate day
          const day = values[3].trim();
          const validDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
          if (!validDays.includes(day)) {
            throw new Error(`Invalid day value ${day} found for stationCode ${stationCode} with hour ${hour} in row ${index + 2}. Expected format: Saturday, Sunday, etc..`);
          }

          const combinationKey = `${stationCode}_${day}_${hour}`;
          if (seenCombinations[combinationKey]) {
            throw new Error(`Duplicate entry found for stationCode ${stationCode} with hour ${hour} on day ${day} in row ${index + 2}.`);
          }
          seenCombinations[combinationKey] = true;

          return {
            stationCode: stationCode,
            setting: setting,
            hour: hour,
            day: day,
          };
        });

        this.setState({ dataMap: items });
        this.setState({ csvContent: text });
      } catch (e) {
        this.setState({ error: `${e}` });
        return;
      }
    }
  }

  isValidHourValue(hours: string): boolean {
    const regex = /^(2[0-3]|[01]?[0-9]):00$/;
    return regex.test(hours);
  }

  render() {
    return (
      <Modal
        visible={true}
        header={'Drive Time Setting Bulk Upload'}
        onDismiss={() => this.props.onDismiss()}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button disabled={this.state.loading} onClick={() => this.props.onDismiss()}>
                Cancel
              </Button>
              <Button
                loading={this.state.loading}
                disabled={!!this.state.error}
                variant="primary"
                onClick={async () => {
                  this.setState({ loading: true });
                  try {
                    if (this.state.dataMap.length !== 0) {
                      const resp = await configScheduleClient.bulkUploadConfigSchedules({
                        configSchedules: this.state.dataMap,
                      });
                      this.props.onDismiss();
                      this.props.onSubmitSuccess('');
                      return resp;
                    }
                  } catch (err) {
                    this.setState({ error: `Bulk upload failed due to ${err}` });
                  } finally {
                    this.setState({ loading: false });
                  }
                }}
              >
                Upload
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        {this.state.error && (
          <Alert
            header="Error"
            type="error"
            dismissible={true}
            dismissAriaLabel="Close Alert"
            onDismiss={() => {
              this.setState({ error: undefined });
            }}
          >
            {this.state.error}
          </Alert>
        )}
        <FormField label="Upload Drive Time Setting Schedules.">
          <FileUpload
            onChange={(evt: { detail: { value: ReadonlyArray<File> } }) => {
              this.setState({ fileName: evt.detail.value, error: '' });
              if (evt.detail.value.length > 0) {
                const file = evt.detail.value[0];
                if (file.type !== 'text/csv' && !file.name.endsWith('.csv')) {
                  this.setState({ error: 'Please upload a valid CSV file.' });
                  return;
                }
                const reader = new FileReader();
                reader.onload = (event: ProgressEvent<FileReader>) => {
                  const text = event.target?.result as string;
                  // Validate CSV content
                  const rows = text
                    .split('\n')
                    .map((row) => row.trim())
                    .filter((row) => row);
                  this.validateCsv(text, rows);
                };
                reader.readAsText(file);
              } else {
                this.setState({ csvContent: '' });
              }
            }}
            value={this.state.fileName}
            fileErrors={this.state.error ? [this.state.error] : undefined}
            i18nStrings={{
              uploadButtonText: (e: any) => (e ? 'Choose files' : 'Choose file'),
              dropzoneText: (e: any) => (e ? 'Drop files to upload' : 'Drop file to upload'),
              removeFileAriaLabel: (e: number) => `Remove file ${e + 1}`,
              limitShowFewer: 'Show fewer files',
              limitShowMore: 'Show more files',
              errorIconAriaLabel: 'Error',
            }}
            showFileLastModified
            showFileSize
            showFileThumbnail
            tokenLimit={3}
            constraintText="The file has to be in CSV format"
            warningText="This upload will truncate all active schedules for the station, keeping only the schedules listed in the file active."
          />
          <Link external={true} href={SERVICE_AREA_FILE_EXAMPLE_LINK}>
            Example
          </Link>
        </FormField>
      </Modal>
    );
  }
}
