import React from 'react';
import Input from '@amzn/awsui-components-react-v3/polaris/input';
import Spinner from '@amzn/awsui-components-react-v3/polaris/spinner';
import FormField from '@amzn/awsui-components-react-v3/polaris/form-field';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Toggle from '@amzn/awsui-components-react-v3/polaris/toggle';
import { InputCellProps, InputCellState, ValidationResult, ValueType } from './models';
import { strictParseBoolean, strictParseFloat } from './utilities';

/**
 *
 * The InputCell supports rendering three different input data types,
 *  * boolean
 *  * number
 *  * string
 * The data type is the type how we store them in backend.
 *
 * Besides data type, it takes a representation type as input. The representation type indicates how the component should render the data.
 *  * boolean: display as a toggle
 *  * number: display as a text input
 *  * string: display as a text input
 *
 * Why can't representation type be the same as data type?
 *
 * In most cases, representation type is same as data type, however, there has exceptions,
 *
 * Example. The backend configurations may literally store boolean flag as string "true" or "false". But when rendering it, we want to render them as a toggle.
 *  * algorithmSettings.parameters.enable_pick_up_correction
 *
 *
 * -------
 *
 * Introduction:
 *
 * InputCell is one of the most challenging components to build in the project. The basic component is the building block to the form editor, and it has the following considerations and supports
 *  * handle different types of user input, boolean, number, string
 *  * the mismatch between the actual data type and the rendering appearance
 *  * ability to validate user input and prompt error message
 *  * blank parameters. All configuration parameters are optional. As we add new parameters, they will be blank at the beginning because we haven't populated them. And this should not fail the frontend validation.
 *  * display what the user input, including intermidate input. See following "12.23" example.
 *
 * Implementation details:
 *
 * Inside the component, we store user input as a plain string for all data types.
 *
 * The design allows us easily to maniuplate the user input, and render the exact thing as the user input. Otherwise, imagine if the number data type is stored as
 * javascript number, and the user wants to input "12.23", as the user typing,
 *  "1" => 1, the user sees 1 on the screen
 *  "12" => 12, the user sees 12 on the screen
 *  "12." => 12, the user sees the dot disppeared instantly because converting string "12." to number returns 12, and re-render the state will show "12" on the UI.
 *    In this case, the UI is basically battling with the user. And user can't input the number.
 *
 * How do we solve it?
 *
 * We store user input as a string. As the user inputs, we only update the rendered state "userInput", and display the original text the user input.
 * The validation and conversion from representation type to data type are done in the `onBlur` callback of the Input component.
 * The `onBlur` callback is invoked as user finishes the typing and leaves the cursor (a.k.a) out of the box. If the user input is valid, we call the `notifyUpdate`
 * callback function, the parent component may then updates it state to reflect the latest input.
 *
 */
export class InputCell extends React.Component<InputCellProps, InputCellState> {
  constructor(props: InputCellProps) {
    super(props);
    this.state = {
      isValid: true,
      userInput: this.props.value?.toString() ?? '',
    };
  }

  componentDidMount() {
    if (this.props.isEditing) {
      /**
       * We need to validate the user input upon the input switches to the editing mode. This is because the configurations retrieved from backend may not
       * pass the validation even before the user makes any change. It could happen if the configurations was manipulated through ad-hoc scripts or updated
       * before we add the validation.
       *
       * In these cases, we should prompt the error message immediately after the user switches to editing mode.
       */
      this.validateUserInputAndTriggerUpdate(this.state.userInput, false);
    }
  }

  componentDidUpdate(prevProps: Readonly<InputCellProps>): void {
    if (prevProps.value !== this.props.value) {
      const userInput = this.props.value?.toString() ?? '';
      this.setState({
        userInput: userInput,
      });

      if (this.props.isEditing) {
        this.validateUserInputAndTriggerUpdate(userInput, false);
      }
    }
  }

  render() {
    if (this.props.isEditing) {
      if (this.props.value === undefined) {
        return <Spinner />;
      } else {
        // here the value can be a number, a boolean, or a string.
        return this.renderInput();
      }
    } else {
      if (this.props.value === undefined) {
        return <Spinner />;
      } else if (this.props.representationType === 'boolean') {
        /**
         * By default, if the input doesn't have the configuration parameter, it will be set to false.
         * This aligns with how backend Java program handle missing boolean: set to false if missing.
         */
        return <Toggle checked={this.state.userInput === 'true'} disabled={true} />;
      } else {
        return <Box>{this.state.userInput}</Box>;
      }
    }
  }

  /**
   * rendering is done based on the representation type
   */
  private renderInput() {
    if (this.props.representationType === 'number' || this.props.representationType === 'string') {
      return (
        <FormField errorText={this.state.hint}>
          <Input
            value={this.state.userInput}
            placeholder={this.props.value === null ? 'Use default value' : undefined}
            onChange={(evt) => this.setState({ userInput: evt.detail.value })}
            onBlur={(evt) => {
              /**
               * Is there a usecase we don't to trim?
               * So far, the anwser is no.
               */
              this.validateUserInputAndTriggerUpdate(this.state.userInput.trim(), true);
            }}
          />
        </FormField>
      );
    } else if (this.props.representationType === 'boolean') {
      return (
        <Toggle
          checked={this.state.userInput === 'true'}
          onChange={(evt) => {
            const userInput = evt.detail.checked ? 'true' : 'false';
            this.setState({ userInput: userInput });
            this.validateUserInputAndTriggerUpdate(userInput, true);
          }}
        />
      );
    } else {
      return <Box>Unsupported representation type {this.props.representationType}</Box>;
    }
  }

  /**
   * validate the user input, and if the user input is valid, then optionally trigger the callback notifyUpdate method.
   *
   * The method is called after the user finishes his/her input. The method validates if user input can be correctly converted to the data type, and meet the optional validator from props.
   * It updates the validation state, and show the error hint if the validation failed, and optionally notifies the validation result to upper component.
   *
   * Finally, if the user input is valid, and triggerUpdate is true, it will call the notifyUpdate callback from props.
   */
  private validateUserInputAndTriggerUpdate(userInput: string, triggerUpdate: boolean) {
    const dataType = this.props.dataType ?? this.props.representationType;
    let validationResult: ValidationResult = {
      field: this.props.field,
      scope: this.props.scope,
      value: this.state.userInput,
      isValid: true,
    };

    let valueToValidate: ValueType = userInput;
    if (userInput === '') {
      // empty, clear the parameter
      valueToValidate = null;
    } else if (dataType === 'number') {
      const num = strictParseFloat(userInput);
      if (typeof num !== 'number') {
        validationResult.hint = 'Input is not a valid number.';
        validationResult.isValid = false;
      } else {
        valueToValidate = num;
      }
    } else if (dataType === 'boolean') {
      const bool_ = strictParseBoolean(userInput);
      if (typeof bool_ !== 'boolean') {
        validationResult.hint = 'Input is not a valid boolean.';
        validationResult.isValid = false;
      } else {
        valueToValidate = bool_;
      }
    }

    if (validationResult.isValid && this.props.inputValidator) {
      validationResult = this.props.inputValidator({
        field: this.props.field,
        scope: this.props.scope,
        value: valueToValidate,
      });
    }

    this.setState({
      isValid: validationResult.isValid,
      hint: validationResult.hint,
    });

    const shouldUpdateValidationResult =
      (this.state.isValid && !validationResult.isValid) || // valid status changes
      this.state.hint !== validationResult.hint; // hint changes

    if (shouldUpdateValidationResult && this.props.notifyValidationResult) {
      this.props.notifyValidationResult(validationResult);
    }

    if (validationResult.isValid && triggerUpdate) {
      this.props.notifyUpdate({
        field: this.props.field,
        newValue: valueToValidate,
        scope: this.props.scope,
      });
    }
  }
}
