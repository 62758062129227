import { ServiceAreaConfig } from '../../../../models/routing-models';
import { PickupStartOffsetConfigItem } from './models';

export function deleteItems(serviceAreaConfig: ServiceAreaConfig, items: ReadonlyArray<PickupStartOffsetConfigItem>): ServiceAreaConfig {
  const newAccessPointConfigs = serviceAreaConfig.accessPointConfigs ? Array.from(serviceAreaConfig.accessPointConfigs) : [];

  for (let i = 0; i < items.length; i++) {
    const apConfigIndexToUpdate = newAccessPointConfigs.findIndex((config) => config.accessPointId === items[i].accessPointId);
    if (typeof apConfigIndexToUpdate === 'number' && apConfigIndexToUpdate >= 0) {
      const deletedItem = items[i];
      let newApConfig = newAccessPointConfigs[apConfigIndexToUpdate];
      if (deletedItem.orderType === undefined) {
        newApConfig = {
          ...newApConfig,
          pickUpStartOffsetConfigs: [],
        };
      } else {
        newApConfig = {
          ...newApConfig,
          pickUpStartOffsetOverrides: newApConfig.pickUpStartOffsetOverrides?.filter((override) => override.orderType !== deletedItem.orderType),
        };
      }
      newAccessPointConfigs[apConfigIndexToUpdate] = newApConfig;
    }
  }

  return {
    ...serviceAreaConfig,
    accessPointConfigs: newAccessPointConfigs,
  };
}

export function addItem(serviceAreaConfig: ServiceAreaConfig, item: PickupStartOffsetConfigItem): ServiceAreaConfig {
  if (typeof item.addressId !== 'string') {
    return serviceAreaConfig;
  }

  const newAccessPointConfigs = serviceAreaConfig.accessPointConfigs ? Array.from(serviceAreaConfig.accessPointConfigs) : [];

  let targetConfigIndex = newAccessPointConfigs.findIndex((apc) => apc.accessPointId === item.accessPointId);
  if (targetConfigIndex < 0) {
    newAccessPointConfigs.push({
      accessPointId: item.accessPointId,
      addressId: item.addressId,
      pickUpStartOffsetConfigs: [],
      pickUpStartOffsetOverrides: [],
    });

    targetConfigIndex = newAccessPointConfigs.length - 1;
  }

  if (item.orderType === undefined) {
    const configs = Array.from(newAccessPointConfigs[targetConfigIndex].pickUpStartOffsetConfigs);
    configs.push({
      slammedOffsetMinutes: item.slammedTRPickupStartOffset,
      nonSlammedOffsetMinutes: item.nonSlammedTRPickupStartOffSet,
    });

    newAccessPointConfigs[targetConfigIndex] = {
      ...newAccessPointConfigs[targetConfigIndex],
      pickUpStartOffsetConfigs: configs,
    };
  } else {
    const overrideConfigs = Array.from(newAccessPointConfigs[targetConfigIndex].pickUpStartOffsetOverrides);
    overrideConfigs.push({
      orderType: item.orderType,
      offsetConfig: {
        slammedOffsetMinutes: item.slammedTRPickupStartOffset,
        nonSlammedOffsetMinutes: item.nonSlammedTRPickupStartOffSet,
      },
    });

    newAccessPointConfigs[targetConfigIndex] = {
      ...newAccessPointConfigs[targetConfigIndex],
      pickUpStartOffsetOverrides: overrideConfigs,
    };
  }

  return {
    ...serviceAreaConfig,
    accessPointConfigs: newAccessPointConfigs,
  };
}

export function updateTRPickupOffset(serviceAreaConfig: ServiceAreaConfig, type: 'slammed' | 'nonslammed', accessPointId: string, orderType: string | undefined, value: number): ServiceAreaConfig {
  const newAccessPointConfigs = serviceAreaConfig.accessPointConfigs ? Array.from(serviceAreaConfig.accessPointConfigs) : [];
  const targetConfigIndex = newAccessPointConfigs.findIndex((apc) => apc.accessPointId === accessPointId);

  if (targetConfigIndex >= 0) {
    let apConfig = newAccessPointConfigs[targetConfigIndex];
    if (typeof orderType !== 'string' && apConfig.pickUpStartOffsetConfigs.length === 1) {
      apConfig = {
        ...apConfig,
        pickUpStartOffsetConfigs: [
          {
            slammedOffsetMinutes: type === 'slammed' ? value : apConfig.pickUpStartOffsetConfigs[0].slammedOffsetMinutes,
            nonSlammedOffsetMinutes: type === 'nonslammed' ? value : apConfig.pickUpStartOffsetConfigs[0].nonSlammedOffsetMinutes,
          },
        ],
      };
    } else if (typeof orderType === 'string') {
      const overrides = Array.from(apConfig.pickUpStartOffsetOverrides);
      const targetOverrideIndex = overrides.findIndex((override) => override.orderType === orderType);
      if (targetOverrideIndex >= 0) {
        overrides[targetOverrideIndex] = {
          ...overrides[targetOverrideIndex],
          offsetConfig: {
            slammedOffsetMinutes: type === 'slammed' ? value : overrides[targetConfigIndex].offsetConfig.slammedOffsetMinutes,
            nonSlammedOffsetMinutes: type === 'nonslammed' ? value : overrides[targetConfigIndex].offsetConfig.nonSlammedOffsetMinutes,
          },
        };
      }

      apConfig = {
        ...apConfig,
        pickUpStartOffsetOverrides: overrides,
      };
    }

    newAccessPointConfigs[targetConfigIndex] = apConfig;
  }

  return {
    ...serviceAreaConfig,
    accessPointConfigs: newAccessPointConfigs,
  };
}
