import { ConfigTableDefinition as TableDefinition, ItemConfig } from '../../../models';

export * from './anomaly-config';

export const experiments: TableDefinition = {
  headerText: 'Routing Experiments',
  items: [
    {
      scope: 'serviceAreaConfig',
      field: 'featureFlags.spsRealTimeDataEnabled',
      name: 'Enable real-time cost matrix experiment',
      representationType: 'boolean',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'featureFlags.spsRealTimeDataEnabledForRouting',
      name: 'Enable real-time cost matrix experiment for Routing',
      representationType: 'boolean',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'featureFlags.bothTrafficAndSpocsEnabled',
      name: 'Enable both Realtime Traffic and SPOCs',
      representationType: 'boolean',
    },
    {
      field: 'parameters.prevent_ab_route_creation',
      scope: 'algorithmSettings',
      representationType: 'boolean',
      dataType: 'string',
      name: 'Prevent AB Route Creation',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'featureFlags.injectRoutesMissingInitialTransit',
      name: 'Inject Routes Missing Initial Transit',
      description: 'Inject routes to route store even when there is no initial Transit Action',
      representationType: 'boolean',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'featureFlags.skipAssignedRouteUpdatesInRouteStore',
      name: 'Skip Assigned Route Updates In RouteStore',
      description: 'Skip updating assigned Routes in RouteStore to work with RDM',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
  ],
};

export const instantOffer: TableDefinition = {
  headerText: 'Instant Offer',
  items: [
    {
      scope: 'serviceAreaConfig',
      field: 'instantOffersEnabled',
      name: 'Enable Instant Offer',
      representationType: 'boolean',
    },
    {
      field: 'parameters.instant_offer_max_pickup_distance_secs',
      scope: 'algorithmSettings',
      representationType: 'string',
      name: 'IO Max Pickup Search Radius (Sec)',
      validators: [
        {
          name: 'ValueBetween0And900Validator',
        },
      ],
    },
    {
      field: 'parameters.instant_offer_min_pickup_distance_secs',
      scope: 'algorithmSettings',
      name: 'IO Min Pickup Search Radius (Sec)',
      representationType: 'string',
      validators: [
        {
          name: 'ValueBetween0And900Validator',
        },
      ],
    },
    {
      field: 'parameters.instant_offer_max_attempts',
      scope: 'algorithmSettings',
      name: 'IO Max Attempts',
      representationType: 'string',
      validators: [
        {
          name: 'ValueBetween5And30Validator',
        },
      ],
    },
    {
      field: 'parameters.instant_offer_driver_proximity_to_pickup_location_cost',
      scope: 'algorithmSettings',
      name: 'IO Driver Proximity Pickup Location Cost',
      representationType: 'string',
      validators: [
        {
          name: 'ValueBetween0And900Validator',
        },
      ],
    },
    {
      field: 'parameters.instant_offer_delay_cost_factor',
      scope: 'algorithmSettings',
      name: 'IO Delay Cost Factor',
      representationType: 'string',
      validators: [
        {
          name: 'ValueBetween0And5Validator',
        },
      ],
    },
    {
      field: 'parameters.instant_offer_cost',
      scope: 'algorithmSettings',
      name: 'IO Cost',
      representationType: 'string',
      validators: [
        {
          name: 'ValueBetween0And5000Validator',
        },
      ],
    },
    {
      field: 'parameters.instant_offer_additional_window_buffer_secs',
      scope: 'algorithmSettings',
      name: 'IO Additional Window Buffer (Sec)',
      representationType: 'string',
      validators: [
        {
          name: 'ValueBetween0And900Validator',
        },
      ],
    },
    {
      scope: 'serviceAreaConfig',
      field: 'eaputBufferMinutes',
      name: 'EAPuT Buffer (Min)',
      representationType: 'number',
      validators: [
        {
          name: 'ValueBetweenNeg90And0Validator',
        },
      ],
    },
    {
      scope: 'serviceAreaConfig',
      field: 'throughputAwareDispatchEnabled',
      name: 'Throughput Aware Dispatch Enabled',
      representationType: 'boolean',
    },
    // {
    //   field: 'lockRouteIOOfferRevokedEnabled',
    //   name: 'lockRouteIOOfferRevokedEnabled',
    //   representationType: 'boolean',
    // },
    // {
    //   field: 'ioDefaultAcceptanceRate',
    //   name: 'ioDefaultAcceptanceRate',
    // },
    // {
    //   field: 'laputDefaultIOLockOffsetMinutes',
    //   name: 'laputDefaultIOLockOffsetMinutes',
    // },
  ],
};

export const overflowRoute: TableDefinition = {
  headerText: 'Overflow Route Configuration',
  items: [
    {
      scope: 'serviceAreaConfig',
      field: 'overflowDriverDelayMinutes',
      name: 'Overflow Driver Delay Setting',
      representationType: 'number',
      validators: [
        {
          name: 'NonNegativeValidator',
        },
      ],
    },
    {
      scope: 'serviceAreaConfig',
      field: 'overflowDriverShiftMinutes',
      name: 'Overflow Driver Shift Length',
      representationType: 'number',
      validators: [
        {
          name: 'NonNegativeValidator',
        },
      ],
    },
    {
      scope: 'serviceAreaConfig',
      field: 'lockOverflowRoutes',
      name: 'Lock Overflow Routes',
      representationType: 'boolean',
    },
    {
      field: 'parameters.num_overflow_transporters',
      scope: 'algorithmSettings',
      representationType: 'string',
      name: 'Overflow Transporter Multiplier',
      validators: [
        {
          name: 'NonNegativeValidator',
        },
      ],
    },
    {
      scope: 'serviceAreaConfig',
      field: 'futureDriverCutoffMinutes',
      name: 'Future Driver Cutoff (Min)',
      representationType: 'number',
      validators: [
        {
          name: 'ValueBetween-360And2880Validator',
        },
      ],
    },
  ],
  valueColHeaderText: 'Value (Minutes)',
};

export const defaultPlanningHorizon: TableDefinition = {
  headerText: 'Planning Horizon',
  valueColHeaderText: 'Value (Minutes)',
  valueColMinWidth: '150px',
  items: [
    {
      scope: 'serviceAreaConfig',
      field: 'defaultHorizonConfig.stagedPlanningHorizonMinutes',
      name: 'Slammed Planning Horizon',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'defaultHorizonConfig.nonStagedPlanningHorizonMinutes',
      name: 'Non-slammed Planning Horizon',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'defaultHorizonConfig.lockOffsetMinutes',
      name: 'Lock Offset',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'applyPlanningHorizonToPreviouslyPlannedTasks',
      name: 'Apply To Previously Planned Tasks',
      representationType: 'boolean',
    },
  ],
};

export const readyToWork: TableDefinition = {
  headerText: 'Ready to work',
  items: [
    {
      scope: 'serviceAreaConfig',
      field: 'requireReadyToWorkVerification',
      name: 'Require ready to work verification',
      representationType: 'boolean',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'requireCheckInValidations',
      name: 'Require check in validations',
      representationType: 'boolean',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'requireDriverLicenseVerification',
      name: "Require driver's license verification",
      representationType: 'boolean',
    },
  ],
};

export const locking: TableDefinition = {
  headerText: 'Locking Configuration',
  items: [
    {
      scope: 'serviceAreaConfig',
      field: 'lockingConfig.dynamicLockerEnabled',
      name: 'Enable Dynamic Locker',
      representationType: 'boolean',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'lockingConfig.timedOffsetLockerEnabled',
      name: 'Enable Timed Offset Locker',
      representationType: 'boolean',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'lockingConfig.batchingBufferMinutes',
      name: 'Batching Buffer (Minutes)',
      representationType: 'number',
      validators: [
        {
          name: 'NonNegativeValidator',
        },
      ],
    },
    {
      scope: 'serviceAreaConfig',
      field: 'lockingConfig.minimumDPHRequiredToLock',
      name: 'Minimum DPH',
      representationType: 'number',
      validators: [
        {
          name: 'ValueBetween0And10Validator',
        },
      ],
    },
  ],
};
//This Assignment Table lives in the Routing Configurations Service Area Details page
export const assignment: TableDefinition = {
  headerText: 'Assignment Configuration',
  valueColMinWidth: '150px',
  items: [
    {
      scope: 'serviceAreaConfig',
      field: 'assignmentConfig.assignerEnabled',
      name: 'Enable Auto Assign/Locking',
      representationType: 'boolean',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'assignmentConfig.justInTimeEnabled',
      name: 'Enable Just In Time (JIT)',
      representationType: 'boolean',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'assignmentConfig.executePlanNotifierEnabled',
      name: 'Enable Plan Notifier',
      representationType: 'boolean',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'assignmentConfig.earlyOfferToReturningTransporterEnabled',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'assignmentConfig.returningTransporterBufferSec',
      representationType: 'number',
      visibility: 'developer-only',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'assignmentConfig.newBlockBufferSec',
      representationType: 'number',
    },
  ],
};

export const earlyDropoff: TableDefinition = {
  headerText: 'Early Dropoff Settings',
  valueColHeaderText: 'Value (Minutes)',
  nameColMinWidth: '150px',
  valueColMinWidth: '150px',
  items: [
    {
      scope: 'serviceAreaConfig',
      field: 'earlyDropOffMinutesConfig.OTHER',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'earlyDropOffMinutesConfig.HOTWHEELS',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'earlyDropOffMinutesConfig.ONE_HOUR_RUSH',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'earlyDropOffMinutesConfig.TWO_HOUR_FIXED',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'earlyDropOffMinutesConfig.ONE_HOUR_FIXED',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'earlyDropOffMinutesConfig.FRESH',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'earlyDropOffMinutesConfig.ROAR',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'earlyDropOffMinutesConfig.ROAR_ONE_DAY_CORE',
      representationType: 'number',
    },
  ],
};

export const endBufferMinutes: TableDefinition = {
  headerText: 'End of Window Buffer',
  valueColHeaderText: 'Value (Minutes)',
  nameColMinWidth: '150px',
  valueColMinWidth: '150px',
  items: [
    {
      scope: 'serviceAreaConfig',
      field: 'endBufferMinutesConfig.OTHER',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'endBufferMinutesConfig.HOTWHEELS',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'endBufferMinutesConfig.ONE_HOUR_RUSH',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'endBufferMinutesConfig.TWO_HOUR_FIXED',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'endBufferMinutesConfig.ONE_HOUR_FIXED',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'endBufferMinutesConfig.FRESH',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'endBufferMinutesConfig.ROAR',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'endBufferMinutesConfig.ROAR_ONE_DAY_CORE',
      representationType: 'number',
    },
  ],
};

export const unitTaskDelayCost: TableDefinition = {
  headerText: 'Unit Task Delay Cost',
  visibility: 'developer-only',
  valueColHeaderText: 'Value (Minutes)',
  nameColMinWidth: '150px',
  valueColMinWidth: '150px',
  items: [
    {
      scope: 'serviceAreaConfig',
      field: 'unitTaskDelayCostConfig.OTHER',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'unitTaskDelayCostConfig.HOTWHEELS',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'unitTaskDelayCostConfig.ONE_HOUR_RUSH',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'unitTaskDelayCostConfig.TWO_HOUR_FIXED',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'unitTaskDelayCostConfig.ONE_HOUR_FIXED',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'unitTaskDelayCostConfig.FRESH',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'unitTaskDelayCostConfig.ROAR',
      representationType: 'number',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'unitTaskDelayCostConfig.ROAR_ONE_DAY_CORE',
      representationType: 'number',
    },
  ],
};

export const elongation: TableDefinition = {
  headerText: 'Elongation',
  nameColMinWidth: '150px',
  valueColMinWidth: '150px',
  items: [
    {
      field: 'parameters.elongation_duration_mins',
      scope: 'algorithmSettings',
      representationType: 'number',
      dataType: 'string',
      name: 'Elongation Duration (Minutes)',
      validators: [
        {
          name: 'NonNegativeValidator',
        },
      ],
    },
    {
      field: 'parameters.elongation_fixed_cost',
      scope: 'algorithmSettings',
      representationType: 'number',
      dataType: 'string',
      visibility: 'developer-only',
      name: 'Elongation Cost',
      validators: [
        {
          name: 'NumberValidator',
        },
      ],
    },
  ],
};

/*
Below are tables for the COView. These are tables that are in the OEView, but have less parameters.
 */
export const defaultPlanningHorizonCOView: TableDefinition = {
  headerText: 'Planning Horizon',
  valueColHeaderText: 'Value (Minutes)',
  valueColMinWidth: '150px',
  items: [
    {
      scope: 'serviceAreaConfig',
      field: 'defaultHorizonConfig.stagedPlanningHorizonMinutes',
      name: 'Slammed Planning Horizon',
      representationType: 'number',
      validators: [
        {
          name: 'Negative360ValueOrGreaterValidator',
        },
      ],
    },
    {
      scope: 'serviceAreaConfig',
      field: 'defaultHorizonConfig.nonStagedPlanningHorizonMinutes',
      name: 'Non-slammed Planning Horizon',
      representationType: 'number',
      validators: [
        {
          name: 'Negative360ValueOrGreaterValidator',
        },
      ],
    },
    {
      scope: 'serviceAreaConfig',
      field: 'defaultHorizonConfig.lockOffsetMinutes',
      name: 'Lock Offset',
      representationType: 'number',
      validators: [
        {
          name: 'NonNegativeValidator',
        },
      ],
    },
  ],
};

export const elongationCOView: TableDefinition = {
  headerText: 'Elongation',
  nameColMinWidth: '150px',
  valueColMinWidth: '150px',
  items: [
    {
      field: 'parameters.elongation_duration_mins',
      scope: 'algorithmSettings',
      representationType: 'number',
      dataType: 'string',
      name: 'Elongation Duration (Minutes)',
      validators: [
        {
          name: 'NonNegativeValidator',
        },
      ],
    },
  ],
};

export const assignmentCOViewWithAutoAssign: TableDefinition = {
  headerText: 'Assignment Configuration',
  items: [
    {
      scope: 'serviceAreaConfig',
      field: 'assignmentConfig.assignerEnabled',
      name: 'Enable Auto Assign',
      representationType: 'boolean',
    },
    {
      scope: 'serviceAreaConfig',
      field: 'assignmentConfig.executePlanNotifierEnabled',
      name: 'Enable Plan Notifier',
      representationType: 'boolean',
    },
  ],
};

export const assignmentCOView: TableDefinition = {
  headerText: 'Assignment Configuration',
  items: [
    {
      scope: 'serviceAreaConfig',
      field: 'assignmentConfig.executePlanNotifierEnabled',
      name: 'Enable Plan Notifier',
      representationType: 'boolean',
    },
  ],
};

export const instantOfferCOView: TableDefinition = {
  headerText: 'Instant Offer',
  items: [
    {
      scope: 'serviceAreaConfig',
      field: 'instantOffersEnabled',
      name: 'Enable Instant Offer',
      representationType: 'boolean',
    },
  ],
};

export const drpConfigs: TableDefinition = {
  headerText: 'DRP Configurations',
  items: [
    {
      scope: 'algorithmSettings',
      field: 'parameters.drp.max_under_over_resolution_iterations',
      representationType: 'string',
      name: 'DRP improve function iterations',
      validators: [
        {
          name: 'ValueBetween1And10Validator',
        },
      ],
    },
  ],
};
