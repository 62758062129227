import { ApplyProfileResults } from './models';

export function mergeApplyProfileResults(r1: ApplyProfileResults, r2: ApplyProfileResults): ApplyProfileResults {
  let succeededServiceAreaIds = r1.succeededServiceAreaIds;
  if (succeededServiceAreaIds === undefined) {
    succeededServiceAreaIds = r2.succeededServiceAreaIds;
  } else if (r2.succeededServiceAreaIds !== undefined) {
    succeededServiceAreaIds = Array.from(succeededServiceAreaIds).concat(r2.succeededServiceAreaIds);
  }

  return {
    succeededServiceAreaIds,
    failedServiceAreaIdAndReasons: {
      ...r1.failedServiceAreaIdAndReasons,
      ...r2.failedServiceAreaIdAndReasons,
    },
    url: r1.url ?? r2.url,
  };
}
