import { MidwayCognitoAuthClientBuilder } from './midway-cognito-auth-client-builder';
import { config } from '../utilities';
import { AxiosInstance } from 'axios';
import { LaborPlanningClient, LaborPlanningClientImpl } from './labor-planning-client';
import { AssignmentClient, AssignmentClientImpl } from './assignment-client';
import { RoutingClient, RoutingClientImpl } from './routing-client';
import { CommonClient, CommonClientImpl } from './common-client';
import { DriaClient } from './dria-client/dria-client';
import { DriaClientImpl } from './dria-client/dria-client-impl';
import { DrasScopeConfigClient, DrasScopeConfigClientImpl } from './dras-scope-config-client';
import { ConfigScheduleClient, ConfigScheduleClientImpl } from './config-schedule-client';

let httpClient: AxiosInstance;

if (config.identityPoolId === '') {
  // calling the local backend (when calling local backend, we don't need aws credentials)
  httpClient = MidwayCognitoAuthClientBuilder.build({
    baseUrl: config.apiGatewayBaseURL,
  });
} else {
  httpClient = MidwayCognitoAuthClientBuilder.build({
    baseUrl: config.apiGatewayBaseURL,
    identityPoolId: config.identityPoolId,
    awsRegion: config.awsRegion,
  });
}

/**
 * Create global singleton client objects that can be used anywhere
 */
export const assignmentClient: AssignmentClient = new AssignmentClientImpl(httpClient);
export const routingClient: RoutingClient = new RoutingClientImpl(httpClient);
export const lpClient: LaborPlanningClient = new LaborPlanningClientImpl(httpClient);
export const driaClient: DriaClient = new DriaClientImpl(httpClient);
export const drasScopeConfigClient: DrasScopeConfigClient = new DrasScopeConfigClientImpl(httpClient);
export const commonClient: CommonClient = new CommonClientImpl(httpClient);
export const configScheduleClient: ConfigScheduleClient = new ConfigScheduleClientImpl(httpClient);
