import { InputUpdate } from '../../../common-components/input-cell';
import { DriaConfig } from '../../../models/dria-models';
import { isNil } from '../../../utilities/helper-functions';

export function updateDriaConfigurations(update: InputUpdate, configs: DriaConfig): DriaConfig {
  if (update.scope === undefined || update.scope === 'driaConfig') {
    const fields = update.field.split('.');
    // use a shadow copy to make the update
    const newConfigs = { ...configs } as any;
    let directParent = newConfigs;
    for (let i = 0; i < fields.length - 1; i++) {
      if (isNil(directParent[fields[i]])) {
        directParent[fields[i]] = {};
      }
      directParent = directParent[fields[i]];
    }
    directParent[fields[fields.length - 1]] = update.newValue;

    return newConfigs;
  } else {
    console.warn(`unknown dria configuration scope ${update.scope}`);
    return configs;
  }
}
