// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._20cfCwRBW97KxRkDaPInUQ\\=\\={display:-ms-grid;display:grid;-ms-grid-columns:50% 50%;grid-template-columns:50% 50%}._20cfCwRBW97KxRkDaPInUQ\\=\\=>div{margin:2rem 0;padding:0 2rem;border:0 solid var(--color-border-divider-default-in8dkg, #eaeded)}._20cfCwRBW97KxRkDaPInUQ\\=\\=>div:nth-child(2n){border-left-width:1px}@media(min-width: 1200px){._20cfCwRBW97KxRkDaPInUQ\\=\\={-ms-grid-columns:20% 20% 20% 20% 20%;grid-template-columns:20% 20% 20% 20% 20%}._20cfCwRBW97KxRkDaPInUQ\\=\\=>div:not(:first-child){border-left-width:1px}}", "",{"version":3,"sources":["webpack://./src/components/routing-service-area-details/service-area-info/service-area-info.module.scss"],"names":[],"mappings":"AAGA,6BACE,gBAAA,CACA,YAAA,CACA,wBAAA,CACA,6BAAA,CAEA,iCACE,aAAA,CACA,cAAA,CACA,kEAAA,CACA,+CACE,qBAAA,CAIJ,0BAfF,6BAgBI,oCAAA,CACA,yCAAA,CAEE,mDACE,qBAAA,CAAA","sourcesContent":["@use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;\n\n\n.service-area-info-grid {\n  display: -ms-grid;\n  display: grid;\n  -ms-grid-columns: 50% 50%;\n  grid-template-columns: 50% 50%;\n\n  & > div {\n    margin: 2rem 0;\n    padding: 0 2rem;\n    border: 0 solid awsui.$color-border-divider-default;\n    &:nth-child(2n) {\n      border-left-width: 1px;\n    }\n  }\n\n  @media (min-width: 1200px) {\n    -ms-grid-columns: 20% 20% 20% 20% 20%;\n    grid-template-columns: 20% 20% 20% 20% 20%;\n    & > div {\n      &:not(:first-child) {\n        border-left-width: 1px;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"service-area-info-grid": "_20cfCwRBW97KxRkDaPInUQ=="
};
export default ___CSS_LOADER_EXPORT___;
