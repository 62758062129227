import React from 'react';

import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Checkbox from '@amzn/awsui-components-react-v3/polaris/checkbox';
import Container from '@amzn/awsui-components-react-v3/polaris/container';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import ColumnLayout from '@amzn/awsui-components-react-v3/polaris/column-layout';
import { PlaceholderTRDefaults, CubicVolume, Weight } from '../../../../models/routing-models';
import { isNil, isNotNil } from '../../../../utilities';
import { NumericInput } from '../../../../common-components/numeric-input';

const DEFAULT_UNITS = 1;

interface SizeEstimationRowProps {
  readonly label: string;
  readonly value?: number;
  readonly isEditing: boolean;
  readonly onUpdate: (newValue: number | undefined) => void;
}

/*
 * The config model holds a single number here.
 * An "undefined" number indicates the row is disabled.
 * But when users toggle a row, we don't want to clear their input.
 * This "cachedValue" holds the currently-being-edited value for page duration only.
 */
interface SizeEstimationRowState {
  readonly cachedValue: number;
}

class SizeEstimationRow extends React.Component<SizeEstimationRowProps, SizeEstimationRowState> {
  constructor(props: SizeEstimationRowProps) {
    super(props);
    this.state = {
      cachedValue: props.value ?? DEFAULT_UNITS,
    };
  }

  render() {
    return this.props.isEditing ? this.renderForEdit() : this.renderForView();
  }

  private renderForView() {
    if (typeof this.props.value === 'number') {
      return (
        <Box padding={{ vertical: 'xs', horizontal: 'l' }}>
          <SpaceBetween direction="horizontal" size="l">
            <Box variant="span">{this.props.label}</Box>
            <Box variant="span">{this.props.value}</Box>
          </SpaceBetween>
        </Box>
      );
    } else {
      return null;
    }
  }

  private renderForEdit() {
    return (
      <Box padding={{ vertical: 'xs', horizontal: 'l' }}>
        <Checkbox
          checked={isNotNil(this.props.value)}
          onChange={(evt) => {
            if (evt.detail.checked) {
              // if we are enabling this, use whatever we have stored in the temp field
              this.props.onUpdate(this.state.cachedValue);
            } else {
              // if we are disabling this row, cache whatever we currently had
              this.setState({ cachedValue: this.props.value ?? DEFAULT_UNITS });
              // and then set the config field to undefined
              this.props.onUpdate(undefined);
            }
          }}
        >
          {this.props.label}
        </Checkbox>
        <NumericInput
          startingText={(this.props.value ?? 0).toString()}
          minimum={0}
          disabled={isNil(this.props.value)}
          onChange={(value) => {
            this.props.onUpdate(value);
            this.setState({ cachedValue: value });
          }}
        />
      </Box>
    );
  }
}

export interface PlaceholderTRSizeEstimationTableProps {
  readonly placeholderTRDefaults?: PlaceholderTRDefaults;
  readonly isEditing: boolean;
  readonly onUpdate: (placeholderTRDefaults: PlaceholderTRDefaults) => void;
}

export interface TRSizeEstimationTableState {}

export class PlaceholderTRSizeEstimationTable extends React.Component<PlaceholderTRSizeEstimationTableProps, TRSizeEstimationTableState> {
  render() {
    const volume = this.props.placeholderTRDefaults?.cubicVolumeEstimateOverride;
    const weight = this.props.placeholderTRDefaults?.weightEstimateOverride;

    return (
      <Container disableContentPaddings header={<Header variant="h3">Non-Slammed TR Size Estimation</Header>}>
        <ColumnLayout columns={1} borders="horizontal" disableGutters={true}>
          <SizeEstimationRow
            label="Volume (Cubic Feet)"
            value={volume ? volume.value : undefined}
            isEditing={this.props.isEditing}
            onUpdate={(value) => {
              let newVolume: CubicVolume | undefined = undefined;
              if (typeof value === 'number') {
                newVolume = { value: value, unit: 'CU_FT' };
              }
              this.props.onUpdate({
                cubicVolumeEstimateOverride: newVolume,
                weightEstimateOverride: weight,
              });
            }}
          />
          <SizeEstimationRow
            label="Weight (Pounds)"
            value={weight ? weight.value : undefined}
            isEditing={this.props.isEditing}
            onUpdate={(value) => {
              let newWeight: Weight | undefined = undefined;
              if (typeof value === 'number') {
                newWeight = { value: value, unit: 'LB' };
              }
              this.props.onUpdate({
                cubicVolumeEstimateOverride: volume,
                weightEstimateOverride: newWeight,
              });
            }}
          />
        </ColumnLayout>
      </Container>
    );
  }
}
