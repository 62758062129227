import { ConfigTableDefinition } from '../../../models';

export const ROUTE_SNAPSHOT: ConfigTableDefinition = {
  headerText: 'Route Snapshot Configurations',
  valueColMinWidth: '150px',
  sizeOnGrid: 'default',
  items: [
    {
      field: 'loadRoutesFromPreviousSnapshot',
      scope: 'driaConfig',
      name: 'Use Previous Route Snapshot',
      description: `If enabled, DRIA will read previous route snapshot and avoid calling route store for routes that don't change.`,
      representationType: 'boolean',
    },
  ],
};
