import { ConfigTableDefinition } from '../../../models';

export const NURSERY_ASSIGNMENT_CONFIGURATIONS: ConfigTableDefinition = {
  headerText: 'Nursery Assignment Configurations',
  valueColMinWidth: '150px',
  sizeOnGrid: 'default',
  visibility: 'developer-only',
  items: [
    {
      field: 'algorithmParametersMap.FUNGIBLE_ASSIGNMENT_ALGORITHM.enableNurseryAssignment',
      scope: 'assignmentConfig',
      name: 'Enable Nursery Assignment',
      description: 'Feature flag to enable nursery assignments.',
      representationType: 'boolean',
    },
    {
      field: 'algorithmParametersMap.FUNGIBLE_ASSIGNMENT_ALGORITHM.maxNurseryBlockUtilization',
      scope: 'assignmentConfig',
      name: 'Maximum Nursery Block Utilization',
      description: 'Maximum percent of block duration a nursery transporter can work to deliver a route.',
      representationType: 'number',
      validators: [
        {
          name: 'ValueBetween0And1Validator',
        },
      ],
    },
    {
      field: 'algorithmParametersMap.FUNGIBLE_ASSIGNMENT_ALGORITHM.maxNurseryAssignmentOverhead',
      scope: 'assignmentConfig',
      name: 'Maximum Nursery Overhead',
      description: 'Additional cost for assigning routes to nursery transporter.',
      representationType: 'number',
      validators: [
        {
          name: 'ValueBetween0And900Validator',
        },
      ],
    },
  ],
};
