import { ProfileSortingGroups } from '../../common-components/config-profile-panel';

/*
 * This list will need manual updates as we need new categoriess.
 *
 * Matching Logic:
 *
 * Each map entry is a dropdown. Name is the dropdowns "title".
 * Rows are search groups within the dropdown.
 *
 * Each string in the selected group is matched against the profile names.
 * The first string in a group is additionally used as the display label for that group.
 *
 * Uppercase strings (acronyms) are valid matches only against those letters, in uppercase, in a config title.
 * This prevents unintended matching, especially with acronyms like "IO"
 *
 * Otherwise everything is lowercased, and spaces are removed from both the query terms and the titles.
 * Then matching is done. Every profile title with one match gets displayed as an option.
 */

const ROUTING_PROFILE_SORTING_GROUPS: Map<string, string[][]> = new Map<string, string[][]>();
ROUTING_PROFILE_SORTING_GROUPS.set('by program', [
  ['3P', 'third party'],
  ['UFF', 'ultra fast fresh'],
  ['ROAR Launch'],
  ['ROAR Update'],
  ['Houdini'],
  ['Mendel'],
  ['WFM', 'whole foods'],
  ['Food Bank', 'Community Flex'],
  ['Amazon Food'],
]);

ROUTING_PROFILE_SORTING_GROUPS.set('by feature', [['Auto Assign'], ['Instant Offer', 'IO'], ['MMOT', 'MOT', 'Mode of Transport'], ['DSLR'], ['ILS'], ['On Demand'], ['DSP Routing', 'DSP'], ['DRP']]);

ROUTING_PROFILE_SORTING_GROUPS.set('misc', [
  ['Order Type Isolation', 'isolation', 'isolate'],
  ['Incident Response', 'incident', 'lse'],
  ['Traffic Estimate', 'drive time', 'real time'],
  ['Non-Car', 'three-wheeler', 'three wheeler', 'bike', 'bicycle', 'walker'],
  ['DRS', 'dynamic route solver'],
  ['LMDP', 'last mile disruption planner'],
  ['Locking Behavior', 'Locking'],
]);

export default ROUTING_PROFILE_SORTING_GROUPS as ProfileSortingGroups;
