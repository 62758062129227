/**
 * path /labor-planning-service-areas
 */
import React from 'react';
import { ServiceAreaList as MainContent } from '../../common-components/service-area-list';
import { lpClient } from '../../http-clients';
import { ServiceAreaInfo } from '../../models';
import { GlobalContext } from '../../main-app/global-context';
import { readable } from '../../utilities';

export interface LaborPlanningServiceAreaListProps {}

export interface LaborPlanningServiceAreaListState {
  readonly isLoading: boolean;
  readonly serviceAreas?: ReadonlyArray<ServiceAreaInfo>;
}

export class LaborPlanningServiceAreaList extends React.Component<LaborPlanningServiceAreaListProps, LaborPlanningServiceAreaListState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: LaborPlanningServiceAreaListProps) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.setTools('info-panel');
    this.context.updateBreadcrumbItems([
      {
        text: 'Labor Planning Service Areas',
        href: '/labor-planning-service-areas',
      },
    ]);
    await this.loadServiceAreas();
  }

  private async loadServiceAreas() {
    try {
      this.setState({ isLoading: true });
      const resp = await lpClient.getServiceAreas();
      this.setState({
        serviceAreas: resp.serviceAreas,
      });
    } catch (err) {
      this.context.addNotification({
        header: 'Error',
        content: `Failed to load labor planning service areas due to ${readable(err)}`,
        type: 'error',
        dismissible: true,
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <MainContent
        header="Labor Planning Service Areas"
        serviceAreas={this.state.serviceAreas}
        onServiceAreaClick={(serviceArea) => {
          this.context.onGoto(`/labor-planning-service-areas/${serviceArea.serviceAreaId}`);
        }}
        buildHref={(serviceArea) => `/labor-planning-service-areas/${serviceArea.serviceAreaId}`}
      />
    );
  }
}
