/**
 * Extract error message from err object.
 * @param err
 * @returns
 */
export function readable(err: any) {
  return typeof err?.response?.data === 'string' ? err?.response?.data : err?.message;
}

export function deepClone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export async function asleep(milliseconds: number) {
  await new Promise((resolve) => {
    return setTimeout(resolve, milliseconds);
  });
}

export function isNil<T>(value: T | undefined | null): value is null | undefined {
  return value === null || value === undefined;
}

export function isNotNil<T>(value: T | undefined | null): value is T {
  return value !== null && value !== undefined;
}
