import { ProfileSortingGroups } from '../../common-components/config-profile-panel';

/*
 * This list will need manual updates as we need new categoriess.
 *
 * Matching Logic:
 *
 * Each map entry is a dropdown. Name is the dropdowns "title".
 * Rows are search groups within the dropdown.
 *
 * Each string in the selected group is matched against the profile names.
 * The first string in a group is additionally used as the display label for that group.
 *
 * Uppercase strings (acronyms) are valid matches only against those letters, in uppercase, in a config title.
 * This prevents unintended matching, especially with acronyms like "IO"
 *
 * Otherwise everything is lowercased, and spaces are removed from both the query terms and the titles.
 * Then matching is done. Every profile title with one match gets displayed as an option.
 */

const ASSIGNMENT_PROFILE_SORTING_GROUPS: Map<string, string[][]> = new Map<string, string[][]>();
ASSIGNMENT_PROFILE_SORTING_GROUPS.set('by program', [['UFF', 'ultra fast fresh'], ['SSD', 'sub same day'], ['WFM', 'whole foods'], ['AMZL'], ['Amazon Food'], ['ROAR']]);
ASSIGNMENT_PROFILE_SORTING_GROUPS.set('by action', [['Enable'], ['Disable'], ['Shadow']]);

export default ASSIGNMENT_PROFILE_SORTING_GROUPS as ProfileSortingGroups;
