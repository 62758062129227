import React from 'react';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Table, { TableProps } from '@amzn/awsui-components-react-v3/polaris/table';
import { CreateNewPickupStartOffsetModal } from './create-new-pickup-start-offset-modal';
import { GridSizeDefaultProp } from '../../../../common-components/config-grid';
import { PickupStartOffsetConfigItem } from './models';
import { buildTableDefinition } from './build-table-definition';
import { sortPickupStartOffsetConfigItems } from './utilities';
import { isNil } from '../../../../utilities';
import { AccessPoint, AccessPointConfiguration } from '../../../../models/routing-models';

interface AccessPointConfigsTableProps {
  readonly isEditing: boolean;

  // current configuration
  readonly items: ReadonlyArray<PickupStartOffsetConfigItem>;

  // present all the access points when creating a new pickup start time offset.
  readonly accessPoints: ReadonlyArray<AccessPoint>;

  readonly onDeleteItems: (items: ReadonlyArray<PickupStartOffsetConfigItem>) => void; // callback when a new override is created
  readonly onAddItem: (item: PickupStartOffsetConfigItem) => void; // callback when delete overrides

  // an access point configuration is identified by accessPointId and orderType.
  readonly onUpdateSlammedTRPickupOffset: (accessPointId: string, orderType: string | undefined, value: number) => void;
  readonly onUpdateNonSlammedTRPickupOffset: (accessPointId: string, orderType: string | undefined, value: number) => void;
}

interface AccessPointConfigsTableState {
  readonly isAddingNewPickupStartOffset: boolean; // control whether to open the popup modal.
  readonly selectedItems: ReadonlyArray<PickupStartOffsetConfigItem>;
  readonly isDescending?: boolean;
  readonly sortingColumn?: TableProps.SortingColumn<PickupStartOffsetConfigItem>;
}

/**
 * The component
 * 1. show access point configuration in a table, each row is a {@link PickupStartOffsetConfigItem}
 * 2. in editing mode, it will show a checkbox preceding each row. Select the checkbox to delete a configuration.
 * 3. to add a new configuration, click the "Add" button. It will bring the Modal.
 *
 */
export class AccessPointConfigsTable extends React.Component<AccessPointConfigsTableProps, AccessPointConfigsTableState> {
  static defaultProps: GridSizeDefaultProp = { sizeOnGrid: 'wide' };

  constructor(props: AccessPointConfigsTableProps) {
    super(props);
    this.state = {
      isAddingNewPickupStartOffset: false,
      selectedItems: [],
      isDescending: false,
    };
  }

  render() {
    const columns = buildTableDefinition({
      isEditing: this.props.isEditing,
      onUpdateNonSlammedTRPickupOffset: this.props.onUpdateNonSlammedTRPickupOffset,
      onUpdateSlammedTRPickupOffset: this.props.onUpdateSlammedTRPickupOffset,
    });

    const sortingColumn = columns.find((col) => col.sortingField !== undefined && col.sortingField === this.state.sortingColumn?.sortingField);

    const items = sortPickupStartOffsetConfigItems(this.props.items, {
      sortingColumn: sortingColumn,
      isDescending: this.state.isDescending,
    });

    return (
      <React.Fragment>
        {this.state.isAddingNewPickupStartOffset ? (
          <CreateNewPickupStartOffsetModal
            accessPoints={this.props.accessPoints}
            items={items}
            onCreate={this.props.onAddItem}
            onDismiss={() => this.setState({ isAddingNewPickupStartOffset: false })}
          />
        ) : null}
        <Table<PickupStartOffsetConfigItem>
          header={this.renderHeader()}
          wrapLines={true}
          columnDefinitions={columns}
          items={items}
          selectionType={this.props.isEditing ? 'multi' : undefined}
          onSelectionChange={({ detail }) => this.setState({ selectedItems: detail.selectedItems })}
          selectedItems={this.state.selectedItems}
          onSortingChange={({ detail }) => {
            this.setState({
              isDescending: detail.isDescending,
              sortingColumn: detail.sortingColumn,
            });
          }}
          sortingColumn={sortingColumn}
          sortingDescending={this.state.isDescending}
        />
      </React.Fragment>
    );
  }

  private renderHeader() {
    // "delete" and "add" buttons
    return (
      <Header
        variant="h3"
        actions={
          this.props.isEditing ? (
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                onClick={() => {
                  this.props.onDeleteItems(this.state.selectedItems);
                  this.setState({ selectedItems: [] });
                }}
              >
                Delete
              </Button>
              <Button variant="primary" onClick={() => this.setState({ isAddingNewPickupStartOffset: true })}>
                Add
              </Button>
            </SpaceBetween>
          ) : null
        }
      >
        Access Point Configurations
      </Header>
    );
  }
}

/**
 * Helper function that converts {@link AccessPointConfiguration} to an array of {@link PickupStartOffsetConfigItem}
 */

export function convertAccessPointConfiguration(accessPointConfigs: ReadonlyArray<AccessPointConfiguration>, accessPoints: ReadonlyArray<AccessPoint>): PickupStartOffsetConfigItem[] {
  const result: PickupStartOffsetConfigItem[] = [];

  accessPointConfigs.forEach((apConfig) => {
    const accessPointId = apConfig.accessPointId;
    if (isNil(accessPointId) || accessPointId === 'NOT FOUND') {
      return;
    }

    const accessPoint = accessPoints.find((ap) => ap.accessPointId === apConfig.accessPointId);

    // add the default offset
    if (apConfig.pickUpStartOffsetConfigs.length > 1) {
      console.warn('Invalid length of pickupStartOffsetConfigs, it should be 0 or 1');
    }

    if (apConfig.pickUpStartOffsetConfigs.length > 0) {
      result.push({
        accessPointId: apConfig.accessPointId,
        addressId: accessPoint?.addressId,
        accessPointName: accessPoint?.name,
        slammedTRPickupStartOffset: apConfig.pickUpStartOffsetConfigs[0].slammedOffsetMinutes,
        nonSlammedTRPickupStartOffSet: apConfig.pickUpStartOffsetConfigs[0].nonSlammedOffsetMinutes,
      });
    }

    // add the override offset
    apConfig.pickUpStartOffsetOverrides.forEach((offsetOverride) => {
      result.push({
        accessPointId: apConfig.accessPointId,
        addressId: accessPoint?.addressId,
        accessPointName: accessPoint?.name,
        orderType: offsetOverride.orderType,
        slammedTRPickupStartOffset: offsetOverride?.offsetConfig?.slammedOffsetMinutes,
        nonSlammedTRPickupStartOffSet: offsetOverride?.offsetConfig?.nonSlammedOffsetMinutes,
      });
    });
  });
  return result;
}
