/**
 * path /assignment-service-areas
 */
import React from 'react';
import { ServiceAreaList as MainContent } from '../../common-components/service-area-list';
import { ServiceAreaInfo } from '../../models';
import { GlobalContext } from '../../main-app/global-context';
import { readable } from '../../utilities';
import { commonClient } from '../../http-clients';

export interface AssignmentServiceAreaListProps {}

export interface AssignmentServiceAreaListState {
  readonly isLoading: boolean;
  readonly serviceAreas?: ReadonlyArray<ServiceAreaInfo>;
}

export class AssignmentServiceAreaList extends React.Component<AssignmentServiceAreaListProps, AssignmentServiceAreaListState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: AssignmentServiceAreaListProps) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.setTools('info-panel');
    this.context.updateBreadcrumbItems([
      {
        text: 'Assignment Service Areas',
        href: '/assignment-service-areas',
      },
    ]);
    await this.loadAssignmentServiceAreas();
  }

  private async loadAssignmentServiceAreas() {
    try {
      this.setState({ isLoading: true });
      const lcAllowlistResp = await commonClient.getServiceAreaAllowList('LORD_COMMANDER_SERVICE_AREA_ALLOWLIST');
      const assignmentSaIds = new Set(Object.keys(lcAllowlistResp.serviceAreaAllowList.serviceAreaIdToAllowListMetadataMap));
      const serviceAreaListResp = await commonClient.listServiceAreas();
      this.setState({
        serviceAreas: serviceAreaListResp.serviceAreas.filter((sa) => assignmentSaIds.has(sa.serviceAreaId)),
      });
    } catch (err) {
      this.context.addNotification({
        header: 'Error',
        content: `Failed to load assignment service areas due to ${readable(err)}`,
        type: 'error',
        dismissible: true,
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <MainContent
        header="Assignment Service Areas"
        serviceAreas={this.state.serviceAreas}
        onServiceAreaClick={(serviceArea) => {
          this.context.onGoto(`/assignment-service-areas/${serviceArea.serviceAreaId}`);
        }}
        buildHref={(serviceArea) => `/assignment-service-areas/${serviceArea.serviceAreaId}`}
      />
    );
  }
}
