import React from 'react';
import Container from '@amzn/awsui-components-react-v3/polaris/container';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import Spinner from '@amzn/awsui-components-react-v3/polaris/spinner';
import StatusIndicator from '@amzn/awsui-components-react-v3/polaris/status-indicator';
import { StrictBoolean } from '../../../common-components/strict-boolean';
import { LordCommanderMode } from '../../../models/assignment-models';

interface OperatingModeSelectorProps {
  readonly operatingMode?: LordCommanderMode;
  readonly isEditing: boolean;
  readonly onUpdate: (lordCommanderMode: LordCommanderMode) => void;
}

interface OperatingModeSelectorState {}

export class OperatingModeSelector extends React.Component<OperatingModeSelectorProps, OperatingModeSelectorState> {
  private renderHeader() {
    return (
      <Header variant="h3" description={'Select whether assignment should be enabled or set to shadow mode'}>
        Operating Mode
      </Header>
    );
  }

  // if assignment mode is DISABLED, we should still display when not in edit mode
  private renderContent() {
    if (this.props.operatingMode === undefined) {
      return <Spinner />;
    }

    switch (this.props.operatingMode) {
      case 'DISABLED':
        return (
          <StatusIndicator type="stopped" colorOverride="red">
            Disabled
          </StatusIndicator>
        );
      case 'ENABLED':
        return <StatusIndicator type="success">Enabled</StatusIndicator>;
      case 'SHADOW_MODE':
        return <StatusIndicator type="info">Shadow</StatusIndicator>;
      default:
        return <StatusIndicator type="warning">{this.props.operatingMode}</StatusIndicator>;
    }
  }

  private renderStrictBoolean() {
    return (
      <StrictBoolean<LordCommanderMode>
        message="Enable Assignment Operating Mode"
        enabledChoice={'ENABLED'}
        disabledChoice={'SHADOW_MODE'}
        isEnabled={this.props.operatingMode === 'ENABLED'}
        notifyUpdate={(selectedOperatingMode) => this.props.onUpdate(selectedOperatingMode)}
      />
    );
  }

  render() {
    // if assignment mode is neither ENABLED nor SHADOW_MODE, we should not give the option to edit mode
    const isValidOperatingMode = this.props.operatingMode === 'ENABLED' || this.props.operatingMode === 'SHADOW_MODE';
    return <Container header={this.renderHeader()}>{this.props.isEditing && isValidOperatingMode ? this.renderStrictBoolean() : this.renderContent()}</Container>;
  }
}
