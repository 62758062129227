import React from 'react';
import Modal from '@amzn/awsui-components-react-v3/polaris/modal';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Toggle from '@amzn/awsui-components-react-v3/polaris/toggle';
import { GlobalContext } from './global-context';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import RadioGroup from '@amzn/awsui-components-react-v3/polaris/radio-group';
import { timezoneManager, TimezonePreference } from '../utilities';

import './preferences-style.scss';

interface Props {
  readonly visible: boolean;
  readonly hide: () => void;
}

export class Preferences extends React.Component<Props, {}> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  renderPreferredTimezoneSelection() {
    return (
      <RadioGroup
        value={this.context.timezonePreference}
        items={[
          // todo: because the backend returned service areas don't have timezone, we can't support the station tz in configs manager.
          // { value: 'station_tz', label: 'Station local timezone' },
          { value: 'user_tz', label: `User local timezone: ${timezoneManager.getUserTimezone()}` },
          { value: 'utc_tz', label: `UTC+0` },
        ]}
        onChange={(evt) => {
          this.context.updateTimezonePreference(evt.detail.value as TimezonePreference);
        }}
      />
    );
  }

  render() {
    return (
      <Modal header="Preferences" visible={this.props.visible} onDismiss={this.props.hide}>
        <SpaceBetween direction="vertical" size="s">
          <Box className="item">
            <Box className="item-name item-name-text">Dark mode</Box>
            <Box className="item-value item-value-toggle">
              <Toggle
                checked={this.context.uiMode === 'dark'}
                onChange={(event) => {
                  this.context.updateUIMode(event.detail.checked ? 'dark' : 'light');
                }}
              />
            </Box>
          </Box>
          <Box className="item">
            <Box className="item-name item-name-text">Compact mode</Box>
            <Box className="item-value item-value-toggle">
              <Toggle
                checked={this.context.density === 'compact'}
                onChange={(event) => {
                  this.context.updateDensity(event.detail.checked ? 'compact' : 'comfortable');
                }}
              />
            </Box>
          </Box>
          <Box className="item">
            <Box className="item-name item-name-text">Visual Refresh</Box>
            <Box className="item-value item-value-toggle">
              <Toggle
                checked={this.context.theme === 'visual-refresh'}
                onChange={(event) => {
                  this.context.updateTheme(event.detail.checked ? 'visual-refresh' : 'regular');
                }}
              />
            </Box>
          </Box>
          <Box className="item item-vertical">
            <Box className="item-name item-name-text">Preferred Timezone</Box>
            <Box className="item-value item-value-radio-button">{this.renderPreferredTimezoneSelection()}</Box>
          </Box>
        </SpaceBetween>
      </Modal>
    );
  }
}
