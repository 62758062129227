import React from 'react';

import Alert from '@amzn/awsui-components-react-v3/polaris/alert';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Grid from '@amzn/awsui-components-react-v3/polaris/grid';
import Container from '@amzn/awsui-components-react-v3/polaris/container';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import TextEditor from '../../common-components/text-editor';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import Spinner from '@amzn/awsui-components-react-v3/polaris/spinner';
import Table from '@amzn/awsui-components-react-v3/polaris/table';
import Autosuggest from '@amzn/awsui-components-react-v3/polaris/autosuggest';
import Toggle from '@amzn/awsui-components-react-v3/polaris/toggle';
import Icon from '@amzn/awsui-components-react-v3/polaris/icon';

import { GlobalContext } from '../../main-app/global-context';
import { isNil, readable } from '../../utilities';
import { commonClient, routingClient } from '../../http-clients';

import { AreaInformation, FlipperState } from './models';

export class FeatureFlagFlipper extends React.Component<{}, FlipperState> {
  static contextType = GlobalContext;
  declare context: React.ContextType<typeof GlobalContext>;

  constructor(props: {}) {
    super(props);
    this.state = {
      isLoadingOverview: true,
      isLoadingConfigs: false,
      uploadingStatus: 'NotYet',
      possibleAreaIdsToCodesMap: new Map(),
      idToAreaInformation: new Map(),
      numberInvalidAreas: 0,
      selectedFlag: '',
      mode: 'ChoosingAreas',
      inputText: '',
      targetValue: false,
      errorMessage: undefined,
    };
  }

  private async resetFlow() {
    this.setState({
      isLoadingOverview: true,
      isLoadingConfigs: false,
      uploadingStatus: 'NotYet',
      possibleAreaIdsToCodesMap: new Map(),
      idToAreaInformation: new Map(),
      numberInvalidAreas: 0,
      selectedFlag: '',
      mode: 'ChoosingAreas',
      inputText: '',
      targetValue: false,
      errorMessage: undefined,
    });
    await this.fetchAreaList();
  }

  async componentDidMount() {
    this.context.resetLayout();
    this.context.setTools('info-panel');

    this.context.updateBreadcrumbItems([
      {
        text: 'Feature Flag Flipper',
        href: '/routing-feature-flag-flipper',
      },
    ]);

    await this.fetchAreaList();
  }

  private targetStateString() {
    return this.state.targetValue ? 'TRUE' : 'FALSE';
  }

  private async fetchAreaList() {
    this.setState({ isLoadingOverview: true });

    try {
      const serviceAreaListResp = await commonClient.listServiceAreas();
      const routingServiceAreasResp = await routingClient.listRoutingServiceAreas();
      const routingSaIds = new Set(routingServiceAreasResp.serviceAreaIds);
      this.setState({
        possibleAreaIdsToCodesMap: new Map(serviceAreaListResp.serviceAreas.filter((sa) => routingSaIds.has(sa.serviceAreaId)).map((area) => [area.serviceAreaId, area.stationCode])),
      });
    } catch (err) {
      this.setState({ errorMessage: `Failed to load area list due to ${readable(err)}` });
    } finally {
      this.setState({ isLoadingOverview: false });
    }
  }

  private async fetchConfigs() {
    try {
      this.setState({ isLoadingConfigs: true });

      const areaInformation = Array.from(this.state.idToAreaInformation.values());
      const newState = new Map<String, AreaInformation>();

      await Promise.all(
        areaInformation.map(async (info) => {
          const response = await routingClient.getRoutingServiceAreaDetails({ serviceAreaId: info.serviceAreaId, type: 'ALL' });
          newState.set(info.serviceAreaId, { ...info, response });
        }),
      );

      this.setState({ idToAreaInformation: newState });
    } catch (err) {
      this.setState({ errorMessage: `Failed to load configurations due to ${readable(err)}` });
    } finally {
      this.setState({ isLoadingConfigs: false });
    }
  }

  private listPossibleFlags(): string[] {
    if (this.state.idToAreaInformation.size === 0 || this.state.isLoadingConfigs === true) {
      return [];
    }

    const areaInformation = Array.from(this.state.idToAreaInformation.values());

    const featureFlagList = areaInformation.map((info) => {
      return info.response?.serviceAreaConfig.featureFlags;
    });

    const allPossibleFlags = new Set<string>();
    for (const flagGroup of featureFlagList) {
      for (const key in flagGroup) {
        allPossibleFlags.add(key);
      }
    }

    return Array.from(allPossibleFlags).sort();
  }

  private getFlagState(serviceAreaId: string): boolean | 'No Flags Present' {
    const areaInformation = this.state.idToAreaInformation.get(serviceAreaId);
    if (isNil(areaInformation)) {
      return 'No Flags Present';
    }

    const featureFlags = areaInformation.response?.serviceAreaConfig.featureFlags;
    if (isNil(featureFlags)) {
      return 'No Flags Present';
    }

    return featureFlags[this.state.selectedFlag];
  }

  render() {
    return (
      <SpaceBetween direction="vertical" size="m">
        {this.renderErrorMessage()}
        <Container
          header={
            <Header
              variant="h1"
              actions={
                <Button variant="normal" onClick={async () => await this.resetFlow()}>
                  Reset Controls
                </Button>
              }
            >
              Feature Flag Flipper
            </Header>
          }
        >
          <Grid gridDefinition={[{ colspan: { default: 12, s: 7 } }, { colspan: { default: 12, s: 5 } }]}>
            <SpaceBetween direction="vertical" size="l">
              <Box variant="h2">Service Areas:</Box>
              {this.state.mode === 'ChoosingAreas' ? this.renderEditor() : this.renderTable()}
            </SpaceBetween>
            <SpaceBetween direction="vertical" size="l">
              <Box variant="h2">Control Panel:</Box>
              {this.renderAreaSelectionControls()}
              {this.renderFlagSelectionControls()}
              {this.renderConfirmationControls()}
            </SpaceBetween>
          </Grid>
        </Container>
      </SpaceBetween>
    );
  }

  private renderErrorMessage() {
    if (isNil(this.state.errorMessage)) {
      return null;
    }

    return (
      <Alert header="Error" type="error" dismissible={true} dismissAriaLabel="Close Alert" onDismiss={() => this.setState({ errorMessage: undefined })}>
        {this.state.errorMessage}
      </Alert>
    );
  }

  private renderEditor() {
    return (
      <TextEditor
        data={this.state.inputText}
        minLine={30}
        maxLine={35}
        onChange={(newText: string) => {
          this.setState({ inputText: newText });

          const everyLine: ReadonlyArray<string> = newText.trim().split('\n');

          const areaInformation = new Map();
          const seenIds = new Set();
          let numInvalid = 0;
          let numDuplicate = 0;

          for (const serviceAreaId of everyLine) {
            const stationCode = this.state.possibleAreaIdsToCodesMap.get(serviceAreaId);
            if (!stationCode) {
              numInvalid += 1;
              continue;
            }

            if (seenIds.has(serviceAreaId)) {
              numDuplicate += 1;
              continue;
            }
            seenIds.add(serviceAreaId);
            areaInformation.set(serviceAreaId, { serviceAreaId, stationCode });
          }

          const validLines = everyLine.filter((id) => this.state.possibleAreaIdsToCodesMap.has(id));

          this.setState({
            idToAreaInformation: areaInformation,
            numberInvalidAreas: everyLine.length - validLines.length,
          });
        }}
      />
    );
  }

  private renderTable() {
    if (this.state.isLoadingConfigs) {
      return (
        <Box padding={'xl'}>
          <Spinner size="large" />
        </Box>
      );
    }

    return (
      <Table
        columnDefinitions={[
          {
            id: 'stationCode',
            header: 'Station Code',
            cell: (item) => {
              return (
                <SpaceBetween direction="horizontal" size="s">
                  {isNil(item.response) || isNil(item.response.serviceAreaConfig.featureFlags) ? <Icon variant="warning" alt="flags not defined" /> : null}
                  <Box>{item.stationCode}</Box>
                </SpaceBetween>
              );
            },
            isRowHeader: true,
          },
          {
            id: 'currentValue',
            header: this.state.selectedFlag === '' ? 'No flag selected' : this.state.selectedFlag,
            cell: (item) => {
              const raw = this.getFlagState(item.serviceAreaId);
              if (raw === undefined) {
                return 'N/A';
              }
              return raw ? 'true' : 'false';
            },
          },
        ]}
        items={Array.from(this.state.idToAreaInformation.values())}
      />
    );
  }

  private renderAreaSelectionControls() {
    return (
      <Container
        header={
          <Header
            variant="h2"
            actions={
              <Button
                variant="primary"
                disabled={!(this.state.mode === 'ChoosingAreas' && this.state.numberInvalidAreas === 0 && this.state.idToAreaInformation.size > 0)}
                onClick={async () => {
                  this.setState({ mode: 'ChoosingFlag' });
                  await this.fetchConfigs();
                }}
              >
                Proceed
              </Button>
            }
          >
            1: Select Service Areas
          </Header>
        }
      >
        {this.renderAreaValidationInfo()}
      </Container>
    );
  }

  private renderAreaValidationInfo() {
    if (this.state.inputText.length === 0) {
      return <Box>Nothing yet entered. Paste service area ids on the left, keeping 1 id per row. Service area ids are generaly long form, like "b4b6e686-5cd7-43ca-bf1b-5a62a4cf404d".</Box>;
    } else if (this.state.isLoadingOverview) {
      return (
        <SpaceBetween direction="horizontal" size="s">
          <Spinner size="normal" />
          <Box>Loading Service Area List</Box>
        </SpaceBetween>
      );
    } else if (this.state.possibleAreaIdsToCodesMap.size === 0) {
      return <Box color="text-status-error">Error: Loading timeout. Try refreshing page.</Box>;
    } else if (this.state.numberInvalidAreas === 0) {
      return <Box color={this.state.mode === 'ChoosingAreas' ? 'text-status-success' : 'text-status-inactive'}>All {this.state.idToAreaInformation.size} unique ids are valid.</Box>;
    } else if (this.state.numberInvalidAreas <= 5) {
      let invalidIds = this.state.inputText
        .trim()
        .split('\n')
        .filter((id) => !this.state.possibleAreaIdsToCodesMap.has(id));
      return <Box color="text-status-error">Error with following ids: {invalidIds.join(', ')}</Box>;
    } else if (this.state.numberInvalidAreas > 5) {
      return <Box color="text-status-error">Error: {this.state.numberInvalidAreas} invalid service area ids.</Box>;
    }
  }

  private renderFlagSelectionControls() {
    const canUseControls = this.state.mode === 'ChoosingFlag' && this.state.idToAreaInformation.size > 0 && this.state.isLoadingConfigs === false;

    const possibleFlags = this.listPossibleFlags();

    const canProceed = this.state.mode === 'ChoosingFlag' && this.state.isLoadingConfigs === false && this.state.selectedFlag.length > 0 && possibleFlags.includes(this.state.selectedFlag);

    return (
      <Container
        header={
          <Header
            variant="h2"
            actions={
              <Button disabled={!canProceed} variant="primary" onClick={() => this.setState({ mode: 'Confirming' })}>
                Proceed
              </Button>
            }
          >
            2: Select Feature Flag
          </Header>
        }
      >
        <SpaceBetween direction="vertical" size="m">
          <Autosuggest
            disabled={!canUseControls}
            onChange={(event) => this.setState({ selectedFlag: event.detail.value })}
            value={this.state.selectedFlag}
            options={possibleFlags.map((flag) => ({ value: flag }))}
            placeholder="No selection"
            empty="No flags present on these areas"
          />
          <SpaceBetween direction="horizontal" size="xs">
            <Box>To:</Box>
            <Toggle disabled={!canUseControls} checked={this.state.targetValue} onChange={(event) => this.setState({ targetValue: event.detail.checked })}>
              ({this.targetStateString()})
            </Toggle>
          </SpaceBetween>
        </SpaceBetween>
      </Container>
    );
  }

  private renderConfirmationControls() {
    let areasAffected = 0;
    let areasFromUndefined = 0;
    let areasAlreadySet = 0;

    Array.from(this.state.idToAreaInformation.values()).forEach((info) => {
      const currentFlag = this.getFlagState(info.serviceAreaId);
      if (currentFlag === undefined) {
        areasFromUndefined += 1;
      } else if (currentFlag !== this.state.targetValue) {
        areasAffected += 1;
      } else {
        areasAlreadySet += 1;
      }
    });

    let contents = (
      <SpaceBetween direction="horizontal" size="m">
        <SpaceBetween direction="vertical" size="s">
          <Box>{`${areasAffected} areas will be affected`}</Box>
          {areasFromUndefined > 0 ? <Box>{`...of which ${areasFromUndefined} were previously undefined`}</Box> : undefined}
          <Box>{`${areasAlreadySet} areas have flag already set.`}</Box>
        </SpaceBetween>
      </SpaceBetween>
    );

    if (this.state.uploadingStatus === 'NotYet') {
      if (this.state.mode === 'ChoosingAreas' || this.state.isLoadingConfigs || this.state.selectedFlag === '') {
        contents = <Box color="text-status-inactive">Select service areas first.</Box>;
      }
    } else if (this.state.uploadingStatus === 'Active') {
      contents = (
        <SpaceBetween direction="horizontal" size="m">
          <Spinner size="normal" />
          <Box>Uploading Changes...</Box>
        </SpaceBetween>
      );
    }

    const buttonEnabled = this.state.mode === 'Confirming' && areasAffected > 0 && this.state.uploadingStatus !== 'Done';

    let action = null;
    if (this.state.uploadingStatus === 'Done') {
      action = (
        <SpaceBetween direction="horizontal" size="s">
          <Box color="text-status-success">Complete</Box>
          <Icon variant="success" />
        </SpaceBetween>
      );
    } else {
      action = (
        <Button variant={buttonEnabled ? 'primary' : 'normal'} disabled={!buttonEnabled} onClick={async () => this.submitChanges()}>
          Confirm
        </Button>
      );
    }

    let header = (
      <Header variant="h2" actions={action}>
        3: Check and Confirm
      </Header>
    );
    return <Container header={header}>{contents}</Container>;
  }

  private async submitChanges() {
    const validAreas = Array.from(this.state.idToAreaInformation.values());
    const message = `Flag ${this.state.selectedFlag} set to ${this.targetStateString()} at ${validAreas.length} sites.`;

    this.setState({ uploadingStatus: 'Active' });

    await Promise.all(
      validAreas.map(async (info) => {
        if (isNil(info.response) || isNil(info.response!.serviceAreaConfig.featureFlags)) {
          return; // no pre-existing flag object, skip
        }

        let currentFlag = this.getFlagState(info.serviceAreaId);
        if (currentFlag === this.state.targetValue) {
          return; // flag already set as desired, no change needed
        }

        info.response.serviceAreaConfig.featureFlags![this.state.selectedFlag] = this.state.targetValue;

        await routingClient.putRoutingServiceAreaDetails({
          serviceAreaId: info.serviceAreaId,
          description: message,
          serviceAreaMetadata: info.response.serviceAreaMetadata,
          algorithmSettings: info.response.algorithmSettings,
          serviceAreaConfig: info.response.serviceAreaConfig,
        });
      }),
    );

    this.setState({ uploadingStatus: 'Done' });
  }
}
