import { ConfigTableDefinition } from '../../../models';

export const ROUTE_CONFIGURATIONS: ConfigTableDefinition = {
  headerText: 'Route Configurations',
  description: 'Control route input to assignment algorithm.',
  valueColMinWidth: '150px',
  sizeOnGrid: 'default',
  visibility: 'developer-only',
  items: [
    {
      field: 'useRouteSnapshot',
      scope: 'assignmentConfig',
      name: 'Use route snapshot',
      description: 'Get routes from route snapshot instead of calling route store.',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'populateDispatchWindowFromTransporterEnabled',
      scope: 'assignmentConfig',
      name: 'Populate dispatch window',
      description: 'To support AMZL DPO, populate route dispatch window to be the wave of the planned transporter.',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'lastWaveDurationInMinutes',
      scope: 'assignmentConfig',
      name: 'Last wave duration',
      description: "The value configures the last wave's duration in the same cycle.",
      representationType: 'number',
      visibility: 'developer-only',
      validators: [
        {
          name: 'ValueBetween0And90Validator',
        },
      ],
    },
    {
      field: 'markAllTrsAsPickupReady',
      scope: 'assignmentConfig',
      name: 'Mark TRs as pickup ready',
      description: 'AMZL assignment assumes all the routes are staged and ready for pickup.',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'addInitialTransitActionEnabled',
      scope: 'assignmentConfig',
      name: 'Add initial Transit Action',
      description: 'Add an initial Transit Action if there is none after Start',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'filterOutPristineGroceryRoutes',
      scope: 'assignmentConfig',
      name: 'Filter out pristine grocery routes',
      description: 'Filter out pristine grocery routes at commingling sites as auto assign is not supported yet.',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'populateRouteProgramType',
      scope: 'assignmentConfig',
      name: 'Populate RouteProgramType',
      description: 'Populate RouteProgramType, such as PRISTINE_SSD, COMMINGLED_SSD_GROCERY.',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'manipulateReturnToStationTransitTimeEnabled',
      scope: 'assignmentConfig',
      name: 'Include RTS duration',
      description: 'To ask FDP to accommodate AMZL route RTS requirement, we move the final RTS duration to the final stop action.',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'backfillRouteActionsEnabled',
      scope: 'assignmentConfig',
      name: 'Backfill route actions',
      description: "To run FDP, we need backfill AMZL routes with a START action. And update transit action's addressId to be the start location's addressId.",
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'badTransportRequestRouteFilterEnabled',
      scope: 'assignmentConfig',
      name: 'Filter out routes with bad trs',
      description: 'AMZL routes may have trs with missing orderId or other info, which can cause FDP failure.',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'badRoutePickupAddressIdFilterEnabled',
      scope: 'assignmentConfig',
      name: 'Filter out routes with bad Trs',
      description: "AMZL routes may have an invalid pickup addressId that does not match any driver's start location.",
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'previousCycleRouteFilterEnabled',
      scope: 'assignmentConfig',
      name: 'Filter out routes with duplicated trs',
      description: 'Due to AMZL operations, multiple routes may same tr. We should only keep the latest route, otherwise, it can cause FDP failure.',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
    {
      field: 'useAutoAssignRouteDenylist',
      scope: 'assignmentConfig',
      name: 'Use Route Deny List',
      description: 'Filter out routes from assignment planning based on route denylist',
      representationType: 'boolean',
      visibility: 'developer-only',
    },
  ],
};
