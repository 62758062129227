import React from 'react';
import Modal from '@amzn/awsui-components-react-v3/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import PermissionTable from '../permission-table';
import { PermissionTableProps } from '../permission-table/permission-table';

export interface PermissionTableModalProps {
  readonly message: string;
  readonly onDismiss: () => void;
  readonly access: PermissionTableProps['access'];
}

export function PermissionTableModal(props: PermissionTableModalProps) {
  return (
    <Modal visible={true} header="Request Permission" onDismiss={() => props.onDismiss()} size="large">
      <SpaceBetween direction="vertical" size="m">
        <Box>{props.message}</Box>
        <PermissionTable access={props.access} />
      </SpaceBetween>
    </Modal>
  );
}
