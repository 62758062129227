import React from 'react';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Table, { TableProps } from '@amzn/awsui-components-react-v3/polaris/table';
import { ServiceArea } from './models';
import { buildTableDefinition } from './build-table-definition';
import { sortConfigScheduleItems } from './utilities';
import { configScheduleClient } from '../../http-clients';
import { AddScheduleModal } from './add-schedule-modal';
import { DeleteConfirmationModal } from './delete-confirmation-modal';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import { ConfigScheduleInfo } from '../../http-clients/config-schedule-client';

interface ConfigSettingsTableProps {
  // current configuration
  readonly items: ReadonlyArray<ConfigScheduleInfo>;
  readonly scopeId: string;
  readonly serviceArea?: ServiceArea;
  readonly onValueEdited: (newValue: string) => void;
  readonly onSubmitSuccess: (id: string) => void;
  readonly onError: (error: string) => void;
}

interface ConfigSettingsTableState {
  readonly selectedItems: ReadonlyArray<ConfigScheduleInfo>;
  readonly isDescending?: boolean;
  readonly sortingColumn?: TableProps.SortingColumn<ConfigScheduleInfo>;
  readonly isTableInEditing: boolean;
  readonly enableAddConfigButton: boolean;
  readonly showConfirmationModal: boolean;
  readonly showDeleteScheduleModal: boolean;
  readonly editedValues: Record<string, string>;
}

export class ConfigSettingsTable extends React.Component<ConfigSettingsTableProps, ConfigSettingsTableState> {
  constructor(props: ConfigSettingsTableProps) {
    super(props);
    this.state = {
      selectedItems: [],
      isDescending: false,
      isTableInEditing: false,
      editedValues: {},
      enableAddConfigButton: true,
      showConfirmationModal: false,
      showDeleteScheduleModal: false,
    };
  }

  handleValueEdited = (scopeId: string, editedValue: string, item: ConfigScheduleInfo) => {
    this.setState({
      editedValues: {
        [scopeId]: editedValue,
      },
    });
    this.setState({ selectedItems: [item] });
  };

  handleChildSubmitSuccess = (scopeId: string) => {
    this.setState({ showConfirmationModal: false });
    const { onSubmitSuccess } = this.props;
    onSubmitSuccess(scopeId);
    this.setState({ selectedItems: [] });
    this.setState({ isTableInEditing: false });
  };

  render() {
    const columns = buildTableDefinition({
      onValueEdited: this.handleValueEdited,
    });

    const sortingColumn = columns.find((col) => col.sortingField !== undefined && col.sortingField === this.state.sortingColumn?.sortingField);

    const items = sortConfigScheduleItems(this.props.items, {
      sortingColumn: sortingColumn,
      isDescending: this.state.isDescending,
    });

    return (
      <React.Fragment>
        {this.renderAddScheduleModal()}
        {this.renderDeleteModal()}
        <Table<ConfigScheduleInfo>
          header={this.renderHeader()}
          wrapLines={true}
          columnDefinitions={columns}
          items={items}
          selectionType="multi"
          onSelectionChange={({ detail }) => {
            if (detail.selectedItems.length > 0) {
              this.setState({ selectedItems: detail.selectedItems, isTableInEditing: true });
            } else {
              this.setState({ selectedItems: detail.selectedItems, isTableInEditing: false });
            }
          }}
          selectedItems={this.state.selectedItems}
          enableKeyboardNavigation
          trackBy="schedule"
          onSortingChange={({ detail }) => {
            this.setState({
              isDescending: detail.isDescending,
              sortingColumn: detail.sortingColumn,
            });
          }}
          sortingColumn={sortingColumn}
          sortingDescending={this.state.isDescending}
          submitEdit={async () => {
            const { editedValues, selectedItems } = this.state;
            if (!editedValues || !selectedItems.length) {
              return;
            }
            const { scopeId, schedule } = selectedItems[0];
            if (schedule === undefined) {
              this.props.onError('Schedule value is empty.');
              return;
            }
            const payload = {
              scopeId,
              schedule,
              editedValue: editedValues,
            };
            try {
              await configScheduleClient.updateConfigSchedules(payload);
              this.handleChildSubmitSuccess(scopeId);
            } catch (error) {
              this.props.onError('Item with same day and time setting already exists.');
              throw error;
            }
          }}
          empty={
            <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
              <SpaceBetween size="m">
                <b>No schedules</b>
              </SpaceBetween>
            </Box>
          }
        />
      </React.Fragment>
    );
  }

  private renderAddScheduleModal() {
    if (this.state.showConfirmationModal) {
      return (
        <AddScheduleModal
          onDismiss={() => this.setState({ showConfirmationModal: false })}
          onSubmitSuccess={this.handleChildSubmitSuccess}
          scopeId={this.props.scopeId}
          stationCode={this.props.serviceArea?.stationCode}
        />
      );
    }
  }

  private renderDeleteModal() {
    if (this.state.showDeleteScheduleModal) {
      return <DeleteConfirmationModal onDismiss={() => this.setState({ showDeleteScheduleModal: false })} selectedItems={this.state.selectedItems} onSubmitSuccess={this.handleChildSubmitSuccess} />;
    }
  }

  private renderHeader() {
    return (
      <Header
        variant="h3"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button disabled={!this.state.isTableInEditing} onClick={() => this.setState({ showDeleteScheduleModal: true })}>
              Delete
            </Button>
            <Button onClick={() => this.setState({ showConfirmationModal: true })}>Add Config</Button>
          </SpaceBetween>
        }
        description={this.props.scopeId}
      >
        {this.props.serviceArea?.stationCode ?? this.props.scopeId}
      </Header>
    );
  }
}
