import { InputUpdate } from '../../../common-components/input-cell';
import { GSFRoutingConfigurations } from '../../../models/routing-models';
import { isNil } from '../../../utilities';

/**
 * Update configurations in a shadow copy
 * @param update
 * @param serviceAreaConfig
 * @param algorithmSettings
 * @returns
 */
export function updateGSFRoutingConfigurations(update: InputUpdate, configs: GSFRoutingConfigurations): GSFRoutingConfigurations {
  if (update.scope === undefined || update.scope === 'serviceAreaConfig') {
    const fields = update.field.split('.');
    // use a shadow copy to make the update
    const newServiceAreaConfig = { ...configs.serviceAreaConfig } as any;
    let directParent = newServiceAreaConfig;
    for (let i = 0; i < fields.length - 1; i++) {
      if (isNil(directParent[fields[i]])) {
        directParent[fields[i]] = {};
      }
      directParent = directParent[fields[i]];
    }
    directParent[fields[fields.length - 1]] = update.newValue;

    return {
      serviceAreaConfig: newServiceAreaConfig,
      algorithmSettings: configs.algorithmSettings,
    };
  } else if (update.scope === 'algorithmSettings') {
    const newAlgorithmSettings = { ...configs.algorithmSettings } as any;
    let directParent = newAlgorithmSettings;

    if (update.field === 'algorithm') {
      directParent['algorithm'] = update.newValue;
    } else if (update.field.startsWith('parameters.')) {
      if (isNil(directParent['parameters'])) {
        directParent['parameters'] = {};
      }
      const parameterKey = update.field.slice('parameters.'.length, update.field.length);
      if (isNil(update.newValue)) {
        delete directParent['parameters'][parameterKey];
      } else {
        directParent['parameters'][parameterKey] = update.newValue;
      }
    }

    return {
      serviceAreaConfig: configs.serviceAreaConfig,
      algorithmSettings: newAlgorithmSettings,
    };
  } else {
    console.warn(`unknown configuration scope ${update.scope}`);
    return configs;
  }
}
