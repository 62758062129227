import { InputUpdate } from '../../../common-components/input-cell';
import { AssignmentConfig } from '../../../models/assignment-models';
import { isNil } from '../../../utilities';

export function updateAssignmentConfigurations(update: InputUpdate, configs: AssignmentConfig): AssignmentConfig {
  if (update.scope === undefined || update.scope === 'assignmentConfig') {
    const fields = update.field.split('.');
    // use a shadow copy to make the update
    const newAssignmentConfig = { ...configs } as any;
    let directParent = newAssignmentConfig;
    for (let i = 0; i < fields.length - 1; i++) {
      if (isNil(directParent[fields[i]])) {
        directParent[fields[i]] = {};
      }
      directParent = directParent[fields[i]];
    }
    directParent[fields[fields.length - 1]] = update.newValue;

    return newAssignmentConfig;
  } else {
    console.warn(`unknown assignment configuration scope ${update.scope}`);
    return configs;
  }
}
