// https://code.amazon.com/packages/AWS-UI-Examples/blobs/mainline-3.0/--/src/common/clipboard.js

/**
 * Copy text to clipboard
 * @param text
 */
export async function copyToClipboard(text: string) {
  try {
    // Try to use the modern clipboard API.
    // Some browsers only allow this API in response to a user initiated event.
    await navigator.clipboard.writeText(text);
  } catch {
    // Fall back to using a textarea. Making it asynchronous to align with clipboard API
    // https://stackoverflow.com/a/30810322/898577
    const activeElement = document.activeElement;
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
    } finally {
      document.body.removeChild(textArea);
      (activeElement as any).focus();
    }
  }
}
