import React from 'react';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import Alert from '@amzn/awsui-components-react-v3/polaris/alert';
import Link from '@amzn/awsui-components-react-v3/polaris/link';

import { ApplyProfileResults as ApplyProfileResults_, OperatingMode } from './models';

export interface ApplyProfileResultsProps {
  readonly operatingMode: OperatingMode;
  readonly results: ApplyProfileResults_;
  readonly errorMessage?: string;
  readonly onDismissError: () => void;
  readonly onDismissSucceededServiceAreas: () => void;
  readonly onDismissFailedServiceAreas: () => void;
  readonly onDismissUrl: () => void;
}

export interface ApplyProfileResultsState {}

export class ApplyProfileResults extends React.Component<ApplyProfileResultsProps, ApplyProfileResultsState> {
  constructor(props: ApplyProfileResultsProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <SpaceBetween direction="vertical" size="m">
        {this.renderErrorMessage()}
        {this.renderSucceededServiceAreas()}
        {this.renderFailedServiceAreas()}
        {this.renderUrlToOpen()}
      </SpaceBetween>
    );
  }

  private renderErrorMessage() {
    if (typeof this.props.errorMessage === 'string') {
      return (
        <Alert header="Error" dismissible={true} onDismiss={() => this.props.onDismissError()} type="error">
          <Box>{this.props.errorMessage}</Box>
        </Alert>
      );
    }
  }
  private renderSucceededServiceAreas() {
    if (this.props.results.succeededServiceAreaIds !== undefined && this.props.results.succeededServiceAreaIds.length > 0) {
      let summary: string;
      if (this.props.operatingMode === 'launch') {
        summary = `Successfully launched ${this.props.results.succeededServiceAreaIds.length} service areas`;
      } else {
        summary = `Successfully applied config profile on ${this.props.results.succeededServiceAreaIds.length} service areas`;
      }
      return (
        <Alert header="Succee" dismissible={true} onDismiss={() => this.props.onDismissSucceededServiceAreas()} type="success">
          <Box>{summary}</Box>
          {this.props.results.succeededServiceAreaIds.map((id, index) => {
            return (
              <Box key={index}>
                {index + 1}. {id}
              </Box>
            );
          })}
        </Alert>
      );
    }
  }

  private renderFailedServiceAreas() {
    if (this.props.results.failedServiceAreaIdAndReasons !== undefined) {
      const entries = Array.from(Object.entries(this.props.results.failedServiceAreaIdAndReasons));
      if (entries.length > 0) {
        return (
          <Alert header={'Failure'} dismissible={true} type="error">
            {entries.map((entry, index) => {
              return (
                <Box key={index}>
                  {index + 1}. {entry[0]}: {entry[1]}
                </Box>
              );
            })}
          </Alert>
        );
      }
    }
  }

  private renderUrlToOpen() {
    if (typeof this.props.results.url === 'string') {
      return (
        <Alert header={'Reference'} dismissible={true} type="info">
          <Box>
            Reference <Link external={true}>{this.props.results.url}</Link>
          </Box>
        </Alert>
      );
    }
  }
}
